// generated with @7nohe/openapi-react-query-codegen@1.6.2

import { UseQueryResult } from '@tanstack/react-query';
import { DefaultService } from '../requests/services.gen';
import {
  CandleField,
  CandleFilter,
  CandleOption,
  HiroStreamString,
  Order,
} from '../requests/types.gen';
export type DefaultServiceGetAuthDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getAuth>
>;
export type DefaultServiceGetAuthQueryResult<
  TData = DefaultServiceGetAuthDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetAuthKey = 'DefaultServiceGetAuth';
export const UseDefaultServiceGetAuthKeyFn = (
  {
    authorization,
  }: {
    authorization: string;
  },
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetAuthKey, ...(queryKey ?? [{ authorization }])];
export type DefaultServiceGetCandlesDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getCandles>
>;
export type DefaultServiceGetCandlesQueryResult<
  TData = DefaultServiceGetCandlesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetCandlesKey = 'DefaultServiceGetCandles';
export const UseDefaultServiceGetCandlesKeyFn = (
  {
    end,
    field,
    filter,
    limit,
    option,
    order,
    start,
    stream,
    sym,
  }: {
    end?: number;
    field?: CandleField;
    filter?: CandleFilter;
    limit?: number;
    option?: CandleOption;
    order?: Order;
    start?: number;
    stream?: HiroStreamString;
    sym: string;
  },
  queryKey?: Array<unknown>,
) => [
  useDefaultServiceGetCandlesKey,
  ...(queryKey ?? [
    { end, field, filter, limit, option, order, start, stream, sym },
  ]),
];
export type DefaultServiceGetSgManifestDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getSgManifest>
>;
export type DefaultServiceGetSgManifestQueryResult<
  TData = DefaultServiceGetSgManifestDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetSgManifestKey = 'DefaultServiceGetSgManifest';
export const UseDefaultServiceGetSgManifestKeyFn = (
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetSgManifestKey, ...(queryKey ?? [])];
export type DefaultServiceGetSgTnsContractsDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getSgTnsContracts>
>;
export type DefaultServiceGetSgTnsContractsQueryResult<
  TData = DefaultServiceGetSgTnsContractsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetSgTnsContractsKey =
  'DefaultServiceGetSgTnsContracts';
export const UseDefaultServiceGetSgTnsContractsKeyFn = (
  {
    filters,
    limit,
    offset,
    sorting,
  }: {
    filters?: string;
    limit?: number;
    offset?: number;
    sorting?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useDefaultServiceGetSgTnsContractsKey,
  ...(queryKey ?? [{ filters, limit, offset, sorting }]),
];
export type DefaultServiceGetSgTnsFeedDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getSgTnsFeed>
>;
export type DefaultServiceGetSgTnsFeedQueryResult<
  TData = DefaultServiceGetSgTnsFeedDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetSgTnsFeedKey = 'DefaultServiceGetSgTnsFeed';
export const UseDefaultServiceGetSgTnsFeedKeyFn = (
  {
    filters,
    limit,
    offset,
    sorting,
  }: {
    filters?: string;
    limit?: number;
    offset?: number;
    sorting?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useDefaultServiceGetSgTnsFeedKey,
  ...(queryKey ?? [{ filters, limit, offset, sorting }]),
];
export type DefaultServiceGetSgTnsFlowSumDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getSgTnsFlowSum>
>;
export type DefaultServiceGetSgTnsFlowSumQueryResult<
  TData = DefaultServiceGetSgTnsFlowSumDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetSgTnsFlowSumKey =
  'DefaultServiceGetSgTnsFlowSum';
export const UseDefaultServiceGetSgTnsFlowSumKeyFn = (
  {
    filters,
  }: {
    filters?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetSgTnsFlowSumKey, ...(queryKey ?? [{ filters }])];
export type DefaultServiceGetSgTnsHighlightsDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getSgTnsHighlights>
>;
export type DefaultServiceGetSgTnsHighlightsQueryResult<
  TData = DefaultServiceGetSgTnsHighlightsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetSgTnsHighlightsKey =
  'DefaultServiceGetSgTnsHighlights';
export const UseDefaultServiceGetSgTnsHighlightsKeyFn = (
  {
    end,
    start,
  }: {
    end?: number;
    start?: number;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useDefaultServiceGetSgTnsHighlightsKey,
  ...(queryKey ?? [{ end, start }]),
];
export type DefaultServiceGetStreamDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getStream>
>;
export type DefaultServiceGetStreamQueryResult<
  TData = DefaultServiceGetStreamDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetStreamKey = 'DefaultServiceGetStream';
export const UseDefaultServiceGetStreamKeyFn = (
  {
    token,
  }: {
    token: string;
  },
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetStreamKey, ...(queryKey ?? [{ token }])];
export type DefaultServiceGetValidateBearerDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getValidateBearer>
>;
export type DefaultServiceGetValidateBearerQueryResult<
  TData = DefaultServiceGetValidateBearerDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetValidateBearerKey =
  'DefaultServiceGetValidateBearer';
export const UseDefaultServiceGetValidateBearerKeyFn = (
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetValidateBearerKey, ...(queryKey ?? [])];
export type DefaultServiceGetVixQuoteDefaultResponse = Awaited<
  ReturnType<typeof DefaultService.getVixQuote>
>;
export type DefaultServiceGetVixQuoteQueryResult<
  TData = DefaultServiceGetVixQuoteDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDefaultServiceGetVixQuoteKey = 'DefaultServiceGetVixQuote';
export const UseDefaultServiceGetVixQuoteKeyFn = (
  {
    dates,
  }: {
    dates?: string[];
  } = {},
  queryKey?: Array<unknown>,
) => [useDefaultServiceGetVixQuoteKey, ...(queryKey ?? [{ dates }])];
