import { useEffect, useState, useRef, useMemo } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Brush,
} from 'recharts';
import useGammaLevels from '../../../hooks/indices/useGammaLevels';
import useBrushZoom from '../../../hooks/useBrushZoom';
import {
  BrushZoomConfig,
  GammaLevel,
  GammaLevelType,
  IndicesContentType,
  RawGammaLevelData,
  SymSelectorSettings,
} from '../../../types';
import {
  TIMESTAMP_TICK_CONFIG,
  formatAsCompactNumber,
  formatAsCurrency,
  getTicksBrushed,
} from '../../../util';
import { getZoomConfigRefArea } from 'util/shared/chart';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import { ZoomOutButton } from 'components/shared';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

const ZOOM_PCT = 0.1; // % of maximum gamma for which we search for to set our default zoom
const getZoomBounds = (data: any[], gammaMode: string) => {
  const putKey = `${gammaMode}_put`;
  const callKey = `${gammaMode}_call`;
  const maxPutIdx = data.reduce((maxIdx, entry, idx) => {
    return (entry[putKey] ?? 0) < (data[maxIdx][putKey] ?? 0) ? idx : maxIdx;
  }, 0);
  const maxCallIdx = data.reduce((maxIdx, entry, idx) => {
    return (entry[callKey] ?? 0) > (data[maxIdx][callKey] ?? 0) ? idx : maxIdx;
  }, 0);

  // Find the leftmost strike that has at least ZOOM_PCT of the maximum gamma
  let leftIdx = 0;
  for (let idx = 0; idx < data.length; ++idx) {
    if (
      data[idx][putKey] < ZOOM_PCT * (data[maxPutIdx][putKey] ?? 0) ||
      data[idx][callKey] > ZOOM_PCT * (data[maxCallIdx][callKey] ?? 0)
    ) {
      leftIdx = idx;
      break;
    }
  }

  // Find the rightmost strike that has at least ZOOM_PCT of the maximum gamma
  let rightIdx = data.length - 1;
  for (let idx = data.length - 1; idx >= 0; --idx) {
    if (
      data[idx][putKey] < ZOOM_PCT * (data[maxPutIdx][putKey] ?? 0) ||
      data[idx][callKey] > ZOOM_PCT * (data[maxCallIdx][callKey] ?? 0)
    ) {
      rightIdx = idx;
      break;
    }
  }

  // Return one-off zoom bounds for UX to avoid chopping off a bar in the middle
  return {
    leftIdx: Math.max(0, leftIdx - 1),
    rightIdx: Math.min(rightIdx + 1, data.length - 1),
  };
};

interface GammaLevelsProps {
  hideTitle?: boolean;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
}

export const GammaLevels = ({
  hideTitle,
  selectedSym,
  symSelectorSettings,
  chartStyleOverrides,
  containerStyleOverrides,
}: GammaLevelsProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const { getGammaLevels } = useGammaLevels();
  const [gammaMode, setGammaMode] = useState<GammaLevelType>(
    GammaLevelType.CURRENT,
  );
  const [gammaData, setGammaData] = useState<GammaLevel[]>([]);
  const [zoomConfig, setZoomConfig] = useState<BrushZoomConfig>(
    DEFAULT_BRUSH_ZOOM_CONFIG,
  );

  const { zoomChartConfig } = useBrushZoom<GammaLevel>(
    zoomConfig,
    setZoomConfig,
    'strike',
    gammaData,
  );

  useEffect(() => {
    async function generateChartData() {
      let data = await getGammaLevels(selectedSym);
      let rawData = data as RawGammaLevelData[];

      const puts: any = rawData.find((d) => d.model === 'absPuts');
      const calls: any = rawData.find((d) => d.model === 'absCalls');
      const putsCurrentMap: Map<number, number> = new Map(
        JSON.parse(puts.current_list),
      );
      const callsCurrentMap: Map<number, number> = new Map(
        JSON.parse(calls.current_list),
      );
      const putsNextExpMap: Map<number, number> = new Map(
        JSON.parse(puts.next_exp_list),
      );
      const callsNextExpMap: Map<number, number> = new Map(
        JSON.parse(calls.next_exp_list),
      );
      const allStrikes = new Set([
        ...putsCurrentMap.keys(),
        ...callsCurrentMap.keys(),
        ...putsNextExpMap.keys(),
        ...callsNextExpMap.keys(),
      ]);

      const transformedData = [...allStrikes]
        .sort()
        .map((strike: number) => {
          const currentCall = callsCurrentMap.get(strike);
          const currentPut = putsCurrentMap.get(strike);
          const nextExpCall = callsNextExpMap.get(strike);
          const nextExpPut = putsNextExpMap.get(strike);
          return {
            strike,
            current_call: currentCall === 0 ? undefined : currentCall,
            current_put: currentPut === 0 ? undefined : currentPut,
            next_exp_call: nextExpCall === 0 ? undefined : nextExpCall,
            next_exp_put: nextExpPut === 0 ? undefined : nextExpPut,
          };
        })
        .filter(
          (e) =>
            e.current_call ||
            e.current_put ||
            e.next_exp_call ||
            e.next_exp_put,
        );

      setGammaData(transformedData);
    }
    generateChartData();
  }, [getGammaLevels, setGammaData, selectedSym]);

  useEffect(() => {
    const defaultZoom = getZoomBounds(gammaData, gammaMode);
    setZoomConfig((prev) => ({
      ...prev,
      data: [...gammaData],
      ...defaultZoom,
    }));
  }, [gammaMode, gammaData, setZoomConfig]);

  const ticks = useMemo(
    () =>
      getTicksBrushed(
        gammaData.map((d) => d.strike),
        zoomConfig,
        TIMESTAMP_TICK_CONFIG,
      ),
    [gammaData, zoomConfig],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        symbol={selectedSym}
        type={IndicesContentType.GAMMA_LEVELS}
        title="Absolute Gamma"
        expandable
        symSelectorSettings={symSelectorSettings}
        hideTitle={hideTitle}
        controllerProps={{
          buttonGroups: [
            {
              buttons: [
                { value: GammaLevelType.CURRENT, label: 'Total' },
                { value: GammaLevelType.NEXT_EXP, label: 'Next Expiration' },
              ],
              setter: setGammaMode,
              curValue: gammaMode,
            },
          ],
        }}
        customController={
          <ZoomOutButton
            zoomConfig={zoomConfig}
            setZoomConfig={setZoomConfig}
            initialData={gammaData}
            overrideDefault={{
              leftIdx: 0,
              rightIdx: gammaData.length - 1,
            }}
          />
        }
      />

      <ChartWatermarkContainer ref={ref} style={{ ...chartStyleOverrides }}>
        <ResponsiveContainer>
          <BarChart
            margin={{
              ...DEFAULT_CHART_MARGINS,
              right: 42,
            }}
            stackOffset="sign"
            barGap={0}
            barCategoryGap="0%"
            barSize={4}
            {...zoomChartConfig}
          >
            <CartesianGrid strokeDasharray="1 10" />
            <XAxis
              allowDataOverflow
              dataKey="strike"
              domain={['dataMin', 'dataMax']}
              tick={{ fontSize: 11 }}
              ticks={ticks}
              tickFormatter={(v: number) => formatAsCurrency(v)}
              label={{
                value: 'Strike',
                ...DEFAULT_X_AXIS_STYLES,
                fontSize: 10,
                offset: 2,
              }}
              type="number"
              interval="preserveStartEnd"
            />
            <YAxis
              allowDataOverflow
              domain={[
                (min: number) => min - Math.abs(min) * 0.05,
                (max: number) => max + Math.abs(max) * 0.05,
              ]}
              tickFormatter={(v: number) => `$${formatAsCompactNumber(v)}`}
              tick={{ fontSize: 11 }}
              label={{
                value: 'Gamma',
                ...DEFAULT_Y_AXIS_STYLES,
              }}
            />
            <Brush
              dataKey="strike"
              tickFormatter={(v: number) => formatAsCurrency(v)}
              startIndex={zoomConfig.leftIdx}
              endIndex={zoomConfig.rightIdx}
              onChange={(brushIndices: any) =>
                setZoomConfig((prev) => ({
                  ...prev,
                  leftIdx: brushIndices.startIndex,
                  rightIdx: brushIndices.endIndex,
                }))
              }
              height={18}
              travellerWidth={15}
              stroke={theme.palette.gray}
              fill={theme.palette.background.paper}
              alwaysShowText
            />
            <Tooltip
              formatter={(v: string) => formatAsCurrency(v)}
              labelFormatter={(value) => `Strike: ${formatAsCurrency(value)}`}
              itemStyle={{ fontSize: '11px' }}
              contentStyle={{
                color: theme.palette.text.primary,
                border: 'none',
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.palette.shadows.paperBoxShadow,
              }}
              separator=": "
            />
            <Bar
              dataKey={`${gammaMode}_put`}
              stackId="gamma"
              name="Put"
              fill={theme.palette.core.put}
            />
            <Bar
              dataKey={`${gammaMode}_call`}
              stackId="gamma"
              name="Call"
              fill={theme.palette.core.call}
            />
            {getZoomConfigRefArea(zoomConfig)}
          </BarChart>
        </ResponsiveContainer>
      </ChartWatermarkContainer>
    </Box>
  );
};
