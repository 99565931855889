import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import useSetSym from 'hooks/hiro/useSetSym';
import { useRecoilValue } from 'recoil';
import { freeSymbolState } from '../states';
import { StrategyCompassWithLayout } from 'components/equityhub/Compass/StrategyCompassWithLayout';

export const StockScannerPage = () => {
  const render = () => <StrategyCompassWithLayout />;
  const freeSym = useRecoilValue(freeSymbolState);
  const { getSym } = useSetSym();
  const sym = getSym(ProductType.HIRO);

  return (
    <AccessProtectedPage
      productType={ProductType.SCANNERS}
      renderContent={render}
      // override default page upsell if no sym param is present OR sym is already set to free sym of day
      overrideCheck={!sym || sym === freeSym}
    />
  );
};
