import {
  Box,
  Button,
  Collapse,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import StockIcon from 'components/StockIcon';
import { LegacyEquity, SynthOIEquity, EquityFieldKey } from 'types';
import { EQUITY_FIELD_LABEL_MAPPING } from 'config/equityhub';
import EquityDetailRow from './EquityDetailRow';
import { formatEquityFieldValue } from 'util/equityhub';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { positiveTrendColorState, negativeTrendColorState } from 'states';
import EquityDetailsEditorBtn from './EquityDetailsEditorBtn';
import { dayjs } from 'util/shared/date';
import { SGTooltip } from 'components/core';

interface EquityDetailsProps {
  equity?: LegacyEquity | SynthOIEquity;
  allEquities?: (LegacyEquity | SynthOIEquity)[];
  isLoading?: boolean;
  isExpanded?: boolean;
  onToggleExpanded?: () => void;
  equityMainViewFields: EquityFieldKey[];
  equityExpandedViewFields: EquityFieldKey[];
  equityOtherFields: EquityFieldKey[];
  onEditFields: (
    mainViewFields: EquityFieldKey[],
    expandedViewFields: EquityFieldKey[],
  ) => void;
}

const EquityDetails = ({
  equity,
  allEquities,
  isLoading,
  isExpanded,
  onToggleExpanded,
  equityMainViewFields,
  equityExpandedViewFields,
  equityOtherFields,
  onEditFields,
}: EquityDetailsProps) => {
  const theme = useTheme();

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const getTrendColor = (field: EquityFieldKey, value: number) => {
    if (field === EquityFieldKey.dailyChange && value != null) {
      return value >= 0 ? serverPositiveTrendColor : serverNegativeTrendColor;
    }

    return undefined;
  };

  const getOptionsImpactGauge = (currentValue: number) => {
    if (!allEquities || currentValue == null) {
      return '-';
    }

    // Extract all valid options impact values
    const allImpactValues = allEquities
      .map((eq) => (eq as SynthOIEquity)[EquityFieldKey.optionsImpact])
      .filter((val) => val != null && !isNaN(val)) as number[];

    if (allImpactValues.length === 0) {
      return null;
    }

    // Sort values to determine percentiles
    const sortedValues = [...allImpactValues].sort((a, b) => a - b);
    const min = sortedValues[0];
    const max = sortedValues[sortedValues.length - 1];

    // Calculate 75th and 90th percentiles
    const p75 = sortedValues[Math.floor(sortedValues.length * 0.75)];
    const p90 = sortedValues[Math.floor(sortedValues.length * 0.9)];

    // Calculate position in the bar (0-100) using linear interpolation within each zone
    let position;
    if (currentValue <= p75) {
      // Red zone (0-50% of bar width) - linear interpolation from min to p75
      position = ((currentValue - min) / (p75 - min)) * 50;
    } else if (currentValue <= p90) {
      // Orange zone (50-75% of bar width) - linear interpolation from p75 to p90
      position = 50 + ((currentValue - p75) / (p90 - p75)) * 25;
    } else {
      // Green zone (75-100% of bar width) - linear interpolation from p90 to max
      position = 75 + ((currentValue - p90) / (max - p90)) * 25;
    }

    // Ensure position is within bounds
    position = Math.max(0, Math.min(100, position));

    return (
      <SGTooltip
        title="This metric quantifies the hedging pressure for a name relative to the volume traded, measuring how impactful options are for each stock."
        arrow
        placement="top"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            minWidth: 160,
            maxWidth: '40%',
            gap: 3,
            cursor: 'help',
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
            }}
          >
            {formatEquityFieldValue(EquityFieldKey.optionsImpact, currentValue)}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ borderColor: theme.palette.text.primary, borderWidth: 1 }}
            flexItem
          />
          <Box sx={{ position: 'relative', height: 16, width: '100%' }}>
            {/* Background bar */}
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              {/* Red section (50%) */}
              <Box
                sx={{
                  width: '50%',
                  height: '100%',
                  bgcolor: alpha(theme.palette.error.main, 0.9),
                }}
              />
              {/* Orange section (25%) */}
              <Box
                sx={{
                  width: '25%',
                  height: '100%',
                  bgcolor: alpha(theme.palette.warning.main, 0.9),
                }}
              />
              {/* Green section (25%) */}
              <Box
                sx={{
                  width: '25%',
                  height: '100%',
                  bgcolor: alpha(theme.palette.success.main, 0.9),
                }}
              />
            </Box>

            {/* Marker */}
            <Box
              sx={{
                position: 'absolute',
                left: `${position}%`,
                top: 0,
                transform: 'translateX(-50%)',
                width: 10,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2, // Ensure marker is above background
              }}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  bgcolor: 'white',
                  border: `2px solid ${theme.palette.grey[900]}`,
                  boxShadow: '0 0 4px rgba(0,0,0,0.5)',
                }}
              />
            </Box>
          </Box>
        </Box>
      </SGTooltip>
    );
  };

  if (isLoading) {
    return (
      <Stack sx={{ gap: 4, width: '100%', height: '100%', padding: '24px' }}>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <Skeleton variant="circular" width={24} height={24} />
          <Stack sx={{ flex: 1 }}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={200} height={20} />
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2 }}>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} width="100%" height={24} />
          ))}
        </Stack>
      </Stack>
    );
  }

  if (!equity) {
    return null;
  }

  return (
    <Stack sx={{ gap: 4, width: '100%', height: '100%', padding: '16px' }}>
      <Stack
        sx={{ flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}
      >
        <Stack sx={{ flexDirection: 'row', gap: 4 }}>
          <StockIcon
            symbol={equity.sym}
            sx={{ width: 24, height: 24, mt: 2 }}
          />
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                textTransform: 'uppercase',
              }}
            >
              {equity.sym}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 12, md: 14 },
                color: 'text.secondary',
              }}
            >
              {equity.name}
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ alignItems: 'flex-end' }}>
          <EquityDetailsEditorBtn
            mainViewFields={equityMainViewFields}
            expandedViewFields={equityExpandedViewFields}
            otherFields={equityOtherFields}
            onSave={onEditFields}
            mainViewMaxLimit={10}
          />
          {equity[EquityFieldKey.quoteDate] && (
            <Typography
              sx={{
                fontSize: 11,
                color: 'text.secondary',
                textAlign: 'right',
              }}
            >
              Date Shown:{' '}
              {dayjs.utc(equity[EquityFieldKey.quoteDate]).format('YYYY-MM-DD')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ overflow: 'auto', px: '8px', gap: '4px' }}>
        {equityMainViewFields.map((field) => (
          <EquityDetailRow
            key={field}
            label={EQUITY_FIELD_LABEL_MAPPING[field]}
            value={
              field === EquityFieldKey.optionsImpact
                ? getOptionsImpactGauge(
                    (equity as SynthOIEquity)[
                      EquityFieldKey.optionsImpact
                    ] as number,
                  )
                : formatEquityFieldValue(
                    field,
                    equity[field as keyof (LegacyEquity | SynthOIEquity)],
                  )
            }
            valueColor={getTrendColor(
              field,
              equity[field as keyof (LegacyEquity | SynthOIEquity)] as number,
            )}
          />
        ))}

        <Collapse in={isExpanded}>
          {equityExpandedViewFields.map((field) => (
            <EquityDetailRow
              key={field}
              label={EQUITY_FIELD_LABEL_MAPPING[field]}
              value={
                field === EquityFieldKey.optionsImpact
                  ? getOptionsImpactGauge(
                      (equity as SynthOIEquity)[
                        EquityFieldKey.optionsImpact
                      ] as number,
                    )
                  : formatEquityFieldValue(
                      field,
                      equity[field as keyof (LegacyEquity | SynthOIEquity)],
                    )
              }
            />
          ))}
        </Collapse>
      </Stack>

      {equityExpandedViewFields.length > 0 && (
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              height: '1.5px',
            }}
          />

          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={onToggleExpanded}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              textTransform: 'none',
            }}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>

          <Box
            sx={{
              flex: 1,
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              height: '1.5px',
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default EquityDetails;
