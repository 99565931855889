import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HistoryTable } from '../EquityhubTable/HistoryTable';
import { SkewGraph } from './SkewGraph';
import { PutCallImpactGraph } from './PutCallImpactGraph';
import { SynthOIPutCallImpactGraph } from './SynthOIPutCallImpactGraph';
import { CompositeViewGraph } from './CompositeViewGraph';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ehCompositeModeState,
  isMobileState,
  liveSgChartDataState,
  putCallImpactInitialDataState,
  putCallImpactZoomConfigState,
  realTimeChartZoomConfigState,
  rrChartZoomConfigState,
  rrInitialDataState,
  selectedEquitySymbolState,
  selectedEquityState,
  synthOIPCImpactZoomCfgState,
  synthOIPCImpactDataState,
  termStructureChartDataState,
  termStructureChartZoomConfigState,
  userDetailsState,
  volSkewChartDataState,
  volSkewChartZoomConfigState,
  synthOILiveChartTypeState,
} from '../../../states';
import { InfoButton, SGSelect, UpsellModal, ZoomOutButton } from '../../shared';
import { RiskReversalGraph } from './RiskReversalGraph';
import { getSubscriptionLevel, updateSearch } from '../../../util';
import { SGTooltip } from '../../core';
import { SymbolTabs } from '../../hiro/HiroChart/controls/SymbolTabs';
import { DEFAULT_BRUSH_ZOOM_CONFIG } from '../../../config';
import { IVOL_UPSELL } from '../../../config/iVol';
import useAuth from '../../../hooks/auth/useAuth';
import {
  EhCompositeMode,
  IVolTab,
  ProductType,
  TOTAL_OI_TAB_KEYS,
  SYNTH_OI_TAB_KEYS,
  SubLevel,
  SynthOIChartType,
  EH_TOTAL_OI_ORDERED_TABS,
  EH_SYNTH_OI_ORDERED_TABS,
  SynthOILiveChartTypeLabels,
  EquityHubTab,
} from '../../../types';
import { FixedStrikeTable } from '../../implied_vol/FixedStrikeMatrix';
import { SynthOIPutCallImpactSettings } from './SynthOIPutCallImpactSettings';
import { FixedStrikeTableSettings } from '../../implied_vol/FixedStrikeMatrix/FixedStrikeTableControls';
import { TermStructureChartSettings } from '../../implied_vol/TermStructure';
import TermStructureChart from '../../implied_vol/TermStructure/TermStructureChart';
import { VolSkewChartSettings } from '../../implied_vol/VolSkew';
import VolSkewChart from '../../implied_vol/VolSkew/VolSkewChart';
import { DownloadChartButton } from '../../shared/DownloadChartButton';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import TabbedContent from 'components/shared/TabbedContent';
import { TabItem } from 'types/shared';
import { LivePriceAndSgLevels } from './LivePriceAndSgLevels';
import { RealTimeGraph } from './RealTimeGraph';
import useSynthOi from 'hooks/equityhub/useSynthOi';

const ivolChartTabs = [
  IVolTab.TermStructure as string,
  IVolTab.VolSkew as string,
];

const ivolTabs = [IVolTab.FixedStrikeMatrix as string, ...ivolChartTabs];

const greekTypeTextTransform = (value: SynthOIChartType) => {
  return SynthOILiveChartTypeLabels.get(value) || '';
};

const COMPOSITE_LABELS = new Map([
  [EhCompositeMode.Gamma, 'Gamma'],
  [EhCompositeMode.Factors, 'Impact'],
]);

const compositeTypeTextTransform = (value: SynthOIChartType) => {
  return COMPOSITE_LABELS.get(value) || '';
};

export const EquityGraph = () => {
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const tsRef = useRef<HTMLDivElement | null>(null);
  const vsRef = useRef<HTMLDivElement | null>(null);
  const [zoomedIn, setZoomedIn] = useState<boolean>(false);
  const [searchParams, setSearch] = useSearchParams();
  const userDetails = useRecoilValue(userDetailsState);

  const { isSynthOI } = useSynthOi();

  const { hasAccessToProduct } = useAuth();
  const hasImpliedVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);
  const membership = useMemo(
    () => getSubscriptionLevel(userDetails),
    [userDetails],
  );

  const currentTab = useMemo(() => {
    const tab = searchParams.get('tab');
    return tab ?? (isSynthOI ? 'pc_impact' : 'composite');
  }, [searchParams, isSynthOI]);

  useEffect(() => {
    if (
      currentTab == null ||
      (!TOTAL_OI_TAB_KEYS.has(currentTab) && !SYNTH_OI_TAB_KEYS.has(currentTab))
    ) {
      setSearch(updateSearch({ tab: isSynthOI ? 'pc_impact' : 'composite' }));
    }
  }, [currentTab, setSearch]);

  useEffect(() => {
    if (ivolTabs.includes(currentTab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    }
  }, [currentTab, hasImpliedVolAccess]);

  const handleChange = (tab: string) => {
    if (ivolTabs.includes(tab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    } else {
      setZoomedIn(false);
      setSearch(updateSearch({ tab }));
    }
  };

  const selectedEquity = useRecoilValue(selectedEquityState);
  const selectedSymbol = useRecoilValue(selectedEquitySymbolState);
  const [showKeyLevels, setShowKeyLevels] = useState(true);
  const isMobile = useRecoilValue(isMobileState);
  const [realTimeZoomConfig, setRealTimeZoomConfig] = useRecoilState(
    realTimeChartZoomConfigState,
  );
  const realTimeInitialData = useRecoilValue(liveSgChartDataState);
  const [putCallImpactZoomConfig, setPutCallImpactZoomConfig] = useRecoilState(
    putCallImpactZoomConfigState,
  );
  const [synthOIZoom, setSynthOIZoom] = useRecoilState(
    synthOIPCImpactZoomCfgState,
  );

  const putCallImpactInitialData = useRecoilValue(
    putCallImpactInitialDataState,
  );
  const synthOIPCImpactData = useRecoilValue(synthOIPCImpactDataState);
  const [rrImpactZoomConfig, setRRImpactZoomConfig] = useRecoilState(
    rrChartZoomConfigState,
  );
  const rrImpactInitialData = useRecoilValue(rrInitialDataState);

  const [liveChartType, setLiveChartType] = useRecoilState(
    synthOILiveChartTypeState,
  );

  const [termStructureChartZoomConfig, setTermStructureChartZoomConfig] =
    useRecoilState(termStructureChartZoomConfigState);
  const termStructureChartData = useRecoilValue(termStructureChartDataState);
  const [volSkewChartZoomConfig, setVolSkewChartZoomConfig] = useRecoilState(
    volSkewChartZoomConfigState,
  );
  const volSkewChartData = useRecoilValue(volSkewChartDataState);
  const [compositeMode, setCompositeMode] = useRecoilState(ehCompositeModeState);

  const zoomButton = useMemo(() => {
    switch (currentTab) {
      case 'live_price':
        return !isSynthOI ? (
          <ZoomOutButton
            zoomConfig={realTimeZoomConfig}
            setZoomConfig={setRealTimeZoomConfig}
            initialData={realTimeInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: realTimeInitialData.length - 1,
            }}
          />
        ) : null;
      case 'pc_impact':
        return (
          <ZoomOutButton
            zoomConfig={isSynthOI ? synthOIZoom : putCallImpactZoomConfig}
            setZoomConfig={
              isSynthOI ? setSynthOIZoom : setPutCallImpactZoomConfig
            }
            initialData={
              isSynthOI ? synthOIPCImpactData : putCallImpactInitialData
            }
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx:
                (isSynthOI ? synthOIPCImpactData : putCallImpactInitialData)
                  .length - 1,
            }}
          />
        );
      case 'rr':
        return (
          <ZoomOutButton
            zoomConfig={rrImpactZoomConfig}
            setZoomConfig={setRRImpactZoomConfig}
            initialData={rrImpactInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: rrImpactInitialData.length - 1,
            }}
          />
        );
      case IVolTab.TermStructure:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={termStructureChartZoomConfig}
            setZoomConfig={setTermStructureChartZoomConfig}
            initialData={termStructureChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: termStructureChartData.length - 1,
            }}
          />
        );
      case IVolTab.VolSkew:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={volSkewChartZoomConfig}
            setZoomConfig={setVolSkewChartZoomConfig}
            initialData={volSkewChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: volSkewChartData.length - 1,
            }}
          />
        );
      default:
        return null;
    }
  }, [
    currentTab,
    realTimeZoomConfig,
    setRealTimeZoomConfig,
    realTimeInitialData,
    putCallImpactZoomConfig,
    setPutCallImpactZoomConfig,
    putCallImpactInitialData,
    rrImpactZoomConfig,
    setRRImpactZoomConfig,
    rrImpactInitialData,
    termStructureChartZoomConfig,
    setTermStructureChartZoomConfig,
    termStructureChartData,
    volSkewChartZoomConfig,
    setVolSkewChartZoomConfig,
    volSkewChartData,
  ]);

  const settingsBtn = useMemo(() => {
    switch (currentTab) {
      case 'pc_impact':
        return isSynthOI ? (
          <SynthOIPutCallImpactSettings key="net-pos-pc-impact-settings" />
        ) : null;
      case IVolTab.FixedStrikeMatrix:
        return (
          <FixedStrikeTableSettings
            key="fixed-strike-table-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.TermStructure:
        return (
          <TermStructureChartSettings
            key="term-structure-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.VolSkew:
        return (
          <VolSkewChartSettings
            key="volatility-skew-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      default:
        return null;
    }
  }, [currentTab, isSynthOI, selectedSymbol]);

  const hasFactors = useMemo(() => {
    return isSynthOI && !isNaN(selectedEquity?.position_factor ?? NaN);
  }, [isSynthOI, selectedEquity]);

  useEffect(() => {
    if (isSynthOI && selectedEquity != null && !hasFactors) {
      // Force Gamma mode when we can't support position_factor
      setCompositeMode(EhCompositeMode.Gamma);
    }
  }, [isSynthOI, selectedEquity, hasFactors]);

  const downloadBtn = useMemo(() => {
    switch (currentTab) {
      case IVolTab.TermStructure:
        return (
          <DownloadChartButton
            chartRef={tsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Term-Structure"
          />
        );
      case IVolTab.VolSkew:
        return (
          <DownloadChartButton
            chartRef={vsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Volatility-Skew"
          />
        );
      default:
        return null;
    }
  }, [currentTab, selectedSymbol]);

  const getTabActions = useCallback(
    (tabKey: string) => {
      const actions = [];

      if (!['skew', 'history', ...ivolTabs].includes(tabKey)) {
        actions.push(
          <FormControlLabel
            sx={{
              width: '120px',
              marginLeft: !isMobile ? '4px' : 0,
            }}
            control={
              <Switch
                checked={showKeyLevels}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setShowKeyLevels(event.target.checked)
                }
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: isMobile ? 11 : 13,
                }}
              >
                Show key levels
              </Typography>
            }
            labelPlacement={isMobile ? 'top' : 'end'}
          />,
        );
      }

      if (tabKey === 'composite' && isSynthOI) {
        actions.push(
          <SGSelect
            key="composite-mode-selector"
            label="Chart Type"
            value={compositeMode}
            disabled={!hasFactors}
            setter={setCompositeMode}
            options={[EhCompositeMode.Gamma, EhCompositeMode.Factors]}
            multiple={false}
            optionsTextTransform={compositeTypeTextTransform}
            sx={{ minWidth: 120, fontSize: '0.8rem' }}
          />,
        );
      }

      if (tabKey === 'live_price' && isSynthOI) {
        actions.push(
          <SGSelect
            key="greek-type-selector"
            label="Greek Type"
            value={liveChartType}
            setter={setLiveChartType}
            options={[
              SynthOIChartType.Gamma,
              SynthOIChartType.Delta,
              SynthOIChartType.OpenInterest,
              SynthOIChartType.NetPositioning,
            ]}
            multiple={false}
            optionsTextTransform={greekTypeTextTransform}
            sx={{ minWidth: 120, fontSize: '0.8rem' }}
          />,
        );
      }

      if (!['history'].includes(tabKey)) {
        actions.push(
          <InfoButton
            key={`${tabKey}-info`}
            articleKey={isSynthOI ? `${tabKey}_synthoi` : tabKey}
          />,
        );
      }

      if (ivolChartTabs.includes(tabKey)) {
        actions.push(downloadBtn);
      }

      const zoomButtonElement =
        zoomedIn || !zoomButton ? (
          zoomButton
        ) : (
          <SGTooltip title="Drag on the chart to zoom in" placement="top">
            {zoomButton}
          </SGTooltip>
        );

      if (zoomButtonElement) {
        actions.push(zoomButtonElement);
      }

      return actions.filter(Boolean);
    },
    [
      compositeMode,
      downloadBtn,
      hasFactors,
      isMobile,
      isSynthOI,
      liveChartType,
      setLiveChartType,
      setShowKeyLevels,
      showKeyLevels,
      zoomButton,
      zoomedIn,
    ],
  );

  const staticActions = [
    settingsBtn,
    <PanelFullscreenButton panelType="main" />,
  ].filter(Boolean);

  const filteredTABS = Object.keys(
    isSynthOI ? EH_SYNTH_OI_ORDERED_TABS : EH_TOTAL_OI_ORDERED_TABS,
  )
    .filter((key) => !(membership === SubLevel.ALPHA && key === 'skew'))
    .reduce((obj, key) => {
      obj[key] = isSynthOI
        ? EH_SYNTH_OI_ORDERED_TABS[key as EquityHubTab] ?? ''
        : EH_TOTAL_OI_ORDERED_TABS[key as EquityHubTab] ?? '';
      return obj;
    }, {} as { [key: string]: string });

  const tabs: TabItem[] = useMemo(
    () =>
      Object.entries(filteredTABS).map(([key, label]) => ({
        label,
        value: key,
        content: (
          <>
            {key === 'composite' && (
              <CompositeViewGraph showKeyLevels={showKeyLevels} />
            )}
            {key === 'live_price' &&
              (isSynthOI ? (
                <LivePriceAndSgLevels showKeyLevels={showKeyLevels} />
              ) : (
                <RealTimeGraph showKeyLevels={showKeyLevels} />
              ))}
            {key === 'skew' && <SkewGraph />}
            {key === 'pc_impact' &&
              (isSynthOI ? (
                <SynthOIPutCallImpactGraph showKeyLevels={showKeyLevels} />
              ) : (
                <PutCallImpactGraph showKeyLevels={showKeyLevels} />
              ))}
            {key === 'rr' && <RiskReversalGraph />}
            {key === IVolTab.FixedStrikeMatrix && (
              <FixedStrikeTable selectedSym={selectedSymbol ?? ''} />
            )}
            {key === IVolTab.TermStructure && (
              <TermStructureChart
                ref={tsRef}
                selectedSym={selectedSymbol ?? ''}
              />
            )}
            {key === IVolTab.VolSkew && (
              <VolSkewChart ref={vsRef} selectedSym={selectedSymbol ?? ''} />
            )}
            {key === 'history' && <HistoryTable />}
          </>
        ),
        actions: getTabActions(key),
      })),
    [
      filteredTABS,
      isSynthOI,
      showKeyLevels,
      selectedSymbol,
      tsRef,
      vsRef,
      getTabActions,
    ],
  );

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <TabbedContent
        currentSym={selectedSymbol}
        tabs={tabs}
        subTabsComponent={<SymbolTabs productType={ProductType.EQUITYHUB} />}
        defaultTab={currentTab}
        staticActions={staticActions}
        onChange={handleChange}
      />

      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={IVOL_UPSELL.title}
        subtitle={IVOL_UPSELL.subtitle}
        items={IVOL_UPSELL.items}
      />
    </Box>
  );
};
