import { alpha, Theme } from '@mui/material';
import { camelCase, capitalize, cloneDeep, mergeWith } from 'lodash';
import {
  ComboGroup,
  CoreEquity,
  IndicesContentType,
  Manifest,
  ProductType,
  Quadrant,
  QuadrantId,
  QuadrantTab,
  EquityFieldKey,
} from '../../types';
import {
  gridClasses,
  GridColDef,
  GridColumnOrderChangeParams,
  GridComparatorFn,
  GridSortCellParams,
} from '@spotgamma/x-data-grid-premium';
import { EmptyGridOverlay } from '../../components/stock_scanner/EmptyGridOverlay';
import { EmptyResultsOverlay } from '../../components/stock_scanner/EmptyResultsOverlay';

// a lot of formatter callbacks pass in a second param. if using formatAsCompactNumber
// typescript will complain, since the types wont match
export const formatAsCompactNumberCallback = (n: any): string => {
  let num = n as number;
  let opts = {
    notation: 'compact',
  } as Intl.NumberFormatOptions;
  return Intl.NumberFormat('en', opts).format(num);
};

export const formatAsCompactNumber = (
  n: any,
  auxiliaryOpts?: Intl.NumberFormatOptions,
): string => {
  const num = Number(n);

  if (Math.abs(num) < 0.0001 && num !== 0) {
    return num.toExponential(2);
  }

  const opts = {
    notation: 'compact',
    ...(auxiliaryOpts ?? {}),
  } as Intl.NumberFormatOptions;
  return Intl.NumberFormat('en', opts).format(num);
};

export const formatAsCurrency = (n: any, rounding?: boolean): string => {
  let num = n as number;
  return Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    ...(rounding
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3,
        }),
  }).format(num);
};

export const calculateTextWidth = (text: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context!.font = getComputedStyle(document.body).font;

  return context!.measureText(text).width;
};

// positive percent for lighter, negative for darker
export const shadeColor = (color: string, percent: number): string => {
  if (percent === 0) {
    return color;
  }

  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  // zero-pad our hex numbers
  var RR = `00${R.toString(16)}`.slice(-2);
  var GG = `00${G.toString(16)}`.slice(-2);
  var BB = `00${B.toString(16)}`.slice(-2);

  return '#' + RR + GG + BB;
};

export const parseSpaceDelimittedList = (list: string, seperator?: string) =>
  list ? JSON.parse(list.replace(/\s\s+/g, seperator ? seperator : ',')) : [];

export const valGetter = (arr: any[], idx: number, fallback: any) =>
  arr ? arr[idx] : fallback;

export const mean = (entries: number[]) =>
  entries.reduce((agg, entry) => agg + entry / entries.length, 0);

export const formatAsPercentage = (
  n: any,
  rounding?: boolean,
  maxDigits: number = 2,
) => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    ...(rounding
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {
          minimumFractionDigits: 2,
          maximumFractionDigits: maxDigits,
        }),
  }).format(n);
};

export const formatAsDelta = (n: number) => {
  const value = Math.round(n <= 0.5 ? n * 100 : (1 - n) * 100);
  const suffix = n >= 0.5 ? 'P' : 'C';
  return `${value}D${value === 50 ? '' : suffix}`;
};

export const chunkArray = (arr: any[], size: number) => {
  let ret = [];
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    ret.push(chunk);
  }
  return ret;
};

// Higher-order function utility for updating search params without losing
// pre-existing settings in keys
export const updateSearch = (updates: any) => {
  return (params: URLSearchParams) =>
    Object.fromEntries(
      Object.entries({
        ...Object.fromEntries(params.entries()),
        ...updates,
      }).filter(([_k, v]: [any, any]) => v != null),
    ) as Record<string, string>;
};

// Assumes monotically "decreasing" elements with respect to the predicate.
// i.e. the predicate returns `true` then `false` moving from lower to higher indices in the array.
// Returns the index of the last element for which the predicate returns true
export const predicateSearch = <T>(
  arr: T[],
  passes: (arg: T) => boolean,
): number => {
  let left = 0;
  let right = arr.length;
  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    if (!passes(arr[mid])) {
      right = mid;
    } else {
      left = mid + 1;
    }
  }

  if (left > 0 && left < arr.length && passes(arr[left])) {
    return left;
  }
  // `left` now represents the first element that failed to pass.  Return the last success.
  // NOTE: if no matching element was found, this will return -1.
  return left - 1;
};

export enum Order {
  ASC = 0,
  DESC,
}

export const findNearestIdx = (
  arr: number[],
  tgt: number,
  order: Order = Order.ASC,
): number => {
  const cmp =
    order === Order.ASC ? (e: number) => e <= tgt : (e: number) => e >= tgt;
  let idx = predicateSearch(arr, cmp);
  return idx + 1 < arr.length &&
    (idx < 0 || Math.abs(arr[idx + 1] - tgt) < Math.abs(arr[idx] - tgt))
    ? idx + 1
    : idx;
};

export const isValidHex = (hex: string) =>
  /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st: string, chunkSize: number) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr: string) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a: any, alpha: any) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const hexToRGBA = (hex: any, alpha: any) => {
  if (!isValidHex(hex)) {
    hex = '#00BBAA'; // default to spotgamma teal
  }

  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr: any = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};

// lodash merge mutates the first object ('target') passed into it
// to use it without mutating the target, pass in a deep clone of the target
// returning undefined tells lodash to use built-in behavior
// to explicitly overwrite a value and nullify it, you must set that value to 'null', not 'undefined'
export const safeMerge = (...objs: any) => {
  return mergeWith(
    cloneDeep(objs[0]),
    ...objs.slice(1),
    (secondary: any, primary: any) => {
      if (Array.isArray(primary) && Array.isArray(secondary)) {
        // We explicitly overwrite the entirety of secondary array with the primary array
        // and make no attempt to "merge" them as doing the latter results in bugs like duplicate entries
        // for a list of symbols when an item gets deleted
        return primary;
      }
      // returning undefined tells lodash to use built-in behavior
      return undefined;
    },
  );
};

export const getDefaultSlotProps = (theme: any, isMobile?: boolean): any => {
  return {
    rightArrowIcon: {
      sx: {
        color: theme.palette.text.primary,
      },
    },
    leftArrowIcon: {
      sx: {
        color: theme.palette.text.primary,
      },
    },
    switchViewIcon: {
      sx: {
        color: theme.palette.text.primary,
      },
    },
    popper: {
      sx: {
        // zIndex set to 10001 since parent popper component has zIndex of 10000
        zIndex: 10001,
      },
    },
    textField: {
      size: 'small',
      style: {
        width: '180px',
        fontSize: '14px',
      },
      helperText: null,
      FormHelperTextProps: {
        style: {
          maxWidth: '160px',
          flexWrap: 'wrap',
          fontSize: '12px',
        },
      },
      error: undefined,
      sx: {
        svg: {
          color: theme.palette.text.primary,
        },
        '& .MuiInputBase-input': {
          fontSize: isMobile ? '10px' : '13px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(theme.palette.primary.main, 0.5),
        },
      },
    },
  };
};

export const arraySpliceCopy = (
  arr: any[],
  index: number,
  replace: any = undefined,
) => {
  const arrCopy = [...arr];
  replace === undefined
    ? arrCopy.splice(index, 1)
    : arrCopy.splice(index, 1, replace);
  return arrCopy;
};

export const isZerohedge = () => {
  return /zerohedge/.test(window.location.pathname);
};

export const READABLE_PROD_TYPES: Record<ProductType, string> = {
  [ProductType.EQUITYHUB]: 'Equity Hub',
  [ProductType.HIRO]: 'HIRO',
  [ProductType.SCANNERS]: 'Scanners',
  [ProductType.INDICES]: 'Indices',
  [ProductType.FOUNDERS_NOTES]: "Founder's Notes",
  [ProductType.HOME]: 'Market Overview',
  [ProductType.INTEGRATIONS]: 'Integrations',
  [ProductType.IMPLIED_VOL]: 'Volatility Dashboard',
  [ProductType.INTERNAL_OPEN_INTEREST]: 'Open Interest',
  [ProductType.INTERNAL_RISK_ENGINE]: 'Risk Engine',
  [ProductType.TAPE]: 'Tape',
  [ProductType.TRACE]: 'TRACE',
};

export const NON_PRODUCT_PAGES: Record<string, string> = {
  '/resources': 'Resources',
  '/resources/discord': 'Spotgamma Discord',
  '/preferences': 'Preferences',
};

export const readableProductType = (productType: ProductType | null) => {
  if (!productType) {
    return null;
  }
  return (
    READABLE_PROD_TYPES[productType] ??
    capitalize(productType.split('_').join(' '))
  );
};

export const productTypeForPath = (location: string) => {
  return (
    Object.values(ProductType).find((pt: ProductType) =>
      location.includes(camelCase(pt)),
    ) ?? null
  );
};

export const minVolumeThresholdFilter = (e: CoreEquity) => {
  const callVol = e[EquityFieldKey.callVolume];
  const putVol = e[EquityFieldKey.putVolume];
  return callVol >= 5_000 && callVol + putVol >= 10_000;
};

export const nullsToEndComparator = (comparatorFn: GridComparatorFn) => {
  return (sortDirection: string) => {
    const modifier = sortDirection === 'desc' ? -1 : 1;
    return (
      value1: any,
      value2: any,
      cellParams1: GridSortCellParams<any>,
      cellParams2: GridSortCellParams<any>,
    ) => {
      // Return 1 when left value is null, -1 when right value is null, 0 when both are null.
      const nullMask = (value1 == null ? 1 : 0) + (value2 == null ? 2 : 0);
      if (nullMask > 0) {
        return nullMask === 1 ? nullMask : nullMask - 3;
      }
      return modifier * comparatorFn(value1, value2, cellParams1, cellParams2);
    };
  };
};

export const defaultGridTableSlots = {
  noRowsOverlay: EmptyGridOverlay,
  noResultsOverlay: EmptyResultsOverlay,
};

export const getDefaultGridTableSlotProps = (theme: Theme) => {
  const defaultContentColor = theme.palette.getContrastText(
    theme.palette.background.default,
  );

  return {
    basePopper: {
      sx: {
        '& .MuiSvgIcon-root': {
          color: alpha(defaultContentColor, 0.75),
        },
      },
    },
    filterPanel: {
      filterFormProps: {
        logicOperatorInputProps: {
          variant: 'outlined',
          size: 'small',
        },
        columnInputProps: {
          variant: 'outlined',
          size: 'small',
          sx: { mt: 'auto' },
        },
        operatorInputProps: {
          variant: 'outlined',
          size: 'small',
          sx: { mt: 'auto' },
        },
        valueInputProps: {
          InputComponentProps: {
            variant: 'outlined',
            size: 'small',
          },
        },
      },
      sx: {
        gap: 2,
        '& .MuiButtonBase-root': {
          textTransform: 'capitalize',
        },
        '& .MuiDataGrid-filterForm': { p: 3, mt: 6 },
        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
        '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(theme.palette.primary.main, 0.5),
        },
      },
    },
  };
};

export const getDefaultGridTableStyles = (theme: Theme) => {
  const defaultContentColor = theme.palette.getContrastText(
    theme.palette.background.default,
  );

  return {
    borderRadius: 0,
    minHeight: 150,
    outline: 'none',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '.MuiDataGrid-filler *': {
      border: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.scannerGrid.pinnedRow,
    },
    '& .MuiDataGrid-pinnedRows': {
      '& .MuiDataGrid-row': {
        '& .MuiDataGrid-cell': {
          backgroundColor: theme.palette.scannerGrid.pinnedRow,
        },
      },
    },
    '& .MuiDataGrid-pinnedColumns': {
      backgroundColor: theme.palette.scannerGrid.pinnedColumn,
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer', // Makes the cursor a hand sign when hovering over rows
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },
    '& .custom-group-header': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        height: '35px',
      },
    },
    '& .grid-header-cell': {
      backgroundColor: theme.palette.scannerGrid.mainHeader,
      ':hover': {
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
      border: 'none',
    },
    '& .MuiDataGrid-topContainer': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      border: 'none',
    },
    '& .MuiDataGrid-scrollbar': {
      background: theme.palette.background.default,
    },
    '& .MuiDataGrid-sortIcon': {
      color: defaultContentColor,
    },
    '& .MuiDataGrid-menuIconButton': {
      color: defaultContentColor,
    },
    '& .MuiDataGrid-filterIcon': {
      color: defaultContentColor,
    },
    '& .MuiDataGrid-menuIconButton:hover': {
      backgroundColor: theme.palette.background.paper,
      color: alpha(defaultContentColor, 0.5),
    },
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiDataGrid-cell': {
      color: defaultContentColor,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row .MuiDataGrid-cell': {
      border: 'none',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: 'none',
      },
  };
};

export const getEnv = () =>
  import.meta.env.VITE_ENV ?? import.meta.env.NODE_ENV;

const MAX_DIGITS = 20;

export const round = (num: number, digits: number = 4): number => {
  const effectiveDigits = Math.min(MAX_DIGITS, Math.max(0, digits));
  const mult = Math.pow(10, effectiveDigits);
  return Math.round(num * mult) / mult;
};

export const roundToNearest = (num: number, nearest: number) => {
  return Math.round(num / nearest) * nearest;
};

export const roundUpToNearest = (num: number, nearest: number) => {
  return Math.ceil(num / nearest) * nearest;
};

export const valOrNa = (val: any) => {
  if (val == null || val === false) {
    // still want to show 0s
    return '';
  }
  return val;
};

export const getCurrentContentId = (contentId: string) => {
  switch (contentId) {
    // older keys must go here
    case 'real_volatility':
      return IndicesContentType.HIST_RETURNS;
    default:
      return contentId;
  }
};

// This function makes custom settings changes push-safe. It merges old and new fields stored in user's settings and reconciles otherwise breaking changes
// (e.g. no sym or sym options available in user's old settings, or if labels are outdated, etc.)
// it also can overwrite some old fields with new ones
export const mergeQuadrantsWithDefaults = (
  quadrants: Map<QuadrantId, Quadrant>,
  getDefaultSym: (contentId: string) => string | undefined,
  getDefaultSymOptions: (contentId: string) => string[] | undefined,
  getDefaultLabel: (contentId: string) => string,
) => {
  const getDefaultTabProps = (tab: QuadrantTab) => {
    const contentId =
      getCurrentContentId(tab.contentId) ?? tab.contentId ?? tab.id;
    return {
      ...tab,
      contentId,
      sym: tab.sym ?? getDefaultSym(contentId),
      symOptions: tab.symOptions ?? getDefaultSymOptions(contentId),
      label: getDefaultLabel(contentId) ?? tab.label,
    };
  };

  const mergeQuadrant = (quadrant: Quadrant): Quadrant => {
    return {
      ...quadrant,
      tabs: quadrant.tabs.map(getDefaultTabProps),
    };
  };

  const mergedQuadrants = new Map<QuadrantId, Quadrant>();

  quadrants.forEach((quadrant, quadrantId) => {
    mergedQuadrants.set(quadrantId, mergeQuadrant(quadrant));
  });

  return mergedQuadrants;
};

export const ONE_MIN_SECS = 60;
export const ONE_HOUR_SECS = 60 * ONE_MIN_SECS;
export const ONE_MIN_MS = ONE_MIN_SECS * 1000;
export const ONE_HOUR_MS = ONE_HOUR_SECS * 1000;
export const ONE_DAY_MS = ONE_HOUR_MS * 24;

export function textColorForBg(hexcolor: string) {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}

export const getParsedManifest = (manifest: any): Manifest => ({
  combos: manifest.combos,
  underlyingsSet: new Set(manifest.underlyings),
  combosSet: new Set(manifest.combos.map((group: ComboGroup) => group.combo)),
});

export const getAllSymsFromManifest = (manifest: Manifest | null): string[] =>
  manifest != null
    ? Array.from(new Set([...manifest.underlyingsSet, ...manifest.combosSet]))
    : [];

export const getUpdatedColumnOrder = (
  oldOrderedColumns: GridColDef[],
  params: GridColumnOrderChangeParams,
) => {
  // Create a new array with the updated order
  const newOrder = oldOrderedColumns.map((col) => col.field);
  const fieldToMove = newOrder[params.oldIndex];

  // Remove the field from its old position and insert at the new position
  newOrder.splice(params.oldIndex, 1);
  newOrder.splice(params.targetIndex, 0, fieldToMove);

  return newOrder;
};
