import { useEffect, useMemo, useRef, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ComposedChart,
  Area,
} from 'recharts';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
  ehCompositeModeState,
  equityQuantilesState,
  positiveTrendColorState,
  negativeTrendColorState,
  screenWidthState,
  selectedEquityLevelsState,
  selectedEquityState,
} from '../../../states';
import {
  calculateTextWidth,
  shadeColor,
  fetchAPI,
  formatAsCurrency,
  formatAsCompactNumberCallback,
} from '../../../util';
import { ColorMode } from '../../../theme';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { useSetSym } from '../../../hooks';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import {
  EhCompositeMode,
  SynthOIChartData,
  ProductType,
  LegacyEquity,
  SynthOIEquity,
} from 'types';
import { useSearchParams } from 'react-router-dom';
import useSynthOi from 'hooks/equityhub/useSynthOi';

const WINDOW = 10;

type CompositeViewGraphProps = {
  showKeyLevels: boolean;
};

interface CompositeDatum {
  strike: number;
  value: number;
}

export const CompositeViewGraph = ({
  showKeyLevels,
}: CompositeViewGraphProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const selectedEquity = useRecoilValue(selectedEquityState);
  const levels = useRecoilValue(selectedEquityLevelsState);
  const [gammaData, setGammaData] = useState<CompositeDatum[]>([]);
  const equityQuantiles = useRecoilValue(equityQuantilesState);
  const screenWidth = useRecoilValue(screenWidthState);
  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;
  const [rawChartData, setRawChartData] = useState<SynthOIChartData | null>(
    null,
  );
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [searchParams, _setSearchParams] = useSearchParams();
  const { getSym } = useSetSym();
  const sym = getSym(ProductType.EQUITYHUB);
  const compositeMode = useRecoilValue(ehCompositeModeState);

  const { isSynthOI } = useSynthOi();

  const posColor: string = useRecoilValue(positiveTrendColorState);
  const negColor: string = useRecoilValue(negativeTrendColorState);

  useEffect(() => {
    const dateStr = searchParams.get('date');
    setDate(dateStr ? dayjs(dateStr) : null);
  }, [searchParams]);

  // Calculate the equivalent of `mf_list` on the frontend
  useEffect(() => {
    if (!isSynthOI || rawChartData?.curves?.cust?.gamma?.all == null) {
      return;
    }

    const gamma = rawChartData.curves.cust.gamma.all;
    const spotPrices = rawChartData.curves.spot_prices;
    const slopes = gamma.slice(1).map((g, i: number) => {
      const num = g - gamma[i];
      const denom = spotPrices[i + 1] - spotPrices[i];
      return num / denom;
    });
    let sum: number = 0;
    let len = 0;
    const rollingMeans = slopes.map((pct, i) => {
      sum += pct - (i - WINDOW >= 0 ? slopes[i - WINDOW] : 0);
      return sum / Math.min(WINDOW, len + 1);
    });

    setGammaData(
      rollingMeans.map((m, i) => ({
        strike: spotPrices[i + 1],
        value: m,
      })),
    );
  }, [rawChartData, setGammaData, isSynthOI]);

  // legacy data setter
  useEffect(() => {
    if (
      isSynthOI ||
      selectedEquity == null ||
      (selectedEquity as LegacyEquity).mf_list == null
    ) {
      return;
    }
    const gammaList = JSON.parse((selectedEquity as LegacyEquity).mf_list);
    const strikeList = JSON.parse((selectedEquity as LegacyEquity).smf_list);

    setGammaData(
      strikeList.map((px: number, i: number) => ({
        strike: px,
        gamma: gammaList[i],
      })),
    );
  }, [rawChartData, selectedEquity, isSynthOI]);

  // TODO: Consolidate EH raw chart data fetching this with SynthOIPCImpactGraph and LivePrice
  useEffect(() => {
    if (!isSynthOI) {
      return;
    }

    // TODO: handle errors and offer toasts to retry etc.
    const fetchData = async () => {
      const params: Record<string, string> = { sym };
      if (date != null) {
        params['date'] = date.format('YYYY-MM-DD');
      }
      const urlParams = new URLSearchParams(params);
      const chartJSON = await fetchAPI(`synth_oi/v1/chart_data?${urlParams}`);
      setRawChartData(chartJSON);
    };
    fetchData();
  }, [setRawChartData, isSynthOI, sym, date]);

  const xBounds = useMemo(() => {
    if ((gammaData?.length ?? 0) === 0) {
      return { min: 0, max: Infinity };
    }
    const strikes = gammaData.map((d) => d.strike);
    const min = Math.min(...strikes);
    const max = Math.max(...strikes);
    return { min, max };
  }, [gammaData]);

  // Find zero crossing and highlight red at negative gamma and green at positive gamma

  const legacyOffset = useMemo(() => {
    if (gammaData?.length > 0 && selectedEquity) {
      const putctrl = isSynthOI
        ? selectedEquity.upx // TODO: Plumb down putctrl
        : ((selectedEquity as LegacyEquity).putctrl ??
          (selectedEquity as LegacyEquity).upx);
      return (putctrl - xBounds.min) / (xBounds.max - xBounds.min);
    }
  }, [selectedEquity, gammaData, xBounds, isSynthOI]);

  const maxGammaStrikeDate =
    selectedEquity &&
    dayjs
      .utc((selectedEquity as LegacyEquity).max_exp_g_date)
      .format('L')
      .toString();

  const maxDeltaStrikeDate =
    selectedEquity &&
    dayjs
      .utc((selectedEquity as LegacyEquity).max_exp_d_date)
      .format('L')
      .toString();

  const gammaHedgeEst = useMemo(
    () =>
      selectedEquity &&
      Math.round(
        ((selectedEquity as LegacyEquity).atmgc +
          (selectedEquity as LegacyEquity).atmgp) /
          (selectedEquity as LegacyEquity).upx,
      ).toLocaleString(),
    [selectedEquity],
  );

  const recentActivityColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateBlues)
        .domain([equityQuantiles.activityMin, equityQuantiles.activityMax])(
        selectedEquity.activity_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  const positionGreenColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateGreens)
        .domain([equityQuantiles.positionMin, equityQuantiles.positionMax])(
        selectedEquity.position_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  const positionRedColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateReds)
        .domain([equityQuantiles.positionMin, equityQuantiles.positionMax])(
        selectedEquity.position_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  const colors: { offset: number; color: string }[] = useMemo(() => {
    const gamma: number[] = rawChartData?.curves?.cust?.gamma?.all!;
    const { min, max } = xBounds;
    if (
      !isSynthOI ||
      (gamma?.length ?? 0) === 0 ||
      selectedEquity == null ||
      !isFinite(max)
    ) {
      return [];
    }

    const strikes: number[] = rawChartData?.curves?.spot_prices!;
    let prevPos = gamma[0] >= 0;
    const offsets = [
      {
        offset: 0,
        strike: strikes[0],
        gamma: gamma[0],
        color: prevPos ? posColor : negColor,
      },
    ];
    let minIdx = 0;
    let maxIdx = 0;
    for (let i = 1; i < gamma.length; ++i) {
      const pos = gamma[i] >= 0;
      minIdx = gamma[i] < gamma[minIdx] ? i : minIdx;
      maxIdx = gamma[i] > gamma[maxIdx] ? i : maxIdx;
      if (pos !== prevPos) {
        const hump = pos ? minIdx : maxIdx;
        offsets.push({
          strike: strikes[hump],
          gamma: gamma[hump],
          offset: (strikes[hump] - min) / (max - min),
          color: prevPos ? posColor : negColor,
        });
        const fraction = (0 - gamma[i - 1]) / (gamma[i] - gamma[i - 1]);
        const spot = d3.interpolateNumber(strikes[i - 1], strikes[i])(fraction);
        offsets.push({
          strike: spot,
          gamma: gamma[i],
          offset: (spot - min) / (max - min),
          color: theme.palette.equityHub.composite.neutral,
        });
        minIdx = maxIdx = i;
        prevPos = pos;
      }
    }
    const hump = prevPos ? maxIdx : minIdx;
    offsets.push({
      offset: 1.0,
      strike: strikes[hump],
      gamma: gamma[hump],
      color: prevPos ? posColor : negColor,
    });
    return offsets;
  }, [selectedEquity, rawChartData, xBounds, isSynthOI]);

  let referenceLines;
  if (levels != null && showKeyLevels) {
    // show longer level names on top to make it easier to read
    const sortedLevels = [...levels].sort((a, b) =>
      a.name.length < b.name.length ? 1 : -1,
    );
    referenceLines = sortedLevels.map(({ field, value, name }, i) => {
      const values = gammaData.map((d) => d.strike);
      const min = Math.min(...values);
      const max = Math.max(...values);
      const xAxisTicks = max - min;
      // 0.7 is default content width in dashboardLayout.tsx
      const chartWidth = ref.current?.offsetWidth ?? screenWidth * 0.7;
      const pxPerTick = chartWidth / xAxisTicks;
      const pxFromMax = (max - value) * pxPerTick;
      // if window is taller, add more spacing
      // if there are more than 5 levels to add, decrease spacing proportionally since youll need it
      // note window.innerHeight does not handle resizing after render, youd need to refresh
      const levelsCountRatio = Math.min(1, 5 / levels.length);
      const y =
        20 +
        i *
          Math.max(((55 * window.innerHeight) / 1_000) * levelsCountRatio, 20);
      const text = `${name} $${value}`;
      const width = calculateTextWidth(text);
      const x = width > pxFromMax ? -1 * width * 0.82 : 1;

      const stroke =
        fieldColorMapping[
          field.split(',')[0] as keyof typeof fieldColorMapping
        ];
      const textShadowColor = shadeColor(
        stroke,
        theme.colorMode === ColorMode.LIGHT ? -60 : -90,
      );
      return (
        <ReferenceLine
          x={value}
          key={`composite-reference-line-${field}`}
          stroke={stroke}
          strokeWidth={2}
          label={
            <Label
              position={{ x: x, y: y }}
              value={text}
              angle={0}
              height={20}
              style={{
                textAnchor: 'start',
                fill: stroke,
                fontSize: 13,
                fontWeight: '900',
                textShadow: `-1px -1px 0 ${textShadowColor}, 1px -1px 0 ${textShadowColor}, -1px 1px 0 ${textShadowColor}, 1px 1px 0 ${textShadowColor}`,
              }}
            />
          }
          isFront
        />
      );
    });
  }

  const defs =
    isSynthOI && compositeMode == EhCompositeMode.Gamma ? (
      <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
        {colors.map((z, i) => (
          <stop key={i} offset={z.offset} stopColor={z.color} stopOpacity={1} />
        ))}
      </linearGradient>
    ) : (
      <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
        <stop
          offset={legacyOffset}
          stopColor={positionRedColor}
          stopOpacity={1}
        />
        <stop
          offset={legacyOffset}
          stopColor={positionGreenColor}
          stopOpacity={1}
        />
      </linearGradient>
    );

  return (
    <ChartWatermarkContainer
      ref={ref}
      style={{
        background: theme.palette.background.default,
        borderRadius: '8px',
      }}
      size={15}
      offsetX={50}
      offsetY={25}
      sym={selectedEquity?.sym}
      symStyles={{
        right: '15px',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          data={gammaData}
          margin={{ ...DEFAULT_CHART_MARGINS, right: 30, top: 20 }}
        >
          <CartesianGrid
            strokeDasharray={
              theme.colorMode === ColorMode.LIGHT ? '2 8' : '0.3 8'
            }
          />
          <XAxis
            dataKey="strike"
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            label={{
              value: 'Strike',
              ...DEFAULT_X_AXIS_STYLES,
            }}
            tickCount={10}
            type="number"
            interval="preserveStartEnd"
          />
          <YAxis
            domain={['auto', 'auto']}
            tick={{ fontSize: 11 }}
            tickFormatter={formatAsCompactNumberCallback}
            label={{
              value: 'SG Acceleration Indicator',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
          />
          <Tooltip
            formatter={formatAsCompactNumberCallback}
            itemStyle={{
              fontSize: '11px',
              color: theme.palette.text.secondary,
            }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
            }}
            labelFormatter={(value) =>
              `Strike: ${formatAsCurrency(value, true)}`
            }
            separator=": "
          />
          <defs>{defs}</defs>
          <Area
            type="monotone"
            name="Gamma Momentum"
            dataKey={isSynthOI ? 'value' : 'gamma'}
            stroke={recentActivityColor}
            strokeWidth={5}
            fill="url(#splitColor)"
            fillOpacity={1}
          />
          {referenceLines}
          <Legend
            content={
              <div
                style={{
                  fontSize: 11,
                  color: theme.palette.text.secondary,
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 30,
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    justifyContent: 'space-around',
                  }}
                >
                  <div>Gamma Hedge Est {gammaHedgeEst}</div>
                  <div>Largest Gamma Strike {maxGammaStrikeDate}</div>
                  <div>Largest Delta Strike {maxDeltaStrikeDate}</div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    justifyContent: 'space-around',
                    marginTop: 15,
                  }}
                >
                  <div>
                    Recent Activity
                    <div
                      style={{
                        background: `linear-gradient(to left, ${d3.interpolateBlues(
                          1,
                        )}, ${d3.interpolateBlues(0.2)})`,
                        width: 125,
                        height: 12,
                        marginTop: 5,
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>0.0</div>
                      <div>0.3</div>
                    </div>
                  </div>
                  <div>
                    {compositeMode === EhCompositeMode.Gamma
                      ? 'Gamma'
                      : 'Position Size'}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 15,
                        marginTop: 5,
                      }}
                    >
                      {compositeMode === EhCompositeMode.Gamma ? (
                        <div>
                          <div
                            style={{
                              background: `linear-gradient(to right, ${negColor}, ${theme.palette.equityHub.composite.neutral}, ${posColor})`,
                              width: 125,
                              height: 12,
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>-</div>
                            <div>+</div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <div
                              style={{
                                background: `linear-gradient(to left, ${d3.interpolateReds(
                                  1,
                                )}, ${d3.interpolateReds(0.2)})`,
                                width: 125,
                                height: 12,
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>0.0</div>
                              <div>0.1</div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                background: `linear-gradient(to left, ${d3.interpolateGreens(
                                  1,
                                )}, ${d3.interpolateGreens(0.2)})`,
                                width: 125,
                                height: 12,
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>0.0</div>
                              <div>0.1</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
