import { alpha, useTheme } from '@mui/material/styles';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  Brush,
  ComposedChart,
  Area,
  ReferenceLine,
} from 'recharts';
import { ErrorContent, Loader } from '../../shared';
import { DEFAULT_CHART_MARGINS, DEFAULT_Y_AXIS_STYLES } from 'config';
import {
  iVolErrorState,
  iVolLoadingState,
  volSkewChartDataState,
  volSkewDataState,
  volSkewChartZoomConfigState,
  volSkewSelectedDatedGreeksState,
  volSkewExpirationsState,
  volSkewSelectedRawGreeksState,
  volSkewDisplayTypeState,
  volSkewVisibleChartLinesState,
  volSkewDateState,
  volSkewSelectedExpirationsState,
  currentStatsState,
  showVolSkewIVStatsState,
  volSkewUsedLineColorsState,
  currentStatsLookbackState,
} from 'states/iVol';
import {
  STRIKE_TICK_CONFIG,
  findNearestIdx,
  formatAsPercentage,
  getCurrentDate,
  getOverrideHeader,
  getQueryDate,
  updateBrushZoomConfig,
} from 'util/shared';
import poll from 'util/poll';
import {
  getZoomConfigRefArea,
  DELTA_TICK_CONFIG,
  MONEYNESS_TICK_CONFIG,
  getTicksBrushed,
} from 'util/shared/chart';
import {
  DatedGreeks,
  RawGreeksDataMap,
  VolSkew,
  StrikeDisplayType,
  RawStatsData,
  RawStatsDataMap,
} from 'types/impliedVol';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import {
  DELTA_KEY,
  SKEW_TICK_FORMATTERS,
  VSKEW_DEFAULT_DTE,
  extractVolSkewData,
  findClosestExpiration,
  getFilteredGreeksData,
  getGroupedPayloads,
  getNextAvailableColor,
  getSnapshotTime,
  getStatsFromExp,
  getVolSkewKey,
  getVolSkewKeyFromLabel,
  getVolSkewLineLabel,
  mergeObjectLists,
  normDelta,
  getDeltaFromX,
  getDeltaX,
  convertToDaysTillExpiry,
} from 'util/iVol';
import { isMobileState, workerState } from 'states/shared';
import useBrushZoom from 'hooks/useBrushZoom';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { pricesState, timezoneState } from 'states';
import usePrices from 'hooks/equityhub/usePrices';
import { Paper, Typography, Stack } from '@mui/material';
import { ProductType, VolSkewKey } from 'types';
import dayjs from 'dayjs';
import useAuth from 'hooks/auth/useAuth';

const DEFAULT_DELTA_TICKS = [
  0.01, 0.02, 0.04, 0.05, 0.1, 0.25, 0.5, 0.75, 0.9, 0.95, 0.9, 0.95, 0.96,
  0.97, 0.98, 0.99,
];

const SKEW_STRIKE_TICK_CONFIG: Map<number, number> = new Map([
  [1200, 200],
  ...STRIKE_TICK_CONFIG,
  [10, 2],
  [1, 1],
]);

const SKEW_KEY_TO_DISPLAY = {
  delta: StrikeDisplayType.Delta,
  'delta-x': StrikeDisplayType.Delta,
  moneyness: StrikeDisplayType.Moneyness,
  strike: StrikeDisplayType.FixedStrike,
};

interface VolSkewChartProps {
  selectedSym: string;
}

const VolSkewChart: ForwardRefRenderFunction<
  HTMLDivElement,
  VolSkewChartProps
> = ({ selectedSym }, ref) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);
  const worker = useRecoilValue(workerState);
  const { hasAccessToProduct } = useAuth();
  const hasIVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);
  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const { getCurrentGreeksData, getDailyGreeksData, getStatisticsData } =
    useImpliedVolatility();

  const availableColors = Object.values(theme.palette.iVol.volSkew);
  const [volSkewUsedLineColors, setVolSkewUsedLineColors] = useRecoilState(
    volSkewUsedLineColorsState,
  );

  const { getPrices } = usePrices();

  const [zoomConfig, setZoomConfig] = useRecoilState(
    volSkewChartZoomConfigState,
  );
  const [volSkewSelectedDatedGreeks, setVolSkewSelectedDatedGreeks] =
    useRecoilState(volSkewSelectedDatedGreeksState);

  const [prices, setPrices] = useRecoilState(pricesState);
  const currentPrice = prices.get(selectedSym) as number;

  const [volSkewChartData, setVolSkewChartData] = useRecoilState(
    volSkewChartDataState,
  );
  const setSelectedRawData = useSetRecoilState(volSkewSelectedRawGreeksState);
  const setExpirations = useSetRecoilState(volSkewExpirationsState);
  const selectedDisplayType = useRecoilValue(volSkewDisplayTypeState);
  const selectedDate = useRecoilValue(volSkewDateState);
  const setSelectedExpirations = useSetRecoilState(
    volSkewSelectedExpirationsState,
  );
  const [volSkewData, setVolSkewData] = useRecoilState(volSkewDataState);

  const [volSkewVisibleChartLines, setVisibleChartLines] = useRecoilState(
    volSkewVisibleChartLinesState,
  );

  const showVolSkewIVStats = useRecoilValue(showVolSkewIVStatsState);
  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const setCurrentStats = useSetRecoilState(currentStatsState);

  const volSkewSharedKey: VolSkewKey = useMemo(
    () => getVolSkewKeyFromLabel(selectedDisplayType),
    [selectedDisplayType],
  );

  const { zoomChartConfig } = useBrushZoom<VolSkew>(
    zoomConfig,
    setZoomConfig,
    volSkewSharedKey,
    volSkewChartData,
  );

  const updatePrices = useCallback(async () => {
    const payload = await getPrices([selectedSym]);
    setPrices((prices) => new Map([...prices, ...payload]));
  }, []);

  const handleResponse = useCallback(
    async (response: { data: RawGreeksDataMap }) => {
      if (response.data != null) {
        const filteredGreeks = getFilteredGreeksData(response.data);

        // update every timeframe that involves Today's trade date
        setVolSkewSelectedDatedGreeks((prevSelectedDatedGreeks) => {
          const updatedDatedGreeks = prevSelectedDatedGreeks.map(
            (datedGreeks: DatedGreeks) => {
              let updatedGreeks = datedGreeks;
              if (updatedGreeks.tradeDate.isSame(getCurrentDate(), 'day')) {
                // up to date strike map
                const updatedGreeksMap =
                  filteredGreeks ?? datedGreeks.greeksMap;

                // Update current greeks with latest data and current date timestamp
                updatedGreeks = {
                  ...updatedGreeks,
                  tradeDate: getCurrentDate(),
                  greeksMap: updatedGreeksMap,
                };
              }

              return updatedGreeks;
            },
          );

          return updatedDatedGreeks;
        });
        // update prices to match latest values
        updatePrices();
      }
    },
    // updatePrices is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVolSkewSelectedDatedGreeks],
  );

  useEffect(() => {
    return poll(
      worker,
      {
        url: `v1/${
          hasIVolAccess ? 'current_greeks' : 'free_current_greeks'
        }?sym=${encodeURIComponent(selectedSym)}`,
        interval: 10_000,
        buffer: true,
        msgpack: true,
        onResponse: handleResponse,
      },
      getOverrideHeader(),
    );
  }, [worker, hasIVolAccess, handleResponse, selectedSym]);

  const setChartToDefaultState = useCallback(async () => {
    const rawCurrentGreeksData: RawGreeksDataMap | null =
      await getCurrentGreeksData(selectedSym);
    const rawStats = await getStatisticsData(selectedSym);

    if (rawCurrentGreeksData && rawStats) {
      const newStatsDataObj: RawStatsData = rawStats[currentStatsLookback];

      const snapTime = getSnapshotTime(rawCurrentGreeksData);
      const expirations = Object.keys(rawCurrentGreeksData);
      const defaultExpiration: string = findClosestExpiration(
        snapTime,
        expirations,
        VSKEW_DEFAULT_DTE,
      );
      const tradeDate = dayjs(snapTime);
      const timeFrameKey = getVolSkewKey(tradeDate, defaultExpiration);
      const strikeMap = rawCurrentGreeksData[Number(defaultExpiration)];

      // retrieve the corresponding tte to access the right stats entry
      const statsMap = getStatsFromExp(
        newStatsDataObj,
        rawCurrentGreeksData,
        defaultExpiration,
      );

      const finalData = extractVolSkewData(strikeMap, timeFrameKey, statsMap);

      const color =
        getNextAvailableColor([], availableColors) ??
        theme.palette.primary.main;

      setExpirations(expirations);
      setSelectedExpirations([]);
      setSelectedRawData(rawCurrentGreeksData);
      setVolSkewSelectedDatedGreeks([
        {
          key: timeFrameKey,
          tradeDate,
          expiryDate: defaultExpiration,
          greeksMap: rawCurrentGreeksData,
          statsMap,
          color,
        },
      ]);
      setVisibleChartLines([timeFrameKey]);
      setVolSkewData(finalData);
      setVolSkewUsedLineColors([color]);
      setCurrentStats(rawStats);
      updatePrices();
    }
    // updatePrices is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableColors,
    selectedSym,
    currentStatsLookback,
    volSkewUsedLineColors,
    getCurrentGreeksData,
    getStatisticsData,
    setCurrentStats,
    setExpirations,
    setSelectedExpirations,
    setSelectedRawData,
    setVisibleChartLines,
    setVolSkewData,
    setVolSkewSelectedDatedGreeks,
    setVolSkewUsedLineColors,
    theme,
  ]);

  useEffect(() => {
    let finalData: VolSkew[] = [];

    volSkewSelectedDatedGreeks.forEach((datedGreeks) => {
      const snapTime = getSnapshotTime(datedGreeks.greeksMap);
      const expirations = Object.keys(datedGreeks.greeksMap);
      const defaultExpiration: string = findClosestExpiration(
        snapTime,
        expirations,
        VSKEW_DEFAULT_DTE,
      );
      let expiry = datedGreeks.expiryDate;
      if (!datedGreeks.greeksMap[Number(datedGreeks.expiryDate)]) {
        expiry =
          findClosestExpiration(
            snapTime,
            expirations,
            convertToDaysTillExpiry(
              getQueryDate(true, currentTimezone).valueOf(),
              Number(expiry),
            ),
          ) ?? defaultExpiration;
      }

      const updatedVolSkewData: VolSkew[] = extractVolSkewData(
        datedGreeks.greeksMap[Number(expiry)],
        datedGreeks.key,
        datedGreeks.statsMap,
      );

      if (volSkewVisibleChartLines.includes(datedGreeks.key)) {
        finalData = mergeObjectLists(
          finalData,
          updatedVolSkewData,
          volSkewSharedKey,
        );
      }
    });

    setVolSkewData(finalData);
  }, [volSkewSelectedDatedGreeks, volSkewVisibleChartLines]);

  // this hook handles initializing and resetting chart state (also detects ticker symbol changes when greeks hook changes)
  useEffect(() => {
    const fetchData = async () => {
      let finalData: VolSkew[] = [];
      let updatedSelectedDatedGreeks: DatedGreeks[] = [];
      let usedColors: string[] = [];

      // if page is newly opened or ticker symbol was just changed
      if (volSkewSelectedDatedGreeks.length === 0) {
        setChartToDefaultState();
      } else if (volSkewSelectedDatedGreeks.length > 0) {
        try {
          const selectedDateFormatted: string =
            selectedDate.format('YYYY-MM-DD');

          // create separate promises to be resolved in parallel for fetching greeks and stats
          const rawStatsPromise: Promise<RawStatsDataMap | null> =
            getStatisticsData(selectedSym);

          const selectedDateGreeksPromise: Promise<RawGreeksDataMap | null> =
            selectedDate.isSame(getCurrentDate(), 'day')
              ? getCurrentGreeksData(selectedSym)
              : getDailyGreeksData(selectedDateFormatted, selectedSym);

          const rawGreeksPromises: Promise<RawGreeksDataMap | null>[] =
            volSkewSelectedDatedGreeks.map((datedGreeks: DatedGreeks) => {
              const formattedDate: string =
                datedGreeks.tradeDate.format('YYYY-MM-DD');

              return datedGreeks.tradeDate.isSame(getCurrentDate(), 'day')
                ? getCurrentGreeksData(selectedSym)
                : getDailyGreeksData(formattedDate, selectedSym);
            });

          // grab the resolved greeks and stats data
          const [stats, selectedDateGreeks, ...greeks]: [
            RawStatsDataMap | null,
            RawGreeksDataMap | null,
            ...(RawGreeksDataMap | null)[],
          ] = await Promise.all([
            rawStatsPromise,
            selectedDateGreeksPromise,
            ...rawGreeksPromises,
          ]);

          volSkewSelectedDatedGreeks.forEach(
            (dg: DatedGreeks, index: number) => {
              // access updated greeks from the previously resolved promises
              const updatedRawGreeks: RawGreeksDataMap | null = greeks[index];

              if (updatedRawGreeks && stats) {
                const newStatsDataObj: RawStatsData =
                  stats[currentStatsLookback];

                const snapTime = getSnapshotTime(updatedRawGreeks);
                const expirations = Object.keys(updatedRawGreeks);
                const defaultExpiration: string = findClosestExpiration(
                  snapTime,
                  expirations,
                  VSKEW_DEFAULT_DTE,
                );

                let expiry = dg.expiryDate;
                if (!updatedRawGreeks[Number(expiry)]) {
                  expiry =
                    findClosestExpiration(
                      snapTime,
                      expirations,
                      convertToDaysTillExpiry(
                        getQueryDate(true, currentTimezone).valueOf(),
                        Number(dg.expiryDate),
                      ),
                    ) ?? defaultExpiration;
                }

                const strikeMap = updatedRawGreeks[Number(expiry)];
                const statsMap = getStatsFromExp(
                  newStatsDataObj,
                  updatedRawGreeks,
                  expiry,
                );

                const transformedVolSkewData: VolSkew[] = extractVolSkewData(
                  strikeMap,
                  dg.key,
                  statsMap,
                );

                const color =
                  dg.color !== ''
                    ? dg.color
                    : getNextAvailableColor(
                        volSkewUsedLineColors,
                        availableColors,
                      ) ?? theme.palette.primary.main;

                if (volSkewVisibleChartLines.includes(dg.key)) {
                  finalData = mergeObjectLists(
                    finalData,
                    transformedVolSkewData,
                    volSkewSharedKey,
                  );
                }

                usedColors = [...usedColors, color];
                updatedSelectedDatedGreeks = [
                  ...updatedSelectedDatedGreeks,
                  {
                    ...dg,
                    expiryDate: expiry,
                    statsMap,
                    greeksMap: updatedRawGreeks,
                    color,
                  },
                ];
              }
            },
          );

          if (selectedDateGreeks) {
            const expirations = Object.keys(selectedDateGreeks);
            setSelectedRawData(selectedDateGreeks);
            setExpirations(expirations);
            setSelectedExpirations([]);
          }

          setCurrentStats(stats);
          setVolSkewUsedLineColors(usedColors);
          setVolSkewSelectedDatedGreeks(updatedSelectedDatedGreeks);
          setVolSkewData(finalData);
          updatePrices();
        } catch {
          setChartToDefaultState();
        }
      }
    };

    fetchData();
    // updatePrices is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentGreeksData,
    getDailyGreeksData,
    getStatisticsData,
    selectedSym,
  ]);

  useEffect(() => {
    if (volSkewData) {
      let sortedData = volSkewData.slice();

      sortedData = sortedData
        .sort(
          (vs1: VolSkew, vs2: VolSkew) =>
            vs1[volSkewSharedKey] - vs2[volSkewSharedKey],
        )
        .filter((vs: VolSkew, index: number, self: VolSkew[]) => {
          const prev = self[index - 1]?.[volSkewSharedKey];
          return (
            prev !== vs[volSkewSharedKey] &&
            vs['delta-x'] >= 0.001 &&
            vs['delta-x'] <= 0.999
          );
        });

      setVolSkewChartData(sortedData);
      updateBrushZoomConfig(zoomConfig, sortedData, setZoomConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVolSkewChartData, setZoomConfig, volSkewData, volSkewSharedKey]);

  const visibleSelectedDatedGreeks = useMemo(
    () =>
      volSkewSelectedDatedGreeks.filter((datedGreeks: DatedGreeks) =>
        volSkewVisibleChartLines.includes(datedGreeks.key),
      ),
    [volSkewSelectedDatedGreeks, volSkewVisibleChartLines],
  );

  const ticks = useMemo(() => {
    const values =
      volSkewSharedKey === 'delta-x'
        ? volSkewChartData.map((e: VolSkew) =>
            normDelta(getDeltaFromX(e['delta-x'])),
          )
        : volSkewChartData.map((volSkew: VolSkew) => volSkew[volSkewSharedKey]);
    let ticks = getTicksBrushed(
      values,
      zoomConfig,
      selectedDisplayType === StrikeDisplayType.FixedStrike
        ? SKEW_STRIKE_TICK_CONFIG
        : selectedDisplayType === StrikeDisplayType.Moneyness
        ? MONEYNESS_TICK_CONFIG
        : DELTA_TICK_CONFIG,
    );
    if (volSkewSharedKey === 'delta-x') {
      // Manually add all rounded numbers at the wings if they are within our values range
      ticks = ticks ?? [...DEFAULT_DELTA_TICKS];
      const maxVal = values[zoomConfig.leftIdx ?? 0]; // normed deltas are descending
      const minVal = values[zoomConfig.rightIdx ?? values.length - 1];
      let tick = (Math.floor(ticks[0] * 100) - 1) / 100;
      for (; tick > minVal; tick -= 0.01) {
        ticks.unshift(tick);
      }
      tick = (Math.floor(ticks[ticks.length - 1] * 100) + 1) / 100;
      for (; tick < maxVal; tick += 0.01) {
        ticks.push(tick);
      }
      return ticks.map(getDeltaX);
    }
    return ticks;
  }, [selectedDisplayType, volSkewChartData, volSkewSharedKey, zoomConfig]);

  const tickFormatter = (key: VolSkewKey) => (value: number) =>
    SKEW_TICK_FORMATTERS[key](value);

  const customTooltipTickFormatter = (key: VolSkewKey, value: any) => {
    const keyLabel = SKEW_KEY_TO_DISPLAY[key];
    const valueLabel = SKEW_TICK_FORMATTERS[key](value);

    return (
      <Stack
        key={key}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {keyLabel}
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {valueLabel}
        </Typography>
      </Stack>
    );
  };

  const chartTooltipShadow = `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`;

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const firstPayloadObj = payload[0];
      const groupedPayloads = getGroupedPayloads('bounds-', payload);
      return (
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            gap: '6px',
            color: theme.palette.text.primary,
            border: 'none',
            backgroundColor: alpha(theme.palette.background.paper, 0.85),
            boxShadow: theme.palette.shadows.paperBoxShadow,
            width: '300px',
          }}
        >
          <Stack>
            {Object.entries(firstPayloadObj.payload)
              .filter(([key]) => key === 'strike' || key === 'moneyness')
              .map(([key, value]: [string, any]) =>
                customTooltipTickFormatter(key as VolSkewKey, value),
              )}
          </Stack>

          {Object.entries(groupedPayloads).map(([key, items]) => (
            <Stack key={key}>
              {items.map((payloadObj) => {
                if (payloadObj.name.includes('Bounds')) {
                  return (
                    <Stack key={payloadObj.name} width="100%" gap={1}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        gap={1}
                      >
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          Delta
                        </Typography>
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          {SKEW_TICK_FORMATTERS['delta'](
                            payloadObj.payload[`${key}-${DELTA_KEY}`],
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        gap={1}
                      >
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          10th pct
                        </Typography>
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          {formatAsPercentage(payloadObj.value[0])}
                        </Typography>
                      </Stack>
                      <Stack
                        key={key}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        gap={1}
                      >
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          90th pct
                        </Typography>
                        <Typography
                          sx={{
                            color: payloadObj.color,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textShadow: chartTooltipShadow,
                          }}
                        >
                          {formatAsPercentage(payloadObj.value[1])}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                } else {
                  return (
                    <Stack
                      key={key}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography
                        sx={{
                          color: payloadObj.color,
                          fontSize: '13px',
                          textShadow: chartTooltipShadow,
                        }}
                      >
                        {payloadObj.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: payloadObj.color,
                          fontSize: '13px',
                          fontWeight: 'bold',
                          textShadow: chartTooltipShadow,
                        }}
                      >
                        {formatAsPercentage(payloadObj.value)}
                      </Typography>
                    </Stack>
                  );
                }
              })}
            </Stack>
          ))}
        </Paper>
      );
    }

    return null;
  };

  const RotatedVerticalLabel = ({
    viewBox,
    value,
    backgroundColor = theme.palette.background.paper,
    textColor = theme.palette.sgGreen,
    fontSize = 13,
    fontWeight = 500,
  }: any) => {
    const { x, width } = viewBox;

    // Adjust these values as needed for your chart's layout
    const topOffset = 130; // How far down from the very top of the viewBox you want the label to appear
    const padding = 10; // Padding around the text for the background
    const estimatedTextLength = value.length * (fontSize * 0.6); // Estimate based on average character width
    const rectHeight = fontSize + padding; // Height of the rectangle, adjusted for padding
    const rectWidth = estimatedTextLength + padding; // Width of the rectangle, adjusted for padding and estimated text length

    // Calculate the center X of the area available for the label
    const centerX = x + width / 2;

    // Adjusted Y position for top placement
    const adjustedY = topOffset + rectHeight / 2; // Adjust this to move the label lower or higher

    return (
      <g>
        {/* Background rectangle with adjusted positioning and size */}
        <rect
          x={centerX - rectWidth / 2} // Center the rectangle horizontally around the text
          y={adjustedY - rectHeight / 2} // Center the rectangle vertically around the text
          width={rectWidth}
          height={rectHeight}
          fill={backgroundColor}
          rx={5} // adds rounded corners
          transform={`rotate(-90, ${centerX}, ${adjustedY})`} // Rotates the text around its center
        />
        {/* Rotated text */}
        <text
          x={centerX}
          y={adjustedY}
          fill={textColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          letterSpacing={1}
          textAnchor="middle" // Ensures the text is horizontally centered
          dominantBaseline="central" // Ensures the text is vertically centered
          transform={`rotate(-90, ${centerX}, ${adjustedY})`} // Rotates the text around its center
        >
          {value}
        </text>
      </g>
    );
  };

  if (!selectedSym || loading) {
    return <Loader isLoading={loading} />;
  }

  if (volSkewData?.length === 0 && !loading && error != null) {
    return (
      <ErrorContent content="Failed to retrieve the data needed for Volatility Skew Chart. Please either refresh the page or contact us!" />
    );
  }

  return (
    <ChartWatermarkContainer
      ref={ref ?? null}
      style={{
        flex: 1,
        position: 'relative',
      }}
      size={20}
      offsetX={55}
      offsetY={50}
      sym={selectedSym}
      symStyles={{ right: '35px' }}
    >
      {!hasIVolAccess && isMobile && (
        <Stack alignItems="center">
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 14,
            }}
          >
            Access limited to <strong>{selectedSym}</strong>
          </Typography>
        </Stack>
      )}

      <ResponsiveContainer>
        <ComposedChart
          margin={{
            ...DEFAULT_CHART_MARGINS,
            bottom: isMobile ? 40 : 20,
            right: 45,
          }}
          {...zoomChartConfig}
        >
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />
          <XAxis
            allowDataOverflow
            label={{
              value: selectedDisplayType,
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
            }}
            dataKey={volSkewSharedKey}
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 12 }}
            type="number"
            ticks={ticks}
            tickFormatter={tickFormatter(volSkewSharedKey)}
          />
          <Brush
            dataKey={volSkewSharedKey}
            tickFormatter={tickFormatter(volSkewSharedKey)}
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            yAxisId="left"
            domain={[
              (dataMin: number) => Math.max(dataMin - 0.03, 0),
              (dataMax: number) => dataMax + 0.03,
            ]}
            tick={{ fontSize: 12 }}
            type="number"
            label={{
              ...DEFAULT_Y_AXIS_STYLES,
              value: 'Implied Volatility',
              offset: 0,
            }}
            tickFormatter={(value: number) => formatAsPercentage(value)}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ fontSize: '12px' }}
            height={40}
          />

          {visibleSelectedDatedGreeks.map((datedGreeks: DatedGreeks) => {
            const name = getVolSkewLineLabel(
              datedGreeks.tradeDate,
              datedGreeks.expiryDate,
              currentTimezone,
            );
            const stroke = datedGreeks.color;
            return (
              <Area
                type="monotone"
                key={`area-${datedGreeks.key}`}
                yAxisId="left"
                legendType="none"
                name={`Bounds ${name}`}
                dataKey={`bounds-${datedGreeks.key}`}
                stroke="none"
                fillOpacity={0.3}
                fill={stroke}
                connectNulls
                hide={!showVolSkewIVStats}
              />
            );
          })}

          {visibleSelectedDatedGreeks.map((datedGreeks: DatedGreeks) => {
            const name = getVolSkewLineLabel(
              datedGreeks.tradeDate,
              datedGreeks.expiryDate,
              currentTimezone,
            );
            const stroke = datedGreeks.color;
            return (
              <Line
                key={datedGreeks.key}
                yAxisId="left"
                type="monotone"
                dataKey={datedGreeks.key}
                name={name}
                stroke={stroke}
                dot={false}
                strokeWidth={3}
                connectNulls
              />
            );
          })}

          {currentPrice != null &&
            volSkewSharedKey !== 'delta-x' &&
            !isNaN(currentPrice) && (
              <ReferenceLine
                x={
                  volSkewSharedKey === 'strike'
                    ? currentPrice
                    : volSkewSharedKey === 'moneyness'
                    ? 1
                    : volSkewChartData?.[
                        findNearestIdx(
                          volSkewChartData.map((vs) => vs['strike']),
                          currentPrice,
                        )
                      ]?.['delta-x'] ?? 0.5
                }
                isFront
                stroke={theme.palette.primary.main}
                ifOverflow="visible"
                yAxisId="left"
                style={{
                  opacity: 0.75,
                }}
                strokeDasharray="8 8"
                label={
                  <RotatedVerticalLabel
                    value={`Current Price: $${currentPrice.toFixed(2)}`}
                    backgroundColor={theme.palette.background.default} // Background color
                    textColor={theme.palette.sgGreen} // Text color
                    fontSize={13}
                    fontWeight={600}
                  />
                }
              />
            )}

          {getZoomConfigRefArea(zoomConfig, 'left')}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};

export default forwardRef(VolSkewChart);
