import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { RiskEngineContainer } from './components/RiskEngineContainer';
import { useCallback } from 'react';

export const RiskEnginePage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.INTERNAL_RISK_ENGINE}
      renderContent={useCallback(
        () => (
          <RiskEngineContainer />
        ),
        [],
      )}
    />
  );
};
