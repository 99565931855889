import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ehModelTypeState } from 'states';
import { EhModelType } from 'types';

const useSynthOi = () => {
  const ehModelType = useRecoilValue(ehModelTypeState);
  const [searchParams] = useSearchParams();

  const currentEhModelType = useMemo(() => {
    return searchParams.get('eh-model') ?? ehModelType;
  }, [searchParams, ehModelType]);

  const isSynthOI = currentEhModelType === EhModelType.SynthOI;

  return {
    isSynthOI,
  };
};

export default useSynthOi;
