/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchRawAPI, getAuthHeader, getPollingHeader } from './shared/fetch';
import { decode } from '@msgpack/msgpack';
import { PollOpts } from '../types/poll';
import { FetchAuxOptions } from '../types';
import { RemotePollingWorker } from 'types/pollingWorker';

const DEFAULT_POLL_INTERVAL = 20_000; // 20s

// A helper/wrapper around the PollingWorker that abstracts away message ID
// matching as well as  removing message handlers and clearing associated
// intervals on unmount while handling race conditions.
//
// Usage: useEffect(() => poll(worker, opts), [worker, ...]);
const poll = (
  worker: RemotePollingWorker,
  opts: PollOpts,
  auxOpts?: FetchAuxOptions,
) => {
  if (!worker) {
    return () => {};
  }

  let pollerId: NodeJS.Timeout | null = null;
  let unsubscribed = false;
  const interval = opts.interval ?? DEFAULT_POLL_INTERVAL;

  const onMessage = (msg: any) => {
    // Handle unsubscribe race and ignore messages that aren't ours.
    if (
      unsubscribed ||
      pollerId == null ||
      msg?.data?.id !== pollerId ||
      msg?.data?.type === 'RPC'
    ) {
      return;
    }
    opts.onResponse(msg.data, opts.url);
    worker.comLinkWorker.setAuthHeader(getAuthHeader());
  };

  const unsubscribe = () => {
    worker?.rawWorker.removeEventListener('message', onMessage);
    if (pollerId) {
      worker.comLinkWorker.clearPoller(pollerId);
    }
    pollerId = null;
    unsubscribed = true;
  };

  const pollOnInit = async () => {
    try {
      const resp = await fetchRawAPI(opts.url, {
        ...(auxOpts ?? {}),
        ...getPollingHeader(interval),
      });

      const responseData = {
        status: resp.status,
        headers: Object.fromEntries(resp.headers.entries()),
      };

      if (opts.buffer) {
        const arrayBuffer = await resp.arrayBuffer();
        const data = opts.msgpack ? decode(arrayBuffer) : arrayBuffer;
        opts.onResponse({ data, ...responseData }, opts.url);
        return;
      }

      const json = await resp.json();
      opts.onResponse({ json, ...responseData }, opts.url);
    } catch (err) {
      console.error(err);
    }
  };

  const startPolling = async () => {
    if (!worker.comLinkWorker.setPoller) {
      return;
    }

    await worker.comLinkWorker.setAuthHeader(getAuthHeader());

    // cannot have functions as part of opts passed into polling worker
    // this doesnt work great with nested objects, if we introduce those and need them
    // in pollingworker, we will want to revisit this
    const serializedOpts = Object.entries(opts).reduce(
      (acc, [key, val]) => {
        if (['string', 'number', 'boolean'].includes(typeof val)) {
          acc[key] = val;
        }
        return acc;
      },
      {} as Record<string, any>,
    );

    pollerId = await (opts.buffer
      ? worker.comLinkWorker.setBufferPoller(
          opts.url,
          interval,
          auxOpts ?? {},
          serializedOpts,
        )
      : worker.comLinkWorker.setPoller(
          opts.url,
          interval,
          auxOpts ?? {},
          serializedOpts,
        ));

    // If we detect that we attempted to unsubscribe before setPoller returned.
    // make sure we follow through and rerun unsubcribe so we don't leave a poller running
    if (unsubscribed) {
      unsubscribe();
    }
  };

  startPolling();
  worker.rawWorker.addEventListener('message', onMessage);
  if (!opts.noPollOnInit) {
    pollOnInit();
  }

  return unsubscribe;
};

export default poll;
