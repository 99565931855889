import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { SGHtmlEditor } from './SGHtmlEditor';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export type TooltipDefinition = {
  html: string;
  text: string;
  dontMatchPartialWord?: boolean; // 'dont' such that undefined = match partial word, which should be default
  dontMatchPunctuation?: boolean;
  dontMatchPlural?: boolean;
};

export const TooltipEditor = ({
  initialTooltips,
  onChange,
}: {
  initialTooltips?: TooltipDefinition[];
  onChange: (newTooltips: TooltipDefinition[]) => void;
}) => {
  const [tooltips, setTooltips] = useState<TooltipDefinition[]>(
    initialTooltips ?? [],
  );

  const theme = useTheme();

  useEffect(() => {
    onChange(tooltips);
  }, [tooltips]);

  const addNewTooltip = () => {
    setTooltips([...tooltips, { html: '', text: '' }]);
  };

  const existingTooltips = tooltips.map((tooltip, idx) => {
    return (
      <Box
        width={1}
        display="flex"
        height={'250px'}
        sx={{ background: theme.palette.primary.main }}
      >
        <IconButton
          sx={{ width: '30px', height: '30px', marginTop: '5px' }}
          onClick={() => setTooltips(tooltips.filter((t, i) => i !== idx))}
        >
          <CloseIcon color="error"></CloseIcon>
        </IconButton>

        <Box
          width={0.3}
          height={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box paddingRight="10px">
            <span style={{ color: '#000' }}>Tooltip text</span>
            <Input
              sx={{
                width: '100%',
                background: '#fff',
                margin: 'auto',
                color: '#000',
                height: '40px',
              }}
              value={tooltip.text}
              onChange={(e) => {
                setTooltips(
                  tooltips.map((t, i) =>
                    i === idx ? { ...tooltip, text: e.target.value } : t,
                  ),
                );
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={!tooltip.dontMatchPlural}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTooltips(
                      tooltips.map((t, i) =>
                        i === idx
                          ? {
                              ...tooltip,
                              dontMatchPlural: !event.target.checked,
                            }
                          : t,
                      ),
                    )
                  }
                />
              }
              label={`Matches plural (vol = vols)`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={!tooltip.dontMatchPunctuation}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTooltips(
                      tooltips.map((t, i) =>
                        i === idx
                          ? {
                              ...tooltip,
                              dontMatchPunctuation: !event.target.checked,
                            }
                          : t,
                      ),
                    )
                  }
                />
              }
              label={`Matches punctuation (vol = vol,./)`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={!tooltip.dontMatchPartialWord}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTooltips(
                      tooltips.map((t, i) =>
                        i === idx
                          ? {
                              ...tooltip,
                              dontMatchPartialWord: !event.target.checked,
                            }
                          : t,
                      ),
                    )
                  }
                />
              }
              label={`Matches partial word (vol = volatility)`}
            />
          </Box>
        </Box>
        <Box width={0.7} height={1}>
          <SGHtmlEditor
            html={tooltip.html}
            key={idx}
            includeStyles={idx === 0}
            onHtmlChange={(newHtml) =>
              setTooltips(
                tooltips.map((t, i) =>
                  i === idx ? { ...tooltip, html: newHtml } : t,
                ),
              )
            }
          />
        </Box>
      </Box>
    );
  });

  const tooltipsComponent = (
    <Box display="flex" flexDirection="column" gap="10px" marginTop="10px">
      {existingTooltips}
    </Box>
  );

  return (
    <Box width={1} height={1}>
      {tooltipsComponent}
      <Button onClick={addNewTooltip}>+ New tooltip</Button>
    </Box>
  );
};
