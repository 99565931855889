import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { SGTooltip } from '../core';
import { downloadChartAsPng } from '../../util/shared';

interface DownloadChartButtonProps {
  chartRef: any;
  selectedSym: string;
  chartName: string;
}

export const DownloadChartButton = ({
  chartRef,
  selectedSym,
  chartName,
}: DownloadChartButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="download-chart"
      color="primary"
      size="small"
      sx={{
        height: '30px',
      }}
      onClick={(e) => {
        e.stopPropagation();
        downloadChartAsPng(chartRef, `${selectedSym}-${chartName}`, {
          backgroundColor: theme.palette.background.paper,
        });
      }}
    >
      <SGTooltip title="Download Chart">
        <DownloadIcon />
      </SGTooltip>
    </IconButton>
  );
};
