import { Dayjs } from 'dayjs';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { DEFAULT_BRUSH_ZOOM_CONFIG } from '../config';
import {
  BrushZoomConfig,
  TermStructure,
  DailyGreeks,
  SelectedDateLine,
  ExpirationsDisplayType,
  VolSkew,
  StrikeDisplayType,
  RawGreeksDataMap,
  DatedGreeks,
  MatrixDataMode,
  MatrixData,
  MatrixColumnData,
  FixedStrikeZoomLevel,
  FixedStrikeCellZoomProps,
  MatrixExpirationsRange,
  MatrixPercentOTMRange,
  PrunePercentRange,
  RawStatsDataMap,
  GreeksStatsLookback,
} from '../types';
import {
  prevBusinessDayOpenMarket,
  getQueryDate,
  isMarketOpenOnDate,
  getCurrentDate,
} from '../util';
import { MATRIX_SETTINGS_ZOOMED_NORMAL } from '../util/iVol';

const { persistAtom } = recoilPersist();

export const usEconomicEventsState = atom<Map<string, string[]> | null>({
  key: 'usEconomicEventsState',
  default: null,
});

// Is data still loading
export const iVolLoadingState = atom<boolean>({
  key: 'iVol-loadingState',
  default: true,
});

// Were there errors fetching data?
export const iVolErrorState = atom<any>({
  key: 'iVol-errorState',
  default: null,
});

// Define the term structure chart zoom level state
export const termStructureChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'iVol-termStructureChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const termStructureChartDataState = atom<TermStructure[]>({
  key: 'iVol-termStructureChartDataState',
  default: [],
});

export const termStructureDataState = atom<TermStructure[] | undefined>({
  key: 'iVol-termStructureDataState',
  default: undefined,
});

export const termStructureDateState = atom<Dayjs>({
  key: 'termStructureDateState',
  default: prevBusinessDayOpenMarket(getQueryDate(true)),
});

export const termStructureSelectedDailyGreeksState = atom<DailyGreeks[]>({
  key: 'termStructureSelectedDailyGreeksState',
  default: [
    {
      key: getQueryDate(true).format('YYYY-MM-DD'),
      tradeDate: getQueryDate(true),
      data: [],
      color: '',
    },
  ],
});

export const termStructureUsedLineColorsState = atom<string[]>({
  key: 'termStructureUsedLineColorsState',
  default: [],
});

export const termStructureDisplayTypeState = atom<ExpirationsDisplayType>({
  key: 'termStructureDisplayTypeState',
  default: ExpirationsDisplayType.ExpirationDate,
  effects_UNSTABLE: [persistAtom],
});

export const showEconomicEventsState = atom<boolean>({
  key: 'showEconomicEventsState',
  default: true,
});

export const showForwardIVsState = atom<boolean>({
  key: 'showForwardIVsState',
  default: false,
});

export const showTermStructureIVStatsState = atom<boolean>({
  key: 'showTermStructureIVStatsState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const termStructureVisibleChartLinesState = atom<string[]>({
  key: 'termStructureVisibleChartLinesState',
  default: [],
});

// Vol Skew States

export const volSkewChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'iVol-volSkewChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const volSkewChartDataState = atom<VolSkew[]>({
  key: 'iVol-volSkewChartDataState',
  default: [],
});

export const volSkewDataState = atom<VolSkew[] | undefined>({
  key: 'iVol-volSkewDataState',
  default: undefined,
});

export const volSkewDateState = atom<Dayjs>({
  key: 'volSkewDateState',
  default: getQueryDate(true),
});

export const volSkewDisplayTypeState = atom<StrikeDisplayType>({
  key: 'volSkewDisplayTypeState',
  default: StrikeDisplayType.FixedStrike,
  effects_UNSTABLE: [persistAtom],
});

export const volSkewExpirationsState = atom<string[]>({
  key: 'volSkewExpirationsState',
  default: [],
});

export const volSkewSelectedExpirationsState = atom<string[]>({
  key: 'volSkewSelectedExpirationsState',
  default: [],
});

export const volSkewVisibleChartLinesState = atom<string[]>({
  key: 'volSkewVisibleChartLinesState',
  default: [],
});

export const volSkewUsedLineColorsState = atom<string[]>({
  key: 'volSkewUsedLineColorsState',
  default: [],
});

export const volSkewSelectedRawGreeksState = atom<RawGreeksDataMap>({
  key: 'volSkewSelectedRawGreeksState',
  default: undefined,
});

export const volSkewSelectedDatedGreeksState = atom<DatedGreeks[]>({
  key: 'volSkewSelectedDatedGreeksState',
  default: [],
});

export const volSkewDTESelectorState = atom<boolean>({
  key: 'volSkewDTESelectorState',
  default: false,
});

export const showVolSkewIVStatsState = atom<boolean>({
  key: 'showVolSkewIVStatsState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

// Matrix Table States

export const matrixDataModeState = atom<MatrixDataMode>({
  key: 'matrixDataModeState',
  default: MatrixDataMode.STATS_MODE,
  effects_UNSTABLE: [persistAtom],
});

export const showHighlightsState = atom<boolean>({
  key: 'showHighlightsState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const matrixPrevExpiryMapState = atom<RawGreeksDataMap>({
  key: 'matrixPrevExpiryMapState',
  default: undefined,
});

export const fixedStrikeCurrentGreeksState = atom<RawGreeksDataMap>({
  key: 'fixedStrikeCurrentGreeksState',
  default: undefined,
});

export const showHistoricalDataState = atom<boolean>({
  key: 'showHistoricalDataState',
  default: false,
});

export const fixedStrikeCurrentDataState = atom<MatrixData[] | undefined>({
  key: 'fixedStrikeCurrentDataState',
  default: undefined,
});

export const fixedStrikeColumnDataState = atom<MatrixColumnData[]>({
  key: 'fixedStrikeColumnDataState',
  default: [],
});

export const matrixCurrentDate = atom<Dayjs>({
  key: 'matrixCurrentDate',
  default: getQueryDate(true),
});

export const matrixCompareDate = atom<Dayjs>({
  key: 'matrixCompareDate',
  default: isMarketOpenOnDate(getCurrentDate())
    ? prevBusinessDayOpenMarket()
    : prevBusinessDayOpenMarket(prevBusinessDayOpenMarket()),
});

export const matrixZoomState = atom<FixedStrikeZoomLevel>({
  key: 'matrixZoomState',
  default: FixedStrikeZoomLevel.Normal,
});

export const matrixZoomPropsState = atom<FixedStrikeCellZoomProps>({
  key: 'matrixZoomPropsState',
  default: MATRIX_SETTINGS_ZOOMED_NORMAL,
});

export const matrixExpirationRangeState = atom<MatrixExpirationsRange>({
  key: 'matrixExpirationRangeState',
  default: MatrixExpirationsRange['9months'],
});

export const matrixExpirationRangeSelectorState = atom<boolean>({
  key: 'matrixExpirationRangeSelectorState',
  default: false,
});

export const matrixPercentOTMRangeState = atom<MatrixPercentOTMRange>({
  key: 'matrixPercentOTMRangeState',
  default: MatrixPercentOTMRange['10percent'],
});

export const matrixPercentOTMRangeSelectorState = atom<boolean>({
  key: 'matrixPercentOTMRangeSelectorState',
  default: false,
});

export const pruneRowsPercentSelectorState = atom<boolean>({
  key: 'pruneRowsPercentSelectorState',
  default: false,
});

export const pruneColumnsPercentSelectorState = atom<boolean>({
  key: 'pruneColumnsPercentSelectorState',
  default: false,
});

export const pruneColumnsPercentState = atom<PrunePercentRange>({
  key: 'pruneColumnsPercentState',
  default: PrunePercentRange.medium,
});

export const pruneRowsPercentState = atom<PrunePercentRange>({
  key: 'pruneRowsPercentState',
  default: PrunePercentRange.medium,
});

// Stats

export const currentStatsState = atom<RawStatsDataMap | null>({
  key: 'currentStatsState',
  default: null,
});

export const currentStatsLookbackState = atom<GreeksStatsLookback>({
  key: 'currentStatsLookbackState',
  default: GreeksStatsLookback.NINETY,
  effects_UNSTABLE: [persistAtom],
});


// VIX

export const vixDateSelectorState = atom<Dayjs>({
  key: 'vixDateSelectorState',
  default: prevBusinessDayOpenMarket(getQueryDate(true)),
});

export const vixUsedLineColorsState = atom<string[]>({
  key: 'vixUsedLineColorsState',
  default: [],
});

export const vixSelectedDatesState = atom<SelectedDateLine[]|undefined>({
  key: 'vixSelectedDatesState',
  default: undefined

});

export const vixVisibleChartLinesState = atom<string[]>({
  key: 'vixVisibleChartLinesState',
  default: [getQueryDate(true).format('YYYY-MM-DD')],
});

export const vixChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'vixChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const vixDisplayTypeState = atom<ExpirationsDisplayType>({
  key: 'vixDisplayTypeState',
  default: ExpirationsDisplayType.ExpirationDate,
  effects_UNSTABLE: [persistAtom],
});