import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Stack,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRecoilValue } from 'recoil';
import MDEditor from '@uiw/react-md-editor';
import dayjs from 'dayjs';
import { timezoneState } from '../../../states';
import { UserNotification } from '../../../types/notifications';

interface FullNotificationDialogProps {
  notification: UserNotification | null;
  open: boolean;
  onClose: () => void;
}

const FullNotificationDialog: React.FC<FullNotificationDialogProps> = ({
  notification,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const timezone = useRecoilValue(timezoneState);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isSmDown}
      maxWidth="md"
      fullWidth
    >
      {notification && (
        <>
          <DialogTitle>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      md: 18,
                    },
                  }}
                >
                  {notification.title}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {dayjs
                    .utc(notification.timeCreated)
                    .tz(timezone)
                    .format('MM-DD h:mm A')}
                </Typography>
              </Stack>
              <IconButton color="primary" onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box>
              <MDEditor.Markdown
                source={notification.message}
                style={{
                  color: theme.palette.text.secondary,
                  background: 'transparent',
                }}
              />
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FullNotificationDialog;
