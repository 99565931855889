import {
  alpha,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import SummaryCardWrapper from './SummaryCardWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tnsFlowSummaryExpandedState } from 'states/tape';
import SGAccordion from 'components/SGAccordion';
import RatioPieChart from './cards/RatioPieChart';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import SharedPieLegend from './cards/SharedPieLegend';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import poll from 'util/poll';
import { workerState } from 'states';
import { Filter, PCFlowSum, TnsFlowSummary } from 'types/tape';
import useTapeFeed from 'hooks/optionsFeed/useTapeFeed';
import React from 'react';
import {
  FLOW_SUM_CATEGORIES,
  FLOW_SUM_CATEGORY_DESCRIPTION,
} from 'config/tape';
import { getFiltersForPayload, makeTnsFlowSummary } from 'util/tape';
import { STREAM_HOST_URL } from 'config/shared';
import { encodeURIJson, getOverrideHeader } from 'util/shared';
import { red } from '@mui/material/colors';

const LoadingChartSkeleton: React.FC = () => (
  <ChartContainer>
    <Skeleton sx={{ width: '100%', height: '100%' }} variant="rectangular" />
  </ChartContainer>
);

const LoadingLegend: React.FC = () => (
  <Stack sx={{ justifyContent: 'center', gap: 3 }}>
    <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <Skeleton variant="rectangular" sx={{ width: 12, height: 12 }} />
      <Skeleton variant="text" sx={{ width: 80, height: 16 }} />
    </Stack>
    <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <Skeleton variant="rectangular" sx={{ width: 12, height: 12 }} />
      <Skeleton variant="text" sx={{ width: 80, height: 16 }} />
    </Stack>
  </Stack>
);

const ChartContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    sx={{
      width: {
        sm: 160,
        md: 180,
        lg: 200,
      },
      height: {
        sm: 120,
        md: 140,
        lg: 160,
      },
    }}
  >
    {children}
  </Box>
);

interface DataGridFlowSummaryProps {
  filters: Filter[];
}

const DataGridFlowSummary = memo(({ filters }: DataGridFlowSummaryProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useRecoilState(tnsFlowSummaryExpandedState);
  const worker = useRecoilValue(workerState);
  const [sumLoading, setSumLoading] = useState<boolean>(false);
  const [flowSumData, setFlowSumData] = useState<TnsFlowSummary | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { fetchFlowSummary } = useTapeFeed();

  const handleError = useCallback((err: any) => {
    console.error(err);
    setError(err.message);
  }, []);

  const handleResponse = useCallback(
    async (response: any) => {
      if (response.error) {
        handleError(response.error);
      } else if (response?.json != null) {
        setFlowSumData(makeTnsFlowSummary(response.json));
        setError(null);
      }
    },
    [handleError],
  );

  const fetchData = async (filters: Filter[]) => {
    try {
      setSumLoading(true);
      const res = await fetchFlowSummary(filters);
      setFlowSumData(res);
      setError(null);
    } catch (err: any) {
      handleError(err);
    } finally {
      setSumLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  useEffect(() => {
    return poll(
      worker,
      {
        url: `sg/tns_flow_sum?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}`,
        interval: 30_000, // poll every 30s
        onResponse: handleResponse,
      },
      {
        host: STREAM_HOST_URL,
        ...getOverrideHeader(),
      },
    );
  }, [worker, filters, handleResponse]);

  const DEFAULT_FLOW_SUM_COLORS = {
    put: theme.palette.core.put,
    call: theme.palette.core.call,
    total: theme.palette.gray,
  };

  const pieLegendItems = useMemo(() => {
    if (!flowSumData) {
      return [];
    }

    // Check if there's at least one non-null 'put' value
    const hasPut = Object.values(flowSumData).some((item) => item.put != null);

    // Check if there's at least one non-null 'call' value
    const hasCall = Object.values(flowSumData).some(
      (item) => item.call != null,
    );

    // Initialize an empty array for legend items
    const items = [];

    // Add 'PUT' to legend if available
    if (hasPut) {
      items.push({
        name: 'PUT',
        color: DEFAULT_FLOW_SUM_COLORS.put,
      });
    }

    // Add 'CALL' to legend if available
    if (hasCall) {
      items.push({ name: 'CALL', color: DEFAULT_FLOW_SUM_COLORS.call });
    }

    return items;
  }, [flowSumData]);

  // Function to map flowSumData to chart data for each category
  const getChartData = (key: keyof TnsFlowSummary): PCFlowSum | null => {
    if (!flowSumData?.[key]) {
      return null;
    }

    return flowSumData[key];
  };

  const isEmpty =
    flowSumData &&
    Object.values(flowSumData).every(
      (data) => data.put == null && data.call == null,
    );

  if (error) {
    return (
      <SGAccordion
        hideSummaryWhenExpanded
        title="Flow Summary"
        icon={
          <PieChartRoundedIcon sx={{ color: theme.palette.primary.main }} />
        }
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
        sx={{
          backgroundColor: 'background.default',
        }}
        paperSx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          backgroundColor: 'inherit',
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Typography sx={{ color: red[600] }}>{error}</Typography>
      </SGAccordion>
    );
  }

  return (
    <SGAccordion
      hideSummaryWhenExpanded
      title="Flow Summary"
      icon={<PieChartRoundedIcon sx={{ color: theme.palette.primary.main }} />}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      sx={{
        backgroundColor: 'background.default',
      }}
      paperSx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        backgroundColor: 'inherit',
        overflowX: 'auto',
        width: '100%',
      }}
    >
      {sumLoading ? (
        <>
          {expanded ? (
            <>
              <LoadingLegend />
              <Stack
                sx={{
                  flexDirection: 'row',
                  gap: 2,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <LoadingChartSkeleton />
                <LoadingChartSkeleton />
                <LoadingChartSkeleton />
                <LoadingChartSkeleton />
                <LoadingChartSkeleton />
              </Stack>
            </>
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: 2,
                backgroundColor: 'background.default',
              }}
            />
          )}
        </>
      ) : (
        <>
          <SharedPieLegend items={pieLegendItems} />
          {isEmpty ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                color: theme.palette.text.secondary,
                padding: 2,
              }}
            >
              <PieChartRoundedIcon
                sx={{
                  fontSize: 48,
                  color: theme.palette.text.secondary,
                  mb: 1,
                }}
              />
              <Typography variant="h6">No Data Available</Typography>
              <Typography variant="body2">
                Please adjust the filters or try again later.
              </Typography>
            </Box>
          ) : (
            FLOW_SUM_CATEGORIES.map((category, index) => {
              const chartData = getChartData(category);
              if (!chartData) {
                return null;
              }

              return (
                <React.Fragment key={category}>
                  {index !== 0 && (
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={{
                        borderColor: alpha(theme.palette.text.secondary, 0.35),
                      }}
                    />
                  )}
                  <SummaryCardWrapper
                    title={category.toUpperCase()}
                    description={FLOW_SUM_CATEGORY_DESCRIPTION[category]}
                  >
                    <ChartContainer>
                      <RatioPieChart
                        data={chartData}
                        colors={DEFAULT_FLOW_SUM_COLORS}
                      />
                    </ChartContainer>
                  </SummaryCardWrapper>
                </React.Fragment>
              );
            })
          )}
        </>
      )}
    </SGAccordion>
  );
});

DataGridFlowSummary.displayName = 'DataGridFlowSummary';

export default DataGridFlowSummary;
