import { PreferencesTab } from '../types';
import { isBloomberg } from '../util';
import { dayjs, ET } from '../util/shared/date';
import { Dayjs } from 'dayjs';

export const EDITABLE_ACCOUNT_DETAILS = isBloomberg()
  ? []
  : [
      {
        field: 'first_name',
        label: 'First Name',
        type: 'string',
      },
      {
        field: 'last_name',
        label: 'Last Name',
        type: 'string',
      },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
      },
    ];

export const PREFERENCES_LABEL_MAPPING = new Map(
  isBloomberg()
    ? [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Alerts, 'Alerts'],
      ]
    : [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Payments, 'Payments'],
        [PreferencesTab.Watchlists, 'Watchlists'],
        [PreferencesTab.Alerts, 'Alerts'],
      ],
);

// putting everything in one place so it can be easily removed later

export type TokenTimeframe = {
  minDate: Dayjs;
  maxDate: Dayjs;
  token: string;
};

export const OH_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTcG90R2FtbWEiLCJ1c2VyX2lkIjoiY2RhYTkxYmItYWZiMC00ZmI2LWE2ZGQtNjZkNzEzMWRhNDliIiwiaXNfaW5zdGl0dXRpb25hbCI6ZmFsc2UsIndwSUQiOjE2NjExLCJlbWFpbCI6ImVuZythbHBoYUBzcG90Z2FtbWEuY29tIiwibWVtYmVyc2hpcHMiOlsiQWxwaGEiXSwicm9sZXMiOlsic3Vic2NyaWJlciJdLCJ1c2VyVHlwZSI6MSwid3BUb2tlbiI6eyJhY2Nlc3NfdG9rZW4iOiJxMGh1aTQzcHZvYW5scWdsbXFsY3NmaTdyand6bm52a2pzY3Vpdm9hIiwiZXhwaXJlc19pbiI6MjU5MjAwLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwic2NvcGUiOiJiYXNpYyIsInJlZnJlc2hfdG9rZW4iOiIwcDZybTB0cDg0MmV6ZmVveHM2cW11emV5OWtzMGl3aXJtZTV2dHpuIn0sImlhdCI6MTc0MzU0MTY5OSwiZXhwIjoxNzQzOTk4NDAwfQ.HcXpQnPBrc-18N6drG_389WaXVr65bhnp-taOQMoovs';

export const ALPHA_TOKEN_OVERRIDES: TokenTimeframe[] = [
  {
    minDate: dayjs.tz('2025-04-02 00:00', ET),
    maxDate: dayjs.tz('2025-04-07 00:00', ET),
    token: OH_TOKEN,
  },
];
