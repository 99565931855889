import { ReactNode, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentProductTypeState,
  isMobileState,
  pageBannerOpenMapState,
  userDetailsState,
  userSubLevelState,
} from '../../states';
import { PageBanner } from './PageBanner';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Link as MUILink,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { showNoLoginUI, isValidTokenOverrideTimeframe } from '../../util';
import { blue } from '@mui/material/colors';
import useAuth from '../../hooks/auth/useAuth';
import { ProductType, SubLevel } from '../../types';
import useSynthOi from 'hooks/equityhub/useSynthOi';

export type PageProps = {
  children: ReactNode | ReactNode[];
  showLoggedOutBanner?: boolean;
  sx?: SxProps;
};

export const Page = ({
  children,
  showLoggedOutBanner = true,
  sx = {},
}: PageProps) => {
  const userDetails = useRecoilValue(userDetailsState);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useRecoilValue(isMobileState);
  const [pageBannerOpenMap, setPageBannerOpenMap] = useRecoilState(
    pageBannerOpenMapState,
  );
  const currentProductType = useRecoilValue(currentProductTypeState);
  const userSubLevel = useRecoilValue(userSubLevelState);
  const { isSynthOI } = useSynthOi();
  const { productsWithAccess } = useAuth();

  const hasAccess = () => {
    if (
      currentProductType === ProductType.EQUITYHUB &&
      userSubLevel === SubLevel.PRO
    ) {
      return !isSynthOI; // Pro users get access to EquityHub but not with SynthOI model
    }

    return currentProductType && productsWithAccess.has(currentProductType);
  };

  const shouldBannerOpenOnInit =
    (userDetails == null ||
      (!hasAccess() && isValidTokenOverrideTimeframe())) &&
    showLoggedOutBanner &&
    !showNoLoginUI();

  const isOpen =
    currentProductType != null &&
    pageBannerOpenMap?.get(currentProductType) === true;

  const setIsOpen = (open: boolean) => {
    if (currentProductType != null) {
      setPageBannerOpenMap(
        (prev) => new Map([...prev.entries(), [currentProductType, open]]),
      );
    }
  };

  useEffect(() => {
    if (shouldBannerOpenOnInit) {
      setIsOpen(true);
    }
  }, [currentProductType]);

  // Reset banner visibility when URL changes
  useEffect(() => {
    if (isValidTokenOverrideTimeframe() && showLoggedOutBanner) {
      setIsOpen(true);
    }
  }, [location.pathname]);

  const renderBannerContent = () => {
    const textStyles = {
      fontSize: '14px',
      maxWidth: '80%',
      display: 'inline-block',
      color: '#fff',
    };

    const linkStyles = { color: blue[300], textDecorationColor: blue[300] };

    const normalLoggedOutContent = (
      <Typography sx={{ ...textStyles }}>
        Viewing the free SpotGamma Preview. Have an account?{' '}
        <MUILink
          href={undefined}
          onClick={() => navigate('/login')}
          sx={{ ...linkStyles }}
        >
          Click here
        </MUILink>{' '}
        to login.
      </Typography>
    );

    const nonSubscriberPromoContent = (
      <Typography sx={{ ...textStyles }}>
        Enjoy SpotGamma unlocked access through April 6th.{' '}
        <MUILink
          href="https://spotgamma.com/eyechart/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          Sign up by Sunday
        </MUILink>{' '}
        to get SpotGamma Alpha (Annual) for 50% OFF your first year.
      </Typography>
    );

    const monthlySubscriberPromoContent = (
      <Typography sx={{ ...textStyles }}>
        Enjoy SpotGamma Alpha tools unlocked access through April 6th.{' '}
        <MUILink
          href="https://spotgamma.com/subscribe-to-spotgamma/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          Sign up by Sunday
        </MUILink>{' '}
        to get SpotGamma Alpha (Annual) for 50% OFF your first year.
      </Typography>
    );

    const annualSubscriberPromoContent = (
      <Typography sx={{ ...textStyles }}>
        Enjoy SpotGamma Alpha tools unlocked access through April 6th.{' '}
        <MUILink
          href="https://spotgamma.com/account/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          Upgrade to Alpha by Sunday
        </MUILink>
        , before prices increase.
      </Typography>
    );

    const hiroDelayedContent = (
      <Typography sx={{ ...textStyles }}>
        Viewing delayed HIRO.{' '}
        <MUILink
          href="https://spotgamma.com/subscribe-to-spotgamma/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          Click here
        </MUILink>{' '}
        to upgrade to Alpha to get full access to HIRO.
      </Typography>
    );

    if (!currentProductType) {
      return null; // no need for banner on non-product pages
    }

    // During promo period
    if (isValidTokenOverrideTimeframe()) {
      // Non-subscribers
      if (userDetails == null) {
        return (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            {nonSubscriberPromoContent}
          </Stack>
        );
      }

      // Subscribers without access to current product (likely Standard/Pro users viewing Alpha products)
      if (!hasAccess()) {
        // Check if user is on monthly or annual plan
        const isAnnual = userDetails.wordpress?.active_memberships.some(
          (membership: { title: string }) =>
            membership.title.includes('Annual'),
        );

        return (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            {isAnnual
              ? annualSubscriberPromoContent
              : monthlySubscriberPromoContent}
          </Stack>
        );
      }

      return null; // Alpha users don't need a promo banner
    }

    // Outside of promo period - show regular banners
    if (userDetails == null) {
      if (currentProductType === ProductType.HIRO) {
        return hiroDelayedContent;
      }
      return normalLoggedOutContent;
    }

    if (!hasAccess()) {
      if (currentProductType === ProductType.HIRO) {
        return hiroDelayedContent;
      }
    }

    return null;
  };

  const renderedBanner = renderBannerContent();

  return (
    <Box
      sx={{
        height: isMobile
          ? undefined
          : `calc(100vh - ${isOpen ? '10px' : '120px'})`,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box sx={{ display: renderedBanner != null ? 'block' : 'none' }}>
        <PageBanner
          message={renderedBanner}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </Box>
      {children}
    </Box>
  );
};
