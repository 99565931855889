import { useRecoilState, useRecoilValue } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsPopout from '../../shared/SettingsPopout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import {
  iVolErrorState,
  showEconomicEventsState,
  vixDisplayTypeState,
  vixUsedLineColorsState,
  vixVisibleChartLinesState,
  vixDateSelectorState,
  vixSelectedDatesState,
} from 'states/iVol';
import { useCallback, useMemo } from 'react';
import {
  getCurrentDate,
  getDefaultSlotProps,
  getQueryDate,
  isBusinessDay,
  isMarketOpenOnDate,
  nextBusinessDay,
  prevBusinessDayOpenMarket,
} from 'util/shared';
import { DailyGreeks, ExpirationsDisplayType } from 'types';
import { TimeFramePill } from '../TimeFramePill';
import { MIN_QUERY_DATE, getNextAvailableColor } from 'util/iVol';
import { timezoneState } from 'states';

interface VixControlsProps {
  compressed?: bool;
}

const VixControls = ({ compressed }: VixControlsProps) => {
  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useRecoilState(vixDateSelectorState);
  const [visibleChartLines, setVisibleChartLines] = useRecoilState(
    vixVisibleChartLinesState,
  );

  const defaultSlotProps = getDefaultSlotProps(theme);

  const [showEconomicEvents, setShowEconomicEvents] = useRecoilState(
    showEconomicEventsState,
  );

  const availableColors = Object.values(theme.palette.iVol.termStructure);

  const [vixUsedLineColors, setVixUsedLineColors] = useRecoilState(
    vixUsedLineColorsState,
  );

  const [selectedDisplayType, setSelectedDisplayType] =
    useRecoilState(vixDisplayTypeState);

  const [vixSelectedDates, setVixSelectedDates] = useRecoilState(
    vixSelectedDatesState,
  );

  const currentTimezone = useRecoilValue(timezoneState);

  const error = useRecoilValue(iVolErrorState);

  const maxQueryDate = getQueryDate(true);

  const onAddNewTimeFrame = useCallback(
    async (dateToAdd: Dayjs) => {
      const tradeDate = dateToAdd.tz(currentTimezone);
      const formattedDate: string = tradeDate.format('YYYY-MM-DD');

      const color =
        getNextAvailableColor(vixUsedLineColors, availableColors) ??
        theme.palette.primary.main;

      setVixSelectedDates((prev: SelectedDateLine[]) => [
        ...prev,
        {
          key: formattedDate,
          tradeDate: tradeDate.isSame(getCurrentDate(), 'day')
            ? getCurrentDate(currentTimezone)
            : tradeDate,
          color:
            getNextAvailableColor(vixUsedLineColors, availableColors) ??
            theme.palette.primary.main,
        },
      ]);
      setVixUsedLineColors((prev: string[]) => [...prev, color]);
      setVisibleChartLines((prevData: string[]) => [
        ...prevData,
        formattedDate,
      ]);
      // automatically change date picker to prev business day with an open market
      if (
        prevBusinessDayOpenMarket(dateToAdd).isAfter(
          prevBusinessDayOpenMarket(MIN_QUERY_DATE),
          'day',
        )
      ) {
        setSelectedDate(prevBusinessDayOpenMarket(dateToAdd));
      }
    },
    [
      vixUsedLineColors,
      setVixUsedLineColors,
      setVisibleChartLines,
      setVixSelectedDates,
      setSelectedDate,
      availableColors,
      currentTimezone,
      theme.palette.primary.main,
    ],
  );

  const onToggleLineVisiblity = useCallback(
    (key: string, toShow: boolean) => {
      const updatedVisibleLines: string[] = toShow
        ? [...visibleChartLines, key]
        : visibleChartLines.filter((line: string) => line !== key);
      setVisibleChartLines(updatedVisibleLines);
    },
    [visibleChartLines, setVisibleChartLines],
  );

  const onChangeXDisplayType = (displayType: ExpirationsDisplayType) => {
    setSelectedDisplayType(displayType);
  };

  const onDeleteTimeFrame = (key: string) => {
    const updatedgreeks = vixSelectedDates.filter(
      (dailyGreeks: DailyGreeks) => dailyGreeks.key !== key,
    );
    const updatedUsedColors = updatedgreeks.map((dg: DailyGreeks) => dg.color);

    setVixUsedLineColors(updatedUsedColors);
    setVixSelectedDates(updatedgreeks);
    setVisibleChartLines(visibleChartLines.filter((l) => l !== key));
  };

  const reachedMaximumDates = useMemo(
    () => vixSelectedDates.length === availableColors.length,
    [vixSelectedDates, availableColors],
  );

  const isSelectedDateValid = useMemo(() => {
    return (
      selectedDate &&
      selectedDate.isValid() &&
      isBusinessDay(selectedDate) &&
      selectedDate.isAfter(prevBusinessDayOpenMarket(MIN_QUERY_DATE), 'day') &&
      selectedDate.isBefore(nextBusinessDay(maxQueryDate), 'day') &&
      !vixSelectedDates.some((dailyGreeks: DailyGreeks) =>
        dailyGreeks.tradeDate.isSame(selectedDate, 'day'),
      )
    );
  }, [maxQueryDate, selectedDate, vixSelectedDates]);

  const datePickerHelpText = useMemo(() => {
    if (reachedMaximumDates) {
      return 'Maximum number of dates used';
    }
    if (error) {
      return 'Failed to fetch data for selected date. Try again!';
    } else if (selectedDate.isBefore(MIN_QUERY_DATE, 'day')) {
      return `No data available for ${selectedDate.format(
        'YYYY-MM-DD',
      )}. Try another date!`;
    }
    return null;
  }, [error, reachedMaximumDates, selectedDate]);

  return (
    <Grid container>
      <Grid item xs={compressed ? 6 : 12}>
        <Stack sx={{ textAlign: 'left' }} spacing={5}>
          <ButtonGroup
            aria-label="outlined secondary button group"
            sx={{ alignSelf: 'left', flexWrap: 'wrap' }}
          >
            {Object.values(ExpirationsDisplayType).map(
              (type: ExpirationsDisplayType) => (
                <Button
                  key={type}
                  sx={{
                    fontFamily: 'SF Pro Display',
                    backgroundColor:
                      selectedDisplayType === type
                        ? alpha(theme.palette.secondary.main, 0.25)
                        : 'transparent',
                    '&:hover': {
                      color: alpha(theme.palette.secondary.main, 1),
                      borderColor: alpha(theme.palette.secondary.main, 1),
                    },
                    fontSize: 14,
                    textTransform: 'none',
                    width: '150px',
                  }}
                  onClick={() => {
                    onChangeXDisplayType(type);
                  }}
                >
                  {type}
                </Button>
              ),
            )}
          </ButtonGroup>
          <FormControl component="fieldset">
            <FormGroup
              aria-label="form-control"
              sx={{
                width: '300px',
                justifyContent: 'space-between',
              }}
              row
            >
              {selectedDisplayType ===
                ExpirationsDisplayType.ExpirationDate && (
                <FormControlLabel
                  sx={{
                    marginLeft: 0,
                  }}
                  control={
                    <Switch
                      sx={{
                        '& .MuiSwitch-track': {
                          opacity: 0.25,
                          backgroundColor: theme.palette.success.main,
                        },
                        '&.Mui-checked .MuiSwitch-track': {
                          opacity: 1,
                        },
                      }}
                      color="success"
                      checked={showEconomicEvents}
                      onChange={(_e, checked: boolean) =>
                        setShowEconomicEvents(checked)
                      }
                    />
                  }
                  label="Economic Events"
                  labelPlacement="top"
                />
              )}
            </FormGroup>
          </FormControl>
          <Typography sx={{ fontSize: '12px', marginBottom: '4px' }}>
            Add Additional Dates to Compare
          </Typography>
          <Stack direction="row" spacing={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['day']}
                label="From Date"
                value={selectedDate}
                disabled={reachedMaximumDates}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    setSelectedDate(newValue);
                  }
                }}
                shouldDisableDate={(date: Dayjs) =>
                  vixSelectedDates.some((dailyGreeks: DailyGreeks) =>
                    dailyGreeks.tradeDate.isSame(date, 'day'),
                  ) ||
                  !isBusinessDay(date) ||
                  date.isBefore(MIN_QUERY_DATE, 'day') ||
                  !isMarketOpenOnDate(date)
                }
                format="YYYY-MM-DD"
                minDate={MIN_QUERY_DATE}
                maxDate={maxQueryDate}
                slotProps={{
                  ...defaultSlotProps,
                  textField: {
                    ...defaultSlotProps.textField,
                    style: {
                      width: '180px',
                      marginBottom: '12px',
                      fontSize: '14px',
                    },
                    helperText: datePickerHelpText,
                    error: error != null,
                  },
                }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              size="small"
              disabled={!isSelectedDateValid || reachedMaximumDates}
              sx={{
                width: '45px',
                height: '37px',
                marginLeft: '20px',
                fontFamily: 'Satoshi Complete',
                fontSize: 14,
                textTransform: 'capitalize',
              }}
              onClick={() => {
                onAddNewTimeFrame(selectedDate);
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={compressed ? 6 : 12}>
        <Stack sx={{ textAlign: 'left' }} spacing={5}>
          {vixSelectedDates.length > 0 && !compressed ? (
            <Divider
              style={{
                borderColor: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
              }}
            />
          ) : null}
          <List
            style={{
              marginTop: compressed ? 0 : 4,
              paddingTop: compressed ? 0 : undefined,
            }}
          >
            {vixSelectedDates.map((dailyGreeks: DailyGreeks) => {
              return (
                <TimeFramePill
                  key={dailyGreeks.key}
                  identifier={dailyGreeks.key}
                  date={dailyGreeks.tradeDate}
                  visibleLines={visibleChartLines}
                  onToggleLineVisiblity={onToggleLineVisiblity}
                  onDelete={onDeleteTimeFrame}
                  icon={<ShowChartIcon />}
                  bgColor={dailyGreeks.color}
                  sx={{
                    marginTop: '0',
                    marginBottom: '2%',
                  }}
                />
              );
            })}
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const VixChartSettings = ({ compressed }: VixControlsProps) => {
  return (
    <SettingsPopout
      title="VIX Settings"
      popperID="vix-controls"
      placement="bottom-end"
      sx={{
        width: compressed ? '750px' : '350px',
        opacity: 1,
      }}
    >
      <VixControls compressed={compressed} />
    </SettingsPopout>
  );
};
