import {
  Box,
  ClickAwayListener,
  Divider,
  Popper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Label, ProductType, Scanner } from '../../../types';
import { RecoilState, useRecoilValue } from 'recoil';
import { isMobileState } from '../../../states';
import { Tag, UpsellModal } from '../../shared';
import {
  EQUITYHUB_UPSELL,
  SCANNER_LIST,
  SCANNER_NAME_MAPPING,
  SG_SPECIAL_SCANNERS,
} from '../../../config';
import GALogger, { GALoggerCategory } from '../../../util/shared/analytics';
import { SGTooltip } from '../../core';
import useAuth from '../../../hooks/auth/useAuth';
import useActiveScanner from '../../../hooks/scanners/useActiveScanner';

interface ScannerProps {
  label: Label;
  scanner: Scanner;
  activeScanner: Scanner | undefined;
  setActiveScanner: (scanner: Scanner | undefined) => void;
  hideTooltip?: boolean | undefined;
}

interface ScannerSectionProps {
  label: Label;
  activeScanner: Scanner | undefined;
  setActiveScanner: (scanner: Scanner | undefined) => void;
  scanners: {
    label: Label;
    scanner: Scanner;
  }[];
  hideTooltip?: boolean;
}

export const ScannerButton = ({
  label,
  scanner,
  activeScanner,
  setActiveScanner,
  hideTooltip,
}: ScannerProps) => {
  const { name, description, icon } = label;
  const theme = useTheme();
  const logger = new GALogger(GALoggerCategory.Scanners);
  const isMobile = useRecoilValue(isMobileState);
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);

  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);

  const setScanner = (newScanner: Scanner) => {
    logger.logEvent(newScanner);
    if (hasScannersAccess) {
      setActiveScanner(newScanner);
    } else {
      setUpsellOpen(true);
    }
  };

  const btn = (
    <Button
      variant="outlined"
      size="small"
      sx={{
        fontSize: isMobile ? 11 : 13,
        textTransform: 'capitalize',
        borderWidth: 2,
        backgroundColor:
          activeScanner === scanner
            ? alpha(theme.palette.primary.main, 0.25)
            : null,
        ':hover': {
          borderWidth: 2,
        },
      }}
      onClick={() =>
        activeScanner === scanner
          ? setActiveScanner(undefined)
          : setScanner(scanner)
      }
      startIcon={icon}
    >
      {name}
    </Button>
  );

  return (
    <>
      {hideTooltip ? (
        btn
      ) : (
        <SGTooltip
          title={description}
          placement="top"
          arrow
          enterTouchDelay={0} // show the tooltip immediately upon touch
          leaveTouchDelay={3000} // tooltip will remain visible for 3 seconds after user stops touching
        >
          {btn}
        </SGTooltip>
      )}
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </>
  );
};

export const ScannerSection = ({
  label,
  scanners,
  hideTooltip,
  activeScanner,
  setActiveScanner,
}: ScannerSectionProps) => {
  return (
    <Box
      sx={{ display: 'flex', width: 'full', gap: 3, flexDirection: 'column' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'top',
          gap: '6px',
        }}
      >
        <Typography>{label.name} </Typography>
        <Typography>{label.icon} </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '3px',
        }}
      >
        {scanners.map((scanner) => (
          <ScannerButton
            label={scanner.label}
            scanner={scanner.scanner}
            key={`scanner-${scanner.label.name}`}
            hideTooltip={hideTooltip}
            activeScanner={activeScanner}
            setActiveScanner={setActiveScanner}
          />
        ))}
      </Box>
    </Box>
  );
};

interface ScannersProps {
  ehActiveScannerState: RecoilState<Scanner | undefined>;
}

export const Scanners = ({ ehActiveScannerState }: ScannersProps) => {
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<any>(null);
  const [tab, setTab] = useState<'main' | 'other'>('main');
  const theme = useTheme();
  const [openScanners, setOpenScanners] = useState(false);
  const { activeScanner, setActiveScanner } =
    useActiveScanner(ehActiveScannerState);
  const isMobile = useRecoilValue(isMobileState);

  const handleClickAway = () => {
    setOpenScanners(false);
  };

  return (
    <>
      <Button
        aria-label="equity-scanners-button"
        sx={{
          border: '1px solid',
          borderColor: theme.palette.primary.main,
          borderRadius: theme.spacing(3),
          maxHeight: 31,
        }}
        onClick={(event) => {
          setAnchorSettingsEl(event.currentTarget);
          setOpenScanners((previousOpen) => !previousOpen);
        }}
      >
        <SGTooltip title="Scanners">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <QrCodeScannerIcon sx={{ fontSize: 18 }} />
            <Typography
              sx={{
                fontFamily: 'SF Pro Display',
                fontSize: 12,
                textTransform: 'capitalize',
                color: theme.palette.primary.main,
              }}
            >
              Scanners
            </Typography>
            {activeScanner && (
              <Tag
                label={SCANNER_NAME_MAPPING[activeScanner]}
                color={theme.palette.primary.main}
              />
            )}
          </Box>
        </SGTooltip>
      </Button>
      <Popper
        id="equity-scanners"
        open={openScanners}
        anchorEl={anchorSettingsEl}
        placement={isMobile ? 'bottom' : 'top-start'}
        style={{ zIndex: 100 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              ...(isMobile ? { width: '100%' } : { width: '550px' }),
              height: 'auto',
              backgroundColor: theme.palette.background.paper,
              flexDirection: 'column',
              display: 'flex',
              padding: '16px',
              boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
              borderRadius: theme.spacing(3),
              gap: theme.spacing(4),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '3px',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                variant="text"
                size="small"
                color="inherit"
                sx={{
                  backgroundColor:
                    tab === 'main'
                      ? alpha(theme.palette.button.default, 0.3)
                      : 'none',
                  fontFamily: 'Satoshi Complete',
                  fontSize: 12,
                  textTransform: 'capitalize',
                  color: theme.palette.text.primary,
                  flex: 1,
                }}
                onClick={() => {
                  setTab('main');
                }}
              >
                Main Scanners
              </Button>
              <Button
                variant="text"
                size="small"
                color="inherit"
                sx={{
                  backgroundColor:
                    tab === 'other'
                      ? alpha(theme.palette.button.default, 0.3)
                      : 'none',
                  fontFamily: 'Satoshi Complete',
                  fontSize: 12,
                  textTransform: 'capitalize',
                  color: theme.palette.text.primary,
                  flex: 1,
                }}
                onClick={() => setTab('other')}
              >
                Other Scanners
              </Button>
            </Box>
            {tab === 'main' ? (
              <ScannerSection
                key="Main Scanners"
                label={{
                  name: '',
                }}
                scanners={SG_SPECIAL_SCANNERS}
                activeScanner={activeScanner}
                setActiveScanner={setActiveScanner}
              />
            ) : (
              SCANNER_LIST.map((scanner) => (
                <ScannerSection
                  key={scanner.label.name}
                  label={scanner.label}
                  scanners={scanner.scanners}
                  activeScanner={activeScanner}
                  setActiveScanner={setActiveScanner}
                />
              ))
            )}

            <Divider
              flexItem
              sx={{
                marginTop: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '3px',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                variant="text"
                size="small"
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi Complete',
                  fontSize: 12,
                  textTransform: 'capitalize',
                  color: theme.palette.text.secondary,
                  flex: 1,
                }}
                onClick={() => {
                  setActiveScanner(undefined);
                }}
              >
                Reset
              </Button>
              <Button
                variant="text"
                size="small"
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi Complete',
                  fontSize: 12,
                  textTransform: 'capitalize',
                  color: theme.palette.text.secondary,
                  flex: 1,
                }}
                onClick={() => setOpenScanners(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
