import * as d3 from "d3";
import { useRef, useEffect } from "react";

export interface D3PlotLine {
  data: number[][];
  stroke: string;
  fill?: string;
}

export interface D3PlotArea {
  data: number[][];
  fill: string;
  opacity: number;
}

export interface D3PlotProps {
  canvasWidth: number;
  canvasHeight: number;
  xDomain: number[];
  yDomain: number[];
  lines: D3PlotLine[];
  areas: D3PlotArea[];
  zoomExtent: [number, number];
  tooltipFormat: (data: number[]) => string;
}

export default function D3Plot(props: D3PlotProps) {
  const margin = { top: 20, right: 20, bottom: 40, left: 40 };
  const width = props.canvasWidth - margin.left - margin.right;
  const height = props.canvasHeight - margin.top - margin.bottom;

  const svgRef = useRef(null);

  const xScale = d3
    .scaleLinear()
    .domain([Math.min(...props.xDomain), Math.max(...props.xDomain)])
    .range([0, width]);

  const yScale = d3
    .scaleLinear()
    .domain([Math.min(...props.yDomain), Math.max(...props.yDomain)])
    .range([height, 0]);

  const xScaleOriginal = xScale.copy();
  const yScaleOriginal = yScale.copy();

  const removeTooltip = () => {
    const tooltip = document.querySelector('.tooltip');
    if (tooltip) {
      tooltip.remove();
    }
  };

  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);

      const renderChart = (xScale: any, yScale: any) => {
        svg.selectAll('*').remove();
        removeTooltip();

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        svg
          .append('defs')
          .append('clipPath')
          .attr('id', 'chart-clip')
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', width)
          .attr('height', height);

        const chartArea = svg
          .append('g')
          .attr('transform', `translate(${margin.left}, ${margin.top})`)
          .attr('clip-path', 'url(#chart-clip)');

        chartArea
          .selectAll('.x-grid-line')
          .data(xScale.ticks())
          .enter()
          .append('line')
          .attr('class', 'x-grid-line')
          .attr('x1', (d) => xScale(d))
          .attr('y1', 0)
          .attr('x2', (d) => xScale(d))
          .attr('y2', height)
          .attr('stroke', '#333333')
          .attr('stroke-width', 1);

        chartArea
          .selectAll('.y-grid-line')
          .data(yScale.ticks())
          .enter()
          .append('line')
          .attr('class', 'y-grid-line')
          .attr('x1', 0)
          .attr('y1', (d) => yScale(d))
          .attr('x2', width)
          .attr('y2', (d) => yScale(d))
          .attr('stroke', '#333333')
          .attr('stroke-width', 1);

        props.lines.forEach((line) => {
          const lineGenerator = d3
            .line()
            .x((d) => xScale(d[0]))
            .y((d) => yScale(d[1]));
          chartArea
            .append('path')
            .datum(line.data)
            // Argument of type Line<[number, number]> is not assignable to attr
            // @ts-ignore
            .attr('d', lineGenerator)
            .attr('stroke', line.stroke)
            .attr('fill', line.fill || 'none');
        });

        props.areas.forEach((area) => {
          const areaGenerator = d3
            .area<[number, number, number]>()
            .x((d) => xScale(d[0]))
            .y0((d) => yScale(d[1]))
            .y1((d) => yScale(d[2]));
          chartArea
            .append('path')
            .datum(area.data)
            // Argument of type Area<[number, number]> is not assignable to attr
            // @ts-ignore
            .attr('d', areaGenerator)
            .style('fill', area.fill)
            .style('opacity', area.opacity);
        });

        svg
          .append('g')
          .attr(
            'transform',
            `translate(${margin.left}, ${margin.top + height + 1})`,
          )
          .call(xAxis);

        svg
          .append('g')
          .attr('transform', `translate(${margin.left}, ${margin.top})`)
          .call(yAxis);

        const tooltip = d3
          .select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
          .style('position', 'absolute')
          .style('background', '#fff')
          .style('border', '1px solid #ccc')
          .style('border-radius', '4px')
          .style('padding', '8px')
          .style('pointer-events', 'none')
          .style('font-size', '12px');

        const crosshairX = chartArea
          .append('line')
          .attr('class', 'crosshair')
          .attr('stroke', 'grey')
          .attr('stroke-dasharray', '3,3')
          .attr('opacity', 0);

        const crosshairY = chartArea
          .append('line')
          .attr('class', 'crosshair')
          .attr('stroke', 'grey')
          .attr('stroke-dasharray', '3,3')
          .attr('opacity', 0);

        chartArea
          .append('rect')
          .attr('width', width)
          .attr('height', height)
          .style('fill', 'none')
          .style('pointer-events', 'all')
          .on('mousemove', function (event) {
            const [x, _] = d3.pointer(event);
            const traceX = xScale.invert(x);
            const closestData = props.lines[0].data.reduce((a, b) =>
              Math.abs(b[0] - traceX) < Math.abs(a[0] - traceX) ? b : a,
            );

            tooltip
              .html(props.tooltipFormat(closestData))
              .style('opacity', 1)
              .style('left', `${event.pageX + 10}px`)
              .style('top', `${event.pageY - 10}px`);

            crosshairX
              .attr('x1', xScale(closestData[0]))
              .attr('x2', xScale(closestData[0]))
              .attr('y1', 0)
              .attr('y2', height)
              .attr('opacity', 1);

            crosshairY
              .attr('x1', 0)
              .attr('x2', width)
              .attr('y1', yScale(closestData[1]))
              .attr('y2', yScale(closestData[1]))
              .attr('opacity', 1);
          })
          .on('mouseout', () => {
            tooltip.style('opacity', 0);
            crosshairX.attr('opacity', 0);
            crosshairY.attr('opacity', 0);
          });

        chartArea
          .append('line')
          .attr('class', 'crosshair')
          .attr('stroke', 'grey')
          .attr('stroke-dasharray', '3,3')
          .attr('opacity', 0);
        chartArea
          .append('line')
          .attr('class', 'crosshair')
          .attr('stroke', 'grey')
          .attr('stroke-dasharray', '3,3')
          .attr('opacity', 0);
      };

      let zoom = d3
        .zoom()
        .scaleExtent(props.zoomExtent)
        .translateExtent([
          [0, 0],
          [
            width + margin.left + margin.right,
            height + margin.top + margin.bottom,
          ],
        ])
        .on('zoom', (event) => {
          const transform = event.transform;
          const newXScale = transform.rescaleX(xScaleOriginal);
          const newYScale = transform.rescaleY(yScaleOriginal);

          renderChart(newXScale, newYScale);
        });

      // Argument of type ZoomBehavior<Element> is not assignable to Selection<...>
      // @ts-ignore
      svg.call(zoom);
      renderChart(xScale, yScale);
    }
  }, [
    props.canvasWidth,
    props.canvasHeight,
    props.xDomain,
    props.yDomain,
    props.zoomExtent,
  ]);

  return (
    <svg
      style={{ width: '100%', height: '100%', cursor: 'move' }}
      viewBox={`0 0 ${props.canvasWidth} ${props.canvasHeight}`}
      preserveAspectRatio="xMinYMin meet"
      ref={svgRef}
    />
  );
};
