import { ComponentHeader, InfoButton, SGSelect } from '../../shared';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Typography,
  alpha,
  useTheme,
  Popper,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DEFAULT_COMPASS_COLORS,
  StrategyCompassMode,
  StrategyCompassModeXMap,
  StrategyCompassModeYMap,
  StrategyCompassModeZMap,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisReadableMap,
} from '../../../types/compass';
import { getModeLabel } from '../../../util/compass';
import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { SGTooltip } from '../../core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobileState } from '../../../states';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import SaveIcon from '@mui/icons-material/Save';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { useEffect, useMemo, useState } from 'react';
import useUserDetails from 'hooks/user/useUserDetails';
import { v4 as uuidv4 } from 'uuid';
import {
  compassIsZoomedState,
  compassNegColorState,
  compassPosColorState,
  compassSavedViewsState,
  compassZoomDomainState,
} from 'states/compass';
import StrategyCompassSavedViews from './StrategyCompassSavedViews';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import SettingsPopout from '../../shared/SettingsPopout';
import { MuiColorInput } from 'mui-color-input';
import { isValidHex } from '../../../util';
import useToast from '../../../hooks/useToast';
import useSynthOi from 'hooks/equityhub/useSynthOi';

type StrategyCompassControlsProps = {
  editable: boolean;
  loading: boolean;
  chartSize: { width: number; height: number };
  compassParams: CompassParams;
};

export const StrategyCompassControls = ({
  editable,
  loading,
  chartSize,
  compassParams,
}: StrategyCompassControlsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const compassSavedViews = useRecoilValue(compassSavedViewsState);
  const { saveSgSettings } = useUserDetails();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [newViewName, setNewViewName] = useState('');
  const [savingView, setSavingView] = useState(false);
  const [savedViewsAnchorEl, setSavedViewsAnchorEl] =
    useState<HTMLElement | null>(null);
  const { openToast } = useToast();

  const setIsZoomed = useSetRecoilState(compassIsZoomedState);
  const setZoomDomain = useSetRecoilState(compassZoomDomainState);

  const compassPosColor = useRecoilValue(compassPosColorState);
  const compassNegColor = useRecoilValue(compassNegColorState);

  // Use local state for colors that will be saved to user settings
  const [posColor, setPosColor] = useState<string>(compassPosColor);
  const [negColor, setNegColor] = useState<string>(compassNegColor);

  const { xAxis, yAxis, setYAxis, setXAxis, mode, setMode, zAxis, setZAxis } =
    compassParams;

  const { isSynthOI } = useSynthOi();

  if (!editable) {
    return null;
  }

  const handleSaveCurrentView = async () => {
    if (!newViewName.trim()) return;

    try {
      setSavingView(true);
      const newView = {
        id: uuidv4(),
        name: newViewName.trim(),
        xAxis: xAxis,
        yAxis: yAxis,
        zAxis: zAxis,
      };

      const updatedViews = [...compassSavedViews, newView];
      await saveSgSettings({ compass: { savedViews: updatedViews } });
      setSaveDialogOpen(false);
      setNewViewName('');
    } catch (error) {
      console.error('Failed to save view:', error);
    } finally {
      setSavingView(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && newViewName.trim() && !savingView) {
      event.preventDefault();
      handleSaveCurrentView();
    }
  };

  const handleOpenSavedViews = (event: React.MouseEvent<HTMLElement>) => {
    setSavedViewsAnchorEl(event.currentTarget);
  };

  const savedViewsOpen = Boolean(savedViewsAnchorEl);

  const selectSx = isMobile
    ? {
        '.MuiSelect-icon': {
          display: 'none !important',
        },
        '.MuiSelect-select': {
          paddingX: '10px !important',
        },
      }
    : undefined;

  const handleColorChange = (newColor: string, isPositive: boolean) => {
    // Just update local state during color selection
    if (isPositive) {
      setPosColor(newColor);
    } else {
      setNegColor(newColor);
    }
  };

  const saveColorChanges = (newColor: string, isPositive: boolean) => {
    if (!isValidHex(newColor)) {
      openToast({
        message: 'Invalid color supplied. Please try again with a valid color.',
        type: 'error',
      });
      return;
    }

    // Save to user settings only when color selection is complete
    if (isPositive) {
      saveSgSettings({ compass: { posColor: newColor } });
    } else {
      saveSgSettings({ compass: { negColor: newColor } });
    }
  };

  useEffect(() => {
    if (isSynthOI) {
      // If in synthetic OI mode, we should avoid using proximity axes
      const isProximityAxis = (axis: StrategyCompassXYZAxis) => {
        return (
          axis === StrategyCompassXYZAxis.ProximityToCallWall ||
          axis === StrategyCompassXYZAxis.ProximityToPutWall
        );
      };

      // Change any proximity axes to defaults
      if (isProximityAxis(xAxis)) {
        setXAxis(StrategyCompassXYZAxis.IvPct);
      }

      if (isProximityAxis(yAxis)) {
        setYAxis(StrategyCompassXYZAxis.OneMonthIv);
      }

      if (zAxis && isProximityAxis(zAxis)) {
        setZAxis(StrategyCompassXYZAxis.None);
      }
    }
  }, [isSynthOI, xAxis, yAxis, zAxis, setXAxis, setYAxis, setZAxis]);

  const compassModeXOptions = useMemo(() => {
    return isSynthOI
      ? StrategyCompassModeXMap.get(mode)!.filter(
          (axis) =>
            axis !== StrategyCompassXYZAxis.ProximityToCallWall &&
            axis !== StrategyCompassXYZAxis.ProximityToPutWall,
        )
      : StrategyCompassModeXMap.get(mode)!;
  }, [isSynthOI, mode]);

  const compassModeYOptions = useMemo(() => {
    return isSynthOI
      ? StrategyCompassModeYMap.get(mode)!.filter(
          (axis) =>
            axis !== StrategyCompassXYZAxis.ProximityToCallWall &&
            axis !== StrategyCompassXYZAxis.ProximityToPutWall,
        )
      : StrategyCompassModeYMap.get(mode)!;
  }, [isSynthOI, mode]);

  const compassModeZOptions = useMemo(() => {
    return mode === StrategyCompassMode.Compass
      ? []
      : isSynthOI
      ? StrategyCompassModeZMap.get(mode)!.filter(
          (axis) =>
            axis !== StrategyCompassXYZAxis.ProximityToCallWall &&
            axis !== StrategyCompassXYZAxis.ProximityToPutWall,
        )
      : StrategyCompassModeZMap.get(mode)!;
  }, [isSynthOI, mode]);

  return (
    <>
      <ComponentHeader
        buttonsProps={{ flexGrow: 1 }}
        buttons={
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'space-between'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            flexGrow={1}
            gap={2}
            marginBottom="4px"
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 12, md: 14 },
                  fontWeight: 600,
                }}
              >
                Compass
              </Typography>
              <ButtonGroup>
                <SGTooltip title="Guided View shows users where any US stock sits across a 2D chart conveying market expecations for volatility and directional outlook, based on the options market.">
                  <Button
                    sx={{ textTransform: 'none', fontSize: '13px' }}
                    variant={
                      mode === StrategyCompassMode.Compass
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => setMode(StrategyCompassMode.Compass)}
                  >
                    {getModeLabel(StrategyCompassMode.Compass)}
                  </Button>
                </SGTooltip>
                <SGTooltip title="Explorer View allows you to select from a wide range of variables to discover relationships between different data points, for the names you trade.">
                  <Button
                    sx={{ textTransform: 'none', fontSize: '13px' }}
                    variant={
                      mode === StrategyCompassMode.Freeform
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => setMode(StrategyCompassMode.Freeform)}
                  >
                    {getModeLabel(StrategyCompassMode.Freeform)}
                  </Button>
                </SGTooltip>
              </ButtonGroup>
            </Stack>

            <Stack direction="row" gap="8px" alignItems="center">
              {loading || chartSize.width === 0 ? (
                <CircularProgress
                  size="18px"
                  color="primary"
                  sx={{ marginRight: '8px' }}
                />
              ) : null}

              {mode !== StrategyCompassMode.Compass && (
                <Stack direction="row" gap="8px">
                  <SGSelect
                    key="compass-mode-x"
                    label="X Axis"
                    multiple={false}
                    value={xAxis}
                    setter={setXAxis}
                    options={compassModeXOptions}
                    optionsTextTransform={(m) =>
                      `${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    valueTextTransform={(m) =>
                      `X: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    sx={selectSx}
                  />
                  <SGSelect
                    key="compass-mode-y"
                    label="Y Axis"
                    multiple={false}
                    value={yAxis}
                    setter={setYAxis}
                    options={compassModeYOptions}
                    optionsTextTransform={(m) =>
                      `${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    valueTextTransform={(m) =>
                      `Y: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    sx={selectSx}
                  />
                  <SGSelect
                    key="compass-mode-z"
                    label="Z Axis"
                    multiple={false}
                    value={zAxis ?? StrategyCompassXYZAxis.None}
                    setter={setZAxis}
                    options={compassModeZOptions}
                    optionsTextTransform={(m) =>
                      `${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    valueTextTransform={(m) =>
                      `Z: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                    }
                    selectTooltipProps={{
                      title:
                        'The Z axis is displayed as the size of the dot. The larger the dot, the higher the Z, and vice versa.',
                    }}
                    sx={selectSx}
                  />
                </Stack>
              )}

              {mode === StrategyCompassMode.Freeform && (
                <>
                  <Box>
                    <SGTooltip title="View saved configurations">
                      <IconButton
                        size="small"
                        onClick={handleOpenSavedViews}
                        color="primary"
                      >
                        <BookmarksIcon fontSize="small" />
                      </IconButton>
                    </SGTooltip>
                  </Box>
                  <Box>
                    <SGTooltip title="Save current configuration">
                      <IconButton
                        size="small"
                        onClick={() => setSaveDialogOpen(true)}
                        color="primary"
                      >
                        <SaveIcon fontSize="small" />
                      </IconButton>
                    </SGTooltip>
                  </Box>
                </>
              )}

              {mode === StrategyCompassMode.Compass && (
                <SettingsPopout
                  title="Compass Color Settings"
                  popperID={'compass-color-settings'}
                  zIndex={2}
                  placement={isMobile ? 'bottom' : 'left-start'}
                  icon={<InvertColorsIcon />}
                >
                  <Stack direction="row" gap="10px">
                    <Box display="flex" flexDirection="column" gap="6px">
                      <SGTooltip
                        placement={'top'}
                        title={
                          'This color is used for the upside potential area on compass chart'
                        }
                      >
                        <Typography variant="h4" gutterBottom color="primary">
                          Upside Color
                        </Typography>
                      </SGTooltip>

                      <MuiColorInput
                        format="hex8"
                        value={posColor}
                        onChange={(value: string) =>
                          handleColorChange(value, true)
                        }
                        PopoverProps={{
                          anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                          },
                          onClose: () => saveColorChanges(posColor, true),
                        }}
                        sx={{
                          width: '100%',
                          '& fieldset': {
                            borderColor: alpha(theme.palette.sgGreen, 0.85),
                          },
                          '&:hover fieldset': {
                            borderColor: theme.palette.sgGreen,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: theme.palette.sgGreen,
                          },
                        }}
                      />
                    </Box>

                    <Box display="flex" flexDirection="column" gap="6px">
                      <SGTooltip
                        placement={'top'}
                        title={
                          'This color is used for the downside potential area on compass chart'
                        }
                      >
                        <Typography variant="h4" gutterBottom color="primary">
                          Downside Color
                        </Typography>
                      </SGTooltip>
                      <MuiColorInput
                        format="hex8"
                        value={negColor}
                        onChange={(value: string) =>
                          handleColorChange(value, false)
                        }
                        PopoverProps={{
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'bottom',
                          },
                          onClose: () => saveColorChanges(negColor, false),
                        }}
                        sx={{
                          width: '100%',
                          '& fieldset': {
                            borderColor: alpha(theme.palette.sgGreen, 0.85),
                          },
                          '&:hover fieldset': {
                            borderColor: theme.palette.sgGreen,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: theme.palette.sgGreen,
                          },
                        }}
                      />
                    </Box>
                  </Stack>
                  <Box display="flex" mt={4} justifyContent="flex-end">
                    <Button
                      size="small"
                      onClick={() => {
                        // Reset to default colors
                        setPosColor(DEFAULT_COMPASS_COLORS.posColor);
                        setNegColor(DEFAULT_COMPASS_COLORS.negColor);
                        saveColorChanges(DEFAULT_COMPASS_COLORS.posColor, true);
                        saveColorChanges(
                          DEFAULT_COMPASS_COLORS.negColor,
                          false,
                        );
                      }}
                      sx={{
                        fontSize: '12px',
                        textTransform: 'none',
                      }}
                    >
                      Reset to Default
                    </Button>
                  </Box>
                </SettingsPopout>
              )}

              <PanelFullscreenButton panelType="main" />
              <SGTooltip title="Reset zoom">
                <IconButton
                  size="small"
                  onClick={() => {
                    setIsZoomed(false);
                    setZoomDomain({
                      x: [0 - 0.035, 1 + 0.035],
                      y: [0 - 0.035, 1 + 0.035],
                    });
                  }}
                  color="primary"
                >
                  <ZoomOutIcon fontSize="small" />
                </IconButton>
              </SGTooltip>
              <InfoButton articleKey={mode} />
            </Stack>
          </Stack>
        }
      />
      {/* Save View Dialog */}
      {compassParams.mode === StrategyCompassMode.Freeform && (
        <Dialog
          maxWidth="md"
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
        >
          <DialogTitle>Save View</DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ mb: 4 }}>
              <Typography variant="body1" color="text.secondary">
                You are saving the following configuration:
              </Typography>
              <Stack spacing={1} sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                  X: {StrategyCompassXYZAxisReadableMap.get(xAxis) || 'None'}
                </Typography>
                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                  Y: {StrategyCompassXYZAxisReadableMap.get(yAxis) || 'None'}
                </Typography>
                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                  Z:{' '}
                  {StrategyCompassXYZAxisReadableMap.get(
                    zAxis || StrategyCompassXYZAxis.None,
                  ) || 'None'}
                </Typography>
              </Stack>
            </Stack>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveCurrentView();
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                label="View Name"
                fullWidth
                value={newViewName}
                onChange={(e) => setNewViewName(e.target.value)}
                onKeyDown={handleKeyDown}
                variant="outlined"
                sx={{
                  width: '100%',
                  minWidth: '280px',
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.35),
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                }}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={handleSaveCurrentView}
              disabled={!newViewName.trim() || savingView}
              startIcon={savingView ? <CircularProgress size={16} /> : null}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Saved Views Popper - with manual close button */}
      {compassParams.mode === StrategyCompassMode.Freeform &&
        savedViewsOpen && (
          <Popper
            open={savedViewsOpen}
            anchorEl={savedViewsAnchorEl}
            placement="bottom-start"
            style={{ zIndex: 1300 }}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
          >
            <Paper
              sx={{
                p: 2,
                maxWidth: 400,
                maxHeight: 500,
                overflow: 'auto',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                boxShadow: `0 4px 8px ${alpha(
                  theme.palette.common.black,
                  0.15,
                )}`,
              }}
            >
              <Stack spacing={6} sx={{ padding: 4 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Saved Views</Typography>
                  <IconButton
                    onClick={() => {
                      setSavedViewsAnchorEl(null);
                    }}
                    size="small"
                    color="primary"
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <StrategyCompassSavedViews compassParams={compassParams} />
              </Stack>
            </Paper>
          </Popper>
        )}
    </>
  );
};
