import {
  Button,
  Typography,
  ListItem,
  ListItemText,
  Paper,
  Box,
  ListItemAvatar,
  Avatar,
  List,
  useTheme,
  Link,
} from '@mui/material';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { DISCORD_CONTENT_LIST } from '../../config';
import useDiscord from '../../hooks/resources/useDiscord';
import useCleanup from '../../hooks/useCleanup';
import Discord from '../../icons/Discord';
import { Page } from '../../pages/shared';
import { userDetailsState, userSubLevelState } from '../../states';
import { SubLevel } from '../../types';
import { Loader } from '../shared';

const DiscordButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  const userDetails = useRecoilValue(userDetailsState);
  return (
    <Button
      disabled={userDetails == null} // block access to logged out users
      color="secondary"
      onClick={onClick}
      sx={{ gap: '4px', alignSelf: 'center', py: 2, px: 4 }}
    >
      <Discord width="24px" />
      {label}
    </Button>
  );
};

export const DiscordSignup = () => {
  useCleanup();

  const theme = useTheme();
  const userDetails = useRecoilValue(userDetailsState);

  const [needRefresh, setNeedRefresh] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [discordCallbackResp, setDiscordCallbackResp] =
    useState<any>(undefined);
  const currentDiscord =
    userDetails?.wordpress?.profile.mepr_discord_username || '';
  const { updateMemberpressDiscord, getDiscordUser, updateDiscordRole } =
    useDiscord();
  const userSubLevel = useRecoilValue(userSubLevelState);

  const redir = useMemo(
    () => encodeURIComponent(`${window.location.origin}/resources/discord`),
    [window],
  );

  const loginDiscord = useCallback(() => {
    window.open(
      `${import.meta.env.VITE_SPOTGAMMA_API}/discord_login?redir=${redir}`,
      '_self',
    );
  }, [window, redir]);

  const openDiscord = useCallback(() => {
    window.open(
      `https://discord.com/channels/834160242316148829/834160242773852162`,
    );
  }, []);

  useEffect(() => {
    const getDiscord = async () => {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      let code = queryParams.get('code');
      if (code == null) {
        setLoggedIn(false);
        setIsLoading(false);
        return;
      }
      let discordDetails;
      try {
        discordDetails = await getDiscordUser({ code, redir });
        setDiscordCallbackResp(code === '' ? undefined : discordDetails);

        if (discordDetails?.error != null) {
          setError(discordDetails?.error);
          setNeedRefresh(true);
          setLoggedIn(false);
          setIsLoading(false);
          return;
        }

        const discordUserId = discordDetails?.user?.id ?? '';

        setLoggedIn(true);

        // Update WordPress w/ Discord ID
        updateMemberpressDiscord(discordUserId);

        // Add User to Guild/Update Users Role/Kick User
        const updateRoleResult = await updateDiscordRole({
          memberpressId: userDetails?.id,
          discordToken: discordDetails?.auth?.access_token || '',
        });
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setNeedRefresh(true);
        setLoggedIn(false);
        setIsLoading(false);
        return;
      }
    };
    if (discordCallbackResp == null) {
      getDiscord();
    }
  }, [
    discordCallbackResp,
    setLoggedIn,
    setError,
    setNeedRefresh,
    setIsLoading,
    updateDiscordRole,
    userDetails,
    currentDiscord,
  ]);

  const content = useMemo(() => {
    if (error) {
      return (
        <>
          <DiscordButton onClick={loginDiscord} label="Login to Discord" />
          <Typography variant="body1" color="error">
            Error logging into discord
          </Typography>
        </>
      );
    }
    if (loggedIn && userSubLevel === SubLevel.STANDARD) {
      return (
        <>
          <DiscordButton onClick={openDiscord} label="Open Discord" />
          <Typography variant="body1">
            <Typography variant="h3" sx={{ fontSize: '16px' }}>
              Welcome to Discord,{' '}
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.light,
                  fontSize: '16px',
                }}
              >
                {userDetails?.username}.
              </Typography>
            </Typography>
            <>
              As a{' '}
              <Typography
                sx={{ fontWeight: 500, color: theme.palette.secondary.light }}
              >
                Standard
              </Typography>{' '}
              subscriber, you have access to the following features:
            </>
            <List
              dense={true}
              sx={{ color: theme.palette.common.white, flex: 1 }}
            >
              {[
                'Interact with other users',
                'Weekly notification of the Top 5 Gamma Squeeze Candidates every Monday at 8:30am EST',
                'Use the SpotGamma bot to easily access charts for major U.S. indices using the $chart STOCK_SYMBOL command',
              ].map((item) => {
                return (
                  <ListItem>
                    <ListItemText
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme.palette.common.white,
                        },
                      }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Typography>
        </>
      );
    }

    if (
      loggedIn &&
      (userSubLevel === SubLevel.PRO || userSubLevel === SubLevel.ALPHA)
    ) {
      return (
        <>
          <DiscordButton onClick={openDiscord} label="Open Discord" />
          <Typography variant="body1">
            <Typography variant="h3" sx={{ fontSize: '16px' }}>
              Welcome to Discord,{' '}
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.light,
                  fontSize: '16px',
                }}
              >
                {userDetails?.username}.
              </Typography>
            </Typography>
            <>
              As a{' '}
              <Typography
                sx={{ fontWeight: 500, color: theme.palette.secondary.light }}
              >
                {userSubLevel === SubLevel.PRO ? 'Pro' : 'Alpha'}
              </Typography>{' '}
              subscriber, you have access to the following features:
            </>
            <List
              dense={true}
              sx={{ color: theme.palette.common.white, flex: 1 }}
            >
              {[
                'Interact with other users',
                'Weekly notification of the Top 5 Gamma Squeeze Candidates every Monday at 8:30am EST',
                'Use the Equity Hub bot to easily access charts for any of the 3,500+ Equity Hub stocks using the $chart STOCK_SYMBOL command',
              ].map((item) => {
                return (
                  <ListItem>
                    <ListItemText
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme.palette.common.white,
                        },
                      }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Typography>
        </>
      );
    }

    if (loggedIn && !needRefresh) {
      return (
        <Typography variant="body1">
          <>
            Sorry, {userDetails?.username} we’re unable to find a SpotGamma
            account associated with your Discord login :(
          </>
          <>
            The SpotGamma Discord server is exclusive to SpotGamma subscribers.
            Please note you must have a Discord account that uses the same email
            address as your SpotGamma account in order to access our community.
            If you think an error has been made, email our team at
            info@spotgamma.com for assistance or refresh the page to try and
            login again.
          </>
          <>
            If you are not currently a member of the SpotGamma community, we
            invite you to sign up today for a SpotGamma membership and take
            advantage of everything our SpotGamma Discord server has to offer:
          </>

          <List
            dense={true}
            sx={{ color: theme.palette.common.white, flex: 1 }}
          >
            {[
              'Interact with other users',
              'Weekly notification of the Top 5 Gamma Squeeze Candidates every Monday at 8:30am EST',
              'Use the SpotGamma bot to easily access charts for major U.S. indices',
            ].map((item) => {
              return (
                <ListItem>
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        color: theme.palette.common.white,
                      },
                    }}
                    primary={item}
                  />
                </ListItem>
              );
            })}
          </List>
          <Link href="https://spotgamma.com/subscribe-to-spotgamma/">
            Subscribe today
          </Link>
        </Typography>
      );
    }

    if (needRefresh) {
      return (
        <>
          <DiscordButton onClick={loginDiscord} label="Login to Discord" />
          <>Please login again to get access.</>
        </>
      );
    }

    if (!loggedIn && currentDiscord.length > 0) {
      return <DiscordButton onClick={loginDiscord} label="Login to Discord" />;
    }

    if (!loggedIn) {
      return (
        <DiscordButton onClick={loginDiscord} label="Register for Discord" />
      );
    }

    return <></>;
  }, [loggedIn, currentDiscord, needRefresh, userSubLevel, error]);

  return (
    <Page
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Paper
        elevation={8}
        sx={{
          padding: '2em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '12px',
          fontSize: '14px',
        }}
      >
        <Loader isLoading={isLoading}>
          <Typography variant="body2" sx={{ color: theme.palette.grey[400] }}>
            SpotGamma subscribers are invited to join our private Discord
            server, home to a lively and generous community of traders.
          </Typography>
          {content}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <List
              dense={true}
              sx={{ color: theme.palette.common.white, flex: 2 }}
            >
              {DISCORD_CONTENT_LIST.map(({ name, description, icon }) => {
                return (
                  <ListItem>
                    <ListItemAvatar color="primary">
                      <Avatar sx={{ bgcolor: theme.palette.primary.dark }}>
                        {icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary.MuiTypography-root': {
                          color: theme.palette.text.primary,
                          fontSize: '13px',
                          mb: 2,
                        },
                      }}
                      primary={name}
                      secondary={description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary, flex: 1 }}
            >
              Please confirm your subscription by clicking the "Login to
              Discord" button above. If you do not currently have a Discord
              account, you will be prompted by Discord to create an account once
              you click the button to register above, where you'll then be
              automatically transferred into the SpotGamma server.
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            Membership and access to this Discord channel means you are willing
            to comply and abide by the{' '}
            <Link href="https://spotgamma.com/discord-community-guidelines/">
              SpotGamma Discord Community Guidelines and Code of Conduct.
            </Link>
          </Typography>
          <Box sx={{ display: 'flex' }}></Box>
        </Loader>
      </Paper>
    </Page>
  );
};
