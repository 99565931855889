import { COMPASS_DEFAULT_ZOOM_DOMAIN } from 'config/compass';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { userSettingsState } from 'states';
import {
  CompassSavedView,
  CompassZoomDomain,
  DEFAULT_COMPASS_COLORS,
  StrategyCompassMode,
  StrategyCompassXYZAxis,
} from 'types/compass';

const { persistAtom } = recoilPersist();

export const compassHoveredSymbolState = atom<string | undefined>({
  key: 'compass-hoveredSymbolState',
  default: undefined,
});

export const compassHiddenSymbolsState = atom<Set<string>>({
  key: 'compass-hiddenSymbolsState',
  default: new Set(),
});

export const compassActiveWatchlistsIdsState = atom<number[]>({
  key: 'compass-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

// Active symbols
export const compassActiveSymbolsState = atom<string[]>({
  key: 'compass-activeSymbols',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const compassActiveModeState = atom<StrategyCompassMode>({
  key: 'compass-activeMode',
  default: StrategyCompassMode.Compass,
  effects_UNSTABLE: [persistAtom],
});

export const compassActiveXAxisState = atom<StrategyCompassXYZAxis>({
  key: 'compass-activeXAxis',
  default: StrategyCompassXYZAxis.SkewRank,
  effects_UNSTABLE: [persistAtom],
});

export const compassActiveYAxisState = atom<StrategyCompassXYZAxis>({
  key: 'compass-activeYAxis',
  default: StrategyCompassXYZAxis.IvRank,
  effects_UNSTABLE: [persistAtom],
});

export const compassActiveZAxisState = atom<StrategyCompassXYZAxis | undefined>(
  {
    key: 'compass-activeZAxis',
    default: undefined,
    effects_UNSTABLE: [persistAtom],
  },
);

// This state returns user's compass view settings
export const compassSavedViewsState = selector<CompassSavedView[]>({
  key: 'compass-savedViewsState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.compass?.savedViews != null) {
      return settings.compass.savedViews;
    }
    return [];
  },
});

// Zoom and selection states for the compass chart
export const compassZoomDomainState = atom<CompassZoomDomain>({
  key: 'compass-zoomDomain',
  default: COMPASS_DEFAULT_ZOOM_DOMAIN,
});

export const compassIsZoomedState = atom<boolean>({
  key: 'compass-isZoomed',
  default: false,
});

export const compassZoomStartPointState = atom<{
  x: number;
  y: number;
} | null>({
  key: 'compass-zoomStartPoint',
  default: null,
});

export const compassZoomEndPointState = atom<{
  x: number;
  y: number;
} | null>({
  key: 'compass-zoomEndPoint',
  default: null,
});

export const compassIsSelectingState = atom<boolean>({
  key: 'compass-isSelecting',
  default: false,
});

export const compassPosColorState = selector<string>({
  key: 'compass-posColor',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.compass?.posColor ?? DEFAULT_COMPASS_COLORS.posColor;
  },
});

export const compassNegColorState = selector<string>({
  key: 'compass-negColor',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.compass?.negColor ?? DEFAULT_COMPASS_COLORS.negColor;
  },
});

export const compassSelectedScannersState = atom<string[]>({
  key: 'compass-selectedScanners',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const compassSelectedWatchlistsState = atom<string[]>({
  key: 'compass-selectedWatchlists',
  default: [],
  effects_UNSTABLE: [persistAtom],
});
