import { useEffect, useMemo, useState } from 'react';
import { getDateFormatted, getParquetUrl, ET } from '../../util';
import { dayjs } from '../../util/shared/date';
import useToast from '../../hooks/useToast';
import { useLog } from '../../hooks';

type useStatsProps = {
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
};

export const useStats = ({ intradayDate, intradaySym }: useStatsProps) => {
  // use a map for stats so that we dont fetch stats for the same date more than once
  const [statsDataMap, setStatsDataMap] = useState<Map<string, any>>(new Map());

  const { openToast } = useToast();
  const { fetchAPIWithLog, nonProdDebugLog } = useLog('useStats');

  const statsParquetUrl = useMemo(() => {
    // Dates before 08-30 don't have 90 days of data, so just use min/max/mean
    const date = dayjs.max(dayjs.tz('2024-09-11', ET), intradayDate)!;
    return getParquetUrl('intradayStats', date, intradaySym);
  }, [intradayDate, intradaySym]);

  const fetchStats = async (retries = 0) => {
    const dateKey = getDateFormatted(intradayDate);
    if (statsDataMap.has(dateKey)) {
      return;
    }

    try {
      const resp = await fetchAPIWithLog(statsParquetUrl);
      const json = typeof resp === 'string' ? JSON.parse(resp) : resp;
      nonProdDebugLog('fetched stats data', json);
      setStatsDataMap((map) => map.set(dateKey, json));
    } catch (err) {
      console.error(err);
      if (retries < 1) {
        setTimeout(() => fetchStats(retries + 1), 5_000);
      } else {
        openToast({
          type: 'error',
          message: `There was an error loading stats data.`,
        });
      }
      return;
    }
  };

  useEffect(() => {
    fetchStats();
  }, [statsParquetUrl, statsDataMap, intradayDate]);

  return { statsDataMap };
};
