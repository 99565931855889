import { Box, Typography } from '@mui/material';

interface EquityDetailRowProps {
  label: string;
  value: string | React.ReactNode;
  valueColor?: string;
}

const EquityDetailRow = ({
  label,
  value,
  valueColor,
}: EquityDetailRowProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
        {label}
      </Typography>
      {typeof value === 'string' ? (
        <Typography sx={{ fontSize: 14, color: valueColor }}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

export default EquityDetailRow;
