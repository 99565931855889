import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useTimeSeries, {
  filterLastDay,
} from '../../../hooks/equityhub/useTimeSeries';
import {
  realTimeChartZoomConfigState,
  realTimeInitialDataState,
  selectedEquityLevelsState,
  selectedEquityState,
  workerState,
} from '../../../states';
import { parseSpaceDelimittedList, updateBrushZoomConfig } from '../../../util';
import { Gamma, Tick } from '../../../types';
import { ErrorContent, TimeSeriesChart } from '../../shared';
import useBrushZoom from '../../../hooks/useBrushZoom';
import poll from '../../../util/poll';

type RealTimeGraphProps = {
  showKeyLevels: boolean;
};

export const RealTimeGraph = ({ showKeyLevels }: RealTimeGraphProps) => {
  const worker = useRecoilValue(workerState);
  const selectedEquity = useRecoilValue(selectedEquityState);
  const levels = useRecoilValue(selectedEquityLevelsState);
  const [gammaData, setGammaData] = useState<Gamma[]>([]);
  const { getLastDaysPrices } = useTimeSeries();
  const [loading, setLoading] = useState(false);
  const [zoomConfig, setZoomConfig] = useRecoilState(
    realTimeChartZoomConfigState,
  );
  const [initialData, setInitialData] = useRecoilState(
    realTimeInitialDataState,
  );

  const { zoomChartConfig } = useBrushZoom<Tick>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  const handleResponse = async (data: { json: Record<string, Tick[]> }) => {
    if (data?.json != null && selectedEquity?.sym) {
      const ticks = filterLastDay(data.json[selectedEquity!.sym]?.values);
      if (ticks == null) {
        return;
      }
      setInitialData(ticks);
      updateBrushZoomConfig(zoomConfig, ticks, setZoomConfig);
    }
  };

  useEffect(() => {
    return poll(worker, {
      url: `v1/twelve_series?symbol=${selectedEquity?.sym}&interval=1min&outputsize=390&order=ASC`,
      interval: 60_000,
      onResponse: handleResponse,
    });
  }, [worker, selectedEquity]);

  useEffect(() => {
    async function generateTimeSeries() {
      setLoading(true);
      let data = await getLastDaysPrices(
        [selectedEquity!.sym],
        true /*ascending*/,
      );
      const ticks = data[selectedEquity!.sym];
      setLoading(false);
      if (ticks == null) {
        return;
      }
      setInitialData(ticks);
      updateBrushZoomConfig(zoomConfig, ticks, setZoomConfig);
    }
    if (selectedEquity) {
      generateTimeSeries();
    }
  }, [
    getLastDaysPrices,
    setInitialData,
    updateBrushZoomConfig,
    setZoomConfig,
    selectedEquity,
  ]);

  useEffect(() => {
    if (!initialData || !selectedEquity) {
      return;
    }

    const callGNotList = parseSpaceDelimittedList(
      selectedEquity.call_gnot_list_absg,
    );
    const callStrikeList = parseSpaceDelimittedList(
      selectedEquity.call_strikes_list_absg,
    );
    const putGNotList = parseSpaceDelimittedList(
      selectedEquity.put_gnot_list_absg,
    );
    const prices = initialData.map((d) => d.price);
    const priceRange = Math.max(...prices) - Math.min(...prices);
    const [minPrice, maxPrice] = [
      Math.min(...prices) - priceRange,
      Math.max(...prices) + priceRange,
    ];

    let gammaData = [];
    for (let i = 0; i < callStrikeList.length; i++) {
      if (callStrikeList[i] >= minPrice && callStrikeList[i] <= maxPrice) {
        gammaData.push({
          call_gnot: Math.abs(callGNotList[i]),
          put_gnot: Math.abs(putGNotList[i]),
          price: callStrikeList[i],
        });
      }
    }
    setGammaData(gammaData);
  }, [selectedEquity, setGammaData, initialData]);

  if (!initialData || initialData.length === 0) {
    <ErrorContent loading={loading} content="Waiting for Market to Open" />;
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <TimeSeriesChart
        sym={selectedEquity?.sym}
        zoomChartConfig={zoomChartConfig}
        gammas={gammaData}
        levels={levels}
        loading={loading}
        showKeyLevels={showKeyLevels}
      />
    </div>
  );
};
