import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import HiroTradingViewContainer from './HiroTradingViewContainer';
import { HiroSettingsBar } from './controls/HiroSettingsBar';
import { useEffect, useState } from 'react';
import { SymbolTabs } from './controls/SymbolTabs';
import { isZerohedge } from '../../../util';
import useSetSym from '../../../hooks/hiro/useSetSym';
import { ProductType } from '../../../types';
import {
  chartExpandedState,
  sidebarExpandedState,
  drawerExpandedState,
  isSideDrawerExpandedState,
  pageBannerOpenMapState,
  isMobileState,
} from '../../../states';

interface HiroContainerProps {
  sym?: string;
}

const HiroContainer = ({ sym }: HiroContainerProps) => {
  const chartExpanded = useRecoilValue(chartExpandedState);
  const sidebarExpanded = useRecoilValue(sidebarExpandedState);
  const drawerExpanded = useRecoilValue(drawerExpandedState);
  const sideDrawerExpanded = useRecoilValue(isSideDrawerExpandedState);
  const pageBannerOpenMap = useRecoilValue(pageBannerOpenMapState);
  // TODO: Investigate parent/child forward-ref relationship in lightweight-charts implementation.
  // The logic below is to ensure a re-render is forced when the expanded states are updated.
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setToggle(!toggle);
  }, [
    chartExpanded,
    sidebarExpanded,
    drawerExpanded,
    sideDrawerExpanded,
    pageBannerOpenMap,
  ]);

  return (
    <>
      <HiroSettingsBar sym={sym} />
      {!isZerohedge() && (
        <SymbolTabs sym={sym} productType={ProductType.HIRO} />
      )}
      <HiroTradingViewContainer sym={sym} />
    </>
  );
};

interface HiroChartProps {
  sym?: string;
}

export const HiroChartDisplay = ({ sym }: HiroChartProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const { getSym } = useSetSym();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: isZerohedge() ? theme.spacing(3) : theme.spacing(5),
        height: 1.0,
        width: 1.0,
        ...(isZerohedge()
          ? {}
          : {
              background: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
              borderRadius: theme.spacing(3),
            }),
        ...(isMobile && !isZerohedge() ? { minHeight: '500px' } : {}),
      }}
    >
      <HiroContainer sym={sym ?? getSym(ProductType.HIRO)} />
    </Box>
  );
};
