import { useEffect, useMemo, useRef, useState } from 'react';
import {
  TraceStrikeBarType,
  StrikeBarsDailyTracker,
  StrikeBarsDailyTrackerEntry,
  StrikeBarsData,
  TraceParams,
} from '../../types';
import {
  fetchRawAPI,
  formatAsCompactNumber,
  getOverrideHeader,
  getStrikeBarsData,
  getStrikeBarsXAxisRange,
  maxStrikeBarY3Offset,
  ONE_HOUR_MS,
  predicateSearch,
  responseToTable,
  strikeBarParquetUrlForType,
  strikeBarTraceNameYOffset,
  textColorForBg,
  TraceStrikeBarsMapStore,
  updateStrikeBarsTracker,
} from '../../util';
import { useRecoilValue } from 'recoil';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiIntradayStrikeBoundsState,
  oiShowGexZeroDteState,
  oiStrikeBarsTrackerEnabledState,
  oiStrikeBarTypeState,
} from '../../states';
import dayjs from 'dayjs';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow/Arrow.dom';
import useToast from '../../hooks/useToast';
import useWasmParquet from '../../hooks/useWasmParquet';
import useLog from '../../hooks/useLog';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js';

type useStrikeBarProps = {
  timestamp: dayjs.Dayjs | null;
  timestamps: dayjs.Dayjs[];
  minY: number;
  maxY: number;
  height: number;
  traceParams: TraceParams;
};

export const useStrikeBars = ({
  timestamp,
  timestamps,
  minY,
  maxY,
  height,
  traceParams,
}: useStrikeBarProps) => {
  const theme = useTheme();
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const trackerEnabled = useRecoilValue(oiStrikeBarsTrackerEnabledState);
  const strikeZoomBounds = useRecoilValue(oiIntradayStrikeBoundsState);

  const [strikeBarsData, setStrikeBarsData] = useState<
    StrikeBarsData | undefined
  >();
  const [gexTable, setGexTable] = useState<arrow.Table | null>(null);
  const [strikeBarsLoading, setStrikeBarsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showZeroLine, setShowZeroLine] = useState(false);

  const strikeBarsTracker = useRef<StrikeBarsDailyTracker | null>(null);

  const { openToast } = useToast();
  const { getWasmPromise } = useWasmParquet();
  const { logError, fetchAPIWithLog, nonProdDebugLog } =
    useLog('useStrikeBars');

  const { intradayDate, intradaySym, heatmapColorSettings } = traceParams;

  useEffect(() => {
    if (gexTable == null || timestamp == null || minY == null || maxY == null) {
      return setStrikeBarsData(undefined);
    }

    const endTs = timestamp.valueOf();
    // if we dont have the strikeBarTracker data for the current strike bar type
    // or if the tracker data we have is for a timestamp that is after the currently selected one
    // regenerate all gex values for all timestamps. otherwise we just need the previous hour
    const useLastTracker =
      strikeBarsTracker.current?.zeroDte === showGexZeroDte &&
      strikeBarsTracker.current?.type === strikeBarType &&
      (strikeBarsTracker.current?.latestTimestamp ?? 0) <= endTs;
    const startTs = useLastTracker
      ? Math.min(
          strikeBarsTracker.current!.latestTimestamp,
          endTs - ONE_HOUR_MS,
        )
      : 0;
    const { allDataByTs, latestTimestamp } = getStrikeBarsData(
      gexTable,
      invert,
      parquetKeys,
      timestamp,
      showGexZeroDte,
      strikeBarType,
      heatmapColorSettings,
      startTs,
      endTs,
      nonProdDebugLog,
    );

    if (allDataByTs == null || latestTimestamp == null) {
      return setStrikeBarsData(undefined);
    }

    strikeBarsTracker.current = updateStrikeBarsTracker(
      allDataByTs,
      strikeBarType,
      useLastTracker ? strikeBarsTracker.current : null,
      showGexZeroDte,
    );

    const range = getStrikeBarsXAxisRange(
      strikeBarsTracker.current,
      minY,
      maxY,
      strikeZoomBounds,
    );

    const plotlyData = convertStrikeBarsDataToChart(
      allDataByTs,
      endTs,
      latestTimestamp,
      range,
    );

    const trackerData = convertStrikeBarsTrackerToChart(latestTimestamp, endTs);

    nonProdDebugLog('strike bars data', {
      latestTimestamp,
      plotlyData,
      trackerData,
      tracker: strikeBarsTracker.current,
    });

    setStrikeBarsData({ latestTimestamp, ...plotlyData, trackerData });
  }, [
    gexTable,
    parquetKeys,
    timestamp,
    timestamps,
    heatmapColorSettings,
    invert,
    showGexZeroDte,
    strikeBarType,
    theme.palette.trace.strikeBarSettings,
    trackerEnabled,
    minY,
    maxY,
    height,
    strikeZoomBounds,
  ]);

  const strikeBarParquetUrl = useMemo(
    () => strikeBarParquetUrlForType(strikeBarType, intradayDate, intradaySym),
    [strikeBarType, intradayDate, intradaySym],
  );

  const triggerUpdate = async () => {
    if (isUpdating) {
      return;
    }

    try {
      setIsUpdating(true);
      const opts = { buffer: true, ...getOverrideHeader() };
      const url = `${strikeBarParquetUrl}&last=1&cb=${dayjs().valueOf()}`;
      const data = await fetchAPIWithLog(url, opts);
      handleUpdate(data);
    } catch (e) {
      logError(e, 'triggerUpdate');
    } finally {
      setIsUpdating(false);
    }
  };

  // do not rely on any states here. setters are fine though
  const handleUpdate = async (data: any) => {
    try {
      const arrowTable = readParquet(new Uint8Array(data));
      const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
      setGexTable((oldTable) => oldTable?.concat(tableAppend) ?? null);
      nonProdDebugLog(`received polling data for strike bars`);
    } catch (err) {
      logError(err, 'handleUpdate');
      openToast({
        message: 'There was an error updating the gamma exposure data.',
        type: 'error',
      });
    }
  };

  const fetchStrikeBars = async (retries = 0) => {
    if (strikeBarParquetUrl == null) {
      return;
    }

    // add the latest heatmap timestamp to force this to not use cache once a new heatmap timestamp comes in
    const urlWithCb = `${strikeBarParquetUrl}&latestHeatmapTs=${
      timestamps[timestamps.length - 1]?.valueOf() ?? ''
    }`;
    nonProdDebugLog('fetching strike bar data...');
    try {
      setStrikeBarsLoading(true);
      const [_wasm, gexResp] = await Promise.all([
        getWasmPromise(),
        fetchRawAPI(urlWithCb, getOverrideHeader()),
      ]);

      if (gexResp.status >= 300) {
        if (retries < 1) {
          // keep it as loading, and try again in a few secs
          console.error('Received GEX resp status: ' + gexResp.status);
          setTimeout(() => fetchStrikeBars(retries + 1), 5_000);
        } else {
          openToast({
            type: 'error',
            message: `There was an error loading the strike bar chart data.`,
          });
        }
        return;
      }

      const gexRespTable = await responseToTable(gexResp);
      setGexTable(gexRespTable);
    } catch (err) {
      console.error(err);
    } finally {
      setStrikeBarsLoading(false);
    }
  };

  useEffect(() => {
    fetchStrikeBars();
  }, [strikeBarParquetUrl]);

  const calcTrackerHeight = (numTraces: number) =>
    (height * 1.75) / (maxY - minY) / (numTraces === 1 ? 1 : numTraces * 1.5);

  const convertStrikeBarsDataToChart = (
    allDataByTs: Map<number, Map<string, TraceStrikeBarsMapStore>>,
    selectedTimestamp: number,
    latestTimestamp: number,
    range: number[] | undefined,
  ): {
    chartData: Plotly.Data[];
    annotations: Partial<Plotly.Annotations>[];
  } => {
    const outOfDate = selectedTimestamp > latestTimestamp;
    let currMap = allDataByTs.get(selectedTimestamp);
    if (currMap == null && outOfDate) {
      // if out of date, we wont have data for the selected timestamp since it's the latest one that strike bars are missing
      // show the latest data we have instead and make the bars translucent to indicate it's out of date
      currMap = allDataByTs.get(latestTimestamp);
    }

    let annotations: Partial<Plotly.Annotations>[] = [];
    const chartData =
      currMap == null
        ? []
        : [...currMap.keys()].map((traceName: string) => {
            const traceData = currMap!.get(traceName)!;
            const minX =
              predicateSearch(
                traceData.y,
                (d) => d < minY - maxStrikeBarY3Offset(strikeBarType),
              ) + 1;
            const maxX = predicateSearch(
              traceData.y,
              (d) => d <= maxY + maxStrikeBarY3Offset(strikeBarType),
            );
            nonProdDebugLog(
              'TRACE: using minX and maxX for strike bars',
              minX,
              maxX,
              minY,
              maxY,
              traceData,
            );

            const xData = traceData.x.slice(minX, maxX + 1);
            const yDataWithOffset = traceData.y
              .slice(minX, maxX + 1)
              .map((v) => v + strikeBarTraceNameYOffset(traceName));
            const colorData = traceData.colors.slice(minX, maxX + 1);

            if (range != null) {
              annotations = annotations.concat(
                xData.flatMap((val, idx) =>
                  val > range[1] || val < range[0]
                    ? [
                        {
                          xanchor: val >= 0 ? 'left' : 'right',
                          yanchor:
                            idx === 0
                              ? 'bottom'
                              : idx === xData.length - 1
                              ? 'top'
                              : undefined,
                          xref: 'x2',
                          x: 0, // place the text label edge along the y axis so it's visible
                          y: yDataWithOffset[idx],
                          text: formatAsCompactNumber(val),
                          font: {
                            color: textColorForBg(colorData[idx]),
                            size: Math.max(
                              // use the tracker height as a proxy for the approxmiate height the bars will be
                              // then set the font such that it scales up on larger devices, but keeps a floor
                              calcTrackerHeight(currMap!.size) /
                                (strikeBarType === TraceStrikeBarType.GAMMA
                                  ? 3
                                  : 0.7),
                              strikeBarType === TraceStrikeBarType.GAMMA
                                ? 10
                                : 6,
                            ),
                          },
                          showarrow: false,
                        },
                      ]
                    : [],
                ),
              );
            }

            return {
              x: xData,
              y: yDataWithOffset,
              xaxis: 'x2',
              yaxis: 'y',
              orientation: 'h',
              type: 'bar',
              marker: {
                color: colorData,
              },
              name: traceName,
              hoverinfo: 'none',
              width: strikeBarType === TraceStrikeBarType.GAMMA ? undefined : 1,
              opacity: outOfDate ? 0.5 : 1,
            } as Plotly.Data;
          });

    return { chartData, annotations };
  };

  const convertStrikeBarsTrackerToChart = (
    latestTimestamp: number,
    selectedTimestamp: number,
  ): Plotly.Data[] => {
    const tracker = strikeBarsTracker.current;
    if (tracker == null) {
      return [];
    }

    const colorSettings = theme.palette.trace.strikeBarSettings;
    const entries = tracker.entriesPerTrace;
    const traces = [...entries.keys()];
    const outOfDate = selectedTimestamp > latestTimestamp;

    const traceForKey = (key: keyof StrikeBarsDailyTrackerEntry) => {
      const useBar = ['dailyMax'].includes(key);
      const options = useBar
        ? { type: 'bar', width: 0.05 }
        : {
            type: 'scatter',
            mode: 'markers',
          };

      return traces.map((traceName: string) => {
        const traceData = entries.get(traceName)!;
        const strikes = [...traceData.keys()].filter(
          (strike) => strike >= minY && strike <= maxY,
        );
        const bases: number[] = [];
        const x = strikes.flatMap((strike) => {
          const entry = traceData.get(strike)!;
          let val = entry[key];
          if (val == null) {
            return [];
          }

          if (useBar) {
            const offset = entry['dailyMin']!;
            bases.push(offset);
            val -= offset;
          }

          return [val];
        });

        return {
          x: x,
          y: strikes.map((v) => v + strikeBarTraceNameYOffset(traceName)),
          xaxis: 'x2',
          yaxis: 'y',
          marker: {
            symbol: 'line-ns-open',
            color: colorSettings.tracker[key] ?? '#fff',
            // shrink the dots a bit based on zoom level (using max-minY to determine that) so that the dots dont
            // make the bars hard to read. we use traces.length because some strike bar types
            // show more bars per strike than others, and for those types the dots will need to be smaller
            size: calcTrackerHeight(traces.length),
            line: {
              width: 3,
            },
          },
          // @ts-ignore
          base: bases.length > 0 ? bases : undefined,
          hoverinfo: 'none',
          orientation: 'h',
          name: traceName,
          showlegend: false,
          showscale: false,
          opacity: outOfDate ? 0.5 : 1,
          ...options,
        } as Plotly.Data;
      });
    };

    const keys: (keyof StrikeBarsDailyTrackerEntry)[] = trackerEnabled
      ? [
          'last',
          'thirtyMin',
          'hour',
          'dailyMax',
          // dailyMax shows both the min and max, since they need to be one bar
        ]
      : ['dailyMax'];
    return keys.flatMap((key) => traceForKey(key));
  };

  const strikeBarsRange = getStrikeBarsXAxisRange(
    strikeBarsTracker.current,
    minY,
    maxY,
    strikeZoomBounds,
  );

  useEffect(() => {
    // there is this very annoying plotly bug where as the strike bars are rendering
    // we show an x axis zeroLine, i.e. a vertical grid line, on the left side of the strike bars
    // there is no way in plotly config to get rid of this while keeping it when we actually have data to show
    // so here we disable it temporarily while loading data, and once we have data loaded for the strike bars
    // we show it again. the time this takes for the effect to trigger is enough delay where this ends up working well
    if (!strikeBarsLoading && !showZeroLine) {
      setShowZeroLine(true);
    } else if (strikeBarsLoading && showZeroLine) {
      setShowZeroLine(false);
    }
  }, [strikeBarsLoading, showZeroLine]);

  return {
    strikeBarsData,
    strikeBarsTracker: strikeBarsTracker.current,
    strikeBarsRange,
    strikeBarsLoading,
    setStrikeBarsLoading,
    triggerUpdate,
    showZeroLine,
  };
};
