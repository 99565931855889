import {
  alpha,
  Box,
  Button,
  Portal,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterAltIcon,
  GridLogicOperator,
  GridSlots,
  GridSlotsComponentsProps,
  GridSortItem,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@spotgamma/x-data-grid-premium';
import { useCallback, useMemo } from 'react';
import {
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedContract,
  RawOptionFeedData,
} from 'types/tape';
import {
  defaultGridTableSlots,
  getDefaultGridTableSlotProps,
  getDefaultGridTableStyles,
  getUpdatedColumnOrder,
} from 'util/shared';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { RecoilState, useRecoilState } from 'recoil';
import {
  DEFAULT_TNS_FIELDS,
  TAPE_COLUMN_TOOLTIPS_MAP,
  TAPE_UNMODIFIABLE_FIELDS,
} from 'config/tape';
import GridTableOverlayWrapper from './GridTableOverlayWrapper';
import { CustomGridController, PopperInfo } from 'types';
import InfoPopper from 'components/shared/InfoPopper';

interface CustomToolbarProps {
  customPositionedControllers?: CustomGridController[];
  disableToolbar?: boolean;
  filterPanelOpenState: RecoilState<boolean>;
  flowLiveState: RecoilState<boolean>;
  columns?: GridColDef[];
}

const CustomToolbar = ({
  customPositionedControllers,
  filterPanelOpenState,
  disableToolbar,
  flowLiveState,
  columns,
}: CustomToolbarProps) => {
  const theme = useTheme();
  const sxProps: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: {
      xs: 12,
      md: 14,
    },
  };

  const regularColumnInfos = columns
    ?.map((col) => ({
      label: col.headerName,
      description: TAPE_COLUMN_TOOLTIPS_MAP.get(
        col.field as OptionsFeedColumnKey,
      ),
    }))
    .filter((v) => v.description != null) as PopperInfo[];

  const [filterPanelOpen, setFilterPanelOpen] =
    useRecoilState(filterPanelOpenState);

  const [flowLive, setFlowLive] = useRecoilState(flowLiveState);

  return (
    <>
      {customPositionedControllers?.map((c) => (
        <Portal container={() => document.getElementById(c.elementId)!}>
          <Box key={c.elementId} sx={{ marginX: '6px' }}>
            {c.component}
          </Box>
        </Portal>
      ))}

      {!disableToolbar && (
        <GridToolbarContainer
          sx={{ padding: '5px', backgroundColor: 'transparent' }}
        >
          <Stack alignItems="center" gap="8px" direction="row">
            <Button
              variant="outlined"
              size="small"
              startIcon={<GridFilterAltIcon />}
              sx={{
                ...sxProps,
                backgroundColor: filterPanelOpen
                  ? theme.palette.button.default
                  : 'inherit',
                ':hover': {
                  backgroundColor: filterPanelOpen
                    ? theme.palette.button.hover
                    : 'inherit',
                },
              }}
              onClick={() => setFilterPanelOpen((prev) => !prev)}
            >
              Filters
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                ...sxProps,
              }}
              startIcon={
                flowLive ? (
                  <PauseCircleFilledRoundedIcon />
                ) : (
                  <PlayCircleFilledRoundedIcon />
                )
              }
              onClick={() => setFlowLive((prev) => !prev)}
            >
              {flowLive ? 'Live Flow' : 'Paused Flow'}
            </Button>
            <GridToolbarColumnsButton
              slotProps={{
                button: {
                  sx: sxProps,
                },
              }}
            />
            {regularColumnInfos && <InfoPopper contents={regularColumnInfos} />}
          </Stack>
        </GridToolbarContainer>
      )}
    </>
  );
};

interface Props {
  rows: RawOptionFeedData[] | RawOptionFeedContract[];
  columns: GridColDef[];
  flowLiveState: RecoilState<boolean>;
  filterPanelOpenState: RecoilState<boolean>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  columnSortModelState: RecoilState<GridSortModel>;
  isError?: boolean;
  isLoading?: boolean;
  customGridSlotProps?: GridSlotsComponentsProps;
  onRowsScrollEnd?: DataGridPremiumProps['onRowsScrollEnd'];
}

const TapeDatagrid = ({
  rows,
  isError,
  columns,
  isLoading,
  filterPanelOpenState,
  flowLiveState,
  columnSortModelState,
  columnVisibilityState,
  columnOrderState,
  columnSizingState,
  customGridSlotProps,
  onRowsScrollEnd,
}: Props) => {
  const theme = useTheme();
  const [columnVisibilityModel, setColumnVisibilityModel] = useRecoilState(
    columnVisibilityState,
  );
  const [columnSortModel, setColumnSortModel] =
    useRecoilState(columnSortModelState);
  const [columnOrder, setColumnOrder] = useRecoilState(columnOrderState);
  const [columnSizing, setColumnSizing] = useRecoilState(columnSizingState);

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !TAPE_UNMODIFIABLE_FIELDS.includes(
            column.field as OptionsFeedColumnKey,
          ),
      )
      .map((column) => column.field);
  };

  const orderedColumns = useMemo(() => {
    const orderMap = new Map(columnOrder.map((field, index) => [field, index]));
    const fallback = new Map(
      DEFAULT_TNS_FIELDS.map((field, idx) => [field, idx + columns.length]),
    );

    return [...columns].sort(
      (a, b) =>
        (orderMap.get(a.field as OptionsFeedColumnKey) ??
          fallback.get(a.field as OptionsFeedColumnKey)!) -
        (orderMap.get(b.field as OptionsFeedColumnKey) ??
          fallback.get(b.field as OptionsFeedColumnKey)!),
    );
  }, [columnOrder, columns]);

  const dynamicallySizedColumns = useMemo(() => {
    return orderedColumns.map((col: GridColDef) => ({
      ...col,
      width: columnSizing[col.field as OptionsFeedColumnKey],
    }));
  }, [orderedColumns, columnSizing]);

  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    _event: any,
    _details: any,
  ) => {
    const newOrder = getUpdatedColumnOrder(orderedColumns, params);
    setColumnOrder(newOrder as OptionsFeedColumnKey[]);
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: 'fit-content',
          overflowY: 'hidden',
        }}
      >
        <DataGridPremium
          filterMode="server"
          sortingMode="server"
          loading={isLoading}
          rows={rows}
          scrollEndThreshold={200}
          onRowsScrollEnd={onRowsScrollEnd}
          // update datagrid rows ui every 3s
          throttleRowsMs={3000}
          filterDebounceMs={300}
          sortModel={columnSortModel}
          onSortModelChange={(model: GridSortModel) =>
            setColumnSortModel(
              model.filter((gsi: GridSortItem) => gsi.sort != null),
            )
          }
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            setColumnVisibilityModel(newModel);
          }}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnResize={(params: GridColumnResizeParams) =>
            setColumnSizing((prev) => ({
              ...prev,
              [params.colDef.field]: params.width,
            }))
          }
          columns={dynamicallySizedColumns}
          initialState={{
            pinnedColumns: { left: [] },
            sorting: {
              sortModel: [
                {
                  field: OptionsFeedColumnKey.Time,
                  sort: 'desc', // latest time always at the top by default
                },
              ],
            },
          }}
          hideFooter
          disableRowSelectionOnClick
          disableMultipleRowSelection
          disableAggregation
          rowHeight={45}
          columnHeaderHeight={60}
          disableRowGrouping
          density="compact"
          slots={{
            ...defaultGridTableSlots,
            toolbar: CustomToolbar as GridSlots['toolbar'],
            noRowsOverlay:
              GridTableOverlayWrapper as GridSlots['noRowsOverlay'],
          }}
          slotProps={{
            ...getDefaultGridTableSlotProps(theme),
            toolbar: {
              filterPanelOpenState,
              flowLiveState,
              columns,
            },
            filterPanel: {
              ...getDefaultGridTableSlotProps(theme).filterPanel,
              logicOperators: [GridLogicOperator.And],
            },
            columnsManagement: {
              getTogglableColumns,
            },
            noRowsOverlay: {
              isError,
              isLoading,
            } as any,
            ...customGridSlotProps,
          }}
          sx={{
            ...getDefaultGridTableStyles(theme),
            backgroundColor: 'trasparent',
            '& .MuiDataGrid-main': {
              backgroundColor: theme.palette.background.default,
            },
            '& .MuiDataGrid-scrollbarFiller': {
              background: theme.palette.background.paper,
            },
            '& .grid-header-cell': {
              backgroundColor: theme.palette.background.paper,
              ':hover': {
                backgroundColor: alpha(theme.palette.text.primary, 0.1),
              },
              border: 'none',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TapeDatagrid;
