import Plotly from 'plotly.js';
import dayjs from 'dayjs';
import { useLog, useSetSym } from 'hooks';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Slider, Stack, Typography, useTheme } from '@mui/material';
import { Plot } from './Plot';
import {
  ProductType,
  SearchHandlerFunction,
  SuggestionSearchData,
} from 'types';
import { decode } from '@msgpack/msgpack';
import { fetchRawAPI } from 'util/shared/fetch';
import { oiSymsState, searchHandlerState, searchSuggestionsData } from 'states';
import { safeMerge } from '../../util';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

type OiEntry = Record<string, number>;
type ExpiryStrikeMap<T> = Map<number, Map<number, [T, T]>>;
type ExpiryStrikeMapOi = ExpiryStrikeMap<OiEntry>;

type RawOiEntry = Record<string, string>;
type RawOiObj = Record<string, Record<string, [RawOiEntry, RawOiEntry]>>;

const MARKER = {
  size: 12,
  symbol: 'circle',
  line: { color: 'rgb(204, 204, 204)', width: 1 },
  opacity: 0.8,
  cmin: -1,
  cmax: 1,
};

const ENTITY_MAP = new Map([
  ['Market Maker', { buy: 'mm_buy_oi', sell: 'mm_sell_oi' }],
  ['Broker Dealer', { buy: 'bd_buy_oi', sell: 'bd_sell_oi' }],
  ['Firms', { buy: 'firm_buy_oi', sell: 'firm_sell_oi' }],
  ['All Customers', { buy: 'cust_buy_oi', sell: 'cust_sell_oi' }],
  ['All Pro Customers', { buy: 'procust_buy_oi', sell: 'procust_sell_oi' }],
  [
    'Customers (Small lot)',
    { buy: 'cust_lt_100_buy_oi', sell: 'cust_lt_100_sell_oi' },
  ],
  [
    'Customers (Medium lot)',
    { buy: 'cust_100_199_buy_oi', sell: 'cust_100_199_sell_oi' },
  ],
  [
    'Customers (Large lot)',
    { buy: 'cust_gt_199_buy_oi', sell: 'cust_gt_199_sell_oi' },
  ],
  [
    'Pro Customers (Small lot)',
    { buy: 'procust_lt_100_buy_oi', sell: 'procust_lt_100_sell_oi' },
  ],
  [
    'Pro Customers (Medium lot)',
    { buy: 'procust_100_199_buy_oi', sell: 'procust_100_199_sell_oi' },
  ],
  [
    'Pro Customers (Large lot)',
    { buy: 'procust_gt_199_buy_oi', sell: 'procust_gt_199_sell_oi' },
  ],
]);

function getTraces(
  data: ExpiryStrikeMapOi,
  dateRange: number[],
  oiRange: number[],
  instrument: number,
  entity: string,
): Plotly.Data[] {
  if (data.size === 0) {
    return [];
  }

  const init = (aux = {}) =>
    safeMerge(
      {
        x: [],
        y: [],
        z: [],
        type: 'scatter3d',
        mode: 'markers',
        marker: MARKER,
      },
      aux,
    );
  const calls = init({
    marker: {
      colorscale: [
        [-1, '#ff3300'],
        [1, '#0068ff'],
      ],
    },
    name: 'Calls',
  });
  const puts = init({ marker: { symbol: 'diamond' }, name: 'Puts' });

  const traces = [calls, puts];
  const re = [...data.entries()];
  const entries = re.slice(dateRange[0], dateRange[1]);
  for (const [exp, strikes] of entries) {
    for (const [strike, entries] of strikes) {
      entries.forEach((e: any, idx: number) => {
        if (e == null) {
          return;
        }
        const buy = e[ENTITY_MAP.get(entity)!.buy];
        const sell = e[ENTITY_MAP.get(entity)!.sell];
        const netOI = buy - sell;
        if (netOI === 0 || (instrument < 2 && idx !== instrument)) {
          return;
        }
        if (Math.abs(netOI) < oiRange[0] || Math.abs(netOI) > oiRange[1]) {
          return;
        }
        const trace: any = traces[idx];
        trace.x.push(strike);
        trace.y.push(new Date(exp));
        trace.z.push(netOI);
      });
    }
  }
  traces.forEach((trace) => {
    const ois: number[] = trace.z;
    const minCall = Math.min(...ois);
    const maxCall = Math.max(...ois);
    trace.marker.color = ois.map((oi: number) => {
      return oi < 0 ? -(oi / minCall) : oi / maxCall;
    });
  });

  return instrument < 2 ? [traces[instrument]] : traces;
}

const INSTRUMENTS = [
  { value: 0, label: 'Calls' },
  { value: 1, label: 'Puts' },
  { value: 2, label: 'All' },
];

const convertEntry = (entry: any) =>
  Object.fromEntries(
    [...Object.entries(entry)].map(([k, v]) => [k, Number(v)]),
  );

export const DailyOpenInterest = () => {
  const [data, setData] = useState<ExpiryStrikeMapOi>(new Map());
  const [dateRange, setDateRange] = useState<number[]>([0, 0]);
  const [oiRange, setOiRange] = useState<number[]>([0, 0]);
  const [maxAbsOI, setMaxAbsOI] = useState<number>(0);
  const [instrument, setInstrument] = useState<number>(2);
  const [entity, setEntity] = useState<string>('Market Maker');
  const { fetchAPIWithLog } = useLog('oi');
  const [oiSyms, setOiSyms] = useRecoilState(oiSymsState);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const { sym, setSym } = useSetSym();
  const theme = useTheme();

  useEffect(() => {
    const fetchSyms = async () => {
      const syms = await fetchAPIWithLog('v1/oi_syms');
      setOiSyms(new Set(syms as string[]));
    };
    fetchSyms();
  }, [setOiSyms]);

  useEffect(() => {
    if (sym == null) {
      setSym('SPX', ProductType.INTERNAL_OPEN_INTEREST);
    }
  }, [sym, setSym]);

  useEffect(() => {
    if (sym == null) {
      return;
    }
    const fetchOI = async () => {
      const resp = await fetchRawAPI(`v1/oi?sym=${sym}`);
      const bytes = await resp.arrayBuffer();
      const rawObj = decode(bytes) as RawOiObj;
      const newData = new Map();
      for (const [expiry, strike2oi] of Object.entries(rawObj).sort()) {
        const strikes = new Map();
        newData.set(Number(expiry), strikes);
        for (const [strike, entries] of Object.entries(strike2oi)) {
          strikes.set(
            Number(strike),
            entries.map((e) => (e == null ? null : convertEntry(e))),
          );
        }
      }
      setData(newData);
    };
    fetchOI();
  }, [sym]);

  useEffect(() => {
    const suggestionsHandler: SearchHandlerFunction = (val: string): void => {
      let sym = val.toUpperCase();
      if (oiSyms.has(sym)) {
        setSym(sym, ProductType.INTERNAL_OPEN_INTEREST);
      }
    };
    setSuggestionsHandler(() => suggestionsHandler);
  }, [oiSyms, setSym, setSuggestionsHandler]);

  useEffect(() => {
    // search suggestions data
    const result: SuggestionSearchData[] = [...oiSyms].map((sym: string) => ({
      symbol: sym,
      name: '',
    }));

    setSuggestionsData(result);
  }, [oiSyms, setSuggestionsData]);

  const traces = useMemo(
    () => getTraces(data, dateRange, oiRange, instrument, entity),
    [data, dateRange, oiRange, instrument, entity],
  );
  const expiries = useMemo(() => [...data.keys()].sort(), [data]);
  useEffect(() => {
    setDateRange([0, data.size - 1]);
  }, [data]);

  useEffect(() => {
    let maxNetOI = 0;
    for (const [_exp, strikes] of data.entries()) {
      for (const [_strike, entries] of strikes) {
        entries.forEach((e: any) => {
          if (e == null) {
            return;
          }

          const buy = e[ENTITY_MAP.get(entity)!.buy];
          const sell = e[ENTITY_MAP.get(entity)!.sell];
          const netOI = buy - sell;
          maxNetOI = Math.max(Math.abs(netOI), maxNetOI);
        });
      }
    }
    setMaxAbsOI(maxNetOI);
  }, [data, entity]);

  useEffect(() => {
    setOiRange([0, maxAbsOI]);
  }, [maxAbsOI]);

  if (data.size === 0) {
    return <Box>fetching...</Box>;
  }

  const handleMultiSlider =
    (setter: Dispatch<SetStateAction<number[]>>) =>
    (_event: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setter((range: number[]) => [
          Math.min(newValue[0], range[1]),
          range[1],
        ]);
      } else {
        setter((range: number[]) => [
          range[0],
          Math.max(newValue[1], range[0]),
        ]);
      }
    };

  const handleDateRange = handleMultiSlider(setDateRange);
  const handleOiRange = handleMultiSlider(setOiRange);
  const handleInstrumentChange = (
    _event: Event,
    newValue: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      setInstrument(newValue);
    }
  };

  return (
    <Box>
      <Box padding="10px">
        <Stack direction="row" justifyContent="start">
          <Box width="400px" sx={{ paddingLeft: '30px' }}>
            <Typography sx={{ fontSize: '14px', my: '8px' }}>
              Expiration
            </Typography>
            <Slider
              getAriaLabel={() => 'Dates'}
              onChange={handleDateRange}
              min={0}
              max={data.size - 1}
              step={1}
              value={dateRange}
              valueLabelDisplay="auto"
              valueLabelFormat={(idx) =>
                dayjs(expiries[idx]).format('YYYY-MM-DD')
              }
            />
          </Box>
          <Box width="150px" sx={{ paddingLeft: '5%' }}>
            <Typography sx={{ fontSize: '14px', my: '8px' }}>
              Instrument
            </Typography>
            <Slider
              aria-label="Instruments"
              onChange={handleInstrumentChange}
              min={0}
              max={2}
              step={1}
              value={instrument}
              marks={INSTRUMENTS}
              valueLabelDisplay="off"
            />
          </Box>
          <Box width="100px" sx={{ paddingLeft: '5%' }}>
            <Typography sx={{ fontSize: '14px', my: '8px' }}>Entity</Typography>
            <Select
              aria-label="Entity"
              onChange={(evt: SelectChangeEvent) => {
                setEntity(evt.target.value);
              }}
              value={entity}
            >
              {[...ENTITY_MAP.keys()].map((mode) => (
                <MenuItem key={`color-mode-${mode}`} value={mode}>
                  {mode}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="start">
          <Box width="400px" sx={{ paddingLeft: '30px' }}>
            <Typography sx={{ fontSize: '14px', my: '8px' }}>
              Open Interest Range
            </Typography>
            <Slider
              getAriaLabel={() => 'Open Interest'}
              onChange={handleOiRange}
              min={0}
              max={maxAbsOI}
              step={10}
              value={oiRange}
              valueLabelDisplay="auto"
              valueLabelFormat={(n) => n}
            />
          </Box>
        </Stack>
      </Box>
      <Plot
        data={traces}
        layout={{
          autosize: true,
          height: 600,
          title: `${entity} Open Interest`,
          paper_bgcolor: theme.palette.background.paper,
          plot_bgcolor: theme.palette.background.default,
        }}
      />
    </Box>
  );
};
