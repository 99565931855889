export enum IndexSymbol {
  SPY = 'SPY',
  SPX = 'SPX',
  RUT = 'RUT',
  QQQ = 'QQQ',
  NDX = 'NDX',
  IWM = 'IWM',
}

// Order matters here and is used in UX
export const IndexSymbols = new Set([
  IndexSymbol.SPX,
  IndexSymbol.SPY,
  IndexSymbol.NDX,
  IndexSymbol.QQQ,
  IndexSymbol.RUT,
  IndexSymbol.IWM,
]);

export enum ETFSymbol {
  SPY = 'SPY',
  SPX = 'SPX',
  RUT = 'RUT',
  QQQ = 'QQQ',
  NDX = 'NDX',
  IWM = 'IWM',
  ARKK = 'ARKK',
  BITO = 'BITO',
  DIA = 'DIA',
  EEM = 'EEM',
  EFA = 'EFA',
  EWZ = 'EWZ',
  VXX = 'VXX',
  SVXY = 'SVXY',
  SPXL = 'SPXL',
  FXI = 'FXI',
  GDX = 'GDX',
  GDXJ = 'GDXJ',
  SQQQ = 'SQQQ',
  TQQQ = 'TQQQ',
  TLT = 'TLT',
  HYG = 'HYG',
  IVR = 'IVR',
  IYR = 'IYR',
  JNK = 'JNK',
  KWEB = 'KWEB',
  LQD = 'LQD',
  KBE = 'KBE',
  KRE = 'KRE',
  OIH = 'OIH',
  SMH = 'SMH',
  VIX = 'VIX',
  SOXX = 'SOXX',
  UVXY = 'UVXY',
  XBI = 'XBI',
  XHB = 'XHB',
  XME = 'XME',
  XLC = 'XLC',
  XLI = 'XLI',
  XLB = 'XLB',
  XLK = 'XLK',
  XLP = 'XLP',
  XLRE = 'XLRE',
  XLF = 'XLF',
  XAR = 'XAR',
  XLU = 'XLU',
  XLV = 'XLV',
  XOP = 'XOP',
  XLE = 'XLE',
  XRT = 'XRT',
  XLY = 'XLY',
  USO = 'USO',
  BOIL = 'BOIL',
  MGK = 'MGK',
  VNQ = 'VNQ',
  MAGS = 'MAGS',
  ITB = 'ITB',
  IBB = 'IBB',
  IYE = 'IYE',
}

export const ETFSymbols: Set<string> = new Set([...Object.values(ETFSymbol)]);

export enum IndexGrouping {
  SP = 'S&P',
  NASDAQ = 'NASDAQ',
  RUSSEL = 'RUSSEL',
}

export type IndexLevel = {
  C1: number;
  C2: number;
  C3: number;
  C4: number;
  L1: number;
  L2: number;
  L3: number;
  L4: number;
  atm_Theta: number;
  atm_Vega: number;
  atm_d_calls: number;
  atm_d_puts: number;
  atm_g_c_n: number;
  atm_g_calls: number;
  atm_g_p_n: number;
  atm_g_puts: number;
  calls_OI: number;
  calls_vol: number;
  callwallgam: number;
  callwallstrike: number;
  itm_d: number;
  max_g_strike: number;
  puts_OI: number;
  puts_vol: number;
  putwallgam: number;
  putwallstrike: number;
  rowno: number;
  rr: number;
  sym: string;
  topabs_strike: number;
  trade_date: string;
  upx: number;
  zero_g_strike: number;
};

export type RawGammaLevelData = {
  current_list: string;
  model: string;
  next_exp_list: string;
  strike_list: string;
  sym: string;
  trade_date: string;
};

export enum GammaLevelType {
  NEXT_EXP = 'next_exp',
  CURRENT = 'current',
}

export enum TiltType {
  GAMMA = 'gamma_tilt',
  DELTA = 'delta_tilt',
}

export type RawTilt = {
  gamma_tilt: number;
  delta_tilt: number;
  trade_date: string;
  upx: number;
};

export type Tilt = {
  gamma_tilt: number;
  delta_tilt: number;
  epoch_millis: number;
  upx: number;
};

export type ExpirationConcentration = {
  Expiration: string;
  Type: string;
  UnderlyingSymbol: string;
  delta: number;
  gamma: number;
  oi: number;
  volume: number;
};

export type GroupedExpirationConcentration = {
  Expiration: string;
  put: number;
  call: number;
};

export enum GammaVannaModel {
  GAMMA = 'gamma',
  VANNA = 'vanna',
  DELTA = 'delta',
  VOLFORECAST = 'volforecast',
}

export type RawModelRow = {
  current_list: string;
  next_exp_list: string;
  strike_list: string;
  model: string;
  sym: string;
  trade_date: string;
};

export type GammaVanna = {
  strike: number;
  current: number;
  next_exp: number;
};

export type Delta = {
  strike: number;
  all_expiry: number;
  wo_next_expiry: number;
  wo_next_monthly_expiry: number;
};

export enum PutCall {
  PUT = 'put',
  CALL = 'call',
}

export type OIVolume = {
  strike: number;
  put_oi: number;
  call_oi: number;
  put_volume: number;
  call_volume: number;
};

export type StrikeConcentration = {
  Strike: number;
  Type: string;
  UnderlyingSymbol: string;
  delta: number;
  gamma: number;
  oi: number;
  volume: number;
};

export type ExpirationPutCallConcentration = {
  expiration: string;
  put?: number;
  call?: number;
  delta: number;
  gamma: number;
  put_delta: number;
  call_delta: number;
  put_gamma: number;
  call_gamma: number;
};

export type StrikePutCallConcentration = {
  strike: number;
  put?: number;
  call?: number;
  delta: number;
  gamma: number;
  percent_gamma?: number;
  percent_delta?: number;
};

export type VolHistogram = {
  five_day?: number;
  thirty_day?: number;
  label: number;
};

export type RawZeroDTE = {
  trade_date: string;
  zero_dte_volume: number;
  all_volume: number;
  zero_dte_oi: number;
  all_oi: number;
  percent_total: number;
  sym: string;
};

export type ZeroDTE = {
  sym: string;
  epoch_millis: number;
  zero_dte_volume: number;
  all_volume: number;
  zero_dte_oi: number;
  all_oi: number;
  percent_total: number;
  zeroOIPercentage: number;
  zeroVolPercentage: number;
};

export type Historical = {
  call_wall_strike: number;
  max_g_strike: number;
  put_wall_strike: number;
  epoch_millis: number;
  upx: number;
};

export type RawHistorical = {
  call_wall_strike: number;
  max_g_strike: number;
  put_wall_strike: number;
  net_gamma: number;
  net_delta: number;
  trade_date: string;
  upx: number;
};

export type OptionsRiskReversal = {
  rr: number;
  epoch_millis: number;
};

export type MaxRealVol = {
  epoch_millis: number;
  price: number;
  vDiv_2m_6m: number;
  vDiv_1m_3m: number;
};

export type Vol = {
  epoch_millis: number;
  five_day_vol: number;
  one_month_vol: number;
  two_month_vol: number;
  six_month_vol: number;
  three_month_vol: number;
};

export enum VDivRatio {
  TWOMONTH_SIXMONTH = 'vDiv_2m_6m',
  ONEMONTH_THREEMONTH = 'vDiv_1m_3m',
}

export const VDIV_RATIO_OPTIONS = [
  VDivRatio.ONEMONTH_THREEMONTH,
  VDivRatio.TWOMONTH_SIXMONTH,
];

export enum VolDuration {
  FIVE_DAY_VOL = 'five_day_vol',
  ONE_MONTH_VOL = 'one_month_vol',
  TWO_MONTH_VOL = 'two_month_vol',
  SIX_MONTH_VOL = 'six_month_vol',
  THREE_MONTH_VOL = 'three_month_vol',
}

export const VOL_DURATION_RADIO_OPTIONS = [
  VolDuration.FIVE_DAY_VOL,
  VolDuration.ONE_MONTH_VOL,
  VolDuration.TWO_MONTH_VOL,
  VolDuration.THREE_MONTH_VOL,
  VolDuration.SIX_MONTH_VOL,
];

export enum IndicesContentType {
  REAL_TIME_LEVELS = 'real_time_levels',
  GAMMA_LEVELS = 'gamma_levels',
  TILT = 'tilt',
  EXP_CONCENTRATION = 'exp_concentration',
  COMBO_STRIKES = 'combo_strikes',
  ZERO_DTE = 'zero_dte',
  GAMMA_MODEL = 'gamma_model',
  VANNA_MODEL = 'vanna_model',
  DELTA_MODEL = 'delta_model',
  VOLFORECAST_MODEL = 'volforecast_model',
  OI_VOLUME = 'oi_volume',
  EXP_CONCENTRATION_TABLE = 'exp_concentration_table',
  STRIKE_CONCENTRATION_TABLE = 'strike_concentration_table',
  OPTIONS_RISK_REVERSAL_CHART = 'options_risk_reversal_chart',
  HISTORICAL_CHART = 'historical_chart',
  MAX_REAL_VOL = 'max_real_vol',
  HIST_RETURNS = 'hist_returns',
  VOLATILITY = 'volatility',
  VIX = 'vix',
}
