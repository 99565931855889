import { SxProps, Theme, Typography, useTheme } from '@mui/material';

interface SelectedSymbolProps {
  symbol: string;
  as?: string;
  sx?: SxProps<Theme> | React.CSSProperties;
}

const SelectedSymbol = ({ symbol, as, sx }: SelectedSymbolProps) => {
  const theme = useTheme();

  return (
    <Typography
      as={as}
      sx={{
        position: 'absolute',
        right: '25px',
        top: '5px',
        fontSize: {
          xs: 16,
          md: 18,
          lg: 20,
        },
        opacity: 0.75,
        py: '4px',
        px: '6px',
        borderRadius: 2,
        color: theme.palette.sgGreen,
        cursor: 'default',
        ...sx,
      }}
    >
      {symbol}
    </Typography>
  );
};

export default SelectedSymbol;
