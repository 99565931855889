import { useRecoilState } from 'recoil';
import { Stack, IconButton } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import DeltaIcon from '../../../icons/DeltaIcon';
import { volSkewDisplayTypeState } from '../../../states';
import { StrikeDisplayType } from '../../../types';

const TYPE_TO_ICON = new Map<StrikeDisplayType, React.ReactNode>([
  [StrikeDisplayType.FixedStrike, <AttachMoneyIcon />],
  [StrikeDisplayType.Delta, <DeltaIcon />],
  [StrikeDisplayType.Moneyness, <PercentIcon />],
]);

export const VolSkewTypeSwitch = () => {
  const [selectedDisplayType, setVolDisplayType] = useRecoilState(
    volSkewDisplayTypeState,
  );
  return (
    <Stack direction="row">
      {[...TYPE_TO_ICON.entries()].map(([dispType, icon], index: number) => (
        <IconButton
          key={`${dispType}-${index}`}
          onClick={(_evt) => {
            setVolDisplayType(dispType);
          }}
          size="small"
          color="primary"
          disabled={selectedDisplayType === dispType}
        >
          {icon}
        </IconButton>
      ))}
    </Stack>
  );
};
