import { Box, Collapse, IconButton, Link, Stack } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip as ChartTooltip,
  Brush,
} from 'recharts';
import useHistorical from '../../../hooks/indices/useHistorical';
import { ErrorContent, Loader, SGSelect, ZoomOutButton } from '../../shared';
import {
  BrushZoomConfig,
  Historical,
  IndicesContentType,
  RawHistorical,
  SymSelectorSettings,
} from '../../../types';
import {
  formatAsCompactNumber,
  formatAsCurrency,
  nextBusinessDay,
  formatAsCompactNumberCallback,
  businessDaysAdd,
  getUtcYMD,
  getZoomConfigRefArea,
} from '../../../util';
import { SGTooltip } from '../../core';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_BRUSH_ZOOM_CONFIG,
} from '../../../config';
import useBrushZoom from '../../../hooks/useBrushZoom';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import { IndicesHeader } from '../shared';
import { componentIsExpandedState } from 'states';

const CSV_FIELDS = [
  'Trade Date',
  'Ref Px',
  'Net Gamma',
  'Net Delta',
  'Call Wall',
  'Put Wall',
  'Vol Trigger',
  'Data Release',
];

// default to showing the last 180 days of data to avoid visual overload
const LOOKBACK = 180;

interface HistoricalChartProps {
  initialData?: any;
  selectedSym: string;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  symSelectorSettings?: SymSelectorSettings;
  isUnfolded?: boolean;
  setIsUnfolded?: SetterOrUpdater<boolean>;
}

export const HistoricalChart = ({
  initialData,
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
  isUnfolded,
  setIsUnfolded,
}: HistoricalChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [isHistChartExpanded, _] =
    useRecoilState(componentIsExpandedState(IndicesContentType.HISTORICAL_CHART));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getHistorical } = useHistorical();
  const [rawData, setRawData] = useState<RawHistorical[]>(initialData ?? []);
  const [historicalData, setHistoricalData] = useState<Historical[]>([]);
  const [zoomConfig, setZoomConfig] = useState<BrushZoomConfig>(
    DEFAULT_BRUSH_ZOOM_CONFIG,
  );

  const SelectableLines = new Map<keyof RawHistorical, string>([
    ['upx', 'Price'],
    ['call_wall_strike', 'Call Wall'],
    ['put_wall_strike', 'Put Wall'],
    ['max_g_strike', 'Volatility Trigger'],
  ]);
  if (selectedSym === 'SPX') {
    SelectableLines.set('net_gamma', 'Gamma Index');
  }

  const [selectedLines, setSelectedLines] = useState<(keyof RawHistorical)[]>([
    ...SelectableLines.keys(),
  ]);

  const { zoomChartConfig } = useBrushZoom<Historical>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    historicalData,
  );

  const getBlobURL = useCallback(() => {
    const records = (rawData ?? []).map((row: RawHistorical) =>
      [
        dayjs(row.trade_date).format('YYYY-MM-DD'),
        row.upx,
        row.net_gamma,
        row.net_delta,
        row.call_wall_strike,
        row.put_wall_strike,
        row.max_g_strike,
        nextBusinessDay(dayjs(row.trade_date)).format('YYYY-MM-DD'),
      ].join(','),
    );
    const csv = records.join('\n');
    const data = `${CSV_FIELDS.join(',')}\n${csv}`;
    return URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
  }, [rawData]);

  const lastDay = useMemo(() => {
    const lastDate = rawData[rawData.length - 1]?.trade_date;
    return nextBusinessDay(dayjs(lastDate)).format('YYYY_MM_DD');
  }, [rawData]);

  useEffect(() => {
    async function generateOCC() {
      let data = initialData;
      if (!Array.isArray(data)) {
        setIsLoading(true);
        // only fetch new data if not passed in initialData
        data = await getHistorical(selectedSym as string);
        setRawData(data);
      }
      const transformedData = data
        .map((row: RawHistorical) => ({
          call_wall_strike: row.call_wall_strike,
          max_g_strike: row.max_g_strike,
          put_wall_strike: row.put_wall_strike,
          epoch_millis: businessDaysAdd(dayjs(row.trade_date), 1).valueOf(),
          net_gamma: row.net_gamma,
          upx: row.upx,
        }))
        .sort(
          (a: Historical, b: Historical) => a.epoch_millis - b.epoch_millis,
        );

      setHistoricalData(transformedData);
      if (transformedData.length > LOOKBACK) {
        setZoomConfig((prev) => ({
          ...prev,
          data: transformedData,
          leftIdx: transformedData.length - LOOKBACK - 1,
          rightIdx: transformedData.length - 1,
        }));
      } else {
        setZoomConfig((prev) => ({
          ...prev,
          data: transformedData,
          leftIdx: 0,
          rightIdx: transformedData.length - 1,
        }));
      }
      setIsLoading(false);
    }
    generateOCC();
  }, [
    getHistorical,
    setZoomConfig,
    selectedSym,
    setHistoricalData,
    initialData,
  ]);

  if (zoomConfig.data == null && !isLoading) {
    return <ErrorContent />;
  }

  const chartComponent = !isLoading && zoomConfig.data && (
    <ChartWatermarkContainer
      ref={ref}
      size={15}
      offsetX={50}
      offsetY={40}
      style={{ ...chartStyleOverrides }}
    >
      <ResponsiveContainer>
        <LineChart
          margin={{ ...DEFAULT_CHART_MARGINS, left: 10 }}
          {...zoomChartConfig}
        >
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />
          <XAxis
            allowDataOverflow
            label={{
              ...DEFAULT_X_AXIS_STYLES,
              value: 'Date',
              offset: 3,
            }}
            dataKey="epoch_millis"
            type="number"
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 10 }}
            tickFormatter={getUtcYMD}
            tickCount={15}
          />
          <Brush
            dataKey="epoch_millis"
            tickFormatter={getUtcYMD}
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={20}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            yAxisId="left"
            tickFormatter={(v: number) => `$${formatAsCompactNumber(v)}`}
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            type="number"
            label={{
              value: 'Price',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
            orientation="left"
          />
          <YAxis
            allowDataOverflow
            yAxisId="right"
            tickFormatter={formatAsCompactNumberCallback}
            tick={{ fontSize: 11 }}
            domain={['dataMin', 'dataMax']}
            type="number"
            label={{
              value: 'Gamma Index',
              ...DEFAULT_Y2_AXIS_STYLES,
            }}
            orientation="right"
          />
          <ChartTooltip
            formatter={(v: string, name: string) =>
              name === 'Gamma Index' ? v : formatAsCurrency(v, true)
            }
            labelFormatter={getUtcYMD}
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
          />
          {selectedLines.includes('max_g_strike') && (
            <Line
              yAxisId="left"
              // type="monotone"
              dataKey="max_g_strike"
              name="Volatility Trigger"
              dot={false}
              stroke={theme.palette.sentiment.historical.max_g_strike}
              connectNulls
              animationDuration={300}
            />
          )}
          {selectedLines.includes('upx') && (
            <Line
              yAxisId="left"
              // type="monotone"
              dataKey="upx"
              name={`${selectedSym} Price`}
              dot={false}
              stroke={theme.palette.sentiment.historical.upx}
              connectNulls
              animationDuration={300}
            />
          )}
          {selectedLines.includes('put_wall_strike') && (
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="put_wall_strike"
              name="Put Wall"
              dot={false}
              stroke={theme.palette.sentiment.historical.put_wall_strike}
              connectNulls
              animationDuration={300}
            />
          )}
          {selectedLines.includes('call_wall_strike') && (
            <Line
              yAxisId="left"
              // type="monotone"
              dataKey="call_wall_strike"
              name="Call Wall"
              dot={false}
              stroke={theme.palette.sentiment.historical.call_wall_strike}
              connectNulls
              animationDuration={300}
            />
          )}
          {selectedLines.includes('net_gamma') && selectedSym === 'SPX' && (
            <Line
              yAxisId="right"
              dataKey="net_gamma"
              name="Gamma Index"
              key="net_gamma"
              type="linear"
              stroke={theme.palette.sentiment.historical.gamma_index}
              dot={false}
              strokeWidth={1}
              connectNulls
            />
          )}
          {getZoomConfigRefArea(zoomConfig)}
        </LineChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );

  return (
    <Loader isLoading={isLoading}>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          gap: '8px',
          ...containerStyleOverrides,
        }}
      >
        <IndicesHeader
          symbol={selectedSym}
          type={IndicesContentType.HISTORICAL_CHART}
          title="Historical Chart"
          expandable={initialData == null}
          isUnfolded={isUnfolded}
          setIsUnfolded={setIsUnfolded}
          shouldHideBtns={isUnfolded === false}
          symSelectorSettings={symSelectorSettings}
          customController={
            <Stack direction="row" gap={1}>
              <SGSelect
                key={'historical_selected_lines'}
                label={'Select lines'}
                value={selectedLines}
                setter={setSelectedLines}
                options={[...SelectableLines.keys()]}
                optionsTextTransform={(o) => SelectableLines.get(o)!}
                dontRenderAllSelected={true}
              />
              <Link
                download={`${selectedSym}_${lastDay}.csv`}
                onClick={(evt: React.SyntheticEvent) => {
                  // Generate CSV on-demand and set it as href of the link
                  const target = evt.currentTarget as HTMLLinkElement;
                  target.href = getBlobURL();
                }}
              >
                <SGTooltip title="Download">
                  <IconButton size="small" color="primary">
                    <FileDownloadIcon />
                  </IconButton>
                </SGTooltip>
              </Link>
              <ZoomOutButton
                zoomConfig={zoomConfig}
                setZoomConfig={setZoomConfig}
                initialData={historicalData}
                overrideDefault={{
                  leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                  rightIdx: historicalData.length - 1,
                }}
              />
            </Stack>
          }
        />

        <Collapse
          in={isUnfolded}
          sx={{
            flex: 1,
            display: isUnfolded != null && setIsUnfolded && !isHistChartExpanded ? 'flex' : 'none',
          }}
          unmountOnExit
        >
          {chartComponent}
        </Collapse>

        {(!isUnfolded && !setIsUnfolded || isHistChartExpanded) && chartComponent}
      </Stack>
    </Loader>
  );
};
