import { atom, selector } from 'recoil';
import {
  COMBO_SYMS,
  DEFAULT_BRUSH_ZOOM_CONFIG,
  defaultColumnScannerVisibility,
  EH_LEGACY_EXPANDED_EQUITY_DETAILS_FIELDS,
  EH_LEGACY_MAIN_EQUITY_DETAILS_FIELDS,
  EH_SYNTH_OI_EXPANDED_EQUITY_DETAILS_FIELDS,
  EH_SYNTH_OI_MAIN_EQUITY_DETAILS_FIELDS,
  FIELD_NAME_MAPPING,
  historyFields,
  scannerFields,
  USING_NEW_MODEL,
} from '../config';
import {
  BrushZoomConfig,
  CoreEquity,
  EhCompositeMode,
  EhModelType,
  EquityFieldKey,
  HistoricalEquity,
  LegacyEquity,
  NewModelPcType,
  RiskReversal,
  Scanner,
  SynthOIChartType,
  SynthOIEquity,
  SynthOIPCImpactBarGroup,
  SynthOIPCImpactChartType,
  SynthOIPCImpactDatum,
  SynthOIPCImpactLine,
  Tick,
  VolSkew,
} from '../types';
import { dayjs, getLevelsFromDataAndFields } from '../util';
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPinnedColumnFields,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { recoilPersist } from 'recoil-persist';
import { watchlistsState } from './auth';
import { ascending, quantile } from 'd3';

const { persistAtom } = recoilPersist();

export const realTimeInitialDataState = atom<Tick[]>({
  key: 'equityhub-realTimeInitialDataState',
  default: [],
});

// we init equities with COMBO_SYMS, so equities will always have at least that many
// items even if we havent fetched from the server yet
export const UNLOADED_EQUITIES_DEFAULT_LENGTH = COMBO_SYMS.length;

// Map ({instrument: data}) of all stocks in screener (unset prices)
export const unpricedEquitiesState = atom<
  Map<string, LegacyEquity | SynthOIEquity>
>({
  key: 'equityhub-equitiesState',
  default: new Map(),
});

export const equitiesFetchLoadingState = atom<boolean>({
  key: 'equityhub-equitiesFetchLoadingState',
  default: true,
});

// Map ({instrument: currentPrice}) of all stocks in screener
export const pricesState = atom<Map<string, number>>({
  key: 'equityhub-prices',
  default: new Map(),
});

// Merge price data with equitiesState
export const synthesizedEquitiesState = selector<
  Map<string, SynthOIEquity | LegacyEquity>
>({
  key: 'equityhub-synthesizedEquities',
  get: ({ get }) => {
    const equities = get(unpricedEquitiesState);
    const prices = get(pricesState);
    const watchlists = get(watchlistsState);
    const watchlistSyms = new Set(watchlists?.flatMap((w) => w.symbols));
    const synthesized = new Map();
    for (const [sym, equity] of equities.entries()) {
      synthesized.set(sym, {
        ...equity,
        price: prices.get(sym),
        id: sym,
        isWatchlisted: watchlistSyms.has(sym),
      });
    }
    return synthesized;
  },
});

export const equitySymbolsState = selector({
  key: 'equityhub-instrumentsState',
  get: ({ get }) => {
    const modelType = get(ehModelTypeState);
    const equities = get(unpricedEquitiesState);

    // Handle both LegacyEquity and SynthOIEquity types
    if (modelType === EhModelType.Legacy) {
      return Array.from(
        equities.entries() as IterableIterator<[string, LegacyEquity]>,
      ).map(([symbol, equity]) => {
        return { symbol, name: equity.name ?? symbol };
      });
    } else {
      return Array.from(
        equities.entries() as IterableIterator<[string, SynthOIEquity]>,
      ).map(([symbol, equity]) => {
        return { symbol, name: equity.name ?? symbol };
      });
    }
  },
});

export const equityHubSymbolsSetState = selector({
  key: 'equityhub-equitySymbolsSetState',
  get: ({ get }) => {
    const modelType = get(ehModelTypeState);
    const equities = get(unpricedEquitiesState);

    if (modelType === EhModelType.Legacy) {
      const legacyEquities = equities as Map<string, LegacyEquity>;
      return new Set(Array.from(legacyEquities.entries()).map(([sym]) => sym));
    } else {
      const synthEquities = equities as Map<string, SynthOIEquity>;
      return new Set(Array.from(synthEquities.entries()).map(([sym]) => sym));
    }
  },
});

export const selectedEquitySymbolState = atom<string>({
  key: 'equityhub-selectedEquitySymbolState',
  default: 'SPX',
});

export const selectedEquityState = selector({
  key: 'equityhub-selectedEquityState',
  get: ({ get }) => {
    const equities = get(synthesizedEquitiesState);
    return equities.get(get(selectedEquitySymbolState)!);
  },
});

export const skewState = atom<VolSkew[] | undefined>({
  key: 'equityhub-skewState',
  default: undefined,
});

export const gammaStrikesState = atom<any>({
  key: 'equityhub-gammaStrikesState',
  default: [],
});

export const ehCompositeModeState = atom<EhCompositeMode>({
  key: 'equityhub-gammaStrikesState',
  default: EhCompositeMode.Factors,
});

// Filter out penny stocks
const MIN_PRICE = 10.0;
interface EquityCompositeQuantiles {
  positionMin: number;
  positionMax: number;
  activityMin: number;
  activityMax: number;
}

export const equityQuantilesState = selector<EquityCompositeQuantiles | null>({
  key: 'eh-equityCompositeQuantilesState',
  get: ({ get }) => {
    const equities = get(unpricedEquitiesState);
    if ((equities?.size ?? 0) === 0) {
      return null;
    }
    const data = [...equities.values()].filter(
      (c: CoreEquity) => c.upx > MIN_PRICE,
    );
    const mFactorArray = data
      .map((c: CoreEquity) => c.activity_factor)
      .filter((factor): factor is number => factor != null)
      .sort(ascending);
    const vFactorArray = data
      .map((c: CoreEquity) => c.position_factor)
      .filter((factor): factor is number => factor != null)
      .sort(ascending);
    return {
      positionMin: quantile(mFactorArray, 0.1) ?? 0,
      positionMax: quantile(mFactorArray, 0.9) ?? 0,
      activityMin: quantile(vFactorArray, 0.1) ?? 0,
      activityMax: quantile(vFactorArray, 0.9) ?? 0,
    };
  },
});

export const gammaNotionalState = atom<any>({
  key: 'equityhub-gammaNotionalState',
  default: [],
});

export const strikeState = atom<any>({
  key: 'equityhub-strikeState',
  default: [],
});

export const tickDataState = atom<Tick[]>({
  key: 'equityhub-tickDataState',
  default: [],
});

// List of all historical equities in screener
export const equityHistoryState = atom<HistoricalEquity[]>({
  key: 'equityhub-equityHistoryState',
  default: [],
});

// Currently active equity scanner in screener
export const ehActiveScannerState = atom<Scanner | undefined>({
  key: 'equityhub-ehActiveScannerState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'equityhub-ehScannerColumnsVisibilityState',
  default: defaultColumnScannerVisibility,
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerPinnedColumnsState = atom<GridPinnedColumnFields>({
  key: 'equityhub-ehScannerPinnedColumnsState',
  default: {
    left: ['isWatchlisted', 'sym'],
    right: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerSortModelState = atom<GridSortModel>({
  key: 'equityhub-ehScannerSortModelState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const ehUseDefaultColumnsState = atom<boolean>({
  key: 'equityhub-ehUseDefaultColumnsState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerFilterModelState = atom<GridFilterModel>({
  key: 'equityhub-ehScannerFilterModelState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehActiveWatchlistsIdsState = atom<number[]>({
  key: 'equityhub-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerColumnOrderState = atom<EquityFieldKey[]>({
  key: 'equityhub-ehScannerColumnOrderState',
  default: scannerFields,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerColumnOrderState = atom<EquityFieldKey[]>({
  key: 'equityhub-ehHistoryScannerColumnOrderState',
  default: historyFields,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerFilterModelState = atom<GridFilterModel>({
  key: 'equityhub-ehHistoryScannerFilterModelState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerColumnsVisibilityState =
  atom<GridColumnVisibilityModel>({
    key: 'equityhub-ehHistoryScannerColumnsVisibilityState',
    default: defaultColumnScannerVisibility,
    effects_UNSTABLE: [persistAtom],
  });

export const selectedEquityLevelsState = selector({
  key: 'equityhub-selectedEquityLevelsState',
  get: ({ get }) => {
    const equity = get(selectedEquityState);
    if (!equity) {
      return undefined;
    }

    return getLevelsFromDataAndFields<LegacyEquity | SynthOIEquity>(
      FIELD_NAME_MAPPING,
      equity,
    );
  },
});

export const rrChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'equityhub-rrChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const rrInitialDataState = atom<RiskReversal[]>({
  key: 'equityhub-rrInitialDataState',
  default: [],
});

export const newModelPcTypeState = atom<NewModelPcType>({
  key: 'equityhub-newModelPcTypeState',
  default: USING_NEW_MODEL
    ? NewModelPcType.SYNTH_GAMMA
    : NewModelPcType.ABS_GAMMA,
});

// Selected watchlist ids
export const ehSelectedWatchlistIdsState = atom<string[]>({
  key: 'equityhub-ehSelectedWatchlistIdsState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

// Selected scanners
export const ehSelectedScannersState = atom<Scanner[]>({
  key: 'equityhub-ehSelectedScannersState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

// Whether the watchlists and scanners sidebar is expanded
export const ehWatchlistsAndScannersExpandedState = atom<boolean>({
  key: 'equityhub-ehWatchlistsAndScannersExpandedState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const legacyEquityMainViewFieldsState = atom<EquityFieldKey[]>({
  key: 'equityhub-legacyEquityMainViewFieldsState',
  default: EH_LEGACY_MAIN_EQUITY_DETAILS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const legacyEquityExpandedViewFieldsState = atom<EquityFieldKey[]>({
  key: 'equityhub-legacyEquityExpandedViewFieldsState',
  default: EH_LEGACY_EXPANDED_EQUITY_DETAILS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const synthOIEquityMainViewFieldsState = atom<EquityFieldKey[]>({
  key: 'equityhub-synthOIEquityMainViewFieldsState',
  default: EH_SYNTH_OI_MAIN_EQUITY_DETAILS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const synthOIEquityExpandedViewFieldsState = atom<EquityFieldKey[]>({
  key: 'equityhub-synthOIEquityExpandedViewFieldsState',
  default: EH_SYNTH_OI_EXPANDED_EQUITY_DETAILS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const synthOIPCImpactTypeState = atom<SynthOIPCImpactChartType>({
  key: 'eh-synth-oi-pc-impact-chart-type-state',
  default: SynthOIPCImpactChartType.Gamma,
  effects_UNSTABLE: [persistAtom],
});

export const synthOILiveChartTypeState = atom<SynthOIChartType>({
  key: 'eh-net-pos-live-chart-type-state',
  default: SynthOIChartType.Gamma,
  effects_UNSTABLE: [persistAtom],
});

export const synthOIPCImpactLineSelection = atom<Set<SynthOIPCImpactLine>>({
  key: 'eh-net-pos-pc-impact-line-selection',
  default: new Set([
    SynthOIPCImpactLine.GammaTotal,
    SynthOIPCImpactLine.DeltaTotal,
  ]),
});

export const synthOIPCImpactBarGroupSelection = atom<
  Set<SynthOIPCImpactBarGroup>
>({
  key: 'eh-net-pos-pc-impact-bar-group-selection',
  default: new Set([
    SynthOIPCImpactBarGroup.GammaTotal,
    SynthOIPCImpactBarGroup.DeltaTotal,
    SynthOIPCImpactBarGroup.OINetPos,
  ]),
});

export const synthOIPCImpactDataState = atom<SynthOIPCImpactDatum[]>({
  key: 'eh-net-pos-pc-impact-data-state',
  default: [],
});

export const synthOILivePriceZoomLevelsState = atom<number>({
  key: 'equityhub-synthOILivePriceZoomLevelsState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const ehModelTypeState = atom<EhModelType>({
  key: 'equityhub-ehModelTypeState',
  default: EhModelType.SynthOI,
  effects_UNSTABLE: [persistAtom],
});

export const synthOIPCImpactZoomCfgState = atom<BrushZoomConfig>({
  key: 'eh-synthOIImpactZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const synthOISelectedDateState = atom<dayjs.Dayjs | null>({
  key: 'equityhub-synthOISelectedDateState',
  default: dayjs(),
});
