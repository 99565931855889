import { Typography, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { SCANNER_DESCRIPTION_MAPPING } from '../../config';
import { watchlistsState } from '../../states';
import { Scanner } from 'types/equityhub';

interface ScannerInfoSectionProps {
  activeScanner: Scanner | undefined;
  activeWatchlistsIds: number[];
}

const ScannerInfoSection = ({
  activeScanner,
  activeWatchlistsIds,
}: ScannerInfoSectionProps) => {
  const theme = useTheme();

  const watchlists = useRecoilValue(watchlistsState);
  const activeWatchlists =
    watchlists?.filter((w) => activeWatchlistsIds.includes(w.id as number)) ??
    [];

  const getDescription = () => {
    if (activeScanner) {
      return SCANNER_DESCRIPTION_MAPPING[activeScanner];
    } else if (activeWatchlistsIds.length > 0) {
      return `Viewing tickers from ${
        activeWatchlistsIds.length
      } selected watchlist${
        activeWatchlistsIds.length > 1 ? 's' : ''
      }: ${activeWatchlists.map((w) => w.name).join(', ')}`;
    }

    return null;
  };

  return activeScanner || activeWatchlistsIds.length > 0 ? (
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.text.secondary,
      }}
    >
      {getDescription()}
    </Typography>
  ) : null;
};

export default ScannerInfoSection;
