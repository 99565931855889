import { isBBEnvAvailable } from '../../../util';
import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useRecoilState } from 'recoil';
import { bssoTokenState } from '../../../states';
import useLog from '../../useLog';
import {
  bssoTokenTest,
  getBSSOTokenQuery,
} from '../../../config/bloomberg/data';

const useTerminalConnect = () => {
  const [bssoTokenGlobal, setBssoTokenGlobal] = useRecoilState(bssoTokenState);
  const { logError } = useLog('useStreaming');

  const fetchBssoGraphQlToken = async (retries: number = 0) => {
    try {
      console.log('fetching bsso token...');
      if (!isBBEnvAvailable()) {
        setBssoTokenGlobal(bssoTokenTest);
        return bssoTokenTest;
      }

      const endpoint = 'http://localhost:9000/terminal_connect/v3/';
      const httpLink = createHttpLink({
        uri: endpoint,
      });
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${
              import.meta.env.VITE_TERMINAL_CONNECT_API_TOKEN
            }`,
            'x-api-key': import.meta.env.VITE_TERMINAL_CONNECT_API_TOKEN,
          },
        };
      });
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            logError(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
        if (networkError) {
          logError(`[Network error]: ${networkError}`);
        }
      });

      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink).concat(errorLink),
      });
      const result = await client.query(getBSSOTokenQuery);
      const newToken = result.data.bssoToken.accessToken;
      setBssoTokenGlobal(newToken);
      return newToken;
    } catch (err: any) {
      logError(err, 'fetchBssoGraphqlToken');
    } finally {
      if (retries < 1 && bssoTokenGlobal == null) {
        fetchBssoGraphQlToken(retries + 1);
      }
    }

    return null;
  };

  const getGraphqlClient = (clientBssoToken?: string) => {
    const endpoint = `https://graph.bloomberg.com/tconnect/user`;
    const httpLink = createHttpLink({
      uri: endpoint,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${clientBssoToken ?? bssoTokenGlobal}`,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          logError(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        logError(`[Network error]: ${networkError}`);
      }
    });

    // disable caching as we want up-to-date data
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };

    return new ApolloClient({
      link: authLink.concat(httpLink).concat(errorLink),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });
  };

  const _runTCQuery = async (query: any, clientBssoToken?: string) => {
    const client = getGraphqlClient(clientBssoToken);
    return await client.query(query);
  };

  const runTCQuery = async (query: any, bssoToken?: string | null) => {
    bssoToken = bssoToken ?? bssoTokenGlobal;
    if (bssoToken == null) {
      console.log('no bsso token, cannot run tc query');
      return;
    }

    let token = bssoToken.slice(); // copy
    try {
      return await _runTCQuery(query);
    } catch (err) {
      logError(err);
      // 401 error if bssoToken is expired. if so renew it and try again.
      // multiple graphql calls can renew it multiple times simultaneously
      // so first check if the token we used is equal to the recoil state
      if (bssoToken === token) {
        // if still using invalid token
        console.log('bsso token expired, renewing...');
        token = await fetchBssoGraphQlToken();
      }
      console.log('retrying grapql call');
      return await _runTCQuery(query, token);
    }
  };

  return { fetchBssoGraphQlToken, runTCQuery };
};

export default useTerminalConnect;
