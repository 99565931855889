import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import {
  Stack,
  Typography,
  Button,
  SelectChangeEvent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  TextField,
  Autocomplete,
  InputAdornment,
  useMediaQuery,
  Popper,
  autocompleteClasses,
} from '@mui/material';
import { CompassData } from '../../../hooks/scanners/useCompassData';
import { alpha, useTheme, styled, Theme } from '@mui/material/styles';
import {
  displayValue,
  formatAsCurrency,
  getEquitiesForScanner,
} from '../../../util';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import OptionsDropdownSelector from '../../shared/OptionsDropdownSelector';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  compassHiddenSymbolsState,
  compassHoveredSymbolState,
  compassSelectedScannersState,
  compassSelectedWatchlistsState,
} from '../../../states/compass';
import {
  isMobileState,
  synthesizedEquitiesState,
  watchlistsState,
} from '../../../states';
import {
  CompassChartData,
  CoreEquity,
  EquityFieldKey,
  Scanner,
  StrategyCompassXYZAxis,
} from 'types';
import { SG_SPECIAL_SCANNERS } from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditWatchlists } from 'components/watchlist/EditWatchlists';
import useToast from 'hooks/useToast';
import StockIcon from 'components/StockIcon';
import SearchIcon from '@mui/icons-material/Search';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { red } from '@mui/material/colors';
import { SGTooltip } from 'components/core';
import useSynthOi from 'hooks/equityhub/useSynthOi';

type StrategyCompassEditSymbolsProps = {
  compassData: CompassData;
  compassParams: CompassParams;
};

const ALL_SCANNER_OPTIONS = SG_SPECIAL_SCANNERS.map((scObj) => ({
  label: scObj.label.name,
  value: scObj.scanner,
}));

const MAX_SYMBOLS = 50;
const LISTBOX_PADDING = 8; // px

// Context for outer element props
const OuterElementContext = React.createContext({});

// Outer element type for the virtualized list
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Hook to reset cache when data changes
function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Render row function for the virtualized list
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet.props} noWrap style={inlineStyle}>
      {dataSet.option}
    </Typography>
  );
}

// Styled Popper component for Autocomplete
const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  [`& .${autocompleteClasses.option}`]: {
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
}));

// Virtualized listbox component for Autocomplete
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: Array<{ props: any; option: string }> = [];

  // Map children to flat array
  React.Children.forEach(children as React.ReactElement[], (child) => {
    if (child) {
      itemData.push({
        props: child.props,
        option: child.props.children,
      });
    }
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 48 : 56; // Increased height for better visibility

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// Sortable table row component using dnd-kit
const SortableTableRow = ({
  data,
  hiddenSymbols,
  toggleHiddenSymbols,
  deleteSym,
  setHoveredSymbol,
  xAxis,
  yAxis,
  zAxis,
  theme,
}: {
  data: CompassChartData;
  index: number;
  hiddenSymbols: Set<string>;
  toggleHiddenSymbols: (sym: string) => void;
  deleteSym: (sym: string) => void;
  setHoveredSymbol: (sym: string | undefined) => void;
  xAxis: string;
  yAxis: string;
  zAxis: string;
  theme: Theme;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: data.sym,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging
      ? alpha(theme.palette.primary.main, 0.3)
      : hiddenSymbols.has(data.sym)
      ? 'inherit'
      : alpha(theme.palette.primary.main, 0.1),
    '&:hover': {
      bgcolor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    '&:last-child td, &:last-child th': { border: 0 },
    '& td, & th': {
      borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    },
  };

  return (
    <TableRow
      ref={setNodeRef}
      sx={style}
      onMouseEnter={() => setHoveredSymbol(data.sym)}
      onMouseLeave={() => setHoveredSymbol(undefined)}
      onClick={() => toggleHiddenSymbols(data.sym)}
    >
      <TableCell
        sx={{
          width: '30px',
          cursor: 'grab',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}
        onClick={(e) => e.stopPropagation()}
        {...attributes}
        {...listeners}
      >
        <DragIndicatorIcon fontSize="small" />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        onClick={() => toggleHiddenSymbols(data.sym)}
        sx={{
          fontSize: { xs: 12, md: 14 },
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100px',
        }}
      >
        <Stack flex={1} direction="row" alignItems="center" spacing={2}>
          <StockIcon symbol={data.sym} />
          <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
            {data.sym}
            {data.symData.equity?.[EquityFieldKey.earningsDate] && (
              <SGTooltip title="Earnings within 3 weeks from now">
                <sup style={{ fontSize: 10, color: red[600] }}> E</sup>
              </SGTooltip>
            )}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {formatAsCurrency(data.symData.price)}
      </TableCell>
      <TableCell align="right">
        {displayValue(data.unroundedX, xAxis as StrategyCompassXYZAxis)}
      </TableCell>
      <TableCell align="right">
        {displayValue(data.unroundedY, yAxis as StrategyCompassXYZAxis)}
      </TableCell>
      {data.unroundedZ != null && (
        <TableCell align="right">
          {displayValue(data.unroundedZ, zAxis as StrategyCompassXYZAxis)}
        </TableCell>
      )}
      <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            toggleHiddenSymbols(data.sym);
          }}
          color="primary"
          sx={{ p: 0.5 }}
        >
          {hiddenSymbols.has(data.sym) ? (
            <VisibilityOffIcon fontSize="small" />
          ) : (
            <VisibilityIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            deleteSym(data.sym);
          }}
          color="primary"
          sx={{ p: 0.5 }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const StrategyCompassEditSymbols = ({
  compassParams,
  compassData,
}: StrategyCompassEditSymbolsProps) => {
  const watchlists = useRecoilValue(watchlistsState);
  const { openToast } = useToast();
  const { isSynthOI } = useSynthOi();

  const { chartData, visibleChartData } = compassData;
  const {
    setSyms,
    syms,
    zAxisDataKey,
    xAxis,
    yAxis,
    zAxis,
    setActiveWatchlistIdsState,
  } = compassParams;

  const equities = useRecoilValue(synthesizedEquitiesState);
  const [showEditWatchlistModal, setShowEditWatchlistModal] = useState(false);
  const [maxSymbolsReached, setMaxSymbolsReached] = useState(false);
  const [symbolsWereTruncated, setSymbolsWereTruncated] = useState(false);
  const [sortedChartData, setSortedChartData] = useState<CompassChartData[]>(
    [],
  );
  const [orderedSyms, setOrderedSyms] = useState<string[]>([]);

  const theme = useTheme();

  const [hiddenSymbols, setHiddenSymbols] = useRecoilState(
    compassHiddenSymbolsState,
  );
  const setHoveredSymbol = useSetRecoilState(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  // Use Recoil states for selected scanners and watchlists
  const [selectedScanners, setSelectedScanners] = useRecoilState(
    compassSelectedScannersState,
  );
  const [selectedWatchlists, setSelectedWatchlists] = useRecoilState(
    compassSelectedWatchlistsState,
  );

  const scannerOptions = useMemo(() => {
    return isSynthOI
      ? ALL_SCANNER_OPTIONS.filter((s) => s.value !== Scanner.SQUEEZE)
      : ALL_SCANNER_OPTIONS;
  }, [isSynthOI]);

  // Initialize sortedChartData and orderedSyms when chartData or syms changes
  useEffect(() => {
    // If we have an existing order, maintain it and add new items at the bottom
    if (orderedSyms.length > 0) {
      // Get symbols that are in chartData
      const chartSymbols = chartData.map((item) => item.sym);

      // Filter out ordered symbols that no longer exist in chartData
      const validOrderedSyms = orderedSyms.filter((sym) =>
        chartSymbols.includes(sym),
      );

      // Find new symbols that aren't in our ordered list yet
      const newSymbols = chartSymbols.filter(
        (sym) => !validOrderedSyms.includes(sym),
      );

      // Create the new complete ordered list with new symbols at the end
      const newOrderedSyms = [...validOrderedSyms, ...newSymbols];
      setOrderedSyms(newOrderedSyms);

      // Sort chartData according to our ordered list
      const newSortedData = [...chartData].sort((a, b) => {
        const indexA = newOrderedSyms.indexOf(a.sym);
        const indexB = newOrderedSyms.indexOf(b.sym);
        return indexA - indexB;
      });

      setSortedChartData(newSortedData);
    } else {
      // Initial setup - use the order from chartData
      setSortedChartData([...chartData]);
      setOrderedSyms(chartData.map((item) => item.sym));
    }
  }, [chartData, syms]);

  // Get all available symbols from manifest
  const allAvailableSymbols = useMemo(
    () => Array.from(equities.values()).map((eq) => eq.sym),
    [equities],
  );

  // Get equity details for autocomplete
  const equityDetails = useMemo(() => {
    const details = new Map<string, { name: string; sym: string }>();
    Array.from(equities.values()).forEach((eq) => {
      details.set(eq.sym, { name: eq.name || '', sym: eq.sym });
    });
    return details;
  }, [equities]);

  // Filter out any selected watchlists that no longer exist
  useEffect(() => {
    if (watchlists && selectedWatchlists.length > 0) {
      const existingWatchlistIds = new Set(watchlists.map((wl) => `${wl.id!}`));

      const validSelectedWatchlists = selectedWatchlists.filter((id) =>
        existingWatchlistIds.has(id),
      );

      // If some watchlists were removed, update the selected watchlists
      if (validSelectedWatchlists.length !== selectedWatchlists.length) {
        setSelectedWatchlists(validSelectedWatchlists);

        // Also update the active watchlist IDs in the compass params
        setActiveWatchlistIdsState(
          validSelectedWatchlists.map(Number).filter((id) => !isNaN(id)),
        );
      }
    }
  }, [
    watchlists,
    selectedWatchlists,
    setSelectedWatchlists,
    setActiveWatchlistIdsState,
  ]);

  const toggleHiddenSymbols = (sym: string) => {
    if (hiddenSymbols.has(sym)) {
      showSymbols([sym]);
    } else {
      hideSymbols([sym]);
    }
  };

  const hideSymbols = (syms: string[]) => {
    setHiddenSymbols(new Set([...hiddenSymbols].concat(syms)));
  };

  const showSymbols = (syms: string[]) => {
    const setArr = [...hiddenSymbols].filter((s) => !syms.includes(s));
    setHiddenSymbols(new Set(setArr));
  };

  const deleteSym = (sym: string) => {
    setSyms(syms.filter((s) => s !== sym));
    setHiddenSymbols(
      (hiddenSyms) => new Set([...hiddenSyms].filter((s) => s !== sym)),
    );
    // Also update our ordered list
    setOrderedSyms(orderedSyms.filter((s) => s !== sym));
    setMaxSymbolsReached(false);
    setSymbolsWereTruncated(false);
  };

  const deleteAllSyms = () => {
    setSyms([]);
    setHiddenSymbols(new Set());
    setActiveWatchlistIdsState([]);
    setOrderedSyms([]);
    setMaxSymbolsReached(false);
    setSymbolsWereTruncated(false);
    setSelectedScanners([]);
    setSelectedWatchlists([]);
  };

  const handleSymbolSearch = (_event: any, newValue: any) => {
    if (!newValue) return;

    // Check if symbol is already in the list
    if (syms.includes(newValue)) {
      openToast({
        message: `${newValue} is already in your symbols list`,
        type: 'error',
      });
      return;
    }

    // Check if we've reached the maximum number of symbols
    if (syms.length >= MAX_SYMBOLS) {
      setMaxSymbolsReached(true);
      openToast({
        message: `Maximum of ${MAX_SYMBOLS} symbols reached. Delete some symbols to add more.`,
        type: 'warning',
      });
      return;
    }

    // Add the new symbol at the beginning of the list
    setSyms([newValue, ...syms]);
  };

  const onScannerSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedScannerIds = event.target.value as Scanner[];
      setSelectedScanners(selectedScannerIds);

      const resultData = [...equities.values()];

      // Get symbols from currently selected scanners
      const selectedScannerSymbols = new Set(
        selectedScannerIds
          .flatMap((scannerId) => getEquitiesForScanner(scannerId, resultData))
          .map((equity: CoreEquity) => equity.sym),
      );

      // Get symbols from selected watchlists to ensure we don't remove them
      // First, filter to make sure we only use watchlists that actually exist
      const existingWatchlistIds = new Set(
        watchlists?.map((wl) => `${wl.id!}`) || [],
      );

      const validSelectedWatchlists = selectedWatchlists.filter((id) =>
        existingWatchlistIds.has(id),
      );

      const selectedWatchlistSymbols = new Set(
        watchlists
          ?.filter((wl) => validSelectedWatchlists.includes(`${wl.id!}`))
          .flatMap((wl) => wl.symbols) ?? [],
      );

      // Combine existing symbols with new scanner symbols
      // We keep:
      // 1. All existing symbols that are in selected watchlists
      // 2. All existing symbols that aren't from scanners
      // 3. All symbols from currently selected scanners
      const updatedSymbols = [
        ...Array.from(selectedScannerSymbols),
        ...syms.filter(
          (sym) =>
            selectedWatchlistSymbols.has(sym) ||
            !ALL_SCANNER_OPTIONS.some((sc) =>
              getEquitiesForScanner(sc.value, resultData).some(
                (eq) => eq.sym === sym,
              ),
            ),
        ),
      ];

      const uniqueSymbols = Array.from(new Set(updatedSymbols));

      if (uniqueSymbols.length > MAX_SYMBOLS) {
        setSyms(uniqueSymbols.slice(0, MAX_SYMBOLS));
        setMaxSymbolsReached(true);
        setSymbolsWereTruncated(true);
      } else {
        setSyms(uniqueSymbols);
        setSymbolsWereTruncated(false);
      }
    },
    [
      equities,
      syms,
      setSyms,
      setSelectedScanners,
      watchlists,
      selectedWatchlists,
    ],
  );

  useEffect(() => {
    // Check if symbols exceed the maximum limit
    if (syms.length > MAX_SYMBOLS) {
      // Truncate symbols to the maximum allowed
      setSyms(syms.slice(0, MAX_SYMBOLS));
      setMaxSymbolsReached(true);
      setSymbolsWereTruncated(true);
    } else if (syms.length === MAX_SYMBOLS) {
      setMaxSymbolsReached(true);
      setSymbolsWereTruncated(false);
    } else {
      setMaxSymbolsReached(false);
      setSymbolsWereTruncated(false);
    }
  }, [syms, setSyms]);

  const watchlistOptions = useMemo(
    () =>
      watchlists?.map((w) => ({
        label: w.name,
        value: `${w.id!}`,
      })) ?? [],
    [watchlists],
  );

  const onEditWatchlists = () => {
    setShowEditWatchlistModal(true);
  };

  const onWatchlistSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedWatchlistIds = event.target.value as string[];
      setSelectedWatchlists(selectedWatchlistIds);

      // Verify that all selected watchlist IDs actually exist
      const existingWatchlistIds = new Set(
        watchlists?.map((wl) => `${wl.id!}`) || [],
      );

      const validSelectedWatchlistIds = selectedWatchlistIds.filter((id) =>
        existingWatchlistIds.has(id),
      );

      const numericWatchlistIds = validSelectedWatchlistIds
        .map(Number)
        .filter((id) => !isNaN(id));

      // Get symbols from currently selected watchlists
      const selectedWatchlistSymbols = new Set(
        watchlists
          ?.filter((wl) => numericWatchlistIds.includes(wl.id!))
          .flatMap((wl) => wl.symbols) ?? [],
      );

      // Get symbols from selected scanners to preserve them
      const scannerSymbols = new Set(
        selectedScanners
          .flatMap((scanner) =>
            getEquitiesForScanner(
              scanner as Scanner,
              Array.from(equities.values()),
            ),
          )
          .map((equity) => equity.sym),
      );

      // Combine existing symbols with watchlist symbols
      // Keep all scanner symbols even if watchlists are deselected
      const updatedSymbols = [
        ...syms.filter(
          (sym) => scannerSymbols.has(sym) || selectedWatchlistSymbols.has(sym),
        ),
        ...Array.from(selectedWatchlistSymbols).filter(
          (sym) => !syms.includes(sym),
        ),
      ];

      const uniqueSymbols = Array.from(new Set(updatedSymbols));

      if (uniqueSymbols.length > MAX_SYMBOLS) {
        setSyms(uniqueSymbols.slice(0, MAX_SYMBOLS));
        setMaxSymbolsReached(true);
        setSymbolsWereTruncated(true);
      } else {
        setSyms(uniqueSymbols);
        setSymbolsWereTruncated(false);
      }
      setActiveWatchlistIdsState(numericWatchlistIds);
    },
    [
      watchlists,
      syms,
      setSyms,
      setActiveWatchlistIdsState,
      setSelectedWatchlists,
      selectedScanners,
      equities,
    ],
  );

  // Set up sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = sortedChartData.findIndex(
      (item) => item.sym === active.id,
    );
    const newIndex = sortedChartData.findIndex((item) => item.sym === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      // Update the sorted chart data
      const newSortedData = arrayMove(sortedChartData, oldIndex, newIndex);
      setSortedChartData(newSortedData);

      // Update the ordered symbols list
      const newOrderedSyms = newSortedData.map((item) => item.sym);
      setOrderedSyms(newOrderedSyms);

      // Update the syms array to match the new order
      // Preserve any symbols that might not be in the chart data
      const chartSymbols = new Set(newOrderedSyms);
      const remainingSyms = syms.filter((sym) => !chartSymbols.has(sym));

      // Set the new symbols order
      setSyms([...newOrderedSyms, ...remainingSyms]);
    }
  };

  return (
    <Stack
      sx={{
        width: 1,
        maxHeight: isMobile ? '500px' : undefined,
        gap: '12px',
        padding: '12px',
        height: '100%',
      }}
    >
      <Autocomplete
        disableListWrap
        options={allAvailableSymbols}
        onChange={handleSymbolSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for a symbol..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.text.secondary }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              width: '100%',
              '& fieldset': {
                borderColor: alpha(theme.palette.primary.main, 0.35),
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            }}
          />
        )}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        renderOption={(props, option) => {
          const equity = equityDetails.get(option);
          return (
            <li {...props} key={option} style={{ padding: '8px 12px' }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', gap: 2 }}
              >
                <StockIcon symbol={option} />
                <Stack sx={{ ml: '12px !important' }}>
                  <Typography fontWeight="bold">{option}</Typography>
                  {equity?.name && (
                    <Typography variant="caption" color="text.secondary">
                      {equity.name}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </li>
          );
        }}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiAutocomplete-paper': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
          },
        }}
      />

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 8,
        }}
      >
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={() =>
            visibleChartData.length === 0
              ? showSymbols(syms)
              : hideSymbols(syms)
          }
          disabled={chartData.length === 0}
        >
          {visibleChartData.length === 0 ? 'Show All' : 'Hide All'}
        </Button>
        <OptionsDropdownSelector
          label="Watchlists"
          value={selectedWatchlists}
          options={watchlistOptions}
          isMultiple
          onChange={onWatchlistSelectChange}
          sx={{ maxWidth: '100%' }}
          onEdit={onEditWatchlists}
          isEditable
          editLabel="Edit Watchlists"
        />
        <OptionsDropdownSelector
          label="Scanners"
          value={selectedScanners}
          options={scannerOptions}
          isMultiple
          onChange={onScannerSelectChange}
          sx={{ maxWidth: '100%' }}
        />
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={deleteAllSyms}
        >
          Clear All
        </Button>
      </Stack>

      {maxSymbolsReached && (
        <Alert
          severity="warning"
          sx={{
            my: 1,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: 'text.primary',
          }}
        >
          {symbolsWereTruncated
            ? `Maximum of ${MAX_SYMBOLS} symbols reached. Some symbols were removed to stay within the limit.`
            : `Maximum of ${MAX_SYMBOLS} symbols reached. Delete some symbols to add more.`}
        </Alert>
      )}

      {chartData.length === 0 ? (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              color: 'text.secondary',
            }}
          >
            <Typography variant="h6">No Equities Found</Typography>
            <Typography variant="body2">
              Select a watchlist or scanner to view equities
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 'calc(100% - 50px)',
            overflowY: 'auto',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '30px' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Symbol</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Price
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    X
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Y
                  </TableCell>
                  {zAxisDataKey != null && (
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      Z
                    </TableCell>
                  )}
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <SortableContext
                  items={sortedChartData.map((data) => data.sym)}
                  strategy={verticalListSortingStrategy}
                >
                  {sortedChartData.map((data, index) => (
                    <SortableTableRow
                      key={data.sym}
                      data={data}
                      index={index}
                      hiddenSymbols={hiddenSymbols}
                      toggleHiddenSymbols={toggleHiddenSymbols}
                      deleteSym={deleteSym}
                      setHoveredSymbol={setHoveredSymbol}
                      xAxis={xAxis}
                      yAxis={yAxis}
                      zAxis={zAxis || ''}
                      theme={theme}
                    />
                  ))}
                </SortableContext>
              </TableBody>
            </Table>
          </DndContext>
        </TableContainer>
      )}

      <EditWatchlists
        onClose={() => setShowEditWatchlistModal(false)}
        open={showEditWatchlistModal}
        modal={true}
        // toast needs to be displayed after the modal disappears
        openToast={(toastData) => setTimeout(() => openToast(toastData), 1000)}
      />
    </Stack>
  );
};
