import React from 'react';
import { useRecoilValue } from 'recoil';
import { IconButton, SxProps, Theme } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenIcon from '@mui/icons-material/FullscreenSharp';
import { isMobileState } from 'states';
import { SGTooltip } from 'components/core';
import { useFullscreen } from 'layouts/ResizableDashboardLayout';

interface PanelFullscreenButtonProps {
  panelType: 'main' | 'bottom';
  sx?: SxProps<Theme>;
  showTooltip?: boolean;
}

const PanelFullscreenButton: React.FC<PanelFullscreenButtonProps> = ({
  panelType,
  sx,
  showTooltip = true,
}) => {
  const isMobile = useRecoilValue(isMobileState);

  const {
    isMainFullscreen,
    isBottomFullscreen,
    toggleMainFullscreen,
    toggleBottomFullscreen,
  } = useFullscreen();

  const isFullscreen =
    panelType === 'main' ? isMainFullscreen : isBottomFullscreen;
  const toggleFullscreen =
    panelType === 'main' ? toggleMainFullscreen : toggleBottomFullscreen;

  if (isMobile) {
    return null;
  }

  const button = (
    <IconButton onClick={toggleFullscreen} size="small" color="primary" sx={sx}>
      {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </IconButton>
  );

  if (!showTooltip) {
    return button;
  }

  return (
    <SGTooltip title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}>
      {button}
    </SGTooltip>
  );
};

export default PanelFullscreenButton;
