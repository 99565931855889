import { useEffect, useState, useRef } from 'react';
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Theme } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
  isMobileState,
  screenWidthState,
  selectedEquityState,
  synthOIPCImpactBarGroupSelection,
  synthOIPCImpactDataState,
  synthOIPCImpactLineSelection,
  synthOIPCImpactTypeState,
  synthOIPCImpactZoomCfgState,
} from '../../../states';
import {
  ProductType,
  SynthOIChartData,
  SynthOIChartLines,
  SynthOIEquity,
  SynthOIPCBarLabels,
  SynthOIPCImpactBar,
  SynthOIPCImpactBarGroup,
  SynthOIPCImpactBarGroups,
  SynthOIPCImpactChartType,
  SynthOIPCImpactDatum,
  SynthOIPCImpactGroupedBars,
  SynthOIPCImpactLine,
  SynthOIPCLineLabels,
} from '../../../types';
import {
  calculateTextWidth,
  fetchAPI,
  formatAsCompactNumberCallback,
  formatAsCurrency,
  getLevelsFromDataAndFields,
  getZoomConfigRefArea,
  shadeColor,
  updateBrushZoomConfig,
} from '../../../util';
import { ColorMode } from '../../../theme';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_Y_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  SYNTH_OI_FIELDS,
} from '../../../config';
import useBrushZoom from '../../../hooks/useBrushZoom';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import { useSetSym } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

type SynthOIPutCallImpactGraphProps = {
  showKeyLevels: boolean;
};

const getTotal = (bars: SynthOIPCImpactBar[], datum: SynthOIPCImpactDatum) => {
  return Math.max(...bars.map((k) => Math.abs(datum[k] ?? 0)));
};

function getDotted(line: SynthOIPCImpactLine) {
  switch (line) {
    case SynthOIPCImpactLine.GammaSansMonthly:
    case SynthOIPCImpactLine.GammaSansNextExp:
    case SynthOIPCImpactLine.DeltaSansMonthly:
    case SynthOIPCImpactLine.DeltaSansNextExp:
      return '3 3';
  }
  return undefined;
}

function getStroke(theme: Theme, bar: SynthOIPCImpactBar) {
  switch (bar) {
    case SynthOIPCImpactBar.OITotalCalls:
    case SynthOIPCImpactBar.OIChangeCalls:
    case SynthOIPCImpactBar.OINetPosCalls:
    case SynthOIPCImpactBar.GammaCalls:
    case SynthOIPCImpactBar.DeltaCalls:
      return alpha(theme.palette.core.call, 0.75);
    case SynthOIPCImpactBar.OITotalPuts:
    case SynthOIPCImpactBar.OIChangePuts:
    case SynthOIPCImpactBar.OINetPosPuts:
    case SynthOIPCImpactBar.GammaPuts:
    case SynthOIPCImpactBar.DeltaPuts:
      return alpha(theme.palette.core.put, 0.75);
    case SynthOIPCImpactBar.GammaTotal:
    case SynthOIPCImpactBar.DeltaTotal:
      return alpha(theme.palette.gray, 0.75);
  }
}

function getFill(theme: Theme, bar: SynthOIPCImpactBar) {
  switch (bar) {
    case SynthOIPCImpactBar.OITotalCalls:
    case SynthOIPCImpactBar.OIChangeCalls:
    case SynthOIPCImpactBar.OINetPosCalls:
    case SynthOIPCImpactBar.GammaCalls:
    case SynthOIPCImpactBar.DeltaCalls:
      return alpha(theme.palette.core.call, 0.75);
    case SynthOIPCImpactBar.OITotalPuts:
    case SynthOIPCImpactBar.OIChangePuts:
    case SynthOIPCImpactBar.OINetPosPuts:
    case SynthOIPCImpactBar.GammaPuts:
    case SynthOIPCImpactBar.DeltaPuts:
      return alpha(theme.palette.core.put, 0.75);
    case SynthOIPCImpactBar.GammaTotal:
    case SynthOIPCImpactBar.DeltaTotal:
      return alpha(theme.palette.equityHub.putCallImpact.allExpGex, 0.75);
  }
}

const Y_AXIS1_LABEL = new Map([
  [SynthOIPCImpactChartType.Delta, 'Est. Delta Notional (line)'],
  [SynthOIPCImpactChartType.Gamma, 'Est. Gamma Notional (line)'],
]);

const Y_AXIS2_LABEL = new Map([
  [SynthOIPCImpactChartType.Delta, 'Est. Delta Notional (bars)'],
  [SynthOIPCImpactChartType.Gamma, 'Est. Gamma Notional (bars)'],
  [SynthOIPCImpactChartType.OpenInterest, 'Open Interest (bars)'],
]);

const getInitialZoomIdx = (
  chartType: SynthOIPCImpactChartType,
  data: SynthOIPCImpactDatum[],
  selectedBarGroups: Set<SynthOIPCImpactBarGroup>,
) => {
  const barGroups: SynthOIPCImpactBarGroup[] =
    SynthOIPCImpactBarGroups.get(chartType)!;
  const group = barGroups.filter((g) => selectedBarGroups.has(g))[0];
  const bars = SynthOIPCImpactGroupedBars.get(group)!;
  const totGammas = data.map((d) => getTotal(bars, d));
  const maxGamma: number = Math.max(...totGammas);
  const threshold = maxGamma * 0.05; // Use 5% threshold of max gamma as filtere
  let left = 0;
  while (left < totGammas.length && totGammas[left] < threshold) {
    left++;
  }

  let right = totGammas.length - 1;
  while (right > 0 && totGammas[right] < threshold) {
    right--;
  }
  return { left, right };
};

export const SynthOIPutCallImpactGraph = ({
  showKeyLevels,
}: SynthOIPutCallImpactGraphProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const ref = useRef<HTMLDivElement | null>(null);
  const screenWidth = useRecoilValue(screenWidthState);
  const { getSym } = useSetSym();
  const [searchParams] = useSearchParams();
  const [zoomConfig, setZoomConfig] = useRecoilState(
    synthOIPCImpactZoomCfgState,
  );
  const selectedEquity = useRecoilValue(selectedEquityState);
  const chartType = useRecoilValue(synthOIPCImpactTypeState);
  const selectedLines = useRecoilValue(synthOIPCImpactLineSelection);
  const selectedBarGroups = useRecoilValue(synthOIPCImpactBarGroupSelection);

  const [rawChartData, setRawChartData] = useState<SynthOIChartData | null>(
    null,
  );
  const [chartData, setChartData] = useRecoilState(synthOIPCImpactDataState);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const sym = getSym(ProductType.EQUITYHUB);

  useEffect(() => {
    const dateStr = searchParams.get('date');
    setDate(dateStr ? dayjs(dateStr) : null);
  }, [searchParams]);

  const { zoomChartConfig } = useBrushZoom<SynthOIPCImpactDatum>(
    zoomConfig,
    setZoomConfig,
    'spot',
    chartData,
  );

  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;

  useEffect(() => {
    // TODO: handle errors and offer toasts to retry etc.
    const fetchData = async () => {
      const params: Record<string, string> = { sym };
      if (date != null) {
        params['date'] = date.format('YYYY-MM-DD');
      }
      const urlParams = new URLSearchParams(params);
      const chartJSON = await fetchAPI(`synth_oi/v1/chart_data?${urlParams}`);
      setRawChartData(chartJSON);
    };
    fetchData();
  }, [setRawChartData, sym, date]);

  useEffect(() => {
    if (rawChartData?.curves?.spot_prices == null) {
      return;
    }

    let curves = rawChartData.curves.spot_prices.map((spot: number, idx) => {
      const gamma = rawChartData.curves.cust.gamma;
      const delta = rawChartData.curves.cust.delta;
      return {
        spot,

        [SynthOIPCImpactLine.GammaTotal]: gamma.all[idx],
        [SynthOIPCImpactLine.GammaNextExp]: gamma.next_exp[idx],
        [SynthOIPCImpactLine.GammaMonthly]: gamma.monthly[idx],
        [SynthOIPCImpactLine.GammaSansNextExp]:
          gamma.all[idx] - gamma.next_exp[idx],
        [SynthOIPCImpactLine.GammaSansMonthly]:
          gamma.all[idx] - gamma.monthly[idx],

        [SynthOIPCImpactLine.DeltaTotal]: delta.all[idx],
        [SynthOIPCImpactLine.DeltaNextExp]: delta.next_exp[idx],
        [SynthOIPCImpactLine.DeltaMonthly]: delta.monthly[idx],
        [SynthOIPCImpactLine.DeltaSansNextExp]:
          delta.all[idx] - delta.next_exp[idx],
        [SynthOIPCImpactLine.DeltaSansMonthly]:
          delta.all[idx] - delta.monthly[idx],
      };
    });

    const bars = rawChartData.bars.strikes.map((spot: number, idx) => {
      const oi = rawChartData.bars.oi;
      const oiChange = rawChartData.bars.oi_change;
      const netPos = rawChartData.bars.cust.net_positioning;
      const gamma = rawChartData.bars.cust.gamma;
      const delta = rawChartData.bars.cust.delta;

      return {
        spot,
        [SynthOIPCImpactBar.OITotalCalls]: oi.calls[idx],
        [SynthOIPCImpactBar.OITotalPuts]: oi.puts[idx],
        [SynthOIPCImpactBar.OIChangeCalls]: oiChange.calls[idx],
        [SynthOIPCImpactBar.OIChangePuts]: oiChange.puts[idx],
        [SynthOIPCImpactBar.OINetPosCalls]: netPos.calls[idx],
        [SynthOIPCImpactBar.OINetPosPuts]: netPos.puts[idx],

        [SynthOIPCImpactBar.GammaTotal]:
          gamma.all.calls[idx] + gamma.all.puts[idx],
        [SynthOIPCImpactBar.GammaCalls]: gamma.all.calls[idx],
        [SynthOIPCImpactBar.GammaPuts]: gamma.all.puts[idx],

        [SynthOIPCImpactBar.DeltaTotal]:
          delta.all.calls[idx] + delta.all.puts[idx],
        [SynthOIPCImpactBar.DeltaCalls]: delta.all.calls[idx],
        [SynthOIPCImpactBar.DeltaPuts]: delta.all.puts[idx],
      };
    });
    const spot2bar = new Map(bars.map((b) => [b.spot, b]));
    const spot2curve = new Map(curves.map((c) => [c.spot, c]));
    const allPrices = [...new Set([...spot2curve.keys()])].sort(
      (a, b) => a - b,
    );
    const newChartData = allPrices.map((spot) => ({
      ...(spot2bar.get(spot) ?? {}),
      ...(spot2curve.get(spot) ?? {}),
    })) as SynthOIPCImpactDatum[];
    setChartData(newChartData);
  }, [rawChartData, setChartData]);

  useEffect(() => {
    if (chartData.length === 0) {
      return;
    }
    const { left, right } = getInitialZoomIdx(
      chartType,
      chartData,
      selectedBarGroups,
    );
    updateBrushZoomConfig(
      zoomConfig,
      chartData,
      setZoomConfig,
      right,
      left,
      true,
    );
    // Don't reset zoom when we change selected bar groups
  }, [sym, chartType, chartData]);

  const getReferenceLines = () => {
    let referenceLines = null;
    if (selectedEquity != null && showKeyLevels) {
      const levels = getLevelsFromDataAndFields<SynthOIEquity>(
        SYNTH_OI_FIELDS,
        selectedEquity,
      );
      // show longer level names on top to make it easier to read
      const sortedLevels = [...levels].sort((a, b) =>
        a.name.length < b.name.length ? 1 : -1,
      );
      referenceLines = sortedLevels.map(({ field, value, name }, i) => {
        const values = chartData
          .map((d) => d.spot ?? -1)
          .filter((d) => d !== -1);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const xAxisTicks = max - min;
        // 0.7 is default content width in dashboardLayout.tsx
        const chartWidth = ref.current?.offsetWidth ?? screenWidth * 0.7;
        const pxPerTick = chartWidth / xAxisTicks;
        const pxFromMax = (max - value) * pxPerTick;
        // if window is taller, add more spacing
        // if there are more than 5 levels to add, decrease spacing proportionally since youll need it
        // note window.innerHeight does not handle resizing after render, youd need to refresh
        const levelsCountRatio = Math.min(1, 5 / levels.length);
        const y =
          20 +
          i *
            Math.max(((55 * window.innerHeight) / 1000) * levelsCountRatio, 20);
        const text = `${name} $${value}`;
        const width = calculateTextWidth(text);
        const x = width > pxFromMax ? -1 * width * 0.82 : 1;
        const stroke =
          fieldColorMapping[
            field.split(',')[0] as keyof typeof fieldColorMapping
          ];
        const textShadowColor = shadeColor(
          stroke,
          theme.colorMode === ColorMode.LIGHT ? -60 : -90,
        );
        return (
          <ReferenceLine
            x={value}
            yAxisId="line"
            key={`put-call-reference-line-${field}`}
            stroke={stroke}
            strokeWidth={2}
            label={
              <Label
                position={{ x: x, y: y }}
                value={text}
                angle={0}
                height={20}
                style={{
                  textAnchor: 'start',
                  fill: stroke,
                  fontSize: isMobile ? 10 : 13,
                  fontWeight: '900',
                  textShadow: `-1px -1px 0 ${textShadowColor}, 1px -1px 0 ${textShadowColor}, -1px 1px 0 ${textShadowColor}, 1px 1px 0 ${textShadowColor}`,
                }}
              />
            }
            isFront
          />
        );
      });
    }

    return referenceLines;
  };

  return (
    <ChartWatermarkContainer
      ref={ref}
      size={25}
      style={{
        background: theme.palette.background.default,
        borderRadius: '8px',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          margin={{ ...DEFAULT_CHART_MARGINS, top: 20 }}
          stackOffset="sign"
          {...zoomChartConfig}
        >
          <CartesianGrid
            strokeDasharray={
              theme.colorMode === ColorMode.LIGHT ? '2 8' : '0.3 8'
            }
          />
          <XAxis
            dataKey="spot"
            allowDataOverflow
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            label={{
              value: 'Strike',
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
            }}
            tickCount={10}
            type="number"
            interval="preserveStartEnd"
          />
          <Brush
            dataKey="spot"
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          {/*chartType !== SynthOIPCImpactChartType.OpenInterest && */}
          <YAxis
            allowDataOverflow
            domain={['dataMin', 'dataMax']}
            tick={{
              fontSize: 12,
              fontWeight: 500,
              fill: theme.palette.text.primary,
            }}
            tickFormatter={formatAsCompactNumberCallback}
            yAxisId="line"
            axisLine={true}
            tickMargin={5}
            label={{
              value: Y_AXIS1_LABEL.get(chartType),
              ...DEFAULT_Y_AXIS_STYLES,
            }}
            tickCount={8}
          />
          <YAxis
            allowDataOverflow
            domain={['dataMin', 'dataMin']}
            tick={{
              fontSize: 12,
              fontWeight: 500,
              fill: theme.palette.text.primary,
            }}
            yAxisId="bar"
            orientation="right"
            axisLine={false}
            tickMargin={5}
            tickFormatter={formatAsCompactNumberCallback}
            label={{
              value: Y_AXIS2_LABEL.get(chartType),
              ...DEFAULT_Y2_AXIS_STYLES,
            }}
            tickCount={8}
          />
          <Tooltip
            formatter={formatAsCompactNumberCallback}
            labelFormatter={(value) => `Strike: ${formatAsCurrency(value)}`}
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
          />
          {SynthOIChartLines.get(chartType)!
            .filter((line) => selectedLines.has(line))
            .map((line: SynthOIPCImpactLine) => {
              return (
                <Line
                  key={line}
                  type="basis"
                  dataKey={line}
                  name={`${SynthOIPCLineLabels.get(line)} (line)`}
                  strokeWidth={2}
                  stroke={theme.palette.equityHub.synthOILines[line]}
                  dot={false}
                  strokeDasharray={getDotted(line)}
                  yAxisId="line"
                  connectNulls
                />
              );
            })}
          {SynthOIPCImpactBarGroups.get(chartType)!
            .filter((grp) => selectedBarGroups.has(grp))
            .flatMap((grp: SynthOIPCImpactBarGroup) => {
              const bars: SynthOIPCImpactBar[] =
                SynthOIPCImpactGroupedBars.get(grp)!;
              return bars.map((bar) => {
                const isOI =
                  chartType === SynthOIPCImpactChartType.OpenInterest;
                return (
                  <Bar
                    key={bar}
                    dataKey={bar}
                    name={`${SynthOIPCBarLabels.get(bar)} ${
                      isOI ? '' : '(bar)'
                    }`}
                    stroke={getStroke(theme, bar)}
                    fill={getFill(theme, bar)}
                    barSize={20}
                    yAxisId="bar"
                  />
                );
              });
            })}
          <Legend
            verticalAlign="top"
            align="center"
            wrapperStyle={{
              paddingBottom: '12px',
              fontSize: '12px',
            }}
            iconSize={10}
          />
          <ReferenceLine
            y={0}
            yAxisId="bar"
            stroke={theme.palette.text.primary}
            strokeWidth={1}
            strokeDasharray="3 3"
            label={{
              value: '0',
              position: 'right',
              fill: theme.palette.text.primary,
              offset: 10,
              fontSize: 14,
            }}
          />
          {getReferenceLines()}
          {getZoomConfigRefArea(zoomConfig, 'line')}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
