// generated with @7nohe/openapi-react-query-codegen@1.6.2

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DefaultService } from '../requests/services.gen';
import {
  CandleField,
  CandleFilter,
  CandleOption,
  HiroStreamString,
  Order,
} from '../requests/types.gen';
import * as Common from './common';
/**
 * The handler for the auth request which generates a token to validate based on basic client
 * ID/secret authentication
 * @param data The data for the request.
 * @param data.authorization Basic Authentication
 * @returns string Success
 * @throws ApiError
 */
export const useDefaultServiceGetAuth = <
  TData = Common.DefaultServiceGetAuthDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    authorization,
  }: {
    authorization: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetAuthKeyFn({ authorization }, queryKey),
    queryFn: () => DefaultService.getAuth({ authorization }) as TData,
    ...options,
  });
/**
 * @param data The data for the request.
 * @param data.sym
 * @param data.stream
 * @param data.field
 * @param data.option
 * @param data.filter
 * @param data.order
 * @param data.limit
 * @param data.start
 * @param data.end
 * @returns string Success
 * @throws ApiError
 */
export const useDefaultServiceGetCandles = <
  TData = Common.DefaultServiceGetCandlesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    end,
    field,
    filter,
    limit,
    option,
    order,
    start,
    stream,
    sym,
  }: {
    end?: number;
    field?: CandleField;
    filter?: CandleFilter;
    limit?: number;
    option?: CandleOption;
    order?: Order;
    start?: number;
    stream?: HiroStreamString;
    sym: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetCandlesKeyFn(
      { end, field, filter, limit, option, order, start, stream, sym },
      queryKey,
    ),
    queryFn: () =>
      DefaultService.getCandles({
        end,
        field,
        filter,
        limit,
        option,
        order,
        start,
        stream,
        sym,
      }) as TData,
    ...options,
  });
/**
 * The handler for the history request
 * @returns Manifest
 * @throws ApiError
 */
export const useDefaultServiceGetSgManifest = <
  TData = Common.DefaultServiceGetSgManifestDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetSgManifestKeyFn(queryKey),
    queryFn: () => DefaultService.getSgManifest() as TData,
    ...options,
  });
/**
 * @param data The data for the request.
 * @param data.filters
 * @param data.sorting
 * @param data.offset
 * @param data.limit
 * @returns ContractRow Success
 * @throws ApiError
 */
export const useDefaultServiceGetSgTnsContracts = <
  TData = Common.DefaultServiceGetSgTnsContractsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    filters,
    limit,
    offset,
    sorting,
  }: {
    filters?: string;
    limit?: number;
    offset?: number;
    sorting?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetSgTnsContractsKeyFn(
      { filters, limit, offset, sorting },
      queryKey,
    ),
    queryFn: () =>
      DefaultService.getSgTnsContracts({
        filters,
        limit,
        offset,
        sorting,
      }) as TData,
    ...options,
  });
/**
 * The handler for the Time & Sales feed
 * @param data The data for the request.
 * @param data.filters
 * @param data.sorting
 * @param data.offset
 * @param data.limit
 * @returns string Success
 * @throws ApiError
 */
export const useDefaultServiceGetSgTnsFeed = <
  TData = Common.DefaultServiceGetSgTnsFeedDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    filters,
    limit,
    offset,
    sorting,
  }: {
    filters?: string;
    limit?: number;
    offset?: number;
    sorting?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetSgTnsFeedKeyFn(
      { filters, limit, offset, sorting },
      queryKey,
    ),
    queryFn: () =>
      DefaultService.getSgTnsFeed({ filters, limit, offset, sorting }) as TData,
    ...options,
  });
/**
 * The handler for the Time & Sales flow summary. Aggregate: volume, premium, delta, gamma, vega
 * @param data The data for the request.
 * @param data.filters
 * @returns HiroAggSummary Success
 * @throws ApiError
 */
export const useDefaultServiceGetSgTnsFlowSum = <
  TData = Common.DefaultServiceGetSgTnsFlowSumDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    filters,
  }: {
    filters?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetSgTnsFlowSumKeyFn(
      { filters },
      queryKey,
    ),
    queryFn: () => DefaultService.getSgTnsFlowSum({ filters }) as TData,
    ...options,
  });
/**
 * @param data The data for the request.
 * @param data.start
 * @param data.end
 * @returns Highlights Success
 * @throws ApiError
 */
export const useDefaultServiceGetSgTnsHighlights = <
  TData = Common.DefaultServiceGetSgTnsHighlightsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    end,
    start,
  }: {
    end?: number;
    start?: number;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetSgTnsHighlightsKeyFn(
      { end, start },
      queryKey,
    ),
    queryFn: () => DefaultService.getSgTnsHighlights({ end, start }) as TData,
    ...options,
  });
/**
 * The handler for the HTTP request (this gets called when the HTTP GET lands at the start
 * of websocket negotiation). After this completes, the actual switching from HTTP to
 * websocket protocol will occur.
 * This is the last point where we can extract TCP/IP metadata such as IP address of the client
 * as well as things from HTTP headers such as user-agent of the browser etc.
 * @param data The data for the request.
 * @param data.token
 * @throws ApiError
 */
export const useDefaultServiceGetStream = <
  TData = Common.DefaultServiceGetStreamDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    token,
  }: {
    token: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetStreamKeyFn({ token }, queryKey),
    queryFn: () => DefaultService.getStream({ token }) as TData,
    ...options,
  });
/**
 * A simple handler for validating and inspecting a JWT token
 * @returns Claims Success
 * @throws ApiError
 */
export const useDefaultServiceGetValidateBearer = <
  TData = Common.DefaultServiceGetValidateBearerDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetValidateBearerKeyFn(queryKey),
    queryFn: () => DefaultService.getValidateBearer() as TData,
    ...options,
  });
/**
 * @param data The data for the request.
 * @param data.dates
 * @returns Page_VixQuote Success
 * @throws ApiError
 */
export const useDefaultServiceGetVixQuote = <
  TData = Common.DefaultServiceGetVixQuoteDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    dates,
  }: {
    dates?: string[];
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseDefaultServiceGetVixQuoteKeyFn({ dates }, queryKey),
    queryFn: () => DefaultService.getVixQuote({ dates }) as TData,
    ...options,
  });
