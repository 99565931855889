import React from 'react';
import {
  Autocomplete,
  Chip,
  TextField,
  InputAdornment,
  useTheme,
  alpha,
  Box,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloseIcon from '@mui/icons-material/Close';

interface MultiOptionAutocompleteProps {
  options: string[];
  optionLabelFormatter?: (val: string) => string;
  placeholder?: string;
  value: string[];
  onChange: (event: React.SyntheticEvent, value: string[]) => void;
  viewOnly?: boolean;
  listBoxProps?: SxProps<Theme>;
}

const MultiOptionAutocomplete = ({
  options,
  placeholder = 'Add a ticker',
  optionLabelFormatter,
  value,
  onChange,
  viewOnly = false,
  listBoxProps,
}: MultiOptionAutocompleteProps) => {
  const theme = useTheme();

  return (
    <Autocomplete
      multiple
      value={value ?? []}
      onChange={viewOnly ? undefined : onChange} // Disable change in view-only mode
      options={options}
      getOptionLabel={(option) =>
        optionLabelFormatter ? optionLabelFormatter(option) : option
      }
      filterSelectedOptions
      popupIcon={null}
      clearIcon={viewOnly ? null : <DeleteRoundedIcon />} // Hide clear icon in view-only mode
      clearOnEscape={!viewOnly}
      disabled={viewOnly} // Disable the whole input in view-only mode
      noOptionsText={
        <Box
          sx={{
            padding: '10px',
            backgroundColor: theme.palette.background.paper,
            textAlign: 'center',
            borderRadius: 1,
            zIndex: 10,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: 14,
              fontStyle: 'italic',
            }}
          >
            No matching options
          </Typography>
        </Box>
      }
      sx={{
        '& .MuiAutocomplete-clearIndicator': {
          color: theme.palette.text.secondary,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            viewOnly ? (value?.length === 0 ? 'No Items' : '') : placeholder
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {!viewOnly && (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
            sx: {
              background: viewOnly
                ? alpha(theme.palette.background.default, 0.5)
                : theme.palette.background.default,
              borderRadius: 2,
              '& .MuiAutocomplete-clearIndicator': {
                visibility: viewOnly ? 'hidden' : 'visible', // Hide the clear icon in view-only mode
              },
            },
            readOnly: viewOnly, // Make input readonly in view-only mode
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              const inputValue = (params.inputProps.value as string)
                .trim()
                .toUpperCase();
              if (
                inputValue &&
                !value.map((v) => v.toUpperCase()).includes(inputValue) &&
                options.map((o) => o.toUpperCase()).includes(inputValue)
              ) {
                onChange(event, Array.from(new Set([...value, inputValue])));
              }
            }
          }}
        />
      )}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              size="small"
              variant="outlined"
              label={
                optionLabelFormatter ? optionLabelFormatter(option) : option
              }
              key={key}
              sx={{
                borderRadius: '12px',
                borderWidth: 2,
                backgroundColor: alpha(theme.palette.sgGreen, 0.25),
                '& .MuiChip-label': {
                  color: theme.palette.text.primary,
                },
              }}
              deleteIcon={
                !viewOnly ? (
                  <CloseIcon
                    sx={{
                      color: `${theme.palette.text.secondary} !important`,
                      '&:hover': {
                        color: `${theme.palette.text.primary} !important`,
                      },
                    }}
                  />
                ) : undefined // No delete icon in view-only mode
              }
              {...(viewOnly ? {} : tagProps)} // No delete actions in view-only mode
            />
          );
        })
      }
      ListboxProps={{
        sx: {
          maxHeight: 200,
          maxWidth: '100%',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          border: `2px solid ${alpha(theme.palette.sgGreen, 0.25)}`,
          fontSize: 12,
          '& .MuiAutocomplete-option': {
            padding: '8px 12px',
            transition: 'background-color 0.2s ease',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Subtle hover effect
              color: theme.palette.text.primary,
            },
          },
          ...listBoxProps,
        },
      }}
    />
  );
};

export default MultiOptionAutocomplete;
