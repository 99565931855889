import { useCallback, useState } from 'react';
import { Avatar, Chip, Stack } from '@mui/material';
import { ChartSettingsButton } from './ChartSettingsButton';
import { HiroChartHeader } from '../HiroChartHeader';
import useSetSym from '../../../../hooks/hiro/useSetSym';
import { HiroInlineControls } from './HiroInlineControls';
import { isZerohedge } from '../../../../util';
import useHiroUi from '../../../../hooks/hiro/useHiroUi';
import { SizeControls } from '../../../shared/SizeControls';
import { ProductType } from '../../../../types';
import { ComponentHeader, InfoButton } from '../../../shared';

interface HiroSettingsBarProps {
  sym?: string;
}

export const HiroSettingsBar = ({ sym }: HiroSettingsBarProps) => {
  const [openSettings, _setOpenSettings] = useState(false);
  const [openSizeControls, _setOpenSizeControls] = useState(false);
  const { getSym } = useSetSym();
  const hiroSym = sym ?? getSym(ProductType.HIRO);
  const { showInlineControls } = useHiroUi();
  const inlineChartControls = showInlineControls && (
    <HiroInlineControls isInline={true} />
  );

  const setOpenSettings = useCallback((open: boolean) => {
    _setOpenSettings(open);
    _setOpenSizeControls(false);
  }, []);

  const setOpenSizeControls = useCallback((open: boolean) => {
    _setOpenSizeControls(open);
    _setOpenSettings(false);
  }, []);

  return (
    <Stack sx={{ marginBottom: '2px' }}>
      <ComponentHeader
        title={<HiroChartHeader />}
        buttons={
          <>
            {inlineChartControls}
            {isZerohedge() && (
              <Chip
                avatar={<Avatar src={`images/hiro-logos/${hiroSym}.png`} />}
                label={hiroSym}
                size="small"
                variant="outlined"
                sx={{
                  background: 'transparent',
                  borderColor: '#18bdac',
                  '&..MuiChip-label': {
                    color: '#000000 !important',
                  },
                }}
                className="zerohedge-ticker-chip"
              />
            )}
            <Stack direction={'row'} sx={{ marginTop: '-5px' }}>
              {!isZerohedge() && (
                <ChartSettingsButton
                  openSettings={openSettings}
                  setOpenSettings={setOpenSettings}
                  size={isZerohedge() ? 'small' : undefined}
                />
              )}
              {!isZerohedge() && (
                <SizeControls
                  open={openSizeControls}
                  setOpen={setOpenSizeControls}
                />
              )}
              {!isZerohedge() && (
                <InfoButton key="hiro-info" articleKey="hiro-info" />
              )}
            </Stack>
          </>
        }
        styleProps={{
          gap: '2px',
          marginRight: '-10px',
        }}
      />
    </Stack>
  );
};
