import { EquityGraph } from '../../components';
import { EquityhubStockScanner } from '../../components/equityhub/EquityhubTable/EquityhubStockScanner';
import {
  CoreEquity,
  ProductType,
  SearchHandlerFunction,
  Scanner,
  EquityFieldKey,
  LegacyEquity,
  SynthOIEquity,
} from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useCleanup from '../../hooks/useCleanup';
import {
  activeSearchState,
  ehActiveScannerState,
  ehSelectedScannersState,
  ehSelectedWatchlistIdsState,
  ehWatchlistsAndScannersExpandedState,
  equitiesFetchLoadingState,
  equitySymbolsState,
  freeSymbolState,
  legacyEquityMainViewFieldsState,
  legacyEquityExpandedViewFieldsState,
  searchHandlerState,
  searchSuggestionsData,
  selectedEquitySymbolState,
  synthOIEquityMainViewFieldsState,
  synthOIEquityExpandedViewFieldsState,
  unpricedEquitiesState,
  watchlistsState,
  ehModelTypeState,
} from '../../states';
import { useSetSym } from '../../hooks';
import useAuth from '../../hooks/auth/useAuth';
import { ResizableDashboardLayout } from 'layouts/ResizableDashboardLayout';
import usePrices from 'hooks/equityhub/usePrices';
import EquityDetails from 'components/equityhub/EquityDetails/EquityDetails';
import WatchlistsAndScanners from 'components/equityhub/Watchlists/WatchlistsAndScanners';
import { SelectChangeEvent } from '@mui/material';
import { getEquitiesForScanner } from 'util/equityhub';
import useToast from 'hooks/useToast';
import { useSearchParams } from 'react-router-dom';
import {
  getCurrentDate,
  isMarketOpenOnDate,
  prevBusinessDayOpenMarket,
  updateSearch,
} from 'util/shared';
import {
  EH_ALL_SYNTH_OI_EQUITY_DETAILS_FIELDS,
  EH_ALL_LEGACY_EQUITY_DETAILS_FIELDS,
} from 'config';
import dayjs from 'dayjs';
import useEquities from '../../hooks/equityhub/useEquities';
import useSynthOi from 'hooks/equityhub/useSynthOi';

const MAIN_SCANNERS = [Scanner.VOL_RISK_PREMIUM, Scanner.SQUEEZE];

export const Equityhub = () => {
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const [selectedSym, setSelectedSym] = useRecoilState(
    selectedEquitySymbolState,
  );
  const { openToast } = useToast();
  const watchlists = useRecoilValue(watchlistsState);
  const [watchlistsAndScannersExpanded, setWatchlistsAndScannersExpanded] =
    useRecoilState(ehWatchlistsAndScannersExpandedState);
  const setActiveSearch = useSetRecoilState(activeSearchState);
  const { hasAccessToProduct } = useAuth();
  const hasEquityhubAccess = hasAccessToProduct(ProductType.EQUITYHUB);
  const setActiveScanner = useSetRecoilState(ehActiveScannerState);
  const equityInstruments = useRecoilValue(equitySymbolsState);
  const liveEhSymbol = useRecoilValue(freeSymbolState);
  const { getPrices } = usePrices();
  const { setSym, getSymRaw, sym } = useSetSym();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const [selectedWatchlistIds, setSelectedWatchlistIds] = useRecoilState(
    ehSelectedWatchlistIdsState,
  );
  const [selectedScanners, setSelectedScanners] = useRecoilState(
    ehSelectedScannersState,
  );
  const [pricedEquities, setPricedEquities] = useState<
    (LegacyEquity | SynthOIEquity)[]
  >([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const ehModel = useRecoilValue(ehModelTypeState);
  const { refetchEquities } = useEquities();
  const { isSynthOI } = useSynthOi();

  const [equityMainViewFieldsLegacy, setEquityMainViewFieldsLegacy] =
    useRecoilState(legacyEquityMainViewFieldsState);

  const [equityMainViewFieldsSynthOI, setEquityMainViewFieldsSynthOI] =
    useRecoilState(synthOIEquityMainViewFieldsState);

  const [equityExpandedViewFieldsLegacy, setEquityExpandedViewFieldsLegacy] =
    useRecoilState(legacyEquityExpandedViewFieldsState);

  const [equityExpandedViewFieldsSynthOI, setEquityExpandedViewFieldsSynthOI] =
    useRecoilState(synthOIEquityExpandedViewFieldsState);

  const equityOtherFields = useMemo(() => {
    const mainViewFields = isSynthOI
      ? equityMainViewFieldsSynthOI
      : equityMainViewFieldsLegacy;
    const expandedViewFields = isSynthOI
      ? equityExpandedViewFieldsSynthOI
      : equityExpandedViewFieldsLegacy;
    const allFields = isSynthOI
      ? EH_ALL_SYNTH_OI_EQUITY_DETAILS_FIELDS
      : EH_ALL_LEGACY_EQUITY_DETAILS_FIELDS;

    return allFields.filter(
      (field) =>
        !mainViewFields.includes(field) && !expandedViewFields.includes(field),
    );
  }, [
    isSynthOI,
    equityMainViewFieldsLegacy,
    equityExpandedViewFieldsLegacy,
    equityMainViewFieldsSynthOI,
    equityExpandedViewFieldsSynthOI,
  ]);

  useCleanup();

  useEffect(() => {
    // if search params doesn't have eh-model, set it to the default model
    if (searchParams.get('eh-model') == null) {
      setSearchParams(updateSearch({ 'eh-model': ehModel }));
    }
  }, [ehModel, setSearchParams]);

  useEffect(() => {
    // Check if date is a weekend or holiday and update to previous business day if needed
    if (isSynthOI) {
      const dateParam = searchParams.get('date');
      if (dateParam) {
        const date = dayjs(dateParam);
        // If market is closed on date
        if (!isMarketOpenOnDate(date)) {
          const today = getCurrentDate();
          const lastBusinessDay = isMarketOpenOnDate(today)
            ? today.format('YYYY-MM-DD')
            : prevBusinessDayOpenMarket().format('YYYY-MM-DD');
          setSearchParams(updateSearch({ date: lastBusinessDay }));
          refetchEquities();
        }
      }
    }
  }, [isSynthOI, searchParams, setSearchParams, refetchEquities]);

  useEffect(() => {
    refetchEquities();
  }, []);

  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (value: string): void => {
      const sym = value.toUpperCase();
      const ehSym = unpricedEquities.has(sym) ? sym : undefined;
      setSelectedSym(ehSym ?? 'SPX');
      setSym(sym, ProductType.EQUITYHUB);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [unpricedEquities, setSelectedSym, setSym, setSuggestionsHandler]);

  useEffect(() => {
    // search suggestions data
    setSuggestionsData(equityInstruments);
  }, [equityInstruments, setSuggestionsData]);

  useEffect(() => {
    if (!hasEquityhubAccess) {
      const newSym = setSym(liveEhSymbol, ProductType.EQUITYHUB);
      setActiveScanner(undefined);
      setSelectedSym(newSym);
      setActiveSearch(newSym);
      return;
    }

    let newSym = getSymRaw();
    // Check if sym is not null and is available, otherwise use default
    if (
      !equitiesLoading &&
      unpricedEquities.size > 0 &&
      !unpricedEquities.has(sym)
    ) {
      newSym = null; // setSym will automatically set to default
    }

    const finalSym = setSym(newSym, ProductType.EQUITYHUB);
    setSelectedSym(finalSym);
    setActiveSearch(finalSym);
  }, [
    equitiesLoading,
    unpricedEquities,
    sym,
    setSelectedSym,
    setActiveSearch,
    hasEquityhubAccess,
    liveEhSymbol,
    setSym,
  ]);

  useEffect(() => {
    if (unpricedEquities.size === 0) {
      return;
    }

    const getUpdatedPricedEquities = async () => {
      const payload = await getPrices([]);
      setPricedEquities(
        Array.from(unpricedEquities.values()).map(
          (e: LegacyEquity | SynthOIEquity) => {
            const priceData = {
              ...e,
              [EquityFieldKey.price]: payload.get(e.sym),
              [EquityFieldKey.dailyChange]:
                payload.get(e.sym) && e.upx
                  ? (payload.get(e.sym)! - e.upx) / e.upx
                  : undefined,
            };
            return priceData as LegacyEquity | SynthOIEquity;
          },
        ),
      );
    };

    getUpdatedPricedEquities();
  }, [unpricedEquities]);

  const pricedEquity = useMemo(
    () => pricedEquities.find((eq) => eq.sym === selectedSym),
    [pricedEquities, selectedSym],
  );

  const onWatchlistSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    setSelectedWatchlistIds(event.target.value as string[]);
  };

  const onScannerSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    setSelectedScanners(event.target.value as Scanner[]);
  };

  const watchlistsAndScannerEquities: (LegacyEquity | SynthOIEquity)[] =
    useMemo(() => {
      let watchlistEquities: (LegacyEquity | SynthOIEquity)[] = [];
      let scannerEquities: (LegacyEquity | SynthOIEquity)[] = [];

      // Get equities from selected watchlists
      if (selectedWatchlistIds.length > 0) {
        const selectedWatchlists =
          watchlists?.filter(
            (w) => w.id && selectedWatchlistIds.includes(w.id.toString()),
          ) ?? [];
        const watchlistSymbols = new Set(
          selectedWatchlists.flatMap((w) => w.symbols),
        );
        watchlistEquities = pricedEquities.filter((eq) =>
          watchlistSymbols.has(eq.sym),
        );
      }

      // Get equities from selected scanners
      if (selectedScanners.length > 0) {
        const scannerEquitiesList = selectedScanners.flatMap((scanner) =>
          getEquitiesForScanner(scanner, pricedEquities),
        );
        const scannerSymbols = new Set(scannerEquitiesList.map((eq) => eq.sym));
        scannerEquities = pricedEquities.filter((eq) =>
          scannerSymbols.has(eq.sym),
        );
      }

      // Combine watchlist and scanner equities and deduplicate using Set
      const combinedEquities = [...watchlistEquities, ...scannerEquities];
      const uniqueSymbols = new Set<string>(
        combinedEquities.map((equity) => equity.sym),
      );

      // Return only the first occurrence of each equity with a unique symbol
      return Array.from(uniqueSymbols).map(
        (symbol) => combinedEquities.find((equity) => equity.sym === symbol)!,
      );
    }, [pricedEquities, selectedWatchlistIds, selectedScanners, watchlists]);

  const onEquityClick = (equity: CoreEquity) => {
    setSym(equity.sym, ProductType.EQUITYHUB);
    setSelectedSym(equity.sym);
  };

  const onWatchlistsAndScannersToggleExpanded = () => {
    setWatchlistsAndScannersExpanded((prev) => !prev);
  };

  const onEditFields = (
    mainViewFields: EquityFieldKey[],
    expandedViewFields: EquityFieldKey[],
  ) => {
    if (isSynthOI) {
      setEquityMainViewFieldsSynthOI(mainViewFields);
      setEquityExpandedViewFieldsSynthOI(expandedViewFields);
    } else {
      setEquityMainViewFieldsLegacy(mainViewFields);
      setEquityExpandedViewFieldsLegacy(expandedViewFields);
    }
    openToast({
      message: 'Fields updated',
      type: 'success',
    });
  };

  return (
    <ResizableDashboardLayout
      dashboardId={ProductType.EQUITYHUB}
      mainContent={<EquityGraph />}
      sidebarTopContent={
        <EquityDetails
          equity={pricedEquity}
          allEquities={pricedEquities}
          isLoading={equitiesLoading}
          isExpanded={!watchlistsAndScannersExpanded}
          onToggleExpanded={onWatchlistsAndScannersToggleExpanded}
          equityMainViewFields={
            isSynthOI ? equityMainViewFieldsSynthOI : equityMainViewFieldsLegacy
          }
          equityExpandedViewFields={
            isSynthOI
              ? equityExpandedViewFieldsSynthOI
              : equityExpandedViewFieldsLegacy
          }
          equityOtherFields={equityOtherFields}
          onEditFields={onEditFields}
        />
      }
      sidebarBottomContent={
        <WatchlistsAndScanners
          watchlists={watchlists ?? []}
          scanners={
            isSynthOI
              ? MAIN_SCANNERS.filter((s) => s !== Scanner.SQUEEZE)
              : MAIN_SCANNERS
          }
          selectedWatchlists={selectedWatchlistIds.filter(Boolean)}
          selectedScanners={selectedScanners}
          onWatchlistSelectChange={onWatchlistSelectChange}
          onScannerSelectChange={onScannerSelectChange}
          equities={watchlistsAndScannerEquities}
          onEquityClick={onEquityClick}
          isExpanded={watchlistsAndScannersExpanded}
          onToggleExpanded={onWatchlistsAndScannersToggleExpanded}
          selectedEquities={selectedSym ? [selectedSym] : []}
        />
      }
      bottomContent={<EquityhubStockScanner />}
      bottomContentHeaderName="Equities"
      customDefaultSizes={{
        main: 75,
        sidebar: 25,
        bottom: 0,
      }}
    />
  );
};
