import {
  ListItem,
  Switch,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { getExpirationDisplayText, getTradeDateDisplayText } from 'util/iVol';
import { useRecoilValue } from 'recoil';
import { timezoneState } from 'states';

interface TimeFrameProps {
  identifier: string;
  date: Dayjs;
  onDelete: (key: string) => void;
  onToggleLineVisiblity?: (key: string, toShow: boolean) => void;
  visibleLines: string[];
  expirationDateUtc?: string;
  icon?: JSX.Element;
  bgColor?: string;
  sx?: React.CSSProperties;
}

export const TimeFramePill = ({
  identifier,
  date,
  onDelete,
  onToggleLineVisiblity,
  visibleLines,
  icon,
  bgColor,
  expirationDateUtc,
  sx = {},
}: TimeFrameProps) => {
  const theme = useTheme();
  const timezone = useRecoilValue(timezoneState);

  const getLabelComponent = useCallback(() => {
    const label = getTradeDateDisplayText(date, timezone);

    let dteLabel = null;

    if (expirationDateUtc) {
      dteLabel = getExpirationDisplayText(date, expirationDateUtc);
    }

    return (
      <>
        <Typography component="span" display="block">
          {label}
        </Typography>
        {dteLabel && (
          <Typography
            component="span"
            display="block"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            Expiration: {dteLabel}
          </Typography>
        )}
      </>
    );
  }, [date, expirationDateUtc]);

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDelete(identifier)}
          style={{
            color: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
      style={{
        backgroundColor: bgColor ?? theme.palette.primary.main,
        marginTop: '2%',
        borderRadius: 10,
        ...sx,
      }}
    >
      <ListItemIcon
        style={{
          color: theme.palette.getContrastText(
            theme.palette.background.default,
          ),
        }}
      >
        {icon ? icon : <ShowChartIcon />}
      </ListItemIcon>
      <ListItemText primary={getLabelComponent()} />
      {onToggleLineVisiblity ? (
        <Switch
          checked={visibleLines.includes(identifier)}
          color="success"
          onChange={(_e, checked: boolean) =>
            onToggleLineVisiblity(identifier, checked)
          }
        />
      ) : null}
    </ListItem>
  );
};
