import { Slider, Typography, Stack, useTheme } from '@mui/material';
import { SGTooltip } from 'components/core';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ValueFormat } from 'types/shared';
import {
  formatAsCompactNumber,
  transformLogToValue,
  transformValueToLog,
} from 'util/shared';

interface SliderFilterProps {
  header: string;
  description?: string;
  name: string;
  min: number;
  max: number;
  value: number | number[];
  onChange: (newValue: number | number[]) => void;
  valueFormat: ValueFormat;
  viewOnly?: boolean;
  disabled?: boolean;
  useLogScale?: boolean;
}

const getFormattedValueLabel = (
  value: number,
  valueFormat: ValueFormat = 'number',
) => {
  if (valueFormat === 'currency') {
    return `$${formatAsCompactNumber(value)}`;
  } else if (valueFormat === 'date') {
    const roundedVal = Math.round(value);
    return roundedVal === 0
      ? 'Today'
      : `${roundedVal} day${roundedVal > 1 ? 's' : ''}`;
  } else {
    return formatAsCompactNumber(value);
  }
};

const SliderFilter = ({
  header,
  description,
  name,
  min,
  max,
  value,
  valueFormat = 'number',
  onChange,
  viewOnly = false,
  disabled = false,
  useLogScale = false, // Default to linear scale
}: SliderFilterProps) => {
  const theme = useTheme();

  // Initialize localValue as an array if value is an array
  const [localValue, setLocalValue] = useState<number | number[]>(
    Array.isArray(value) ? value : [min, max],
  );

  // Synchronize localValue with the value prop
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const getSliderInfo = useCallback(() => {
    if (!Array.isArray(localValue)) {
      return localValue === 0 && valueFormat === 'currency'
        ? 'All Premiums'
        : `More than ${getFormattedValueLabel(localValue, valueFormat)}`;
    }

    const currentValue = Array.isArray(localValue) ? localValue : [min, max];

    if (currentValue[0] === min && currentValue[1] === max) {
      return `All ${name}s`;
    } else if (currentValue[0] > min && currentValue[1] === max) {
      return `More than ${getFormattedValueLabel(
        currentValue[0],
        valueFormat,
      )}`;
    } else if (currentValue[0] === min && currentValue[1] < max) {
      return `Less than ${getFormattedValueLabel(
        currentValue[1],
        valueFormat,
      )}`;
    }

    return `${getFormattedValueLabel(
      currentValue[0],
      valueFormat,
    )} to ${getFormattedValueLabel(currentValue[1], valueFormat)}`;
  }, [name, localValue, min, max, valueFormat]);

  const handleChange = useCallback(
    (
      _event: Event | SyntheticEvent<Element, Event>,
      newValue: number | number[],
    ) => {
      if (viewOnly) {
        return;
      }
      if (useLogScale) {
        if (Array.isArray(newValue)) {
          setLocalValue([
            transformLogToValue(newValue[0], min, max),
            transformLogToValue(newValue[1], min, max),
          ]);
        } else {
          setLocalValue(transformLogToValue(newValue, min, max));
        }
      } else {
        setLocalValue(newValue);
      }
    },
    [viewOnly, useLogScale, min, max],
  );

  const handleChangeCommitted = useCallback(
    (
      _event: Event | SyntheticEvent<Element, Event>,
      newValue: number | number[],
    ) => {
      if (viewOnly) {
        return;
      }

      if (useLogScale) {
        if (Array.isArray(newValue)) {
          onChange([
            transformLogToValue(newValue[0], min, max),
            transformLogToValue(newValue[1], min, max),
          ]);
        } else {
          onChange(transformLogToValue(newValue, min, max));
        }
      } else {
        onChange(newValue);
      }
    },
    [onChange, min, max, useLogScale, viewOnly],
  );

  const sliderValue = useMemo(() => {
    return useLogScale
      ? Array.isArray(localValue)
        ? [
            transformValueToLog(localValue[0], min, max),
            transformValueToLog(localValue[1], min, max),
          ]
        : transformValueToLog(localValue as number, min, max)
      : localValue;
  }, [localValue, useLogScale, min, max]);

  const disabledState = viewOnly || disabled;

  return (
    <Stack sx={{ gap: 1 }}>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SGTooltip title={description}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: disabledState
                ? theme.palette.text.disabled
                : theme.palette.text.secondary,
            }}
          >
            {header}
          </Typography>
        </SGTooltip>

        <Typography
          sx={{
            color: disabledState
              ? theme.palette.text.disabled
              : theme.palette.text.primary,
            fontSize: { xs: 11, md: 13 },
            textAlign: 'end',
          }}
        >
          {getSliderInfo()}
        </Typography>
      </Stack>
      <Slider
        value={sliderValue}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        disabled={disabledState}
        sx={{
          color: disabledState
            ? theme.palette.action.disabled // Discolored when disabled
            : theme.palette.sgGreen,
          height: 8,
          '& .MuiSlider-thumb': {
            cursor: disabledState ? 'default' : 'ew-resize', // Disable cursor change in viewOnly mode
            height: disabledState ? 0 : 20, // Hide thumb in view-only mode
            width: disabledState ? 0 : 8, // Hide thumb in view-only mode
            borderRadius: '4px',
            '&:focus, &:hover, &.Mui-active': {
              boxShadow: 'inherit',
            },
          },
          '& .MuiSlider-track': {
            border: 'none',
            backgroundColor: disabledState
              ? theme.palette.action.disabled // Discolored track
              : theme.palette.sgGreen,
          },
          '& .MuiSlider-rail': {
            opacity: disabledState ? 0.3 : 0.5, // Lighter rail in view-only mode
          },
        }}
      />
    </Stack>
  );
};

export default SliderFilter;
