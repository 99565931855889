import { ReactNode, useState } from 'react';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import {
  Box,
  Typography,
  ListItemText,
  ListItemIcon,
  Divider,
  ListItemButton,
  useMediaQuery,
  useTheme,
  Stack,
  alpha,
} from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { useRecoilValue } from 'recoil';
import FullNotificationDialog from './FullNotificationDialog';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import dayjs from 'dayjs';
import { isMobileState, timezoneState } from '../../../states';
import { userNotificationsState } from '../../../states/notifications';
import {
  NotificationCateogry,
  UserNotification,
} from '../../../types/notifications';

const iconFontSize = {
  fontSize: {
    xs: 20,
    md: 28,
  },
};

const iconMap: { [key in NotificationCateogry]: JSX.Element } = {
  Webinar: (
    <VideocamOutlinedIcon
      color="primary"
      sx={{
        ...iconFontSize,
      }}
    />
  ),
  [`Founder's Notes`]: (
    <ArticleOutlinedIcon
      color="primary"
      sx={{
        ...iconFontSize,
      }}
    />
  ),
  'System Update': (
    <SystemUpdateAltIcon
      color="primary"
      sx={{
        ...iconFontSize,
      }}
    />
  ),
  General: (
    <NotificationsOutlinedIcon
      color="primary"
      sx={{
        ...iconFontSize,
      }}
    />
  ),
};

const UserNotificationsList = () => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const notifications = useRecoilValue(userNotificationsState);
  const timezone = useRecoilValue(timezoneState);
  const [selectedNotification, setSelectedNotification] =
    useState<UserNotification | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleNotificationClick = (notification: UserNotification) => {
    setSelectedNotification(notification);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const notification = notifications[index];
    const message = notification.message || '';

    return (
      <ListItemButton
        style={style}
        key={notification.id}
        onClick={() => handleNotificationClick(notification)}
        sx={{
          maxHeight: '100%',
          ':hover': {
            backgroundColor: theme.palette.background.default,
          },
          overflow: 'hidden',
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: {
              xs: '32px',
              md: '48px',
            },
          }}
        >
          {iconMap[notification.category] as ReactNode}
        </ListItemIcon>

        <ListItemText
          primary={
            <Stack
              direction="row"
              gap={1}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  textOverflow: 'ellipsis',
                }}
              >
                {notification.title}
              </Typography>
              <Typography
                variant="caption"
                color="primary"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {dayjs
                  .utc(notification.timeToPush)
                  .tz(timezone)
                  .format('MM-DD h:mm A')}
              </Typography>
            </Stack>
          }
          secondary={
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  textOverflow: 'ellipsis',
                }}
                onClick={() => handleNotificationClick(notification)}
              >
                {message.substring(0, isMobile ? 60 : 80)}
                {message.length > (isMobile ? 60 : 80) ? '...' : ''}
              </Typography>
              {message.length > 80 && (
                <Typography
                  variant="body2"
                  color="primary"
                  onClick={() => handleNotificationClick(notification)}
                  sx={{ cursor: 'pointer' }}
                >
                  Show full message
                </Typography>
              )}
            </Stack>
          }
          secondaryTypographyProps={{
            overflow: 'clip',
          }}
        />
      </ListItemButton>
    );
  };

  return (
    <Box
      sx={{
        width: isSmDown ? 300 : 400,
        height: '100%',
        paddingTop: '4px',
        paddingBottom: '12px',
      }}
    >
      <Stack
        sx={{
          gap: 1,
          py: 2,
          px: 6,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6">Notifications</Typography>
        <Typography variant="caption" color="primary">
          Past 14 days
        </Typography>
      </Stack>

      <Divider sx={{ borderColor: alpha(theme.palette.text.primary, 0.2) }} />
      {notifications.length > 0 ? (
        <List
          height={400}
          width={isSmDown ? 300 : 400}
          itemSize={() => (isSmDown ? 100 : 80)}
          itemCount={notifications.length}
          itemData={notifications}
        >
          {renderRow}
        </List>
      ) : (
        <Box sx={{ pt: 2, px: 6 }}>
          <Typography>No notifications to show</Typography>
        </Box>
      )}
      <FullNotificationDialog
        notification={selectedNotification}
        open={dialogOpen}
        onClose={handleClose}
      />
    </Box>
  );
};

export default UserNotificationsList;
