import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { positiveTrendColorState, negativeTrendColorState } from 'states';
import { formatAsCurrency } from 'util/shared/common';

interface StatisticProps {
  statistic: string;
  value: string | number;
}

export const Statistic = ({ statistic, value }: StatisticProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1" fontSize="14px" color="text.secondary">
        {statistic}
      </Typography>
      <Typography variant="body1" fontSize="14px" color="text.primary">
        {value}
      </Typography>
    </Box>
  );
};

interface DeltaStatisticProps extends StatisticProps {
  lastValue: number;
  isCurrency: boolean;
}

export const DeltaStatistic = ({ statistic, value, lastValue, isCurrency }: DeltaStatisticProps) => {
  const posColor = useRecoilValue(positiveTrendColorState);
  const negColor = useRecoilValue(negativeTrendColorState);

  const delta = (value as number) - lastValue;
  const changeAmt = Math.abs(delta).toFixed(2);
  const changeAmtPercent = (
    (parseFloat(changeAmt) / lastValue) *
    100
  ).toFixed(2);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1" fontSize="14px" color="text.secondary">
        {statistic}
      </Typography>
      <Typography variant="body1" fontSize="14px" color={delta >= 0.0 ? posColor : negColor}>
        {isCurrency ? formatAsCurrency(delta) : delta}&nbsp;({changeAmtPercent}%)
      </Typography>
    </Box>
  );
};