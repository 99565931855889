import { useCallback } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  AlertData,
  AlertCategory,
  Watchlist,
  ShowingAlertType,
} from '../../types';
import {
  hiroAlertsState,
  lastAlertIdMarkedSeenState,
  latestAlertsState,
  negativeTrendColorState,
  playAlertAudioState,
  positiveTrendColorState,
  timezoneState,
  unseenAlertCountState,
  watchlistsState,
} from '../../states';
import dayjs from 'dayjs';
import { useTheme, Theme } from '@mui/material/styles';
import useLog from '../useLog';
import useUserDetails from '../user/useUserDetails';
import { calcOffsetMS } from '../../util';

function getMarkerLabel(category: AlertCategory) {
  switch (category) {
    case AlertCategory.HIRO_TOP_SIGNAL:
      return 'HVA Top';
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
      return 'HVA Bottom';
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
      return 'Flow';
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
      return 'Exit H-Flow Sell';
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
      return 'Exit H-Flow Buy';
  }
}

function getAlertPosition(category: AlertCategory) {
  switch (category) {
    case AlertCategory.HIRO_TOP_SIGNAL:
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
      return 'aboveBar';
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
    case AlertCategory.HIRO_POS_FLOW:
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
      return 'belowBar';
  }
  return 'inBar';
}

function getPositive(category: AlertCategory) {
  switch (category) {
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
    case AlertCategory.HIRO_POS_FLOW:
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
      return true;
  }
  return false;
}

function getColor(
  category: AlertCategory,
  posColor: string,
  negColor: string,
  isPositive: boolean,
  theme: Theme,
) {
  switch (category) {
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
      return theme.palette.hiro.flow;
  }
  return isPositive ? posColor : negColor;
}

function getShape(category: AlertCategory, isPositive: boolean) {
  switch (category) {
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
      return 'circle';
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
      return 'square';
    default:
      return isPositive ? 'arrowUp' : 'arrowDown';
  }
}

function getSize(category: AlertCategory) {
  switch (category) {
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
      return 1;
    default:
      return 2;
  }
}

function toMarkers(
  alerts: AlertData[],
  posColor: string,
  negColor: string,
  theme: any,
  timezone: string,
) {
  return alerts.map((alert) => {
    const isPositive = getPositive(alert.category);
    const color = getColor(
      alert.category,
      posColor,
      negColor,
      isPositive,
      theme,
    );
    const epoch_millis = dayjs.utc(alert.alert_time).valueOf();
    const utcOffset = calcOffsetMS(timezone, epoch_millis);
    const time = (epoch_millis + utcOffset) / 1000;
    return {
      time,
      position: getAlertPosition(alert.category),
      color,
      text: getMarkerLabel(alert.category),
      shape: getShape(alert.category, isPositive),
      id: `${alert.symbol}_alert_${time}`,
      size: getSize(alert.category),
    };
  });
}

const useAlerts = () => {
  const currentTimezone = useRecoilValue(timezoneState);
  const [latestAlerts, setLatestAlerts] = useRecoilState(latestAlertsState);
  const setAlertCount = useSetRecoilState(unseenAlertCountState);
  const hiroAlerts = useRecoilValue(hiroAlertsState);
  const posColor = useRecoilValue(positiveTrendColorState);
  const negColor = useRecoilValue(negativeTrendColorState);
  const [lastAlertIdSeen, setLastAlertIdSeen] = useRecoilState(
    lastAlertIdMarkedSeenState,
  );
  const playAlertAudio = useRecoilValue(playAlertAudioState);
  const watchlists = useRecoilValue(watchlistsState);
  const { fetchAPIWithLog } = useLog('useAlerts');
  const { saveSgSettings } = useUserDetails();
  const theme = useTheme();

  const getHiroMarkers = useCallback(
    () => toMarkers(hiroAlerts, posColor, negColor, theme, currentTimezone),
    [currentTimezone, hiroAlerts, negColor, posColor],
  );

  const markAlertsSeen = async (alertID: number) => {
    const body = JSON.stringify({ alertID });
    const postOpts = { method: 'POST', body };
    const result = await fetchAPIWithLog('v1/markOlderAlertsSeen', postOpts);
    if (result?.error == null) {
      // There might be a race here, but we'll pick up the latest count in polling
      setAlertCount(0);
      setLastAlertIdSeen(alertID);
    }
  };

  const fetchAndSetLatestAlerts = useCallback(async () => {
    const freshAlerts: any = await fetchAPIWithLog('v1/myAlerts');
    if (freshAlerts.error != null) {
      return console.error(freshAlerts.error);
    }
    setLatestAlerts(freshAlerts);
  }, [setLatestAlerts]);

  const markLatestAlertsSeen = (alertsArr = latestAlerts) => {
    if (alertsArr.length === 0) {
      return;
    }
    const maxID = Math.max(...alertsArr.map((a) => parseInt(a.id)));
    if (maxID > lastAlertIdSeen) {
      const seenAlertWithId = latestAlerts.find(
        (alert) => alert.is_seen && parseInt(alert.id) === maxID,
      );
      if (seenAlertWithId) {
        setLastAlertIdSeen(maxID);
        return;
      }

      markAlertsSeen(maxID);
    }
  };

  const saveAlertsSettings = useCallback(
    async (watchlists: Partial<Watchlist>[]) => {
      const nullWatchlist = watchlists.find((w) => w.id == null);
      const body = JSON.stringify({
        watchlists,
      });
      const postOpts = { method: 'POST', body };
      const [saveResponse, _] = await Promise.all([
        fetchAPIWithLog('v1/users/saveAlertsSettings', postOpts),
        nullWatchlist == null
          ? Promise.resolve()
          : saveSgSettings(
              {
                playAlertAudio: !nullWatchlist.soundDisabled,
                disabledAlertCategories:
                  nullWatchlist.disabledAlertCategories ?? [],
              },
              true,
            ),
      ]);
      return saveResponse;
    },
    [],
  );

  const shouldPlayAlertSound = () => {
    if ((latestAlerts?.length ?? 0) === 0) {
      return false;
    }

    for (const alert of latestAlerts) {
      if (alert.is_seen) {
        continue;
      }

      const diff = dayjs().valueOf() - dayjs.utc(alert.alert_time).valueOf();
      const CUTOFF_MS = 5 * 60 * 1000; // 5 mins
      if (diff >= CUTOFF_MS) {
        continue;
      }

      const watchlistWithSoundEnabled = (watchlists ?? []).find((w) => {
        return w.soundDisabled === false && w.symbols.includes(alert.symbol);
      });

      // if symbol is in at least one watchlist that has sound enabled, play sound
      if (watchlistWithSoundEnabled != null) {
        return true;
      }
    }

    return playAlertAudio;
  };

  const playAlertSound = () => {
    if (shouldPlayAlertSound()) {
      const audio = new Audio('audio/alert-bell.wav');
      try {
        audio.play();
      } catch (e) {
        // fails when user hasnt interacted with site
        console.error(e);
      }
    }
  };

  const shouldShowAlert = (alert: AlertData, showingType: ShowingAlertType) => {
    if (showingType !== ShowingAlertType.Watchlists) {
      return true;
    }
    for (const watchlist of watchlists ?? []) {
      if (
        watchlist.symbols.includes(alert.symbol) &&
        !(watchlist.disabledAlertCategories ?? []).includes(alert.category)
      ) {
        return true;
      }
    }
    return false;
  };

  return {
    getHiroMarkers,
    markLatestAlertsSeen,
    fetchAndSetLatestAlerts,
    saveAlertsSettings,
    playAlertSound,
    shouldShowAlert,
  };
};

export default useAlerts;
