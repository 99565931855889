import { RawGreeksObject } from './impliedVol';

export interface Levels {}

export interface TimeSeriesData {
  symbol: string;
  price: number | null;
  priceDelta: number | null;
  values: number[];
}

export enum EquityFieldKey {
  quoteDate = 'quote_date',
  name = 'name',
  sym = 'sym',
  price = 'price',
  dailyChange = 'daily_change',
  prevClose = 'upx',
  stock_volume = 'stock_volume',
  wkHigh52 = 'dpi_high52w',
  wkLow52 = 'dpi_low52w',
  earningsDate = 'earnings_utc',
  keyGammaStr = 'keyg',
  keyDeltaStr = 'keyd',
  hedgeWall = 'maxfs',
  callWall = 'cws',
  putWall = 'pws',
  low_vol_point = 'low_vol_point',
  high_vol_point = 'high_vol_point',
  optionsImpact = 'tca_score',
  callGamma = 'atmgc',
  putGamma = 'atmgp',
  callDelta = 'atmdc',
  putDelta = 'atmdp',
  nextExpGamma = 'next_exp_g',
  nextExpDelta = 'next_exp_d',
  topGammaExp = 'max_exp_g_date',
  topDeltaExp = 'max_exp_d_date',
  callVolume = 'cv',
  putVolume = 'pv',
  neCallVolume = 'ne_call_volume',
  nePutVolume = 'ne_put_volume',
  putCallRatio = 'put_call_ratio',
  putCallVolRatio = 'volume_ratio',
  gammaRatio = 'gamma_ratio',
  deltaRatio = 'delta_ratio',
  neSkew = 'ne_skew',
  skew = 'skew',
  rv30 = 'rv30',
  iv30 = 'atm_iv30',
  ivRank = 'iv_rank',
  garchRank = 'garch_rank',
  skewRank = 'skew_rank',
  optionsImpliedMove = 'options_implied_move',
  dpi = 'dpi',
  dpiVol = 'dpi_volume',
  dpi5day = 'hist_dpi',
  dpi5dayVol = 'hist_dpi_volume',
  callsum = 'callsum',
  putsum = 'putsum',
  minfs = 'minfs',
  largeCoi = 'largeCoi',
  largePoi = 'largePoi',
  totaldelta = 'totaldelta',
  d95ne = 'd95ne',
  d25ne = 'd25ne',
  d95 = 'd95',
  d25 = 'd25',
  strike_list = 'strike_list',
  cg_list = 'cg_list',
  pg_list = 'pg_list',
  mf_list = 'mf_list',
  smf_list = 'smf_list',
  putctrl = 'putctrl',
  activity_factor = 'activity_factor',
  position_factor = 'position_factor',
  call_strikes_list_absg = 'call_strikes_list_absg',
  call_gnot_list_absg = 'call_gnot_list_absg',
  put_strikes_list_absg = 'put_strikes_list_absg',
  put_gnot_list_absg = 'put_gnot_list_absg',
  date = 'date',
  Symbol = 'Symbol',
  dpi_sector = 'dpi_sector',
  live = 'live',
  vrp_scanner = 'vrp_scanner',
  vrp_scanner_high = 'vrp_scanner_high',
  squeeze_scanner = 'squeeze_scanner',
  call_oi = 'call_oi',
  call_gamma_abs = 'call_gamma_abs',
  put_oi = 'put_oi',
  put_gamma_abs = 'put_gamma_abs',
  iv_pct = 'iv_pct',
  cskew_pct = 'cskew_pct',
  pskew_pct = 'pskew_pct',
}

// Fields common to both SynthOI Equity table & LegacyEquity table
export interface CoreEquity {
  [EquityFieldKey.sym]: string;
  [EquityFieldKey.price]?: number;
  [EquityFieldKey.name]?: string;
  [EquityFieldKey.prevClose]: number;
  [EquityFieldKey.earningsDate]?: string;
  [EquityFieldKey.wkHigh52]: number;
  [EquityFieldKey.wkLow52]: number;
  [EquityFieldKey.callsum]: number;
  [EquityFieldKey.putsum]: number;
  [EquityFieldKey.putCallRatio]: number;
  [EquityFieldKey.call_oi]?: string;
  [EquityFieldKey.put_oi]?: string;
  [EquityFieldKey.largeCoi]: number;
  [EquityFieldKey.largePoi]: number;
  [EquityFieldKey.nextExpGamma]: number;
  [EquityFieldKey.nextExpDelta]: number;
  [EquityFieldKey.topGammaExp]: string;
  [EquityFieldKey.topDeltaExp]: string;
  [EquityFieldKey.putCallVolRatio]: number;
  [EquityFieldKey.callGamma]: number;
  [EquityFieldKey.putGamma]: number;
  [EquityFieldKey.gammaRatio]: number;
  [EquityFieldKey.callDelta]: number;
  [EquityFieldKey.putDelta]: number;
  [EquityFieldKey.putVolume]: number;
  [EquityFieldKey.callVolume]: number;
  [EquityFieldKey.deltaRatio]: number;
  [EquityFieldKey.totaldelta]: number;
  [EquityFieldKey.d95ne]: number;
  [EquityFieldKey.d25ne]: number;
  [EquityFieldKey.d95]: number;
  [EquityFieldKey.d25]: number;
  [EquityFieldKey.neSkew]: number;
  [EquityFieldKey.skew]: number;
  [EquityFieldKey.neCallVolume]: number;
  [EquityFieldKey.nePutVolume]: number;
  [EquityFieldKey.optionsImpliedMove]: number;
  [EquityFieldKey.call_strikes_list_absg]: string;
  [EquityFieldKey.call_gnot_list_absg]: string;
  [EquityFieldKey.put_strikes_list_absg]: string;
  [EquityFieldKey.put_gnot_list_absg]: string;
  [EquityFieldKey.date]: string;
  [EquityFieldKey.Symbol]: string;
  [EquityFieldKey.stock_volume]: number;
  [EquityFieldKey.dpi]: number;
  [EquityFieldKey.dpi_sector]?: string;
  [EquityFieldKey.rv30]: number;
  [EquityFieldKey.iv30]: number;
  [EquityFieldKey.skewRank]: number;
  [EquityFieldKey.garchRank]: number;
  [EquityFieldKey.ivRank]: number;
  [EquityFieldKey.live]?: boolean;
  [EquityFieldKey.activity_factor]: number;
  [EquityFieldKey.position_factor]: number;
  [EquityFieldKey.vrp_scanner]?: number;
  [EquityFieldKey.vrp_scanner_high]?: number;
  [EquityFieldKey.squeeze_scanner]?: number;
  [EquityFieldKey.call_gamma_abs]?: string;
  [EquityFieldKey.put_gamma_abs]?: string;
  [EquityFieldKey.iv_pct]?: number;
  [EquityFieldKey.cskew_pct]?: number;
  [EquityFieldKey.pskew_pct]?: number;
  [EquityFieldKey.quoteDate]?: string;
}

export interface SynthOIEquity extends CoreEquity {
  [EquityFieldKey.high_vol_point]: number;
  [EquityFieldKey.low_vol_point]: number;
  [EquityFieldKey.optionsImpact]?: number;
}

export interface LegacyEquity extends CoreEquity {
  [EquityFieldKey.minfs]: number;
  [EquityFieldKey.keyGammaStr]: number;
  [EquityFieldKey.keyDeltaStr]: number;
  [EquityFieldKey.hedgeWall]: number;
  [EquityFieldKey.callWall]: number;
  [EquityFieldKey.putWall]: number;
  [EquityFieldKey.quoteDate]: string;
  [EquityFieldKey.strike_list]: string;
  [EquityFieldKey.cg_list]: string;
  [EquityFieldKey.pg_list]: string;
  [EquityFieldKey.mf_list]: string;
  [EquityFieldKey.smf_list]: string;
  [EquityFieldKey.putctrl]: number;
  [EquityFieldKey.call_strikes_list_absg]: string;
  [EquityFieldKey.call_gnot_list_absg]: string;
  [EquityFieldKey.put_strikes_list_absg]: string;
  [EquityFieldKey.put_gnot_list_absg]: string;
  [EquityFieldKey.dpi]: number;
  [EquityFieldKey.dpi_sector]?: string;
  [EquityFieldKey.call_gamma_abs]?: string;
  [EquityFieldKey.put_gamma_abs]?: string;
}

export interface SynthOICurveDatum {
  all: number[];
  next_exp: number[];
  monthly: number[];
}

export interface SynthOICurveData {
  [SynthOIChartType.Gamma]: SynthOICurveDatum;
  [SynthOIChartType.Delta]: SynthOICurveDatum;
}

export interface PutCallValues {
  puts: number[];
  calls: number[];
}

export interface SynthOIGreekBars {
  all: PutCallValues;
  next_exp: PutCallValues;
  monthly: PutCallValues;
}

// Strikes associated with all arrays in the payload.
// i.e. The strike for oi at oi.calls[idx] is located at strikes[idx]
export interface SynthOIBarsData {
  strikes: number[];
  oi: PutCallValues;
  oi_change: PutCallValues;
  cust: {
    [SynthOIChartType.Gamma]: SynthOIGreekBars;
    [SynthOIChartType.Delta]: SynthOIGreekBars;
    [SynthOIChartType.NetPositioning]: PutCallValues;
  };
}

// The fields we pull down separate from the equity hub table for rendering tables/charts
export interface SynthOIChartData {
  curves: {
    spot_prices: number[];
    cust: SynthOICurveData;
  };
  bars: SynthOIBarsData;
}

export interface GreekMetric {
  call?: number;
  put?: number;
  strike: number;
  netGamma?: number;
  fill?: string;
}

export type ScannerColumnVisibility = Record<EquityFieldKey, boolean>;

export interface RawDailySkew {
  day: number;
  dte: number;
  exp: number;
  greeks: RawGreeksObject;
}

export interface RawSkewEntry {
  next: RawDailySkew;
  thirty: RawDailySkew;
}

export interface RawSkewObject {
  today: RawSkewEntry;
  yesterday: RawSkewEntry;
}

export interface HistoricalEquity extends Omit<LegacyEquity, 'sym' | 'Symbol'> {
  trade_date: string;
}

export enum Scanner {
  MOST_CALL_GAMMA = 'mostCallGamma',
  LOWEST_PUT_CALL_RATIO = 'lowestPutCallRatio',
  GAMMA_SQUEEZE = 'gammaSqueeze',
  MOST_PUT_GAMMA = 'mostPutGamma',
  HIGHEST_PUT_CALL_RATIO = 'highestPutCallRatio',
  CLOSE_TO_HEDGE_WALL = 'closeToHedgeWall',
  TOP_GAMMA_EXP = 'topGammaExp',
  TOP_DELTA_EXP = 'topDeltaExp',
  LARGEST_DELTA = 'largestDelta',
  CLOSE_TO_KEY_DELTA_STRIKE = 'closeToKeyDeltaStrike',
  HIGH_IMPACT = 'highImpact',
  BEARISH_DARK_POOL = 'bearishDarkPool',
  BULLISH_DARK_POOL = 'bullishDarkPool',
  VOL_RISK_PREMIUM = 'volRiskPremium',
  SQUEEZE = 'squeeze',
  CROSS_ASSET_SUMMARY = 'crossAssetSummary',
}

export type GammaImpact = {
  strike?: number;
  call_gnot?: number;
  put_gnot?: number;
  cg?: number;
  pg?: number;
};

export enum EquityColumnGroup {
  STATISTICS = 'STATISTICS',
  SG_KEY_DAILY_LEVELS = 'SG_KEY_DAILY_LEVELS',
  DIRECTIONAL_INDICATORS = 'DIRECTIONAL_INDICATORS',
  VOLATILITY_INSIGHTS = 'VOLATILITY_INSIGHTS',
  DARK_POOL_INDICATORS = 'DARK_POOL_INDICATORS',
}

export interface RawTimeseriesData {
  [symbol: string]: {
    meta: {
      symbol: string;
      interval: string;
      currency: string;
      exchange_timezone: string;
      exchange: string;
      type: string;
    };
    status: 'ok' | 'error';
    values: {
      datetime: string;
      open: string;
      high: string;
      low: string;
      close: string;
      volume: string;
    }[];
    string: string;
  };
}

export interface Tick {
  price: number;
  epoch_millis: number;
}

export type GammaLevel = {
  strike: number;
  next_exp_put?: number;
  next_exp_call?: number;
  current_put?: number;
  current_call?: number;
};

export enum EquityHubTab {
  PC_IMPACT = 'pc_impact',
  LIVE_PRICE = 'live_price',
  COMPOSITE = 'composite',
  SKEW = 'skew',
  RR = 'rr',
  HISTORY = 'history',
  FIXED_STRIKE_MATRIX = 'fixed_strike_matrix',
  TERM_STRUCTURE = 'term_structure',
  VOL_SKEW = 'vol_skew',
}

export const EH_SYNTH_OI_ORDERED_TABS: { [key in EquityHubTab]?: string } = {
  [EquityHubTab.PC_IMPACT]: 'Put & Call Impact',
  [EquityHubTab.LIVE_PRICE]: 'Live Price & SG Levels',
  [EquityHubTab.COMPOSITE]: 'Composite View',
  [EquityHubTab.SKEW]: 'Skew',
  [EquityHubTab.RR]: 'Risk Reversal',
  [EquityHubTab.HISTORY]: 'History',
};

export const EH_TOTAL_OI_ORDERED_TABS: { [key in EquityHubTab]?: string } = {
  [EquityHubTab.COMPOSITE]: 'Composite View',
  [EquityHubTab.PC_IMPACT]: 'Put & Call Impact',
  [EquityHubTab.LIVE_PRICE]: 'Live Price & SG Levels',
  [EquityHubTab.SKEW]: 'Skew',
  [EquityHubTab.HISTORY]: 'History',
  [EquityHubTab.RR]: 'Risk Reversal',
  [EquityHubTab.FIXED_STRIKE_MATRIX]: 'Fixed Strike Matrix',
  [EquityHubTab.TERM_STRUCTURE]: 'Term Structure',
  [EquityHubTab.VOL_SKEW]: 'Volatility Skew',
};

export const TOTAL_OI_TAB_KEYS = new Set(Object.keys(EH_TOTAL_OI_ORDERED_TABS));

export const SYNTH_OI_TAB_KEYS = new Set(Object.keys(EH_SYNTH_OI_ORDERED_TABS));

export type RiskReversal = {
  upx: number;
  epoch_millis: number;
  rr: number;
};

export type RawRiskReversal = {
  upx: number;
  trade_date: string;
  rr: number;
};

const allGridColumnKeys: EquityFieldKey[] = [
  EquityFieldKey.sym,
  EquityFieldKey.price,
  EquityFieldKey.stock_volume,
  EquityFieldKey.earningsDate,
  EquityFieldKey.keyGammaStr,
  EquityFieldKey.keyDeltaStr,
  EquityFieldKey.hedgeWall,
  EquityFieldKey.callWall,
  EquityFieldKey.putWall,
  EquityFieldKey.callGamma,
  EquityFieldKey.putGamma,
  EquityFieldKey.nextExpGamma,
  EquityFieldKey.nextExpDelta,
  EquityFieldKey.topGammaExp,
  EquityFieldKey.topDeltaExp,
  EquityFieldKey.neCallVolume,
  EquityFieldKey.nePutVolume,
  EquityFieldKey.putCallRatio,
  EquityFieldKey.putCallVolRatio,
  EquityFieldKey.gammaRatio,
  EquityFieldKey.deltaRatio,
  EquityFieldKey.neSkew,
  EquityFieldKey.skew,
  EquityFieldKey.rv30,
  EquityFieldKey.iv30,
  EquityFieldKey.ivRank,
  EquityFieldKey.garchRank,
  EquityFieldKey.optionsImpliedMove,
];

export const ScannerExclusiveColumns: Record<Scanner, EquityFieldKey[]> = {
  [Scanner.SQUEEZE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.stock_volume,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.gammaRatio,
    EquityFieldKey.deltaRatio,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.neSkew,
    EquityFieldKey.skew,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
    EquityFieldKey.garchRank,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.MOST_CALL_GAMMA]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.LOWEST_PUT_CALL_RATIO]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callVolume,
    EquityFieldKey.putVolume,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.MOST_PUT_GAMMA]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.HIGHEST_PUT_CALL_RATIO]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callVolume,
    EquityFieldKey.putVolume,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.CLOSE_TO_HEDGE_WALL]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
  ],
  [Scanner.TOP_GAMMA_EXP]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
  ],
  [Scanner.TOP_DELTA_EXP]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.neCallVolume,
    EquityFieldKey.nePutVolume,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
  ],
  [Scanner.CLOSE_TO_KEY_DELTA_STRIKE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
  ],
  [Scanner.GAMMA_SQUEEZE]: allGridColumnKeys,
  [Scanner.LARGEST_DELTA]: allGridColumnKeys,
  [Scanner.HIGH_IMPACT]: allGridColumnKeys,
  [Scanner.BEARISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.BULLISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.CROSS_ASSET_SUMMARY]: allGridColumnKeys,
};

export const ScannerExclusiveColumnsCondensed: Record<
  Scanner,
  EquityFieldKey[]
> = {
  ...ScannerExclusiveColumns,
  [Scanner.SQUEEZE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.prevClose,
    EquityFieldKey.stock_volume,
    EquityFieldKey.callGamma,
    EquityFieldKey.callVolume,
    EquityFieldKey.topGammaExp,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.prevClose,
    EquityFieldKey.stock_volume,
    EquityFieldKey.iv30,
    EquityFieldKey.rv30,
    EquityFieldKey.ivRank,
  ],
};

export const ScannerExclusiveColumnsCondensedWidths: Map<
  EquityFieldKey,
  number
> = new Map([
  [EquityFieldKey.sym, 85],
  [EquityFieldKey.price, 88],
  [EquityFieldKey.prevClose, 95],
  [EquityFieldKey.stock_volume, 90],
  [EquityFieldKey.callGamma, 89],
  [EquityFieldKey.callVolume, 88],
  [EquityFieldKey.topGammaExp, 90],
  [EquityFieldKey.iv30, 90],
  [EquityFieldKey.rv30, 90],
  [EquityFieldKey.ivRank, 90],
]);

export enum SynthOIPCImpactLine {
  GammaTotal = 'curve_gamma_all',
  GammaNextExp = 'curve_gamma_next_exp',
  GammaMonthly = 'curve_gamma_monthly',
  GammaSansNextExp = 'curve_gamma_all_sans_ne',
  GammaSansMonthly = 'curve_gamma_all_sans_monthly',

  DeltaTotal = 'curve_delta_all',
  DeltaNextExp = 'curve_delta_next_exp',
  DeltaMonthly = 'curve_delta_monthly',
  DeltaSansNextExp = 'curve_delta_all_sans_ne',
  DeltaSansMonthly = 'curve_delta_all_sans_monthly',
}

export enum SynthOIPCImpactBar {
  OITotalCalls = 'bar_tot_oi_calls',
  OITotalPuts = 'bar_tot_oi_puts',
  OIChangeCalls = 'bar_oi_change_calls',
  OIChangePuts = 'bar_oi_change_puts',
  OINetPosCalls = 'bar_oi_net_pos_calls',
  OINetPosPuts = 'bar_oi_net_pos_puts',

  GammaTotal = 'bar_gamma_all_tot',
  GammaCalls = 'bar_gamma_all_calls',
  GammaPuts = 'bar_gamma_all_puts',

  DeltaTotal = 'bar_delta_all_tot',
  DeltaCalls = 'bar_delta_all_calls',
  DeltaPuts = 'bar_delta_all_puts',
}

export type SynthOIPCImpactDatum = {
  spot?: number;

  [SynthOIPCImpactLine.GammaTotal]: number;
  [SynthOIPCImpactLine.GammaNextExp]: number;
  [SynthOIPCImpactLine.GammaMonthly]: number;
  [SynthOIPCImpactLine.GammaSansNextExp]: number;
  [SynthOIPCImpactLine.GammaSansMonthly]: number;

  [SynthOIPCImpactLine.DeltaTotal]: number;
  [SynthOIPCImpactLine.DeltaNextExp]: number;
  [SynthOIPCImpactLine.DeltaMonthly]: number;
  [SynthOIPCImpactLine.DeltaSansNextExp]: number;
  [SynthOIPCImpactLine.DeltaSansMonthly]: number;

  [SynthOIPCImpactBar.OITotalCalls]: number;
  [SynthOIPCImpactBar.OITotalPuts]: number;
  [SynthOIPCImpactBar.OIChangeCalls]: number;
  [SynthOIPCImpactBar.OIChangePuts]: number;
  [SynthOIPCImpactBar.OINetPosCalls]: number;
  [SynthOIPCImpactBar.OINetPosPuts]: number;

  [SynthOIPCImpactBar.GammaTotal]: number;
  [SynthOIPCImpactBar.GammaCalls]: number;
  [SynthOIPCImpactBar.GammaPuts]: number;

  [SynthOIPCImpactBar.DeltaTotal]: number;
  [SynthOIPCImpactBar.DeltaCalls]: number;
  [SynthOIPCImpactBar.DeltaPuts]: number;
};

export enum NewModelPcType {
  OI = 'open_interest',
  ABS_GAMMA = 'absolute_gamma',
  SYNTH_GAMMA = 'synthetic_gamma',
}

export enum SynthOIPCImpactChartType {
  Gamma = 'gamma',
  Delta = 'delta',
  OpenInterest = 'oi',
}

export enum SynthOIChartType {
  Gamma = 'gamma',
  Delta = 'delta',
  OpenInterest = 'oi',
  NetPositioning = 'net_positioning',
}

export const SynthOIPCImpactTypeLabels = new Map([
  [SynthOIPCImpactChartType.Gamma, 'Gamma'],
  [SynthOIPCImpactChartType.Delta, 'Delta'],
  [SynthOIPCImpactChartType.OpenInterest, 'Open Interest'],
]);

export const SynthOILiveChartTypeLabels = new Map([
  [SynthOIChartType.Gamma, 'Gamma'],
  [SynthOIChartType.Delta, 'Delta'],
  [SynthOIChartType.OpenInterest, 'Open Interest'],
  [SynthOIChartType.NetPositioning, 'Net Positioning'],
]);

export const SynthOIPCLineLabels = new Map([
  [SynthOIPCImpactLine.GammaTotal, 'Total Gamma'],
  [SynthOIPCImpactLine.GammaNextExp, 'Next Expiration Gamma'],
  [SynthOIPCImpactLine.GammaMonthly, 'Monthly Expiration Gamma'],
  [SynthOIPCImpactLine.GammaSansNextExp, 'Total Gamma Minus Next Expiration'],
  [
    SynthOIPCImpactLine.GammaSansMonthly,
    'Total Gamma Minus Monthly Expiration',
  ],

  [SynthOIPCImpactLine.DeltaTotal, 'Total Delta'],
  [SynthOIPCImpactLine.DeltaNextExp, 'Next Expiration Delta'],
  [SynthOIPCImpactLine.DeltaMonthly, 'Monthly Expiration Delta'],
  [SynthOIPCImpactLine.DeltaSansNextExp, 'Total Delta Minus Next Expiration'],
  [
    SynthOIPCImpactLine.DeltaSansMonthly,
    'Total Delta Minus Monthly Expiration',
  ],
]);

export const SynthOIChartLines: Map<
  SynthOIPCImpactChartType,
  SynthOIPCImpactLine[]
> = new Map([
  [
    SynthOIPCImpactChartType.Gamma,
    [
      SynthOIPCImpactLine.GammaTotal,
      SynthOIPCImpactLine.GammaNextExp,
      SynthOIPCImpactLine.GammaMonthly,
      SynthOIPCImpactLine.GammaSansNextExp,
      SynthOIPCImpactLine.GammaSansMonthly,
    ],
  ],
  [
    SynthOIPCImpactChartType.Delta,
    [
      SynthOIPCImpactLine.DeltaTotal,
      SynthOIPCImpactLine.DeltaNextExp,
      SynthOIPCImpactLine.DeltaMonthly,
      SynthOIPCImpactLine.DeltaSansNextExp,
      SynthOIPCImpactLine.DeltaSansMonthly,
    ],
  ],
  [SynthOIPCImpactChartType.OpenInterest, []],
]);

export const SynthOIPCBarLabels = new Map([
  [SynthOIPCImpactBar.OITotalCalls, 'Call Open Interest'],
  [SynthOIPCImpactBar.OITotalPuts, 'Put Open Interest'],
  [SynthOIPCImpactBar.OIChangeCalls, 'Daily Call Open Interest Change'],
  [SynthOIPCImpactBar.OIChangePuts, 'Daily Put Open Interest Change'],
  [SynthOIPCImpactBar.OINetPosCalls, 'Net Call Positioning'],
  [SynthOIPCImpactBar.OINetPosPuts, 'Net Put Positioning'],
  [SynthOIPCImpactBar.GammaTotal, 'Total Gamma'],
  [SynthOIPCImpactBar.GammaCalls, 'Call Gamma'],
  [SynthOIPCImpactBar.GammaPuts, 'Put Gamma'],
  [SynthOIPCImpactBar.DeltaTotal, 'Total Delta'],
  [SynthOIPCImpactBar.DeltaCalls, 'Call Delta'],
  [SynthOIPCImpactBar.DeltaPuts, 'Put Delta'],
]);

export const SynthOIPCLineTooltip = new Map([
  [
    SynthOIPCImpactLine.GammaTotal,
    'Cumulative market maker gamma across all strikes',
  ],
  [
    SynthOIPCImpactLine.GammaSansNextExp,
    'Total market maker gamma subtracting all gamma that expires at the next expiration',
  ],
  [
    SynthOIPCImpactLine.GammaSansMonthly,
    'Total market maker gamma subtracting all gamma that expires at monthly options expiration (OPEX)',
  ],
  [
    SynthOIPCImpactLine.GammaNextExp,
    'Gamma held by market makers expiring at the next expiration (weekly for equities, daily for indices)',
  ],
  [
    SynthOIPCImpactLine.GammaMonthly,
    'Gamma held by market makers expiring at monthly options expiration (OPEX)',
  ],

  [
    SynthOIPCImpactLine.DeltaTotal,
    'Cumulative market maker delta across all strikes',
  ],
  [
    SynthOIPCImpactLine.DeltaSansNextExp,
    'Total market maker delta subtracting all delta that expires at the next expiration',
  ],
  [
    SynthOIPCImpactLine.DeltaSansMonthly,
    'Total market maker delta subtracting all delta that expires at monthly options expiration (OPEX)',
  ],
  [
    SynthOIPCImpactLine.DeltaNextExp,
    'Delta held by market makers expiring at the next expiration (weekly for equities, daily for indices)',
  ],
  [
    SynthOIPCImpactLine.DeltaMonthly,
    'Delta held by market makers expiring at monthly options expiration (OPEX)',
  ],
]);

export enum SynthOIPCImpactBarGroup {
  OITotal = 'Total Open Interest',
  OIChange = 'Daily Open Interest Change',
  OINetPos = 'Net Positioning',
  GammaTotal = 'Total Gamma',
  GammaPC = 'Put & Call Gamma',
  DeltaTotal = 'Total Delta',
  DeltaPC = 'Put & Call Delta',
}

export const SynthOIPCImpactBarGroups: Map<
  SynthOIPCImpactChartType,
  SynthOIPCImpactBarGroup[]
> = new Map([
  [
    SynthOIPCImpactChartType.Gamma,
    [SynthOIPCImpactBarGroup.GammaTotal, SynthOIPCImpactBarGroup.GammaPC],
  ],
  [
    SynthOIPCImpactChartType.Delta,
    [SynthOIPCImpactBarGroup.DeltaTotal, SynthOIPCImpactBarGroup.DeltaPC],
  ],
  [
    SynthOIPCImpactChartType.OpenInterest,
    [
      SynthOIPCImpactBarGroup.OITotal,
      SynthOIPCImpactBarGroup.OIChange,
      SynthOIPCImpactBarGroup.OINetPos,
    ],
  ],
]);

export const SynthOIPCImpactBarGroupTooltip = new Map([
  [
    SynthOIPCImpactBarGroup.GammaTotal,
    'Market maker gamma by strike, with call gamma and put gamma summed together',
  ],
  [
    SynthOIPCImpactBarGroup.GammaPC,
    'Market maker gamma by strike, broken out by call gamma and put gamma',
  ],

  [
    SynthOIPCImpactBarGroup.DeltaTotal,
    'Market maker delta by strike, with call delta and put delta summed together',
  ],
  [
    SynthOIPCImpactBarGroup.DeltaPC,
    'Market maker delta by strike, broken out by call delta and put delta',
  ],

  [
    SynthOIPCImpactBarGroup.OITotal,
    'Total market maker open interest, broken out by calls and puts for each strike',
  ],
  [
    SynthOIPCImpactBarGroup.OIChange,
    'The daily change in market maker open interest, measured by how many net contracts (calls or puts) were opened or closed since the previous day',
  ],
  [
    SynthOIPCImpactBarGroup.OINetPos,
    "The net open interest for each strike after summing market makers' long and short positions",
  ],
]);

export const SynthOIPCImpactGroupedBars = new Map<
  SynthOIPCImpactBarGroup,
  SynthOIPCImpactBar[]
>([
  [
    SynthOIPCImpactBarGroup.OITotal,
    [SynthOIPCImpactBar.OITotalCalls, SynthOIPCImpactBar.OITotalPuts],
  ],
  [
    SynthOIPCImpactBarGroup.OIChange,
    [SynthOIPCImpactBar.OIChangeCalls, SynthOIPCImpactBar.OIChangePuts],
  ],
  [
    SynthOIPCImpactBarGroup.OINetPos,
    [SynthOIPCImpactBar.OINetPosCalls, SynthOIPCImpactBar.OINetPosPuts],
  ],
  [SynthOIPCImpactBarGroup.GammaTotal, [SynthOIPCImpactBar.GammaTotal]],
  [
    SynthOIPCImpactBarGroup.GammaPC,
    [SynthOIPCImpactBar.GammaCalls, SynthOIPCImpactBar.GammaPuts],
  ],
  [SynthOIPCImpactBarGroup.DeltaTotal, [SynthOIPCImpactBar.DeltaTotal]],
  [
    SynthOIPCImpactBarGroup.DeltaPC,
    [SynthOIPCImpactBar.DeltaCalls, SynthOIPCImpactBar.DeltaPuts],
  ],
]);

export enum EhModelType {
  SynthOI = 'synthoi',
  Legacy = 'legacy',
}

export enum EhCompositeMode {
  Gamma = 'gamma',
  Factors = 'factors', // Position & Activity factors
}
