import { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Paper,
  Stack,
  Chip,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, alpha } from '@mui/material/styles';
import {
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DndContext,
  closestCenter,
  DragStartEvent,
  DragOverlay,
  DragOverEvent,
  useDroppable,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  sortableKeyboardCoordinates,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import EditIcon from '@mui/icons-material/Edit';
import { EquityFieldKey } from 'types';
import { EQUITY_FIELD_LABEL_MAPPING } from 'config';
import { useRecoilValue } from 'recoil';
import { isMobileState, negativeTrendColorState } from 'states';
import { HideSupportStyle } from 'components/shared/HideSupportStyle';

interface EquityDetailsEditorBtnProps {
  mainViewFields: EquityFieldKey[];
  expandedViewFields: EquityFieldKey[];
  otherFields: EquityFieldKey[];
  onSave: (
    mainViewFields: EquityFieldKey[],
    expandedViewFields: EquityFieldKey[],
  ) => void;
  mainViewMaxLimit?: number;
}
// Define SortableItem component
interface SortableItemProps {
  id: string;
  label: string;
  container: string;
  onDelete?: () => void;
}

const SortableItem = ({
  id,
  label,
  container,
  onDelete,
}: SortableItemProps) => {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
  };

  // Prevent event propagation to ensure delete works properly
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <Chip
      ref={setNodeRef}
      style={style}
      label={label}
      deleteIcon={
        container !== 'noshow' ? (
          <CloseIcon
            fontSize="small"
            onClick={handleDelete}
            style={{ pointerEvents: 'auto' }}
          />
        ) : undefined
      }
      onDelete={container !== 'noshow' ? handleDelete : undefined}
      variant={container === 'noshow' ? 'outlined' : 'filled'}
      sx={{
        m: 0.5,
        textTransform: 'uppercase',
        fontWeight: 'medium',
        cursor: 'grab',
        touchAction: 'none', // Prevent scrolling on touch devices
        backgroundColor:
          container === 'noshow'
            ? alpha(theme.palette.gray, 0.2)
            : alpha(theme.palette.sgGreen, 0.4),
        '&:hover': {
          bgcolor:
            container === 'noshow'
              ? alpha(theme.palette.gray, 0.4)
              : alpha(theme.palette.sgGreen, 0.6),
        },
        '& .MuiChip-label': {
          color: theme.palette.text.secondary,
          fontWeight: 400,
        },
        '& .MuiChip-deleteIcon': {
          color: theme.palette.text.secondary,
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...attributes}
      {...listeners}
    />
  );
};

// Define DroppableContainer component
interface DroppableContainerProps {
  id: string;
  children: React.ReactNode;
  overContainer: string | null;
  theme: any;
  disabled?: boolean;
}

const DroppableContainer = ({
  id,
  children,
  overContainer,
  theme,
  disabled = false,
}: DroppableContainerProps) => {
  const { setNodeRef } = useDroppable({ id, disabled });

  return (
    <Paper
      ref={setNodeRef}
      elevation={0}
      id={id}
      sx={{
        display: 'flex',
        flexWrap: id === 'noshow' ? 'wrap' : 'nowrap',
        flexDirection: id !== 'noshow' ? 'column' : 'row',
        minHeight: 50,
        borderRadius: 1,
        p: 1,
        gap: 2,
        backgroundColor:
          overContainer === id && !disabled
            ? alpha(theme.palette.primary.main, 0.05)
            : 'transparent',
        border: '1px dashed',
        borderColor:
          overContainer === id && !disabled
            ? theme.palette.primary.main
            : 'transparent',
        opacity: disabled ? 0.7 : 1,
      }}
    >
      {children}
    </Paper>
  );
};

const EquityDetailsEditorBtn = ({
  mainViewFields = [],
  expandedViewFields = [],
  otherFields = [],
  onSave,
  mainViewMaxLimit,
}: EquityDetailsEditorBtnProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [open, setOpen] = useState(false);
  const [localMainViewFields, setLocalMainViewFields] =
    useState<EquityFieldKey[]>(mainViewFields);
  const [localExpandedViewFields, setLocalExpandedViewFields] =
    useState<EquityFieldKey[]>(expandedViewFields);
  const [localNoShowFields, setLocalNoShowFields] =
    useState<EquityFieldKey[]>(otherFields);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeContainer, setActiveContainer] = useState<string | null>(null);
  const [overContainer, setOverContainer] = useState<string | null>(null);

  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const isMainViewFull = mainViewMaxLimit
    ? localMainViewFields.length >= mainViewMaxLimit
    : false;

  // Use MouseSensor and TouchSensor instead of PointerSensor for better mobile support
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Add activation constraint to allow click events to work properly
      activationConstraint: {
        distance: 4, // Only start dragging after moving 4px
      },
    }),
    useSensor(TouchSensor, {
      // Add activation constraints with tolerance for touch devices
      activationConstraint: {
        delay: 150, // Short delay for touch to distinguish between tap and drag
        tolerance: 5, // Allow some movement during the delay
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleOpen = () => {
    setLocalMainViewFields([...mainViewFields]);
    setLocalExpandedViewFields([...expandedViewFields]);
    setLocalNoShowFields([...otherFields]);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSave(localMainViewFields, localExpandedViewFields);
    setOpen(false);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const id = event.active.id as string;
    setActiveId(id);
    const [, container] = id.split('|');
    setActiveContainer(container);
  };

  const handleDragOver = (event: DragOverEvent) => {
    if (!event.over) return;

    const overId = event.over.id as string;
    // Check if the over ID contains a pipe character, indicating it's an item
    if (overId.includes('|')) {
      const [, container] = overId.split('|');
      setOverContainer(container);
    } else {
      // If it's a container ID directly
      setOverContainer(overId);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setActiveId(null);
    setActiveContainer(null);
    setOverContainer(null);

    if (!over) {
      return;
    }

    const activeId = active.id as string;
    const overId = over.id as string;

    // Extract container information from the id using a separator
    const [activeFieldId, activeContainer] = activeId.split('|');

    // Handle case when dropping directly onto a container
    let overContainer: string;
    let overFieldId: string;

    if (overId.includes('|')) {
      // Dropping on an item
      [overFieldId, overContainer] = overId.split('|');
    } else {
      // Dropping directly on a container
      overContainer = overId;
      overFieldId = '';
    }

    // Check if trying to add to main view when it's already at max limit
    if (
      overContainer === 'main' &&
      activeContainer !== 'main' &&
      mainViewMaxLimit &&
      localMainViewFields.length >= mainViewMaxLimit
    ) {
      return; // Prevent adding more items to main view
    }

    // If dropped in the same container
    if (activeContainer === overContainer) {
      if (activeContainer === 'main') {
        setLocalMainViewFields((items) => {
          const oldIndex = items.findIndex(
            (item) => item === (activeFieldId as EquityFieldKey),
          );
          const newIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          return arrayMove(items, oldIndex, newIndex);
        });
      } else if (activeContainer === 'expanded') {
        setLocalExpandedViewFields((items) => {
          const oldIndex = items.findIndex(
            (item) => item === (activeFieldId as EquityFieldKey),
          );
          const newIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          return arrayMove(items, oldIndex, newIndex);
        });
      } else if (activeContainer === 'noshow') {
        setLocalNoShowFields((items) => {
          const oldIndex = items.findIndex(
            (item) => item === (activeFieldId as EquityFieldKey),
          );
          const newIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    } else {
      // Moving between containers
      const activeField = activeFieldId as EquityFieldKey;

      // Remove from source container
      if (activeContainer === 'main') {
        setLocalMainViewFields((items) =>
          items.filter((item) => item !== activeField),
        );
      } else if (activeContainer === 'expanded') {
        setLocalExpandedViewFields((items) =>
          items.filter((item) => item !== activeField),
        );
      } else if (activeContainer === 'noshow') {
        setLocalNoShowFields((items) =>
          items.filter((item) => item !== activeField),
        );
      }

      // Add to target container
      if (overContainer === 'main') {
        setLocalMainViewFields((items) => {
          const overIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          const newItems = [...items];
          newItems.splice(
            overIndex >= 0 ? overIndex : items.length,
            0,
            activeField,
          );
          return newItems;
        });
      } else if (overContainer === 'expanded') {
        setLocalExpandedViewFields((items) => {
          const overIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          const newItems = [...items];
          newItems.splice(
            overIndex >= 0 ? overIndex : items.length,
            0,
            activeField,
          );
          return newItems;
        });
      } else if (overContainer === 'noshow') {
        setLocalNoShowFields((items) => {
          const overIndex = overFieldId
            ? items.findIndex(
                (item) => item === (overFieldId as EquityFieldKey),
              )
            : items.length;
          const newItems = [...items];
          newItems.splice(
            overIndex >= 0 ? overIndex : items.length,
            0,
            activeField,
          );
          return newItems;
        });
      }
    }
  };

  const getFieldLabel = (field: EquityFieldKey) => {
    return EQUITY_FIELD_LABEL_MAPPING[field] || field;
  };

  const handleDeleteField = (field: EquityFieldKey, container: string) => {
    // Remove from current container
    if (container === 'main') {
      setLocalMainViewFields((items) => items.filter((item) => item !== field));
    } else if (container === 'expanded') {
      setLocalExpandedViewFields((items) =>
        items.filter((item) => item !== field),
      );
    }

    // Add to noshow container
    setLocalNoShowFields((items) => [...items, field]);
  };

  const renderSortableItem = (field: EquityFieldKey, container: string) => {
    return (
      <SortableItem
        key={`${field}|${container}`}
        id={`${field}|${container}`}
        label={getFieldLabel(field)}
        container={container}
        onDelete={
          container !== 'noshow'
            ? () => handleDeleteField(field, container)
            : undefined
        }
      />
    );
  };

  return (
    <Box>
      <IconButton color="primary" onClick={handleOpen}>
        <EditIcon />
      </IconButton>
      {isMobile && open ? <HideSupportStyle /> : null}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        fullScreen={isMobile}
        maxWidth="md"
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>
          Edit details panel
          <IconButton
            size="small"
            aria-label="close"
            color="primary"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ color: 'text.primary' }}>
            Customize the details you would like to see about each ticker. Drag
            and drop items to reorder them or move between sections.
          </Typography>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <Stack
              direction="row"
              spacing={6}
              sx={{ mt: 4 }}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: alpha(theme.palette.primary.main, 0.25) }}
                />
              }
            >
              {/* Main View Column */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 4,
                    fontWeight: 'medium',
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontSize: 14,
                  }}
                >
                  Main View
                  {mainViewMaxLimit && (
                    <Typography
                      component="span"
                      sx={{
                        ml: 1,
                        color: isMainViewFull
                          ? serverNegativeTrendColor
                          : 'text.secondary',
                        fontSize: 12,
                      }}
                    >
                      ({localMainViewFields.length}/{mainViewMaxLimit})
                    </Typography>
                  )}
                </Typography>
                <DroppableContainer
                  id="main"
                  overContainer={overContainer}
                  theme={theme}
                  disabled={isMainViewFull && activeContainer !== 'main'}
                >
                  <SortableContext
                    items={localMainViewFields.map((field) => `${field}|main`)}
                    strategy={verticalListSortingStrategy}
                  >
                    {localMainViewFields.map((field) => (
                      <Box key={field} sx={{ width: '100%' }}>
                        {renderSortableItem(field, 'main')}
                      </Box>
                    ))}
                  </SortableContext>
                  {localMainViewFields.length === 0 && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ p: 2, textAlign: 'center', width: '100%' }}
                    >
                      Drag items here
                    </Typography>
                  )}
                </DroppableContainer>
                {isMainViewFull && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 4,
                      textAlign: 'center',
                      color: serverNegativeTrendColor,
                      fontWeight: 'medium',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        flexGrow: 1,
                        borderBottom: `2px dashed ${serverNegativeTrendColor}`,
                      }}
                    />
                    <Box component="span" sx={{ mx: 2 }}>
                      Max limit reached
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        flexGrow: 1,
                        borderBottom: `2px dashed ${serverNegativeTrendColor}`,
                      }}
                    />
                  </Typography>
                )}
              </Box>

              {/* Expanded View Column */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 4,
                    fontWeight: 'medium',
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontSize: 14,
                  }}
                >
                  Expanded view
                </Typography>
                <DroppableContainer
                  id="expanded"
                  overContainer={overContainer}
                  theme={theme}
                >
                  <SortableContext
                    items={localExpandedViewFields.map(
                      (field) => `${field}|expanded`,
                    )}
                    strategy={verticalListSortingStrategy}
                  >
                    {localExpandedViewFields.map((field) => (
                      <Box key={field} sx={{ width: '100%' }}>
                        {renderSortableItem(field, 'expanded')}
                      </Box>
                    ))}
                  </SortableContext>
                  {localExpandedViewFields.length === 0 && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ p: 2, textAlign: 'center', width: '100%' }}
                    >
                      Drag items here
                    </Typography>
                  )}
                </DroppableContainer>
              </Box>

              {/* Hidden Fields Column */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 4,
                    fontWeight: 'medium',
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontSize: 14,
                  }}
                >
                  Add more
                </Typography>
                <DroppableContainer
                  id="noshow"
                  overContainer={overContainer}
                  theme={theme}
                >
                  <SortableContext
                    items={localNoShowFields.map((field) => `${field}|noshow`)}
                    strategy={verticalListSortingStrategy}
                  >
                    {localNoShowFields.map((field) =>
                      renderSortableItem(field, 'noshow'),
                    )}
                  </SortableContext>
                  {localNoShowFields.length === 0 && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ p: 2, textAlign: 'center', width: '100%' }}
                    >
                      Drag items here
                    </Typography>
                  )}
                </DroppableContainer>
              </Box>
            </Stack>

            <DragOverlay>
              {activeId ? (
                <Chip
                  label={getFieldLabel(
                    activeId.split('|')[0] as EquityFieldKey,
                  )}
                  color="primary"
                  variant="outlined"
                  deleteIcon={
                    activeContainer !== 'noshow' ? (
                      <CloseIcon fontSize="small" />
                    ) : undefined
                  }
                  onDelete={
                    activeContainer !== 'noshow'
                      ? () => {
                          /* No action needed here, just for display */
                        }
                      : undefined
                  }
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                  }}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, gap: 2 }}>
          <Button
            autoFocus
            variant="outlined"
            sx={{
              textTransform: 'none',
              borderWidth: 2,
              fontSize: 14,
              minWidth: 100,
              flex: 1,
              ':hover': {
                borderWidth: 2,
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              minWidth: 100,
              fontSize: 14,
              flex: 1,
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.button.default,
              boxShadow: 'none',
              ':hover': {
                backgroundColor: theme.palette.button.hover,
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EquityDetailsEditorBtn;
