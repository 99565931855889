import {
  ClickAwayListener,
  IconButton,
  IconButtonProps,
  Popper,
} from '@mui/material';
import { useCallback, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { ChartSettings } from './ChartSettings';
import { SGTooltip } from '../../../core';

interface ChartSettingsProps {
  openSettings: boolean;
  setOpenSettings: (open: boolean) => void;
  size?: IconButtonProps['size'];
}

export const ChartSettingsButton = ({
  openSettings,
  setOpenSettings,
  size,
}: ChartSettingsProps) => {
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<any>(null);

  const onClickAway = useCallback(() => {
    setOpenSettings(false);
  }, [setOpenSettings]);

  return (
    <>
      <IconButton
        aria-label="settings"
        onClick={(event) => {
          setAnchorSettingsEl(event.currentTarget);
          setOpenSettings(!openSettings);
        }}
        size={size ?? 'medium'}
        color="primary"
      >
        <SGTooltip title="All HIRO Settings" placement="top">
          <TuneIcon fontSize="inherit" />
        </SGTooltip>
      </IconButton>
      <Popper
        id="hiro-settings"
        open={openSettings}
        anchorEl={anchorSettingsEl}
        placement="bottom-end"
        style={{ zIndex: 1299 }} // calendar from date picker z-index is 1300
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <ChartSettings onClose={onClickAway} />
        </ClickAwayListener>
      </Popper>
    </>
  );
};
