import { useCallback } from 'react';
import { ProductType } from '../../types';
import useLog from 'hooks/useLog';
import useAuth from 'hooks/auth/useAuth';
import useSynthOi from './useSynthOi';

const getURL = (isSynthOI: boolean, hasAccess: boolean) => {
  if (isSynthOI) {
    return hasAccess ? 'synth_oi/v1/historical' : 'synth_oi/v1/free_historical';
  }
  return hasAccess ? 'v4/historical' : 'v1/free_historical';
};

const useHistory = () => {
  const { fetchAPIWithLog } = useLog('useHistory');
  const { hasAccessToProduct } = useAuth();
  const { isSynthOI } = useSynthOi();

  const getHistory = useCallback(
    async (sym: string) => {
      const url = getURL(isSynthOI, hasAccessToProduct(ProductType.EQUITYHUB));
      return await fetchAPIWithLog(`${url}?sym=${sym}`);
    },
    [hasAccessToProduct, isSynthOI],
  );

  return { getHistory };
};

export default useHistory;
