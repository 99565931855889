import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  activeSearchState,
  chartExpandedState,
  searchSuggestionsState,
  sidebarExpandedState,
  drawerExpandedState,
  isMobileState,
  bottomDrawerExpandedState,
  mainContentWidthRatioState,
  screenWidthState,
  screenHeightState,
} from '../states';
import { MAIN_CONTENT_DEFAULT_WIDTH } from '../config';

interface DashboardLayoutProps {
  bottomDrawerComponent?: React.ReactNode;
  mainContentComponent?: React.ReactNode;
  secondarySidebarComponent?: React.ReactNode;
  expanded?: boolean;
  verticalOffsetPixels?: number;
  horizontalOffsetPixels?: number;
  mainContentDefaultHeight?: number;
  sidebarFillsMaxHeight?: boolean;
  sidebarMinimumWidth?: number;
  bottomContentMinHeight?: number;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  mainContentComponent,
  bottomDrawerComponent,
  secondarySidebarComponent,
  verticalOffsetPixels = 75, // This is me forward proofing us towards a point where this is unnecessary.
  horizontalOffsetPixels = 115,
  mainContentDefaultHeight = 0.6,
  sidebarFillsMaxHeight = false,
  sidebarMinimumWidth,
  bottomContentMinHeight,
}) => {
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const screenWidth = useRecoilValue(screenWidthState);
  const screenHeight = useRecoilValue(screenHeightState);
  const useDynamicSizing = (mainContentDefaultHeight = 0.6) => {
    const [expanded, setExpandedState] = useRecoilState(chartExpandedState);

    const [sidebarExpanded, setSidebarExpanded] =
      useRecoilState(sidebarExpandedState);

    const [drawerExpanded, setDrawerExpanded] =
      useRecoilState(drawerExpandedState);

    const [bottomDrawerExpanded, setBottomDrawerExpanded] = useRecoilState(
      bottomDrawerExpandedState,
    );

    let secondarySidebarDisplay =
      sidebarExpanded && !bottomDrawerExpanded ? 'flex' : 'none';
    let bottomDisplay = drawerExpanded ? 'flex' : 'none';

    const theme = useTheme();
    const [mediumDown, mediumUp] = [
      useMediaQuery(theme.breakpoints.down('md')),
      useMediaQuery(theme.breakpoints.up('md')),
    ];

    const [_mainContentWidth, setMainContentWidth] = useRecoilState(
      mainContentWidthRatioState,
    );
    const [mainContentHeight, setMainContentHeight] = useState(
      mainContentDefaultHeight,
    );

    const calculateWidths = (
      mainWidth?: number,
    ): {
      secondaryContentWidth: number;
      mainContentWidth: number;
    } => {
      mainWidth = mainWidth ?? _mainContentWidth;
      const secondaryContentWidth = sidebarExpanded ? 1 - mainWidth : 0;
      const sidebarWidth = secondaryContentWidth * screenWidth;
      const mainContentWidth = sidebarExpanded ? mainWidth : 1;

      if (
        isMobile ||
        !sidebarExpanded ||
        sidebarMinimumWidth == null ||
        sidebarWidth >= sidebarMinimumWidth
      ) {
        return { secondaryContentWidth, mainContentWidth };
      }

      const minSidebarWidthRatio = sidebarMinimumWidth / screenWidth;

      return {
        secondaryContentWidth: minSidebarWidthRatio,
        mainContentWidth: 1 - minSidebarWidthRatio,
      };
    };

    let bottomContentHeight = bottomDrawerExpanded
      ? 1
      : drawerExpanded
      ? 1 - mainContentHeight
      : 0;
    if (
      bottomContentMinHeight != null &&
      !bottomDrawerExpanded &&
      drawerExpanded &&
      screenHeight * bottomContentHeight < bottomContentMinHeight
    ) {
      bottomContentHeight = bottomContentMinHeight / screenHeight;
    }
    const { mainContentWidth, secondaryContentWidth } = calculateWidths();

    const secondaryContentHeight = sidebarExpanded
      ? sidebarFillsMaxHeight
        ? 1
        : mainContentHeight
      : 0;

    useEffect(() => {
      if (sidebarExpanded) {
        secondarySidebarDisplay = 'flex';
      } else {
        secondarySidebarDisplay = 'none';
      }
      setMainContentWidth(
        calculateWidths(MAIN_CONTENT_DEFAULT_WIDTH).mainContentWidth,
      );
    }, [sidebarExpanded]);

    useEffect(() => {
      if (drawerExpanded) {
        setMainContentHeight(mainContentDefaultHeight);
        bottomDisplay = 'flex';
      } else {
        setMainContentHeight(1);
        bottomDisplay = 'none';
      }
    }, [drawerExpanded, mainContentDefaultHeight]);

    useEffect(() => {
      if (isMobile) {
        setExpandedState(false);
        setSidebarExpanded(false);
        return;
      }
      // Handles the case where you got to expanded state by independenly opening each sidebar
      if (!sidebarExpanded && !drawerExpanded && !expanded) {
        setExpandedState(true);
      }

      // Handles the case where you got to expanded state by independenly opening each sidebar
      if (sidebarExpanded && drawerExpanded && expanded) {
        setExpandedState(false);
      }
    }, [sidebarExpanded, drawerExpanded, isMobile]);

    useEffect(() => {
      setSidebarExpanded(!expanded);
      setDrawerExpanded(!expanded);
    }, [expanded]);

    // The linter is throwing errors here that literally break the app, I don't know why it thinks it needs to be dependent on every value
    // I'm intentially not using it as a dependency.
    useEffect(() => {
      if (expanded && mediumUp) {
        setExpandedState(false);
      } else if (!expanded && mediumDown) {
        setExpandedState(true);
      }
    }, [mediumDown, mediumUp]);

    return {
      bottomContentHeight,
      secondaryContentHeight,
      secondaryContentWidth,
      mainContentHeight,
      mainContentWidth,
      bottomDisplay,
      secondarySidebarDisplay,
    };
  };

  const {
    mainContentHeight,
    mainContentWidth,
    secondaryContentHeight,
    secondaryContentWidth,
    bottomContentHeight,
    bottomDisplay,
    secondarySidebarDisplay,
  } = useDynamicSizing(mainContentDefaultHeight);

  const bottomDrawerExpanded = useRecoilValue(bottomDrawerExpandedState);

  if (isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
          height: '100%',
          width: '100%',
          paddingBottom: isMobile ? '45px' : '70px !important', // support button
          background: theme.palette.background.default,
        }}
      >
        <Box
          display="flex"
          height={`100%`}
          width={`100%`}
          sx={{ padding: 3, background: theme.palette.background.default }}
          flexWrap={'wrap'}
        >
          {mainContentComponent}
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          sx={{ background: theme.palette.background.default }}
        >
          {secondarySidebarComponent}
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          sx={{ background: theme.palette.background.default }}
        >
          {bottomDrawerComponent}
        </Box>
      </Box>
    );
  } else {
    const bottomDrawer = (
      <Box
        display={bottomDisplay}
        width={1}
        height={bottomContentHeight}
        className={'tableView'}
      >
        {bottomDrawerComponent}
      </Box>
    );

    const mainContent = (
      <Box
        display={bottomDrawerExpanded ? 'none' : 'flex'}
        height={bottomDrawerExpanded ? 0 : mainContentHeight}
        width={sidebarFillsMaxHeight ? 1 : mainContentWidth}
        mb={3}
        minHeight={bottomDrawerExpanded ? 0 : 400}
      >
        {mainContentComponent}
      </Box>
    );

    // if sidebar fills the max height, render the bottom drawer together with the mainContent
    // else, render the bottom drawer separately
    return (
      <Box display="flex" height={`100%`} width={`100%`} flexWrap={'wrap'}>
        {sidebarFillsMaxHeight ? (
          <Box
            display="flex"
            flexDirection="column"
            height={Math.min(1, mainContentHeight + bottomContentHeight)}
            width={bottomDrawerExpanded ? 1 : mainContentWidth}
          >
            {mainContent}
            {bottomDrawer}
          </Box>
        ) : (
          mainContent
        )}
        <Box
          display={secondarySidebarDisplay}
          width={secondaryContentWidth}
          height={secondaryContentHeight}
          className="detailView"
        >
          <Box display="flex" flexGrow={1} ml={4} mr={4}>
            {secondarySidebarComponent}
          </Box>
        </Box>

        {!sidebarFillsMaxHeight && bottomDrawer}
      </Box>
    );
  }
};

export const ExpandableDashboardLayout: React.FC<DashboardLayoutProps> = (
  props,
) => {
  const resetSearch = useResetRecoilState(searchSuggestionsState);
  const resetActiveSearch = useResetRecoilState(activeSearchState);

  useEffect(() => {
    resetSearch();
    resetActiveSearch();
  }, [resetActiveSearch, resetSearch]);

  return <DashboardLayout {...props} />;
};
