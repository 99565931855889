export const DEFAULT_BIAXIAL_ZOOM_CONFIG = {
  data: undefined,
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax',
  bottom: 'dataMin',
  top2: 'dataMax',
  bottom2: 'dataMin',
};

export const DEFAULT_ZOOM_CONFIG = {
  data: undefined,
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax',
  bottom: 'dataMin',
};

export const DEFAULT_BRUSH_ZOOM_CONFIG = {
  data: undefined,
  leftIdx: 0,
  rightIdx: undefined,
  refAreaLeft: '',
  refAreaRight: '',
};

export const DEFAULT_Y_AXIS_STYLES = {
  fontSize: 12,
  angle: -90,
  position: 'left',
  fontWeight: 600,
  offset: -7,
};

export const DEFAULT_Y2_AXIS_STYLES = {
  fontSize: 12,
  angle: -90,
  position: 'right',
  fontWeight: 600,
  offset: -7,
};

export const DEFAULT_X_AXIS_STYLES = {
  offset: -5,
  position: 'insideBottom',
  fontSize: 12,
  fontWeight: 600,
};

export const DEFAULT_CHART_MARGINS = {
  top: 5,
  right: 10,
  left: 5,
  bottom: 10,
};
