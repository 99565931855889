import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selectedEquityLevelsState,
  selectedEquityState,
  workerState,
  isMobileState,
  timezoneState,
  liveSgChartDataState,
  synthOILiveChartTypeState,
  synthOILivePriceZoomLevelsState,
} from '../../../states';
import {
  fetchAPI,
  formatAsCurrency,
  formatAsCompactNumber,
  dayjs,
} from '../../../util';
import {
  GreekMetric,
  SynthOIChartData,
  Tick,
  SynthOIChartType,
  SynthOILiveChartTypeLabels,
} from '../../../types';
import { ErrorContent } from '../../shared';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ComposedChart,
  Bar,
  BarChart,
} from 'recharts';
import { DEFAULT_Y_AXIS_STYLES } from '../../../config';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import { Paper, Slider, Typography, Box } from '@mui/material';
import useTimeSeries, {
  filterLastDay,
} from '../../../hooks/equityhub/useTimeSeries';
import poll from '../../../util/poll';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import useSynthOi from 'hooks/equityhub/useSynthOi';

type LivePriceAndSgLevelsProps = {
  showKeyLevels: boolean;
};

const LOWER_BOUND_MULTIPLIER = 0.9995;
const UPPER_BOUND_MULTIPLIER = 1.0005;

// Zoom level constants
const ZOOM_LEVELS = [0, 1, 2, 3, 4, 5];

export const LivePriceAndSgLevels = ({
  showKeyLevels,
}: LivePriceAndSgLevelsProps) => {
  const worker = useRecoilValue(workerState);
  const selectedEquity = useRecoilValue(selectedEquityState);
  const levels = useRecoilValue(selectedEquityLevelsState);
  const [greekMetrics, setGreekMetrics] = useState<GreekMetric[]>([]);
  const { getLastDaysPrices } = useTimeSeries();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const { isSynthOI } = useSynthOi();

  const [synthOIData, setSynthOIData] = useState<SynthOIChartData | null>(null);
  const isMobile = useRecoilValue(isMobileState);
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;
  const currentTimezone = useRecoilValue(timezoneState);
  const [yDomain, setYDomain] = useState<number[]>([0, 0]);
  const [ticks, setTicks] = useRecoilState<Tick[]>(liveSgChartDataState);
  const selectedChartType = useRecoilValue(synthOILiveChartTypeState);
  const [zoomLevel, setZoomLevel] = useRecoilState<number>(
    synthOILivePriceZoomLevelsState,
  );
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
  const date = searchParams.get('date');

  const handleResponse = useCallback(
    async (data: { json: Record<string, Tick[]> }) => {
      if (data?.json != null && selectedEquity?.sym) {
        const ticks = filterLastDay(data.json[selectedEquity.sym]?.values);
        if (ticks == null) {
          return;
        }
        setTicks(ticks);
      }
    },
    [selectedEquity?.sym, setTicks],
  );

  useEffect(() => {
    if (!selectedEquity?.sym) return;

    return poll(worker, {
      url: `v1/twelve_series?symbol=${selectedEquity.sym}&interval=1min&outputsize=390&order=ASC&date=${date}`,
      interval: 60_000,
      onResponse: handleResponse,
    });
  }, [worker, selectedEquity, handleResponse, date]);

  useEffect(() => {
    async function generateTimeSeries() {
      if (!selectedEquity?.sym) return;

      setLoading(true);
      let data = await getLastDaysPrices(
        [selectedEquity.sym],
        true /*ascending*/,
        date ?? undefined,
      );
      const newTicks = data[selectedEquity.sym];
      setLoading(false);

      if (newTicks == null) {
        return;
      }

      setTicks(newTicks);
    }

    generateTimeSeries();
  }, [selectedEquity?.sym, getLastDaysPrices, setTicks, date]);

  useEffect(() => {
    if (!selectedEquity?.sym) return;

    const fetchData = async () => {
      const params: Record<string, string> = { sym: selectedEquity.sym };
      if (date) {
        params.date = date;
      }
      const urlParams = new URLSearchParams(params);
      const chartJSON = await fetchAPI(`synth_oi/v1/chart_data?${urlParams}`);
      setSynthOIData(chartJSON);
    };

    fetchData();
  }, [selectedEquity?.sym, date]);

  // Memoize price data calculations
  const prices = useMemo(() => ticks?.map((t: Tick) => t.price) || [], [ticks]);
  const minPrice = useMemo(
    () => (prices.length > 0 ? Math.min(...prices) : 0),
    [prices],
  );
  const maxPrice = useMemo(
    () => (prices.length > 0 ? Math.max(...prices) : 0),
    [prices],
  );

  // Update y-domain based on price data and current zoom level
  useEffect(() => {
    if (prices.length === 0) {
      setYDomain([0, 100]); // Fallback default
      return;
    }

    // Calculate base domain with padding to ensure min/max prices are always visible
    const lowerBound = minPrice * LOWER_BOUND_MULTIPLIER;
    const upperBound = maxPrice * UPPER_BOUND_MULTIPLIER;

    // Ensure we have a reasonable range even with identical min/max prices
    const baseDomain =
      lowerBound === upperBound
        ? [lowerBound * 0.95, upperBound * 1.05]
        : [lowerBound, upperBound];

    // Calculate the midpoint of the price range
    const midPoint = (baseDomain[0] + baseDomain[1]) / 2;

    // Calculate the base range (distance from min to max with padding)
    const baseRange = baseDomain[1] - baseDomain[0];

    // Apply zoom factor - start with a wider view and zoom in
    // Higher zoom levels = smaller zoom factor = closer view
    const zoomFactor = Math.pow(2, 2 - zoomLevel * 0.4); // Starts wide, gets narrower
    const newRange = baseRange * zoomFactor;

    // Calculate new domain ensuring min/max prices are always visible
    let newMin = midPoint - newRange / 2;
    let newMax = midPoint + newRange / 2;

    // Ensure the domain always includes the min and max prices
    if (newMin > lowerBound) {
      newMin = lowerBound;
    }
    if (newMax < upperBound) {
      newMax = upperBound;
    }

    setYDomain([newMin, newMax]);
  }, [minPrice, maxPrice, zoomLevel, prices.length]);

  // Generate appropriate y-axis ticks based on domain range and zoom level
  useEffect(() => {
    if (yDomain[0] === yDomain[1]) return;

    const range = yDomain[1] - yDomain[0];

    // Calculate ideal number of ticks based on chart readability
    const targetTickCount = 10; // Aim for about 10 ticks for readability

    // Calculate a base interval using logarithmic scale
    // This creates a "nice" rounded number appropriate for the range
    const magnitude = Math.floor(Math.log10(range));
    const powerOfTen = Math.pow(10, magnitude);

    // Start with a base interval that's a power of 10
    let baseInterval = powerOfTen;

    // Refine the interval to get closer to target tick count
    // Try standard intervals: 1, 2, 5, 10 times the power of ten
    if (range / baseInterval > targetTickCount * 2) {
      baseInterval = powerOfTen * 2;
    } else if (range / baseInterval > targetTickCount) {
      baseInterval = powerOfTen;
    } else if (range / (powerOfTen / 2) > targetTickCount) {
      baseInterval = powerOfTen / 2;
    } else if (range / (powerOfTen / 5) > targetTickCount) {
      baseInterval = powerOfTen / 5;
    } else {
      baseInterval = powerOfTen / 10;
    }

    // Adjust interval based on zoom level
    let interval = baseInterval;
    if (zoomLevel >= 4) {
      interval = baseInterval / 2;
    } else if (zoomLevel <= 1) {
      interval = baseInterval * 2;
    }

    // Calculate the first tick (round down to nearest interval)
    const firstTick = Math.floor(yDomain[0] / interval) * interval;

    // Generate ticks
    const ticks = [];
    for (let tick = firstTick; tick <= yDomain[1]; tick += interval) {
      // Use toFixed and parseFloat to avoid floating point precision issues
      ticks.push(parseFloat(tick.toFixed(10)));
    }

    // Ensure we don't have too many ticks
    if (ticks.length > 10) {
      const step = Math.ceil(ticks.length / 10);
      const filteredTicks = ticks.filter((_, i) => i % step === 0);
      setYAxisTicks(filteredTicks);
    } else {
      setYAxisTicks(ticks);
    }
  }, [yDomain, zoomLevel]);

  // Calculate Greek metrics based on current data and filters
  useEffect(() => {
    if (!isSynthOI || !ticks?.length || !synthOIData) {
      return;
    }

    // Extract strike and greek data from synOiData
    const strikeList = synthOIData?.bars.strikes.slice() || [];
    let callValues: number[] = [];
    let putValues: number[] = [];

    if (synthOIData) {
      switch (selectedChartType) {
        case SynthOIChartType.Gamma:
          callValues = synthOIData.bars.cust.gamma.all.calls;
          putValues = synthOIData.bars.cust.gamma.all.puts;
          break;
        case SynthOIChartType.Delta:
          callValues = synthOIData.bars.cust.delta.all.calls;
          putValues = synthOIData.bars.cust.delta.all.puts;
          break;
        case SynthOIChartType.NetPositioning:
          callValues = synthOIData.bars.cust.net_positioning.calls;
          putValues = synthOIData.bars.cust.net_positioning.puts;
          break;
        case SynthOIChartType.OpenInterest:
          callValues = synthOIData.bars.oi.calls;
          putValues = synthOIData.bars.oi.puts;
          break;
      }
    }

    const newGreekMetrics: GreekMetric[] = [];
    // Filter strikes based on the current visible y-domain
    // Add small padding to include strikes just outside the visible range
    const paddingFactor = 0.05; // 5% padding
    const paddedMin = yDomain[0] - (yDomain[1] - yDomain[0]) * paddingFactor;
    const paddedMax = yDomain[1] + (yDomain[1] - yDomain[0]) * paddingFactor;
    for (let i = 0; i < strikeList.length; i++) {
      const strike = strikeList[i];
      // Only include strikes that are within the visible y range with padding
      if (strike >= paddedMin && strike <= paddedMax) {
        // For Gamma, calculate the net value (call + put) to show as a single bar
        if (selectedChartType === SynthOIChartType.Gamma) {
          const netGamma = callValues[i] + putValues[i];
          newGreekMetrics.push({
            netGamma, // Store the net value for tooltip
            strike: strikeList[i],
            // Add color based on gamma value
            fill:
              netGamma >= 0
                ? theme.palette.equityHub.eh_live_price.posGexColor
                : theme.palette.equityHub.eh_live_price.negGexColor,
          });
        } else {
          newGreekMetrics.push({
            call: callValues[i],
            put: putValues[i],
            strike: strikeList[i],
          });
        }
      }
    }

    setGreekMetrics(newGreekMetrics);
  }, [
    synthOIData,
    isSynthOI,
    selectedEquity,
    ticks?.length,
    selectedChartType,
    yDomain,
  ]);

  const handleZoomChange = useCallback(
    (_event: Event, newValue: number | number[]) => {
      setZoomLevel(newValue as number);
    },
    [setZoomLevel],
  );

  if (!isSynthOI) {
    return null;
  }

  if (!ticks || ticks.length === 0) {
    return (
      <ErrorContent loading={loading} content="Waiting for Market Data..." />
    );
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          gap: 2,
          padding: '12px',
          background: theme.palette.background.default,
          borderRadius: '8px',
        }}
      >
        {/* Bar Chart */}
        <Box sx={{ width: '25%', height: '100%', gap: 2 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              margin={{
                top: 10,
                right: 5,
                left: isMobile ? 8 : 20,
                bottom: 5,
              }}
              data={greekMetrics}
              stackOffset={
                selectedChartType !== SynthOIChartType.OpenInterest
                  ? 'sign'
                  : 'none'
              }
              layout="vertical"
            >
              <YAxis
                dataKey="strike"
                allowDataOverflow
                domain={yDomain}
                tick={{ fontSize: 12 }}
                label={{
                  ...DEFAULT_Y_AXIS_STYLES,
                  value: 'Strike',
                  offset: 2,
                }}
                type="number"
                tickFormatter={(value) =>
                  formatAsCurrency(value, value >= 1000)
                }
                hide
                reversed
              />
              <XAxis
                type="number"
                tickCount={8}
                tick={{ fontSize: 11 }}
                tickFormatter={(value) => formatAsCompactNumber(value)}
                label={{
                  value: SynthOILiveChartTypeLabels.get(selectedChartType),
                  fontSize: 12,
                  offset: 1,
                  position: 'insideBottom',
                  fontWeight: 600,
                }}
                domain={['dataMin', 'dataMax']}
                scale="auto"
              />
              <ReferenceLine
                x={0}
                stroke={theme.palette.gray}
                strokeWidth={1}
                strokeOpacity={0.25}
              />
              <Tooltip
                content={
                  <BarChartTooltip
                    selectedGreekType={selectedChartType}
                    theme={theme}
                  />
                }
              />

              {/* For Gamma, show a single bar for net value */}
              {selectedChartType === SynthOIChartType.Gamma ? (
                <>
                  {/* Net Gamma Bar - using netValue with conditional coloring */}
                  <Bar
                    dataKey="netGamma"
                    fillOpacity={0.8}
                    strokeOpacity={0.8}
                    name="net_gamma"
                    isAnimationActive={false}
                    barSize={8}
                    layout="horizontal"
                  />
                </>
              ) : (
                <>
                  {/* Call Bars for non-Gamma views */}
                  <Bar
                    stackId={
                      selectedChartType !== SynthOIChartType.OpenInterest
                        ? 'call_put'
                        : 'oi_put'
                    }
                    dataKey="call"
                    fill={theme.palette.equityHub.eh_live_price.callColor}
                    name="call"
                    isAnimationActive={false}
                    barSize={8}
                    layout="horizontal"
                  />

                  {/* Put Bars for non-Gamma views */}
                  <Bar
                    stackId={
                      selectedChartType !== SynthOIChartType.OpenInterest
                        ? 'call_put'
                        : 'oi_call'
                    }
                    dataKey="put"
                    fill={theme.palette.equityHub.eh_live_price.putColor}
                    name="put"
                    isAnimationActive={false}
                    barSize={8}
                    layout="horizontal"
                  />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>

        {/* Price Line Chart */}
        <Box sx={{ width: '75%', height: '100%' }}>
          <ChartWatermarkContainer
            ref={ref}
            style={{
              height: '100%',
              width: '100%',
            }}
            size={20}
            offsetX={50}
            offsetY={40}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                margin={{
                  top: 10,
                  right: 50,
                  left: 10,
                  bottom: 5,
                }}
                data={ticks}
              >
                <CartesianGrid
                  strokeDasharray="1 10"
                  stroke={theme.palette.gray}
                />
                <XAxis
                  dataKey="epoch_millis"
                  tick={{ fontSize: 11 }}
                  label={{
                    value: 'Time',
                    fontSize: 12,
                    offset: 1,
                    position: 'insideBottom',
                    fontWeight: 600,
                  }}
                  tickCount={10}
                  tickFormatter={(value: any) =>
                    `${dayjs.tz(value, currentTimezone).format('LT')} `
                  }
                />
                <YAxis
                  allowDataOverflow
                  dataKey="price"
                  domain={yDomain}
                  tick={{ fontSize: 11 }}
                  label={{
                    ...DEFAULT_Y_AXIS_STYLES,
                    value: 'Price',
                    offset: 2,
                  }}
                  type="number"
                  tickFormatter={(value) =>
                    formatAsCurrency(value, value >= 1000)
                  }
                  ticks={yAxisTicks}
                />

                <Tooltip
                  content={
                    <CustomTooltip
                      currentTimezone={currentTimezone}
                      greekMetrics={greekMetrics}
                      selectedGreekType={selectedChartType}
                    />
                  }
                />

                <Line
                  type="basis"
                  dataKey="price"
                  name="Price"
                  stroke={theme.palette.core.price}
                  dot={false}
                  strokeWidth={2.5}
                  data={ticks}
                />

                {levels &&
                  showKeyLevels &&
                  levels
                    .filter((level) => level.value != null)
                    .map(({ field, value, name }) => (
                      <ReferenceLine
                        y={value}
                        key={`time-series-reference-line-${field}`}
                        stroke={
                          fieldColorMapping[
                            field.split(
                              ',',
                            )[0] as keyof typeof fieldColorMapping
                          ]
                        }
                        label={
                          <Label
                            position="right"
                            value={`${name} $${value.toLocaleString()}`}
                            fontSize={10}
                            style={{
                              textAnchor: 'end',
                              fill: fieldColorMapping[
                                field as keyof typeof fieldColorMapping
                              ],
                              textShadow: `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`,
                            }}
                          />
                        }
                        isFront
                      />
                    ))}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWatermarkContainer>
        </Box>

        {/* Y-Axis Zoom Level Slider */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography
            variant="caption"
            sx={{ textAlign: 'center', fontSize: '0.7rem' }}
          >
            Price Zoom
          </Typography>
          <Slider
            size="small"
            orientation="vertical"
            getAriaLabel={() => 'Y Axis Zoom'}
            value={zoomLevel}
            sx={{
              maxHeight: '95%',
              '& .MuiSlider-markLabel': {
                fontSize: '0.7rem',
              },
            }}
            onChange={handleZoomChange}
            step={null}
            marks={ZOOM_LEVELS.map((level) => ({
              value: level,
              label: level.toString(),
            }))}
            min={0}
            max={5}
          />
        </Box>
      </Box>
    </div>
  );
};

interface BarChartTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    name: string;
    payload: GreekMetric;
  }>;
  selectedGreekType: SynthOIChartType;
  theme: any;
}

const BarChartTooltip: React.FC<BarChartTooltipProps> = ({
  active,
  payload,
  selectedGreekType,
  theme,
}) => {
  if (!active || !payload || payload.length === 0) return null;

  const data = payload[0].payload;
  const callValue = data.call;
  const putValue = data.put;
  const strikePrice = data.strike;
  const netGamma = data.netGamma; // For Gamma view

  const formatGreekName = (type: SynthOIChartType) =>
    SynthOILiveChartTypeLabels.get(type) ?? type;

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        gap: '6px',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.gray}`,
        borderRadius: '5px',
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'bold',
          borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          paddingBottom: '4px',
          marginBottom: '6px',
        }}
      >
        Strike: {formatAsCurrency(strikePrice)}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {selectedGreekType === SynthOIChartType.Gamma ? (
          // For Gamma view, show net value
          <Box
            sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}
          >
            <Typography
              variant="body2"
              sx={{
                color:
                  netGamma != null && netGamma >= 0
                    ? theme.palette.equityHub.eh_live_price.posGexColor
                    : theme.palette.equityHub.eh_live_price.negGexColor,
                fontWeight: 500,
              }}
            >
              Total {formatGreekName(selectedGreekType)}:
            </Typography>
            <Typography variant="body1">
              {formatAsCompactNumber(netGamma)}
            </Typography>
          </Box>
        ) : (
          // For other views, show call and put separately
          <>
            <Box
              sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.equityHub.eh_live_price.callColor,
                  fontWeight: 500,
                }}
              >
                Call {formatGreekName(selectedGreekType)}:
              </Typography>
              <Typography variant="body1">
                {formatAsCompactNumber(callValue)}
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.equityHub.eh_live_price.putColor,
                  fontWeight: 500,
                }}
              >
                Put {formatGreekName(selectedGreekType)}:
              </Typography>
              <Typography variant="body1">
                {formatAsCompactNumber(putValue)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
  }>;
  label?: number;
  hoveredPrice?: number | null;
  currentTimezone: string;
  greekMetrics: GreekMetric[];
  selectedGreekType: SynthOIChartType;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  currentTimezone,
  greekMetrics,
  selectedGreekType,
}) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    const price = payload[0].value;

    // Find the closest strike price to the current price
    let closestGreekData: GreekMetric | null = null;
    let minDistance = Number.MAX_VALUE;

    // Only iterate if greekMetrics is not empty
    if (greekMetrics && greekMetrics.length > 0) {
      greekMetrics.forEach((metric) => {
        // Ensure metric is properly typed as GreekMetric
        if ('strike' in metric) {
          const strike = parseFloat(String(metric.strike));
          const distance = Math.abs(strike - price);
          if (distance < minDistance) {
            minDistance = distance;
            closestGreekData = metric;
          }
        }
      });
    }

    const formatGreekName = (type: SynthOIChartType) =>
      SynthOILiveChartTypeLabels.get(type) ?? type;

    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '12px',
          gap: '6px',
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.gray}`,
          borderRadius: '5px',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 'bold',
            borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
            paddingBottom: '4px',
            marginBottom: '6px',
          }}
        >
          {dayjs.tz(label, currentTimezone).format('LT')}
        </Typography>

        <Typography sx={{ marginBottom: '6px' }}>
          <span style={{ color: theme.palette.primary.main }}>Price:</span>{' '}
          {formatAsCurrency(price)}
        </Typography>

        {closestGreekData != null && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                borderTop: `1px solid ${alpha(
                  theme.palette.primary.main,
                  0.3,
                )}`,
                paddingTop: '6px',
                marginTop: '2px',
                marginBottom: '6px',
              }}
            >
              Closest Strike:{' '}
              {formatAsCurrency(
                parseFloat(String((closestGreekData as GreekMetric)?.strike)),
              )}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              {selectedGreekType === SynthOIChartType.Gamma ? (
                // For Gamma, show net value with appropriate coloring
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        ((closestGreekData as GreekMetric).netGamma || 0) >= 0
                          ? theme.palette.equityHub.eh_live_price.posGexColor
                          : theme.palette.equityHub.eh_live_price.negGexColor,
                      fontWeight: 500,
                    }}
                  >
                    Total {formatGreekName(selectedGreekType)}:
                  </Typography>
                  <Typography variant="body1">
                    {formatAsCompactNumber(
                      (closestGreekData as GreekMetric).netGamma || 0,
                    )}
                  </Typography>
                </Box>
              ) : (
                // For other views, show call and put separately
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.equityHub.eh_live_price.callColor,
                        fontWeight: 500,
                      }}
                    >
                      Call {formatGreekName(selectedGreekType)}:
                    </Typography>
                    <Typography variant="body1">
                      {formatAsCompactNumber(
                        (closestGreekData as GreekMetric).call,
                      )}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.equityHub.eh_live_price.putColor,
                        fontWeight: 500,
                      }}
                    >
                      Put {formatGreekName(selectedGreekType)}:
                    </Typography>
                    <Typography variant="body1">
                      {formatAsCompactNumber(
                        (closestGreekData as GreekMetric).put,
                      )}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Paper>
    );
  }

  return null;
};
