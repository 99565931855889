// all numeric values except price should be between 0-1
import { LegacyEquity } from './equityhub';

export type CompassSymbolData = {
  price: number;
  sym: string;
  equity?: LegacyEquity;
  bollingerBand?: number;
  ivRank?: number;
  ivPct?: number;
  rrPercentile?: number;
  callSkewPercentile?: number;
  putSkewPercentile?: number;
  oneMonthRv?: number;
  oneMonthIv?: number;
  garchRank?: number;
  skewRank?: number;
  nextExpCallVolumePercent?: number;
  nextExpPutVolumePercent?: number;
  nextExpGammaPercent?: number;
  nextExpDeltaPercent?: number;
  rsi?: number;
  proximityToCallWall?: number;
  proximityToPutWall?: number;
};

export type CompassDesc = {
  [key in StrategyCompassXYZAxis]: string;
};

// the enum values below should exactly match the keys of CompassSymbolData
export enum StrategyCompassXYZAxis {
  // Compass
  Bollinger = 'bollingerBand',
  Rsi = 'rsi',
  RRPercentile = 'rrPercentile',
  CallSkewPercentile = 'callSkewPercentile',
  PutSkewPercentile = 'putSkewPercentile',
  IvRank = 'ivRank',
  IvPct = 'ivPct',
  SkewRank = 'skewRank',
  OneMonthRv = 'oneMonthRv',
  OneMonthIv = 'oneMonthIv',
  GarchRank = 'garchRank',
  NextExpiryGammaPercent = 'nextExpGammaPercent',
  NextExpiryDeltaPercent = 'nextExpDeltaPercent',
  NextExpiryCallVolumePercent = 'nextExpCallVolumePercent',
  NextExpiryPutVolumePercent = 'nextExpPutVolumePercent',
  ProximityToCallWall = 'proximityToCallWall',
  ProximityToPutWall = 'proximityToPutWall',
  None = 'none',
}

// adding put skew percentile and call skew percentile
// change skew rank to risk reversal percentile

export const convertAxisToDataKey = (axis: StrategyCompassXYZAxis) =>
  axis as CompassSymbolDataKey;

export type CompassSymbolDataKey = Exclude<
  keyof CompassSymbolData,
  'sym' | 'price' | 'equity'
>;

export type CompassDataResponse = {
  bollingerBand: number;
  price: string;
  rsi: string;
  sym: string;
};

export type CompassChartData = {
  x: number;
  y: number;
  z?: number;
  sym: string;
  unroundedX: number;
  unroundedY: number;
  unroundedZ?: number;
  symData: CompassSymbolData;
  hidden: boolean;
};

export enum CompassSeverity {
  VERY_SLIGHT = 0,
  SLIGHT = 1,
  MILD = 2,
  NEUTRAL_LOW = 3,
  NEUTRAL = 4,
  NEUTRAL_HIGH = 5,
  MODERATE = 6,
  HIGH = 7,
  VERY_HIGH = 8,
}

export const DEFAULT_COMPASS_COLORS = {
  posColor: '#00d3b0',
  negColor: '#d80082',
};

export enum StrategyCompassMode {
  Compass = 'compass',
  Freeform = 'freeform',
}

export const StrategyCompassModeXMap = new Map<
  StrategyCompassMode,
  StrategyCompassXYZAxis[]
>([
  [
    StrategyCompassMode.Compass,
    [
      // first in the list is default
      StrategyCompassXYZAxis.SkewRank,
      StrategyCompassXYZAxis.Rsi,
      StrategyCompassXYZAxis.Bollinger,
    ],
  ],
  [
    StrategyCompassMode.Freeform,
    [
      // first in the list is default
      StrategyCompassXYZAxis.IvPct,
      StrategyCompassXYZAxis.OneMonthIv,
      StrategyCompassXYZAxis.OneMonthRv,
      StrategyCompassXYZAxis.NextExpiryDeltaPercent,
      StrategyCompassXYZAxis.NextExpiryGammaPercent,
      StrategyCompassXYZAxis.NextExpiryCallVolumePercent,
      StrategyCompassXYZAxis.NextExpiryPutVolumePercent,
      StrategyCompassXYZAxis.ProximityToCallWall,
      StrategyCompassXYZAxis.ProximityToPutWall,
      StrategyCompassXYZAxis.GarchRank,
      StrategyCompassXYZAxis.IvRank,
      StrategyCompassXYZAxis.SkewRank,
      StrategyCompassXYZAxis.RRPercentile,
      StrategyCompassXYZAxis.CallSkewPercentile,
      StrategyCompassXYZAxis.PutSkewPercentile,
      StrategyCompassXYZAxis.Rsi,
      StrategyCompassXYZAxis.Bollinger,
    ],
  ],
]);

export const StrategyCompassModeYMap = new Map<
  StrategyCompassMode,
  StrategyCompassXYZAxis[]
>([
  [
    StrategyCompassMode.Compass,
    [
      // first in the list is default
      StrategyCompassXYZAxis.IvRank,
      StrategyCompassXYZAxis.IvPct,
    ],
  ],
  [
    StrategyCompassMode.Freeform,
    [
      // first in the list is default
      StrategyCompassXYZAxis.ProximityToCallWall,
      StrategyCompassXYZAxis.ProximityToPutWall,
      StrategyCompassXYZAxis.OneMonthIv,
      StrategyCompassXYZAxis.OneMonthRv,
      StrategyCompassXYZAxis.NextExpiryDeltaPercent,
      StrategyCompassXYZAxis.NextExpiryGammaPercent,
      StrategyCompassXYZAxis.NextExpiryCallVolumePercent,
      StrategyCompassXYZAxis.NextExpiryPutVolumePercent,
      StrategyCompassXYZAxis.GarchRank,
      StrategyCompassXYZAxis.IvRank,
      StrategyCompassXYZAxis.SkewRank,
      StrategyCompassXYZAxis.IvPct,
      StrategyCompassXYZAxis.RRPercentile,
      StrategyCompassXYZAxis.CallSkewPercentile,
      StrategyCompassXYZAxis.PutSkewPercentile,
      StrategyCompassXYZAxis.Rsi,
      StrategyCompassXYZAxis.Bollinger,
    ],
  ],
]);

export const StrategyCompassModeZMap = new Map<
  StrategyCompassMode,
  StrategyCompassXYZAxis[]
>([
  [
    StrategyCompassMode.Freeform,
    [
      // first in the list is default
      StrategyCompassXYZAxis.None,
      StrategyCompassXYZAxis.ProximityToCallWall,
      StrategyCompassXYZAxis.ProximityToPutWall,
      StrategyCompassXYZAxis.OneMonthIv,
      StrategyCompassXYZAxis.OneMonthRv,
      StrategyCompassXYZAxis.NextExpiryDeltaPercent,
      StrategyCompassXYZAxis.NextExpiryGammaPercent,
      StrategyCompassXYZAxis.NextExpiryCallVolumePercent,
      StrategyCompassXYZAxis.NextExpiryPutVolumePercent,
      StrategyCompassXYZAxis.GarchRank,
      StrategyCompassXYZAxis.IvRank,
      StrategyCompassXYZAxis.SkewRank,
      StrategyCompassXYZAxis.IvPct,
      StrategyCompassXYZAxis.RRPercentile,
      StrategyCompassXYZAxis.CallSkewPercentile,
      StrategyCompassXYZAxis.PutSkewPercentile,
      StrategyCompassXYZAxis.Rsi,
      StrategyCompassXYZAxis.Bollinger,
    ],
  ],
]);

export const StrategyCompassXYZAxisReadableMap = new Map([
  [StrategyCompassXYZAxis.OneMonthIv, '1 Month Implied Vol'],
  [StrategyCompassXYZAxis.OneMonthRv, '1 Month Realized Vol'],
  [StrategyCompassXYZAxis.NextExpiryDeltaPercent, 'Next Exp Delta %'],
  [StrategyCompassXYZAxis.NextExpiryGammaPercent, 'Next Exp Gamma %'],
  [
    StrategyCompassXYZAxis.NextExpiryCallVolumePercent,
    'Next Exp Call Volume %',
  ],
  [StrategyCompassXYZAxis.NextExpiryPutVolumePercent, 'Next Exp Put Volume %'],
  [StrategyCompassXYZAxis.ProximityToCallWall, 'Proximity to Call Wall'],
  [StrategyCompassXYZAxis.ProximityToPutWall, 'Proximity to Put Wall'],
  [StrategyCompassXYZAxis.GarchRank, 'Garch Rank'],
  [StrategyCompassXYZAxis.IvRank, 'IV Rank'],
  [StrategyCompassXYZAxis.IvPct, 'IV Percentile'],
  [StrategyCompassXYZAxis.RRPercentile, 'Risk Reversal Percentile'],
  [StrategyCompassXYZAxis.SkewRank, 'Risk Reversal Rank'],
  [StrategyCompassXYZAxis.CallSkewPercentile, 'Call Skew Percentile'],
  [StrategyCompassXYZAxis.PutSkewPercentile, 'Put Skew Percentile'],
  [StrategyCompassXYZAxis.Rsi, 'RSI'],
  [StrategyCompassXYZAxis.Bollinger, 'Bollinger Band %'],
  [StrategyCompassXYZAxis.None, 'None'],
]);

export const StrategyCompassXYZAxisNotPercents: Set<StrategyCompassXYZAxis> =
  new Set([StrategyCompassXYZAxis.Rsi]);

export const ENABLED_MODES = [
  StrategyCompassMode.Compass,
  StrategyCompassMode.Freeform,
];

export interface CompassSavedView {
  id: string;
  name: string;
  xAxis: StrategyCompassXYZAxis;
  yAxis: StrategyCompassXYZAxis;
  zAxis?: StrategyCompassXYZAxis;
}

export interface CompassZoomDomain {
  x: [number, number];
  y: [number, number];
}
