import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import initWasm from 'parquet-wasm';
import { parquetWasmInitPromise } from '../states/oi';

const useWasmParquet = () => {
  const [wasmInitPromise, setWasmInitPromise] = useRecoilState(
    parquetWasmInitPromise,
  );

  // Caller's await the returned promise to ensure wasm has been initialized only once
  const getWasmPromise = useCallback(async () => {
    if (wasmInitPromise != null) {
      return wasmInitPromise;
    }
    const promise = initWasm();
    setWasmInitPromise(promise);
    return promise;
  }, [wasmInitPromise]);

  return { getWasmPromise };
};

export default useWasmParquet;
