import { STREAM_HOST_URL } from '../../config';
import { Filter, TnsFlowSummary, TnsFlowHighlights } from '../../types/tape';
import { encodeURIJson } from '../../util';
import { getFiltersForPayload, makeTnsFlowSummary } from '../../util/tape';
import useLog from '../useLog';

const useTapeFeed = () => {
  const { fetchAPIWithLog } = useLog('useTapeFeed');

  /**
   * Fetches the flow summary from the API and transforms it to include the 'total' field.
   * @param filters - Array of filters to apply to the API request.
   * @returns A promise that resolves to TnsFlowSummary.
   */
  const fetchFlowSummary = async (
    filters: Filter[],
  ): Promise<TnsFlowSummary> => {
    const response = await fetchAPIWithLog(
      `sg/tns_flow_sum?filters=${encodeURIJson(getFiltersForPayload(filters))}`,
      { host: STREAM_HOST_URL },
    );

    if (response.error) {
      throw new Error(
        'Failed to fetch flow summary. Your login may have expired. Try refreshing the page or contact us if the issue persists.',
      );
    }

    return makeTnsFlowSummary(response);
  };

  /**
   * Fetches the flow highlights from the API
   * @returns A promise that resolves to TnsFlowHighlights.
   */
  const fetchFlowHighlights = async (): Promise<TnsFlowHighlights> => {
    const response = await fetchAPIWithLog(`sg/tns_highlights`, {
      host: STREAM_HOST_URL,
    });

    if (response.error) {
      throw new Error(
        'Failed to fetch flow highlights. Your login may have expired. Try refreshing the page or contact us if the issue persists.',
      );
    }

    return response as TnsFlowHighlights;
  };

  return {
    fetchFlowSummary,
    fetchFlowHighlights,
  };
};

export default useTapeFeed;
