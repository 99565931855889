import { useCallback } from 'react';
import useLog from '../useLog';

// Multiply by 2 because we have streams of both puts and calls.
// We have roughly one entry every 5 seconds.
const HALF_HOUR = 720; // 2 * (60 / 5)  * 30
const useTrending = () => {
  const { fetchAPIWithLog } = useLog('useTrending');

  const getTrending = useCallback(async () => {
    return await fetchAPIWithLog(`v3/trending?`);
  }, []);

  const getTrendingSparkLines = useCallback(async (syms: string[]) => {
    // Fetch the last half-hour of data for the symbol
    const symStr = syms.map(encodeURIComponent).join('-');
    return await fetchAPIWithLog(
      `v4/latestHiro?syms=${symStr}&all=1&limit=${HALF_HOUR}`,
    );
  }, []);

  return { getTrending, getTrendingSparkLines };
};

export default useTrending;
