import dayjs from 'dayjs';
import {
  getSubLevelFromMembership,
  getSubscriptionLevel,
  isAdmin,
} from './auth';
import { UserDetails, SubLevel, IVolTab, ProductType } from '../../types';
import { IVOL_APPCUES } from '../../config/iVol';
import { isBloomberg } from '../bloomberg';

export const HIRO_TUTORIAL_FLOW_CONTENT_ID = isBloomberg()
  ? '73d9066a-d630-474d-b1a9-50b3ab26b796'
  : '4935b65e-f8e5-463e-84ed-92f9972b6bd0';
export const STOCK_SCREENER_FLOW_CONTENT_ID =
  'e305090f-e2f3-4eba-8c4a-d8a8d2a84d2e';
export const SCANNERS_FLOW_CONTENT_ID = 'e92b8e92-9302-4e24-b677-be36dd8c323e';
const FOUNDERS_NOTES_FLOW_CONTENT_ID = 'c522d920-1275-4e57-80b9-e20f9bfae5d6';
const EQUITY_HUB_FLOW_CONTENT_ID = '53f819d9-f3f8-43ff-aa50-115ce64cfa7d';
const TRACE_FLOW_CONTENT_ID = 'c2ea654e-f277-421c-87d6-f41bbb16d7af';
const INDICES_CONTENT_ID = 'd6fe97e5-aa93-4921-93ec-de914ed3d1cd';
const TAPE_CONTENT_ID = '74b54ab7-4558-4f21-ac58-e3ba2d8bc695';

export const tutorialFlowIdForLocation = (
  productType: ProductType | null,
  searchParams?: URLSearchParams,
) => {
  if (productType === ProductType.HIRO) {
    return HIRO_TUTORIAL_FLOW_CONTENT_ID;
  }
  if (productType === ProductType.IMPLIED_VOL) {
    const tab = searchParams?.get('tab');
    switch (tab) {
      case IVolTab.TermStructure:
        return IVOL_APPCUES[IVolTab.TermStructure];
      case IVolTab.VolSkew:
        return IVOL_APPCUES[IVolTab.VolSkew];
      default:
        return IVOL_APPCUES[IVolTab.FixedStrikeMatrix];
    }
  }
  if (productType === ProductType.SCANNERS) {
    return SCANNERS_FLOW_CONTENT_ID;
  }
  if (productType === ProductType.FOUNDERS_NOTES) {
    return FOUNDERS_NOTES_FLOW_CONTENT_ID;
  }
  if (productType === ProductType.EQUITYHUB) {
    return EQUITY_HUB_FLOW_CONTENT_ID;
  }
  if (
    productType === ProductType.TRACE ||
    productType === ProductType.INTERNAL_OPEN_INTEREST
  ) {
    return TRACE_FLOW_CONTENT_ID;
  }
  if (productType === ProductType.INDICES) {
    return INDICES_CONTENT_ID;
  }
  if (productType === ProductType.TAPE) {
    return TAPE_CONTENT_ID;
  }
  return undefined;
};

export const getAppcues: any = () => {
  return (window as any).Appcues;
};

export const logAppcuesIdentify = (userDetails: UserDetails | undefined) => {
  if (userDetails == null || getAppcues() == null) {
    return false;
  }

  return isBloomberg()
    ? logAppcuesIdentifyBbg(userDetails)
    : logAppcuesIdentifyWp(userDetails);
};

const logAppcuesIdentifyWp = (userDetails: UserDetails | undefined) => {
  const wordpress = userDetails?.wordpress;

  if (
    userDetails == null ||
    wordpress == null ||
    (wordpress.active_memberships?.length ?? 0) === 0
  ) {
    return false;
  }

  const isActiveFreeTrial = (subscription: any) => {
    const trialFlag = parseInt(subscription.trial);
    if (
      subscription.created_at == null ||
      isNaN(parseInt(subscription.trial_days)) ||
      isNaN(trialFlag) ||
      trialFlag <= 0 ||
      parseInt(subscription.prorated_trial) === 1
    ) {
      return false;
    }
    const trialDateStart = dayjs(subscription.created_at);
    const trialEnd = trialDateStart.add(
      parseInt(subscription.trial_days),
      'days',
    );
    return dayjs() <= trialEnd;
  };

  try {
    const subLevel = getSubscriptionLevel(userDetails);
    const membershipLevel = SubLevel[subLevel];
    const highestMembership = (wordpress.active_memberships ?? []).find(
      (m: any) => getSubLevelFromMembership(m) === subLevel,
    );
    const freeTrial = wordpress.recent_subscriptions?.some((t: any) =>
      isActiveFreeTrial(t),
    );
    const metadata = {
      userType: 'web',
      createdAt: dayjs(wordpress.registered_at).valueOf(),
      planTier: membershipLevel,
      role: isAdmin(userDetails) ? 'Admin' : freeTrial ? 'Trial' : 'Subscriber',
      accountId: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      location: wordpress.address['mepr-address-zip'],
      membershipPeriod: highestMembership?.period_type,
      membershipTitle: highestMembership?.title,
      betaUser: ['beta', 'internal'].includes(
        wordpress.profile.mepr_modern_dashboard_access,
      ),
      internalUser:
        wordpress.profile.mepr_modern_dashboard_access === 'internal',
    };
    getAppcues().identify(userDetails.sgId, metadata);
    return true;
  } catch (err) {
    // TODO: frontend logging should go somewhere we can see
    console.error('Appcues error:', err);
    return false;
  }
};

const logAppcuesIdentifyBbg = (userDetails: UserDetails | undefined) => {
  const bloomberg = userDetails?.bloomberg;

  if (userDetails == null || bloomberg == null) {
    return false;
  }

  try {
    const metadata = {
      userType: 'bloomberg',
      accountId: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      username: userDetails.username,
      firmId: bloomberg.firmId,
    };
    getAppcues().identify(userDetails.sgId, metadata);
    return true;
  } catch (err) {
    // TODO: frontend logging should go somewhere we can see
    console.error('Appcues error:', err);
    return false;
  }
};
