import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { LegacyEquity, ProductType, SynthOIEquity } from '../../../types';
import usePrices from '../../../hooks/equityhub/usePrices';
import { InfoButton, UpsellModal, WatchlistMultiSelect } from '../../shared';
import {
  ehActiveScannerState,
  ehActiveWatchlistsIdsState,
  ehScannerColumnOrderState,
  ehScannerColumnsVisibilityState,
  ehScannerFilterModelState,
  ehScannerPinnedColumnsState,
  ehUseDefaultColumnsState,
  pricesState,
  selectedEquitySymbolState,
  synthesizedEquitiesState,
  unpricedEquitiesState,
} from '../../../states/equityhub';
import { watchlistsState } from '../../../states';
import {
  getColumnsForScanner,
  getEquitiesForScanner,
  hasKeyEquityFields,
  minVolumeThresholdFilter,
} from '../../../util';
import {
  EquityTableType,
  useEquityGridColumns,
} from './useEquityScannerGridColumns';
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from '@spotgamma/x-data-grid-premium';
import { useSetSym } from '../../../hooks';
import { Scanner } from '../../../types';
import {
  defaultColumnScannerVisibility,
  scannerFields,
  EQUITYHUB_UPSELL,
} from '../../../config';
import useAuth from '../../../hooks/auth/useAuth';
import useActiveScanner from '../../../hooks/scanners/useActiveScanner';
import { scannerSortModelState } from '../../../states/scanners';
import { EquityScannerGrid } from '../../stock_scanner/EquityScannerGrid';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import PanelFoldButton from 'components/shared/PanelFoldButton';
import ScannersSection from 'components/stock_scanner/ScannersSection';
import useSynthOi from 'hooks/equityhub/useSynthOi';

export const EquityhubStockScanner = () => {
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const equities = useRecoilValue(synthesizedEquitiesState);
  const watchlists = useRecoilValue(watchlistsState);
  const setPrices = useSetRecoilState(pricesState);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    ehActiveWatchlistsIdsState,
  );
  const [dpiRange, setDPIRange] = useState<number[] | undefined>(undefined);
  const { hasAccessToProduct } = useAuth();
  const hasEquityhubAccess = hasAccessToProduct(ProductType.EQUITYHUB);
  const [selectedEquitySymbol, setSelectedSymbol] = useRecoilState(
    selectedEquitySymbolState,
  );
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);

  const useDefaultColumns = useRecoilValue(ehUseDefaultColumnsState);
  const setColumnVisibilityModel = useSetRecoilState(
    ehScannerColumnsVisibilityState,
  );
  const { setSym } = useSetSym();

  const { activeScanner } = useActiveScanner(ehActiveScannerState);
  const { getPrices } = usePrices();
  const theme = useTheme();

  const { isSynthOI } = useSynthOi();

  const { columns: gridCols, columnGroups: gridColGroups } =
    useEquityGridColumns(
      theme,
      dpiRange,
      EquityTableType.StockScreener,
      !hasEquityhubAccess
        ? [...equities.values()]
            .filter((e) => !hasKeyEquityFields(e))
            .map((e) => e.sym)
        : undefined,
    );

  useEffect(() => {
    if (isSynthOI) {
      return;
    }
    const dpiArray = [...equities.values()].map((d) => d.dpi);
    setDPIRange([Math.min(...dpiArray), Math.max(...dpiArray)]);
  }, [equities, isSynthOI, setDPIRange]);

  useEffect(() => {
    if (useDefaultColumns) {
      setColumnVisibilityModel(
        activeScanner
          ? getColumnsForScanner(activeScanner)
          : defaultColumnScannerVisibility,
      );
    }
  }, [useDefaultColumns, activeScanner]);

  const scannerData = useMemo(() => {
    // filters data by search/scanner
    let resultData = [...equities.values()];

    if (activeWatchlistIds.length > 0) {
      const activeWatchlists =
        watchlists?.filter((w) =>
          activeWatchlistIds.includes(w.id as number),
        ) ?? [];

      const syms: Set<string> = new Set(resultData.map((w) => w.sym));
      const watchlistSyms = new Set(activeWatchlists.flatMap((w) => w.symbols));

      resultData = [...watchlistSyms]
        .filter((s) => syms.has(s))
        .map((s) => equities.get(s) as LegacyEquity | SynthOIEquity);
    }

    if (activeScanner) {
      // Apply default filters of (call vol > 5k) and (total options vol > 10k)
      resultData = getEquitiesForScanner(activeScanner, resultData);
      if (activeScanner !== Scanner.CROSS_ASSET_SUMMARY) {
        resultData = resultData.filter(minVolumeThresholdFilter);
      }
    }

    return hasEquityhubAccess
      ? resultData
      : resultData.slice().sort((a, b) => {
          const aFull = hasKeyEquityFields(a) ? 1 : 0;
          const bFull = hasKeyEquityFields(b) ? 1 : 0;
          return bFull - aFull;
        });
  }, [
    activeScanner,
    activeWatchlistIds,
    watchlists,
    equities,
    hasEquityhubAccess,
  ]);

  useEffect(() => {
    async function fetchVisiblePriceData() {
      const newPrices: Map<string, number> = await getPrices([]);
      setPrices((prices) => new Map([...prices, ...newPrices]));
    }
    fetchVisiblePriceData();
  }, [getPrices, setPrices, unpricedEquities]);

  const setSelectedRow = (row: LegacyEquity | SynthOIEquity) => {
    const newSym = row.sym;
    setSelectedSymbol(newSym);
    setSym(newSym, ProductType.EQUITYHUB);
  };

  const isRowDisabled = (row: LegacyEquity | SynthOIEquity) => {
    return !hasEquityhubAccess && !row.live;
  };

  const onRowClick = (
    params: GridRowParams,
    _event: MuiEvent,
    _details: GridCallbackDetails,
  ) => {
    const row = params.row as LegacyEquity | SynthOIEquity;
    if (isRowDisabled(row)) {
      setUpsellOpen(true);
    } else {
      setSelectedRow(row);
    }
  };

  return (
    <Box
      sx={{
        margin: 'auto',
        padding: 3,
        height: '100%',
        maxHeight: '100vh',
      }}
    >
      <EquityScannerGrid
        currentSym={selectedEquitySymbol}
        columns={gridCols}
        data={scannerData}
        columnGroups={gridColGroups}
        onRowClick={onRowClick}
        activeScanner={activeScanner}
        useDefaultColumnState={ehUseDefaultColumnsState}
        columnVisibilityModelState={ehScannerColumnsVisibilityState}
        scannerPinnedColumnsState={ehScannerPinnedColumnsState}
        scannerSortModelState={scannerSortModelState}
        filterModelState={ehScannerFilterModelState}
        activeWatchlistIdsState={ehActiveWatchlistsIdsState}
        columnOrderState={ehScannerColumnOrderState}
        defaultColumnOrder={scannerFields}
        customSettings={
          <Box>
            <InfoButton key="eh-levels-info" articleKey="eh-levels-info" />
            <WatchlistMultiSelect
              activeWatchlistIds={activeWatchlistIds}
              setActiveWatchlistIds={setActiveWatchlistIds}
            />
            <PanelFullscreenButton panelType="bottom" />
            <PanelFoldButton panelType="bottom" />
          </Box>
        }
        customScannerSettings={
          <ScannersSection
            activeWatchlistIdsState={ehActiveWatchlistsIdsState}
            activeScannerState={ehActiveScannerState}
          />
        }
        showScannerInfo
      />
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </Box>
  );
};
