import { Box, Button, Stack } from '@mui/material';
import TapeDatagrid from './TapeDatagrid';
import useTapeWebsocket from 'hooks/optionsFeed/useTapeWebsocket';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { hiroManifestState, timezoneState, userIsLoggedInState } from 'states';
import { decode } from '@msgpack/msgpack';
import {
  TAPE_DEFAULT_FILTER_ID,
  TAPE_FETCH_BATCH_SIZE,
  TAPE_MAX_TOTAL_ROWS,
  TapeDatagridTab,
  TapeFilterTab,
} from 'config/tape';
import { STREAM_HOST_URL } from 'config/shared';
import {
  addOrUpdateFilters,
  dedupeTnsRows,
  getFiltersForPayload,
  getFilterValue,
  getOptionFeedDataRow,
  getSortedOptionsFeedData,
  validateTnsRow,
} from 'util/tape';
import {
  dayjs,
  encodeURIJson,
  fetchRawAPI,
  getParsedManifest,
} from 'util/shared';
import { useLog } from 'hooks';
import {
  Filter,
  FilterConfig,
  FilterOperator,
  FilterPanelProps,
  HiroTapeView,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
  TnsSortedDataWindow,
} from 'types/tape';
import {
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridRowScrollEndParams,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { useTapeColumns } from './useTapeColumns';
import FiltersContainer from './filters/FiltersContainer';
import useEquities from 'hooks/equityhub/useEquities';
import {
  tnsDataGridActiveTabState,
  tnsEquityScannersDataState,
} from 'states/tape';
import useTapeFilters from 'hooks/optionsFeed/useTapeFilters';
import useToast from 'hooks/useToast';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components/shared';
import DataGridFlowSummary from './summary/DataGridFlowSummary';
import useHomeContent from 'hooks/home/useHomeContent';
import { Earnings, HiroTimeRange, ProductType } from 'types';
import ContractDataContainer from './ContractDataContainer';
import { debounce } from 'lodash';
import IsInstitutionalModal from 'components/shared/IsInstitutionalModal';
import useAuth from 'hooks/auth/useAuth';
import { HiroTab } from 'config/hiro';

const TabPanelWrapper = memo(({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflow: 'hidden',
      height: '100%',
      maxHeight: '100%',
      width: '100%',
    }}
  >
    {children}
  </Box>
));

TabPanelWrapper.displayName = 'TabPanelWrapper';

interface OptionsFeedProps {
  filterPanelProps: FilterPanelProps;
  disableWatchlistSelector?: boolean;
  tnsFlowLiveState: RecoilState<boolean>;
  activeCustomFilterState: RecoilState<FilterConfig | undefined>;
  newFilterItemsState: RecoilState<Filter[]>;
  columnSortModel: RecoilState<GridSortModel>;
  savedFiltersState: RecoilState<FilterConfig[]>;
  filterActiveTabState: RecoilState<TapeFilterTab>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  contractsColumnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  contractsSortModelState: RecoilState<GridSortModel>;
  contractsColumnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  contractsColumnSizingState: RecoilState<OptionsFeedColumnSizes>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  customGridSlotProps?: GridSlotsComponentsProps;
  hiroView?: HiroTapeView;
}

const FLUSH_THRESHOLD = 0.9; // Flush when we reach 90% of max
const RETAIN_RATIO = 0.7; // Keep 70% of rows after flush

const Tape = ({
  filterPanelProps,
  activeCustomFilterState,
  savedFiltersState,
  newFilterItemsState,
  columnVisibilityState,
  contractsColumnVisibilityState,
  contractsSortModelState,
  contractsColumnOrderState,
  contractsColumnSizingState,
  columnOrderState,
  columnSortModel,
  columnSizingState,
  filterActiveTabState,
  tnsFlowLiveState,
  customGridSlotProps,
  hiroView,
}: OptionsFeedProps) => {
  const userLoggedIn = useRecoilValue(userIsLoggedInState);
  const [instModalOpen, setInstModalOpen] = useState<boolean>(false);

  const { logError } = useLog('OptionsFeed');
  const { getEarningsForSyms } = useHomeContent();
  const { openToast } = useToast();
  const { getEquityScanners } = useEquities();
  const { productsWithAccess } = useAuth();
  const currentTimezone = useRecoilValue(timezoneState);
  const isTnsFlowLive = useRecoilValue(tnsFlowLiveState);
  const [activeGridTab, setActiveGridTab] = useRecoilState(
    tnsDataGridActiveTabState,
  );
  const [manifest, setManifest] = useRecoilState(hiroManifestState);

  const sortModel = useRecoilValue(columnSortModel);
  const [eqScannersLoading, setEqScannersLoading] = useState<boolean>(false);
  const setEqScanners = useSetRecoilState(tnsEquityScannersDataState);

  const [histError, setHistError] = useState<string | null>(null);
  const [histDataLoading, setHistDataLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [histDataOffset, setHistDataOffset] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false); // To prevent multiple concurrent fetches

  const [combinedRows, setCombinedRows] = useState<RawOptionFeedData[]>([]);
  const [sortedDataWindow, setSortedDataWindow] = useState<
    TnsSortedDataWindow | undefined
  >(undefined);

  const [earningsList, setEarningsList] = useState<Earnings[]>([]);

  const [earningsLoading, setEarningsLoading] = useState<boolean>(false);

  const [activeCustomFilter, setActiveCustomFilter] = useRecoilState(
    activeCustomFilterState,
  );
  const [newFilters, setNewFilters] = useRecoilState(newFilterItemsState);

  const currentFilters = useMemo(() => {
    return activeCustomFilter?.value ?? newFilters;
  }, [activeCustomFilter, newFilters]);

  const setSavedFilters = useSetRecoilState(savedFiltersState);

  const { fetchSavedFilters } = useTapeFilters();

  const { columns, contractColumns } = useTapeColumns({
    earningsList,
  });

  useEffect(() => {
    async function fetchManifest() {
      const response = await fetchRawAPI(`manifest`, {
        host: STREAM_HOST_URL,
      });
      const data = await response.json();
      setManifest(getParsedManifest(data));
    }

    if (manifest == null) {
      fetchManifest();
    }
  }, [manifest]);

  useEffect(() => {
    if (filterPanelProps.currentSym != null) {
      setNewFilters((prev: Filter[]) => {
        return addOrUpdateFilters(prev, [
          {
            id: TAPE_DEFAULT_FILTER_ID.Symbols,
            value: [filterPanelProps.currentSym!],
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.Underlying,
          },
        ]);
      });
    }
  }, [filterPanelProps.currentSym]);

  // Handle live data updates
  const handleLiveDataUpdate = useCallback(
    (newRows: RawOptionFeedData[]) => {
      if (isFetching) {
        return; // avoid race condition by writing to combined rows simultaneously
      }
      const newValidatedRows = newRows.filter((r) =>
        validateTnsRow(r, currentFilters, sortedDataWindow),
      );
      setCombinedRows((prevRows) =>
        getLimitedRows(
          getSortedOptionsFeedData(
            dedupeTnsRows(prevRows, newValidatedRows),
            sortModel,
          ),
        ),
      );
    },
    [sortModel, currentFilters, sortedDataWindow, isFetching],
  );

  const { error: socketError } = useTapeWebsocket({
    filters: currentFilters,
    setRows: handleLiveDataUpdate,
    sortedDataWindow,
    isTnsFlowLive,
  });

  const getUpdatedFiltersWithTime = (
    prevFilters: Filter[],
    from: number,
    to: number,
    currentTZ: string,
  ) => {
    const now = dayjs().tz(currentTZ);

    const diffMins = (t1: number, t2: number, mins = 1) => {
      return Math.abs(t1 - t2) > mins * 60 * 1_000;
    };

    const withinMins = (t1: number, t2: number, mins = 1) => {
      return Math.abs(t1 - t2) <= mins * 60 * 1_000;
    };

    // Extract previous filter values
    const prevFromDateTime = getFilterValue<number | null>(
      prevFilters,
      TAPE_DEFAULT_FILTER_ID.MinDateTime,
      null,
    );

    const prevToTime = getFilterValue<number | null>(
      prevFilters,
      TAPE_DEFAULT_FILTER_ID.MaxDateTime,
      null,
    );

    // Determine if 'from' should be updated
    const newFilters: Filter[] = [];

    if (
      prevFromDateTime == null ||
      (diffMins(from, prevFromDateTime, 1) && from !== prevFromDateTime)
    ) {
      newFilters.push({
        field: OptionsFeedColumnKey.Time,
        value: from,
        id: TAPE_DEFAULT_FILTER_ID.MinDateTime,
        operator: FilterOperator.GreaterThanOrEqual,
      });
    }

    // Update 'to' filter
    const newTo =
      prevToTime == null && diffMins(to, now.valueOf(), 1) && to !== prevToTime
        ? to
        : null;

    if (
      prevToTime ? diffMins(to, prevToTime, 1) : diffMins(to, now.valueOf(), 1)
    ) {
      newFilters.push({
        field: OptionsFeedColumnKey.Time,
        value: withinMins(to, now.valueOf(), 1) ? null : newTo,
        id: TAPE_DEFAULT_FILTER_ID.MaxDateTime,
        operator: FilterOperator.LessThanOrEqual,
      });
    }

    if (newFilters.length > 0) {
      return addOrUpdateFilters(prevFilters, newFilters);
    }

    return prevFilters;
  };

  // Debounced function to update hiro time filters as they're frequent
  const debouncedUpdateHiroTimeFilters = useCallback(
    debounce(
      (
        hiroTimeRange: HiroTimeRange | undefined,
        currentTZ: string,
        isCustomFilterUpdate = false,
      ) => {
        if (
          hiroTimeRange != null &&
          hiroTimeRange.from != null &&
          hiroTimeRange.to != null
        ) {
          const { from, to } = hiroTimeRange;

          if (isCustomFilterUpdate) {
            setActiveCustomFilter((prev) => {
              return {
                ...prev,
                value: getUpdatedFiltersWithTime(
                  prev?.value ?? [],
                  from,
                  to,
                  currentTZ,
                ),
              } as FilterConfig;
            });
          } else {
            setNewFilters((prev: Filter[]) =>
              getUpdatedFiltersWithTime(prev, from, to, currentTZ),
            );
          }
        }
      },
      300, // 300ms debounce delay
    ),
    [],
  );

  useEffect(() => {
    // clear out date time settings from filters panel upon initial load
    if (filterPanelProps.hiroTnsChartTimeInSync) {
      setNewFilters((prev: Filter[]) =>
        prev.filter(
          (f) =>
            f.id !== TAPE_DEFAULT_FILTER_ID.MinDateTime &&
            f.id !== TAPE_DEFAULT_FILTER_ID.MaxDateTime &&
            f.id !== TAPE_DEFAULT_FILTER_ID.Expirations,
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (filterPanelProps.hiroTnsChartTimeInSync) {
      debouncedUpdateHiroTimeFilters(
        filterPanelProps.hiroTimeRange,
        currentTimezone,
      );
    }

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedUpdateHiroTimeFilters.cancel();
    };
  }, [
    filterPanelProps.hiroTimeRange,
    filterPanelProps.hiroTnsChartTimeInSync,
    currentTimezone,
    debouncedUpdateHiroTimeFilters,
  ]);

  const getLimitedRows = useCallback((newRows: RawOptionFeedData[]) => {
    if (newRows.length > TAPE_MAX_TOTAL_ROWS * FLUSH_THRESHOLD) {
      const keepCount = Math.floor(TAPE_MAX_TOTAL_ROWS * RETAIN_RATIO);
      return newRows.slice(0, keepCount);
    }
    return newRows;
  }, []);

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: FilterConfig[] = await fetchSavedFilters(false); // fetch only noSym:false filters
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10_000,
        });
      }
    }
    if (userLoggedIn && productsWithAccess.has(ProductType.TAPE)) {
      fetchFilters();
    }
  }, []);

  useEffect(() => {
    async function fetchEquityScanners() {
      try {
        setEqScannersLoading(true);
        const sc = await getEquityScanners(); // Fetch all equity scanners data
        setEqScanners(sc);
      } catch (err) {
        console.error(err);
        openToast({
          message:
            'Something went wrong while fetching equity scanners data. Refresh the page to retry or contact us if the issue persists.',
          type: 'error',
          duration: 10_000,
        });
      } finally {
        setEqScannersLoading(false);
      }
    }
    fetchEquityScanners();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setEarningsLoading(true);

      try {
        const earnings = await getEarningsForSyms([]);
        setEarningsList(earnings);
      } catch (err) {
        logError(err, 'fetch earnings list data');
      } finally {
        setEarningsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getTnsFeed = async (
    filters: Filter[],
    sorting: GridSortModel,
    offset: number = 0,
    size: number = TAPE_FETCH_BATCH_SIZE,
  ): Promise<RawOptionFeedData[]> => {
    let result: RawOptionFeedData[] = [];
    try {
      setIsFetching(true);
      setHistError(null);
      setHistDataLoading(true);

      const response = await fetchRawAPI(
        `sg/tns_feed?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}&sorting=${encodeURIJson(sorting)}&offset=${offset}&limit=${size}`,
        {
          host: STREAM_HOST_URL,
        },
      );

      if (!response.ok) {
        throw new Error(`Error fetching tns_feed data: ${response.statusText}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const histData: any = decode(arrayBuffer);

      result = histData.map((d: any[]) => getOptionFeedDataRow(d));

      setHasMore(result.length >= size);
    } catch (err) {
      logError(err);
      setHistError('Something went wrong while fetching data...');
    } finally {
      setHistDataLoading(false);
      setIsFetching(false);
    }
    return result;
  };

  useEffect(() => {
    const fetchData = async (filters: Filter[], sorting: GridSortModel) => {
      const tnsFeedData = await getTnsFeed(filters, sorting);
      setHistDataOffset(tnsFeedData.length);
      setCombinedRows(tnsFeedData);
      setSortedDataWindow({
        sortModel: sorting,
        firstRow: tnsFeedData[0],
        lastRow: tnsFeedData[tnsFeedData.length - 1],
      });
    };

    // re-fetch data when filters, sorting or live flow changes
    fetchData(currentFilters, sortModel);
  }, [currentFilters, sortModel, isTnsFlowLive]);

  const handleOnRowsScrollEnd = useCallback<
    NonNullable<DataGridPremiumProps['onRowsScrollEnd']>
  >(
    async (_params: GridRowScrollEndParams) => {
      if (isFetching || !hasMore) {
        return; // Prevent multiple fetches or fetching when no more data
      }

      const tnsFeedData = await getTnsFeed(
        currentFilters,
        sortModel,
        histDataOffset,
      );

      const newCombinedRows = getLimitedRows(
        getSortedOptionsFeedData(
          dedupeTnsRows(combinedRows, tnsFeedData),
          sortModel,
        ),
      );

      setCombinedRows(newCombinedRows);
      setSortedDataWindow((prev) => ({
        sortModel: prev?.sortModel ?? [],
        firstRow: newCombinedRows[0],
        lastRow: newCombinedRows[newCombinedRows.length - 1],
      }));
      setHistDataOffset((prevOffset) => prevOffset + tnsFeedData.length);
    },
    [
      currentFilters,
      combinedRows,
      sortModel,
      histDataOffset,
      isFetching,
      hasMore,
    ],
  );

  return (
    <Stack sx={{ gap: 3, width: '100%', height: '100%', overflowY: 'hidden' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'hidden',
          display: 'flex',
          gap: '8px',
        }}
      >
        <FiltersContainer
          activeCustomFilterState={activeCustomFilterState}
          activeTabState={filterActiveTabState}
          newFilterItemsState={newFilterItemsState}
          savedFiltersState={savedFiltersState}
          filterPanelProps={filterPanelProps}
          tnsFlowLiveState={tnsFlowLiveState}
        />

        <Stack
          sx={{
            backgroundColor: 'background.paper',
            paddingTop: '6px',
            paddingX: '16px',
            paddingBottom: '24px',
            gap: '12px',
            borderRadius: '8px',
            display: 'flex',
            overflow: 'hidden',
            flexGrow: 1,
            maxWidth: '100%',
          }}
        >
          {hiroView === HiroTab.FlowData ? (
            <TapeDatagrid
              rows={combinedRows}
              columns={columns}
              filterPanelOpenState={filterPanelProps.openState}
              flowLiveState={tnsFlowLiveState}
              columnSortModelState={columnSortModel}
              columnVisibilityState={columnVisibilityState}
              columnOrderState={columnOrderState}
              columnSizingState={columnSizingState}
              customGridSlotProps={customGridSlotProps}
              isError={socketError != null || histError != null}
              isLoading={
                earningsLoading || histDataLoading || eqScannersLoading
              }
              onRowsScrollEnd={handleOnRowsScrollEnd}
            />
          ) : hiroView === HiroTab.ContractData ? (
            <ContractDataContainer
              columns={contractColumns}
              filters={currentFilters}
              filterPanelOpenState={filterPanelProps.openState}
              customGridSlotProps={customGridSlotProps}
              contractsColumnVisibilityState={contractsColumnVisibilityState}
              contractsSortModelState={contractsSortModelState}
              contractsColumnOrderState={contractsColumnOrderState}
              contractsColumnSizingState={contractsColumnSizingState}
              flowLiveState={tnsFlowLiveState}
            />
          ) : (
            <TabContext value={activeGridTab}>
              <DataGridFlowSummary filters={currentFilters} />
              <Tabs
                options={
                  new Map(Object.values(TapeDatagridTab).map((t) => [t, t]))
                }
                onChange={(_evt, newTab: TapeDatagridTab) =>
                  setActiveGridTab(newTab)
                }
                isFullWidth
                tabButtonSx={{ minHeight: 40 }}
                tabListSx={{ minHeight: 40 }}
              />
              <TabPanel
                value={TapeDatagridTab.FlowData}
                sx={{
                  padding: 0,
                  overflow: 'hidden',
                }}
              >
                <TabPanelWrapper>
                  <TapeDatagrid
                    rows={combinedRows}
                    columns={columns}
                    columnSortModelState={columnSortModel}
                    filterPanelOpenState={filterPanelProps.openState}
                    flowLiveState={tnsFlowLiveState}
                    columnVisibilityState={columnVisibilityState}
                    columnOrderState={columnOrderState}
                    columnSizingState={columnSizingState}
                    customGridSlotProps={customGridSlotProps}
                    isError={socketError != null || histError != null}
                    isLoading={
                      earningsLoading || histDataLoading || eqScannersLoading
                    }
                    onRowsScrollEnd={handleOnRowsScrollEnd}
                  />
                </TabPanelWrapper>
              </TabPanel>
              <TabPanel
                value={TapeDatagridTab.ContractData}
                sx={{
                  padding: 0,
                  overflow: 'hidden',
                }}
              >
                <TabPanelWrapper>
                  <ContractDataContainer
                    columns={contractColumns}
                    filters={currentFilters}
                    contractsColumnVisibilityState={
                      contractsColumnVisibilityState
                    }
                    flowLiveState={tnsFlowLiveState}
                    filterPanelOpenState={filterPanelProps.openState}
                    customGridSlotProps={customGridSlotProps}
                    contractsSortModelState={contractsSortModelState}
                    contractsColumnOrderState={contractsColumnOrderState}
                    contractsColumnSizingState={contractsColumnSizingState}
                  />
                </TabPanelWrapper>
              </TabPanel>
            </TabContext>
          )}
        </Stack>
      </Box>
      {/* Only when user is logged out we allow them to edit market agreement here, otherwise it's in their preferences page */}
      {!userLoggedIn && (
        <>
          <Button
            size="small"
            variant="outlined"
            sx={{
              textTransform: 'none',
              fontSize: { xs: 12, md: 14 },
              width: 'fit-content',
            }}
            onClick={() => setInstModalOpen(true)}
          >
            Edit Market Data Agreement
          </Button>
          <IsInstitutionalModal
            open={instModalOpen}
            setOpen={setInstModalOpen}
          />
        </>
      )}
    </Stack>
  );
};

export default Tape;
