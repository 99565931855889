import { QueryClient } from '@tanstack/react-query';
import { OpenAPI } from 'gen/stream-api/requests/core/OpenAPI';

OpenAPI.BASE =
  import.meta.env.VITE_OPENAPI_STREAM_API_OVERRIDE ??
  (import.meta.env.VITE_STREAMING_HOST
    ? `https://${import.meta.env.VITE_STREAMING_HOST}`
    : undefined) ??
  'http://localhost:3000';

Object.defineProperty(OpenAPI, 'TOKEN', {
  get: () =>
    import.meta.env.VITE_OPENAPI_STREAM_API_TOKEN_OVERRIDE ??
    localStorage.sgToken,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: true,
    },
  },
});

// automatically resolve application/octet-stream response.blob() into
// arrayBuffer to keep all async fetching code within the useQuery's async fetch
// block
OpenAPI.interceptors.response.use(async (rsp: Respose) => {
  if (
    rsp.status !== 204 &&
    rsp.headers.get('Content-Type')?.includes('application/octet-stream')
  ) {
    rsp.blob = rsp.arrayBuffer;
  }
  return rsp;
});
