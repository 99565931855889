import { GlobalStyles } from '@mui/material';

export const HideSupportStyle = () => (
  <GlobalStyles
    styles={{
      'iframe#launcher': {
        visibility: 'hidden',
      },
    }}
  />
);
