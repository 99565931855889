import { useRecoilValue } from 'recoil';

import { Logtail } from '@logtail/browser';
import { fetchAPI, getEnv, getSubscriptionLevel, isAdmin } from '../util';
import { foundersNotesSelectedNoteState } from '../states/foundersNotes';
import { isObject } from 'lodash';
import { isMobileState, screenHeightState, userDetailsState } from '../states';
import { FetchAuxOptions, SubLevel } from '../types';
import { useSearchParams } from 'react-router-dom';
import { ILogtailLog, ILogtailOptions } from '@logtail/types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const LOG_FLUSH_INTERVAL_MS = 10_000;

class SGLogtail extends Logtail {
  public constructor(sourceToken: string, options?: Partial<ILogtailOptions>) {
    super(sourceToken, options);
    // copied from logtail
    // they somehow make a fetch request even if there are no logs to post
    // we override that behavior by extending the class and returning if there are no logs
    const smartSync = async (logs: ILogtailLog[]): Promise<ILogtailLog[]> => {
      if (logs.length === 0) {
        return logs;
      }

      const res = await fetch(this._options.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this._sourceToken}`,
        },
        body: JSON.stringify(logs),
        keepalive: true,
      });

      if (res.ok) {
        return logs;
      }

      throw new Error(res.statusText);
    };
    this.setSync(smartSync);
  }
}

let logtail: SGLogtail | null = null;

try {
  logtail = new SGLogtail(import.meta.env.VITE_LOGTAIL_API_KEY ?? '', {
    retryCount: 0,
    syncMax: 1,
  });
} catch (err) {
  // logtail error during init should not bring down the whole site
  console.error(err);
  if (getEnv() === 'development') {
    alert('There was an error with logtail (development only alert)');
  }
}

export const useLog = (component: string) => {
  const userDetails = useRecoilValue(userDetailsState);
  const selectedFoundersNote = useRecoilValue(foundersNotesSelectedNoteState);
  const [searchParams] = useSearchParams();
  const screenHeight = useRecoilValue(screenHeightState);
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();

  const logError = (message: any, caller?: string, extra = {}) => {
    try {
      console.error(message);
      if (getEnv() === 'development') {
        return;
      }
      const messageStr = messageToString(message);
      if (messageStr.includes('TokenExpiredError')) {
        // suppressed because this is very common and expected
        return;
      }
      if (message instanceof Error) {
        extra = { ...extra, stack: message.stack?.split('\n').slice(1) };
      }
      logtail?.error(messageStr, getLogParams(caller, extra));
    } catch (e) {
      // in case logtail errors out
      console.error(e);
    }
  };

  const logWarn = (message: any, caller?: string, extra = {}) => {
    try {
      console.warn(message);
      if (getEnv() === 'development') {
        return;
      }
      logtail?.warn(messageToString(message), getLogParams(caller, extra));
    } catch (e) {
      console.error(e);
    }
  };

  const messageToString = (message: any) => {
    if (message instanceof String) {
      return message;
    }
    return !(message instanceof Error) && isObject(message)
      ? JSON.stringify(message)
      : message.toString();
  };

  const getLogParams = (caller?: string, extra = {}): any => {
    return {
      email: userDetails?.email,
      uuid: userDetails?.sgId,
      selectedSym: searchParams.get('sym'),
      path: window.location.pathname,
      component,
      loggedIn: userDetails != null,
      caller,
      selectedFoundersNote: selectedFoundersNote?.title,
      env: getEnv(),
      wpId: userDetails?.id,
      isAdmin: isAdmin(userDetails),
      subLevel: SubLevel[getSubscriptionLevel(userDetails)],
      lastDeployAt: import.meta.env.VITE_LAST_DEPLOY_AT ?? 'n/a',
      gitSha: import.meta.env.VITE_GIT_SHA ?? 'n/a',
      url: window.location.href,
      ...extra,
    };
  };

  const fetchAPIWithLog = async (
    endpoint: string,
    auxiliaryOptions: FetchAuxOptions = {},
  ): Promise<any> => {
    const response = await fetchAPI(endpoint, auxiliaryOptions);
    if (response.error != null) {
      const rawResponse =
        response.response instanceof Response ? response.response : undefined;
      logError(response.error, endpoint, {
        status: rawResponse?.status,
        statusText: rawResponse?.statusText,
        type: rawResponse?.type,
      });
    }
    return response;
  };

  const getTimeSignatureText = (style = {}) => (
    <>
      {import.meta.env.VITE_GIT_SHA && import.meta.env.VITE_VERSION_NUM && (
        <Typography style={style}>
          {(import.meta.env.VITE_GIT_SHA ?? 'unavailable').slice(0, 10)} (
          {import.meta.env.VITE_VERSION_NUM ?? '-1'})
        </Typography>
      )}

      {import.meta.env.VITE_LAST_DEPLOY_AT && (
        <Typography style={style}>
          {import.meta.env.VITE_LAST_DEPLOY_AT ?? 'unavailable'}
        </Typography>
      )}
    </>
  );

  const getTimeSignature = (posAbsolute = true) => {
    const style = {
      color: theme.palette.text.disabled,
      fontSize: isMobile ? '9px' : '11px',
      display: 'block',
      margin: '3px',
    };

    const pos = posAbsolute
      ? { position: 'absolute', bottom: '20px', left: '10px' }
      : {};

    return (
      <Box
        key="time-signature"
        className="hoverable"
        sx={{
          ...// screens below 775 have the version overlap the sidebar links when using position absolute
          (screenHeight < 775
            ? {
                marginTop: '15px',
                marginBottom: '5px',
                marginLeft: '0px',
                marginRight: '0px',
              }
            : pos),
        }}
      >
        {getTimeSignatureText(style)}
      </Box>
    );
  };

  const nonProdDebugLog = (...args: any[]) => {
    if (getEnv() === 'production' && !isAdmin(userDetails)) {
      return;
    }
    console.debug(`${component}:`, ...args);
  };

  return {
    logError,
    logWarn,
    fetchAPIWithLog,
    logtail,
    getTimeSignature,
    getTimeSignatureText,
    nonProdDebugLog,
  };
};

export default useLog;
