import { useCallback, useMemo } from 'react';
import { ProductType, UserDetailsPayload } from 'types';
import {
  checkPermissions,
  getCachedToken,
  isAdmin,
  isBBEnvAvailable,
  isValidTokenOverrideTimeframe,
} from '../../util';
import { productAccessState, userDetailsState } from '../../states';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import useLog from '../useLog';
import useUserDetails from '../user/useUserDetails';
import { OH_TOKEN } from 'config/user';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const QUERY_KEYS = {
  userProfile: 'userProfile',
};

const useAuth = () => {
  const navigate = useNavigate();
  const products = useRecoilValue(productAccessState);
  const { initUserDetails } = useUserDetails();
  const userDetails = useRecoilValue(userDetailsState);
  const queryClient = useQueryClient();

  const { logError, fetchAPIWithLog } = useLog('useAuth');

  const productsWithAccess = useMemo(() => new Set(products), [products]);

  const hasAccessToProduct = (product: ProductType | null) => {
    if (userDetails == null && product === ProductType.INTEGRATIONS) {
      return false;
    }

    return (
      product == null ||
      productsWithAccess.has(product) ||
      isValidTokenOverrideTimeframe()
    );
  };

  const getLogin = async (username: string, password: string) => {
    return await fetchAPIWithLog(`v1/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    });
  };

  const setTokensAndUserDetails = useCallback(
    (token: string | null, payload: UserDetailsPayload | null) => {
      if (token == null || payload == null) {
        setToken(null);
        initUserDetails(undefined);
        // Invalidate the query cache when logging out
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userProfile] });
        return;
      }
      setToken(token);
      payload.watchlists = payload.watchlists ?? [];
      initUserDetails(payload);
      // Update the query cache with the new user data
      queryClient.setQueryData([QUERY_KEYS.userProfile], payload);
    },
    [initUserDetails, queryClient],
  );

  const fetchUserProfile = async () => {
    const sgToken = getCachedToken();
    if (!sgToken) {
      throw new Error('No token available');
    }

    return await fetchAPIWithLog(`v1/sgMyself`, {
      headers: { Authorization: `Bearer ${sgToken}` },
    });
  };

  // Use react-query to cache and manage the user profile data
  const { refetch: refetchUserProfile } = useQuery({
    queryKey: [QUERY_KEYS.userProfile],
    queryFn: fetchUserProfile,
    enabled: false, // Don't run automatically, we'll trigger it manually
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
  });

  const validateCachedToken = useCallback(async () => {
    const sgToken = getCachedToken();
    if (isBBEnvAvailable()) {
      return;
    }

    try {
      // Use the cached data if available, or fetch new data
      const data = await queryClient.fetchQuery({
        queryKey: [QUERY_KEYS.userProfile],
        queryFn: fetchUserProfile,
        staleTime: 5 * 60 * 1000,
      });

      if (data?.code != null) {
        logError(
          `Nulling token due to validateCachedToken error: ${data.error?.name}`,
          'validateCachedToken',
          { error: data.error },
        );
        return setTokensAndUserDetails(null, null);
      } else if (data?.error != null) {
        if (data.error.name === 'TokenExpiredError') {
          // Silently log the error, but no need to confuse end-users with this information.
          logError(data.error, 'validateCached');
        } else {
          logError(
            `Nulling token due to validateCachedToken error: ${data.error?.name}`,
            'validateCachedToken',
            { error: data.error },
          );
        }

        // If this failed to fetch, then abort.  The request to the endpoint never actually went through
        // sometimes will see 'TypeError: Failed to fetch'
        if (data.error.message?.includes('Failed to fetch')) {
          // fetchApi will automatically retry if this error occurs
          // in the exceedingly rare scenario in which it happens again, just return
          // never force logout a user if this error is the cause of the fetch fail
          return;
        }
        return setTokensAndUserDetails(null, null);
      }

      const { hasAccess, errorMessage } = checkPermissions(data);
      if (!hasAccess) {
        if (errorMessage != null) {
          logError(errorMessage, 'validateCachedToken');
        }
        logError(`Nulling token due hasAccess`, 'validateCachedToken', {
          hasAccess,
          errorMessage,
        });
        setTokensAndUserDetails(null, null);
        return navigate('/login');
      }

      setTokensAndUserDetails(sgToken, data);
    } catch (error) {
      logError('Failed to validate token', 'validateCachedToken', { error });
      setTokensAndUserDetails(null, null);
      return navigate('/login');
    }
  }, [setTokensAndUserDetails, logError, navigate, queryClient]);

  const userIsAdmin = useMemo(() => isAdmin(userDetails), [userDetails]);

  const getBbgSGToken = useCallback(async (oauthParams: any) => {
    return await fetchAPIWithLog(`bbg/login`, {
      method: 'POST',
      body: JSON.stringify({ oauthParams: oauthParams }),
    });
  }, []);

  const setToken = (token: string | null) => {
    if (token === OH_TOKEN) {
      throw new Error('Setting token to OH Token!');
    }
    if (token == null) {
      localStorage.removeItem('sgToken');
    } else {
      localStorage.setItem('sgToken', token);
    }
  };

  return {
    hasAccessToProduct,
    getLogin,
    setTokensAndUserDetails,
    validateCachedToken,
    productsWithAccess,
    userIsAdmin,
    getBbgSGToken,
    setToken,
    refetchUserProfile,
  };
};

export default useAuth;
