import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { FormControlLabel, Switch } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import SettingsPopout from '../../shared/SettingsPopout';
import {
  synthOIPCImpactLineSelection,
  synthOIPCImpactBarGroupSelection,
  isMobileState,
  synthOIPCImpactTypeState,
} from 'states';
import {
  SynthOIPCImpactBarGroups,
  SynthOIChartLines,
  SynthOIPCImpactChartType,
  SynthOIPCImpactBarGroup,
  SynthOIPCImpactLine,
  SynthOIPCImpactTypeLabels,
  SynthOIPCLineLabels,
  SynthOIPCLineTooltip,
  SynthOIPCImpactBarGroupTooltip,
} from 'types';
import InfoPopper from 'components/shared/InfoPopper';

const SynthOIChartTypeSelector = () => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [chartType, setChartType] = useRecoilState(synthOIPCImpactTypeState);

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: alpha(theme.palette.gray, 0.1),
        borderRadius: 2,
        padding: '10px',
      }}
    >
      <Typography>Chart Type</Typography>
      <ButtonGroup aria-label="Chart type">
        {[
          SynthOIPCImpactChartType.Gamma,
          SynthOIPCImpactChartType.Delta,
          SynthOIPCImpactChartType.OpenInterest,
        ].map((candidate: SynthOIPCImpactChartType) => (
          <Button
            key={candidate}
            size="small"
            sx={{
              backgroundColor:
                chartType === candidate
                  ? alpha(theme.palette.secondary.main, 0.25)
                  : 'transparent',
              '&:hover': {
                color: alpha(theme.palette.secondary.main, 1),
                borderColor: alpha(theme.palette.secondary.main, 1),
              },
              fontSize: isMobile ? 12 : 14,
              textTransform: 'none',
              width: '33.3%',
            }}
            onClick={() => {
              setChartType(candidate);
            }}
          >
            {SynthOIPCImpactTypeLabels.get(candidate)!}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
};

const SynthOIChartLineSelector = () => {
  const chartType = useRecoilValue(synthOIPCImpactTypeState);
  const lineTypes: SynthOIPCImpactLine[] = SynthOIChartLines.get(chartType)!;
  const [selected, setSelected] = useRecoilState(synthOIPCImpactLineSelection);
  return lineTypes.length > 0 ? (
    <Stack>
      <Typography>Lines</Typography>
      {lineTypes.map((l) => (
        <Stack
          key={l}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={selected.has(l)}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) =>
                  setSelected((prev) => {
                    if (prev.has(l)) {
                      prev.delete(l);
                    } else {
                      prev.add(l);
                    }
                    return new Set(prev);
                  })
                }
              />
            }
            label={<Typography>{SynthOIPCLineLabels.get(l)}</Typography>}
            labelPlacement="end"
          />
          {SynthOIPCLineTooltip.get(l) && (
            <InfoPopper
              contents={[{ description: SynthOIPCLineTooltip.get(l)! }]}
            />
          )}
        </Stack>
      ))}
    </Stack>
  ) : null;
};

const SynthOIChartBarGroupSelector = () => {
  const chartType = useRecoilValue(synthOIPCImpactTypeState);
  const barGroups: SynthOIPCImpactBarGroup[] =
    SynthOIPCImpactBarGroups.get(chartType)!;
  const [selected, setSelected] = useRecoilState(
    synthOIPCImpactBarGroupSelection,
  );
  return barGroups.length > 0 ? (
    <Stack>
      <Typography>Bars</Typography>
      {barGroups.map((barGroup) => (
        <Stack
          key={barGroup}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={selected.has(barGroup)}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) =>
                  setSelected((prev) => {
                    // Groups have mutual exclusivity. Remove everything in the group first
                    for (const group of barGroups) {
                      prev.delete(group);
                    }
                    prev.add(barGroup);
                    return new Set(prev);
                  })
                }
              />
            }
            label={<Typography>{barGroup}</Typography>}
            labelPlacement="end"
          />
          {SynthOIPCImpactBarGroupTooltip.get(barGroup) && (
            <InfoPopper
              contents={[
                { description: SynthOIPCImpactBarGroupTooltip.get(barGroup)! },
              ]}
            />
          )}
        </Stack>
      ))}
    </Stack>
  ) : null;
};

export const SynthOIPutCallImpactSettings = () => {
  const isMobile = useRecoilValue(isMobileState);
  return (
    <SettingsPopout
      title="Fixed Strike Table Settings"
      popperID="fixed-strike-controls"
      placement="bottom-end"
      sx={{
        width: isMobile ? '280px' : '400px',
      }}
    >
      <Stack sx={{ gap: '8px' }}>
        <SynthOIChartTypeSelector />
        <SynthOIChartLineSelector />
        <SynthOIChartBarGroupSelector />
      </Stack>
    </SettingsPopout>
  );
};
