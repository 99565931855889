export type MacroCalendarEvent = {
  event: string;
  date: string;
  country: string;
  actual: number | null;
  previous: number | null;
  change: number | null;
  changePercentage: number | null;
  estimate: number | null;
  impact: string;
};

export enum ImpactType {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export const IMPACT_OPTIONS = [
  ImpactType.HIGH,
  ImpactType.LOW,
  ImpactType.MEDIUM,
];

export enum HomeEventsCalendarTab {
  MACRO_ECONOMIC_CALENDAR = 'macro_economic_calendar',
  EARNINGS = 'earnings',
  EARNINGS_CHART = 'earnings_chart',
}

export enum HomeQuadrantTabId {
  LIVE_PRICES_CHART = 'live_prices_chart',
  SG_LEVELS = 'sg_levels',
  INDEX_METRICS = 'index_metrics',
  SUPP_RESISTANCE_LEVELS = 'supp_resistance_levels',
  INDEX_LEVELS = 'index_levels',
  AT_SPOTGAMMA = 'at_spotgamma',
  MACRO_ECONOMIC_CALENDAR = 'macro_economic_calendar',
  EARNINGS = 'earnings',
  EARNINGS_CHART = 'earnings_chart',
  SCANNER_SQUEEZE_CANDIDATES = 'scanner_squeeze_candidates',
  SCANNER_VRP_CANDIDATES = 'scanner_vrp_candidates',
  OCC_CHART = 'occ_chart',
  ZERO_DTE_EQUITY_VOL_CHART = 'zero_dte_equity_vol_chart',
  EQUITY_PUT_CALL_RATIO_CHART = 'equity_put_call_ratio_chart',
  EXP_CONCENTRATION_CHART = 'exp_concentration_chart',
  VIX_CHART = 'vix_chart',
}

export type FuturesSnapshotData = {
  lastClose?: number;
  lastPrice?: number;
  sym: string;
  target?: string;
};

export type EarningsCalendarEntry = {
  sym: string;
  company_name: string;
  cv: number;
  day: string;
  inHiro: boolean;
  period: string;
  pv: string;
  utc: string;
  activity_factor: number;
  confirmed: number;
  implied_move: number | null;
};
