import { useCallback, useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { LegacyEquity, ProductType, SynthOIEquity } from '../../types';
import { ONE_HOUR_MS, prevBusinessDay } from '../../util';
import useLog from '../useLog';
import poll from '../../util/poll';
import {
  workerState,
  equitiesFetchLoadingState,
  unpricedEquitiesState,
} from '../../states';
import useAuth from '../auth/useAuth';
import { useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useSynthOi from './useSynthOi';

const POLL_INTERVAL = 4 * ONE_HOUR_MS;
const EQUITIES_QUERY_KEY = 'equities';

const createEquitiesURL = (
  isSynthOI: boolean,
  hasScannersAccess: boolean,
  date: string | null = prevBusinessDay().format('YYYY-MM-DD'),
) => {
  const url = isSynthOI
    ? `synth_oi/v1/${hasScannersAccess ? '' : 'free_'}equities`
    : hasScannersAccess
    ? 'v4/equities'
    : 'v1/free_equities';
  return date != null && isSynthOI ? `${url}?date=${date}` : url;
};

const useEquities = () => {
  const worker = useRecoilValue(workerState);
  const setUnpricedEquities = useSetRecoilState(unpricedEquitiesState);
  const setEquitiesLoading = useSetRecoilState(equitiesFetchLoadingState);
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const { fetchAPIWithLog } = useLog('useEquities');
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');
  const queryClient = useQueryClient();

  const { isSynthOI } = useSynthOi();

  const convertDataToMap = (
    data: any,
  ): Map<string, LegacyEquity | SynthOIEquity> => {
    if (isSynthOI) {
      return new Map(data.map((e: any) => [e.sym, e as SynthOIEquity]));
    } else {
      return new Map(data.map((e: any) => [e.sym, e as LegacyEquity]));
    }
  };

  const getEquitiesUrl = useCallback(() => {
    return createEquitiesURL(isSynthOI, hasScannersAccess, date);
  }, [isSynthOI, hasScannersAccess, date]);

  // Use React Query to fetch equities
  const equitiesQuery = useQuery({
    queryKey: [EQUITIES_QUERY_KEY, isSynthOI, date],
    queryFn: async () => {
      try {
        const url = getEquitiesUrl();
        const data = await fetchAPIWithLog(url);
        return data;
      } catch (error) {
        console.error('Failed to fetch equities:', error);
        throw error;
      }
    },
    staleTime: POLL_INTERVAL / 2,
    refetchInterval: POLL_INTERVAL,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    enabled: true,
    initialDataUpdatedAt: 0, // Ensure first load is fresh
  });

  // Update loading state based on query status
  useEffect(() => {
    setEquitiesLoading(equitiesQuery.isLoading || equitiesQuery.isFetching);
  }, [equitiesQuery.isFetching, equitiesQuery.isLoading]);

  // Use effect to process the data when it changes
  useEffect(() => {
    const data = queryClient.getQueryData([
      EQUITIES_QUERY_KEY,
      isSynthOI,
      date,
    ]);
    if (data && Array.isArray(data) && data.length > 0) {
      const equitiesMap = convertDataToMap(data);
      setUnpricedEquities(equitiesMap);
    }
  }, [
    queryClient.getQueryState([EQUITIES_QUERY_KEY, isSynthOI, date])
      ?.dataUpdateCount,
    isSynthOI,
    date,
    setUnpricedEquities,
    queryClient,
  ]);

  // Outputs an object with keys being 'sym' and value being the 'name' of equity
  const getSymbols = useCallback(async () => {
    return await fetchAPIWithLog(
      `${isSynthOI ? 'synth_oi/' : ''}v1/eh_symbols`,
    );
  }, [isSynthOI, fetchAPIWithLog]);

  const getEquityScanners = useCallback(async () => {
    const suffix = hasScannersAccess
      ? 'v1/equityScanners'
      : 'v1/free_equityScanners';
    const url = `${isSynthOI ? 'synth_oi/' : ''}${suffix}`;
    return await fetchAPIWithLog(url);
  }, [isSynthOI, hasScannersAccess, fetchAPIWithLog]);

  const handlePollResponse = ({ json }: any, requestedURL: string) => {
    if ((json?.length ?? 0) === 0) {
      return;
    }
    const currentURL = createEquitiesURL(isSynthOI, hasScannersAccess);
    if (requestedURL !== currentURL) {
      console.log(`synth-oi: Ignoring stale result from ${requestedURL}`);
      return;
    }
    console.log('synth-oi: setting unpriced equities');

    const equitiesMap = convertDataToMap(json);
    setUnpricedEquities(equitiesMap);
    setEquitiesLoading(false);
  };

  const setupEquitiesPoller = useCallback(() => {
    return poll(
      worker,
      {
        url: getEquitiesUrl(),
        interval: POLL_INTERVAL,
        onResponse: handlePollResponse,
      },
      { maxRetries: 1 },
    );
  }, [worker, getEquitiesUrl]);

  const refetchEquities = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [EQUITIES_QUERY_KEY, isSynthOI, date],
    });
  }, [queryClient, isSynthOI, date]);

  return {
    getSymbols,
    getEquityScanners,
    setupEquitiesPoller,
    refetchEquities,
  };
};

export default useEquities;
