import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Stack } from '@mui/material';
import DataAgreementContainer from '../components/tape/DataAgreementContainer';
import TapeContainer from '../components/tape/TapeContainer';
import { userDetailsState, isInstitutionalLocalState } from '../states';
import { ProductType } from '../types';

export const TapePage = () => {
  const userDetails = useRecoilValue(userDetailsState);
  const isInstitutionalLocal = useRecoilValue(isInstitutionalLocalState);

  const render = useCallback(
    () =>
      userDetails?.isInstitutional === false ||
      isInstitutionalLocal === false ? (
        <TapeContainer />
      ) : (
        <Stack
          sx={{
            height: '100%',
            gap: 2,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DataAgreementContainer />
        </Stack>
      ),
    [userDetails?.isInstitutional, isInstitutionalLocal],
  );

  return (
    <AccessProtectedPage
      productType={ProductType.TAPE}
      renderContent={render}
    />
  );
};
