import { atom } from 'recoil';
import {
  DarkPoolSectorAverage,
  EquityPCRatio,
  OCC,
  OCCCustomerOptionType,
  OCCTransactionSize,
  RawOCC,
} from '../types/sentiment';
import { DEFAULT_BRUSH_ZOOM_CONFIG } from '../config';
import { BrushZoomConfig } from '../types';

export const sectorAverageDPIState = atom<DarkPoolSectorAverage[]>({
  key: 'sentiment-sectorAverageDPIState',
  default: [],
});

export const occDataState = atom<RawOCC[] | undefined>({
  key: 'sentiment-occDataState',
  default: undefined,
});

export const occCustomerOptionsTypeState = atom<OCCCustomerOptionType>({
  key: 'sentiment-occCustomerOptionsTypeState',
  default: OCCCustomerOptionType.BUY_CLOSE_CONTRACTS,
});

export const occTransactionSizeState = atom<OCCTransactionSize>({
  key: 'sentiment-occTransactionSizeState',
  default: OCCTransactionSize.ALL,
});

export const occChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'sentiment-occChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const occInitialDataState = atom<OCC[]>({
  key: 'sentiment-occInitialDataState',
  default: [],
});

export const priceDataState = atom<Map<string, number> | undefined>({
  key: 'sentiment-priceDataState',
  default: undefined,
});

export const equityPCChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'sentiment-equityPCChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const equityPCInitialDataState = atom<EquityPCRatio[]>({
  key: 'sentiment-equityPCInitialDataState',
  default: [],
});
