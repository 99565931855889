import { atom, selector } from 'recoil';
import {
  BrushZoomConfig,
  GammaImpact,
  GammaLevel,
  GroupedExpirationConcentration,
  Historical,
  IndexSymbol,
  IndexSymbols,
  IndicesContentType,
  MaxRealVol,
  OIVolume,
  OptionsRiskReversal,
  Quadrant,
  QuadrantId,
  Tilt,
  Vol,
  VolHistogram,
  ZeroDTE,
} from '../types/index';
import {
  IndicesContentGroup,
  DEFAULT_BRUSH_ZOOM_CONFIG,
  CONTENT_TYPE_LABEL_MAP,
} from '../config';
import { mergeQuadrantsWithDefaults } from '../util';
import {
  getDefaultSymOptions,
  DEFAULT_INDICES_QUADRANT_MAP,
} from '../util/indices';
import { userSettingsState } from './auth';

export const selectedIndexState = atom<IndexSymbol>({
  key: 'indices-selectedIndexState',
  default: IndexSymbol.SPX,
});

export const personalViewActiveState = atom<boolean>({
  key: 'indices-personalViewActiveState',
  default: false,
});

export const selectedTabGroupState = atom<IndicesContentGroup>({
  key: 'indices-selectedTabGroup',
  default: IndicesContentGroup.GREEKS,
});

export const selectedConcentrationIndicesState = atom<IndexSymbol[]>({
  key: 'indices-selectedConcentrationIndicesState',
  default: Array.from(IndexSymbols),
});

export const realTimeChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'indices-realTimeChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const expConcentrationChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'indices-expConcentrationChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const putCallImpactZoomConfigState = atom<BrushZoomConfig>({
  key: 'indices-putCallImpactZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const putCallImpactInitialDataState = atom<GammaImpact[]>({
  key: 'indices-putCallImpactInitialDataState',
  default: [],
});

export const vDivZoomConfigState = atom<BrushZoomConfig>({
  key: 'indices-vDivZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const vDivInitialDataState = atom<MaxRealVol[]>({
  key: 'indices-vDivInitialDataState',
  default: [],
});

export const volZoomConfigState = atom<BrushZoomConfig>({
  key: 'indices-volZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const volInitialDataState = atom<Vol[]>({
  key: 'indices-volInitialDataState',
  default: [],
});

export const volHistogramInitialDataState = atom<VolHistogram[]>({
  key: 'indices-volHistogramInitialDataState',
  default: [],
});

export const expConcentrationTableUnfoldState = atom<boolean>({
  key: 'indices-expConcentrationTableUnfoldState',
  default: false,
});

export const strikeTableUnfoldState = atom<boolean>({
  key: 'indices-strikeTableUnfoldState',
  default: false,
});

export const histChartUnfoldState = atom<boolean>({
  key: 'indices-histChartUnfoldState',
  default: false,
});

export const indicesEditModalOpenState = atom<boolean>({
  key: 'indices-indicesEditModalOpenState',
  default: false,
});

// This state returns user available quadrants for indices
export const indicesQuadrantsState = selector<Map<QuadrantId, Quadrant>>({
  key: 'indices-homeQuadrantsState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.indicesQuadrants != null) {
      const quadrantsMap = new Map<QuadrantId, Quadrant>(
        Object.entries(settings.indicesQuadrants).map(([key, value]) => [
          key as QuadrantId,
          value,
        ]),
      );

      return mergeQuadrantsWithDefaults(
        quadrantsMap,
        (_contentId: string) => IndexSymbol.SPX,
        (contentId: string) =>
          getDefaultSymOptions(contentId as IndicesContentType),
        (contentId: string) =>
          CONTENT_TYPE_LABEL_MAP.get(contentId as IndicesContentType)!,
      );
    }
    return DEFAULT_INDICES_QUADRANT_MAP;
  },
});

export const indicesActiveQuadrantTabsState = atom<
  Map<QuadrantId, string | undefined>
>({
  key: 'indices-activeQuadrantTabsState',
  default: new Map(),
});
