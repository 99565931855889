import { useCompassParams } from '../../../hooks/scanners/useCompassParams';
import { useCompassData } from '../../../hooks/scanners/useCompassData';
import { StrategyCompass } from './StrategyCompass';
import { StockScanner } from '../../../pages';
import { ResizableDashboardLayout } from 'layouts/ResizableDashboardLayout';
import { ProductType } from 'types/shared';
import StrategyCompassSidePanel from './StrategyCompassSidePanel';

export const StrategyCompassWithLayout = () => {
  const compassParams = useCompassParams();
  const compassData = useCompassData({
    compassParams,
  });

  return (
    <ResizableDashboardLayout
      dashboardId={ProductType.SCANNERS}
      mainContent={
        <StrategyCompass
          compassParams={compassParams}
          compassData={compassData}
        />
      }
      sidebarTopContent={
        <StrategyCompassSidePanel
          compassParams={compassParams}
          compassData={compassData}
        />
      }
      bottomContent={<StockScanner />}
      bottomContentHeaderName="Stock Scanner"
      customDefaultSizes={{
        main: 65,
        sidebar: 30,
        bottom: 35,
      }}
    />
  );
};
