import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MatrixTooltipInfo } from 'types';
import CellInfoContainer from './CellInfoContainer';

interface TableCellTooltipProps {
  expiryDate: number;
  strike: string;
  data: MatrixTooltipInfo[];
  children: JSX.Element;
}

const TableCellTooltip = ({
  expiryDate,
  strike,
  data,
  children,
}: TableCellTooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      enterTouchDelay={700} // show the tooltip after 700ms of touch
      leaveTouchDelay={3000} // tooltip will remain visible for 3 seconds after user stops touching
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.palette.shadows.paperBoxShadow,
            width: '240px',
          },
        },
      }}
      title={
        <CellInfoContainer
          key={data.map((v) => v.value)?.join('_')}
          expiryDate={expiryDate}
          strike={strike}
          data={data}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export default TableCellTooltip;
