import { useRecoilValue, useSetRecoilState } from 'recoil';
import OptionsFeed from './Tape';
import { Stack } from '@mui/material';
import DailyFlowOverview from './summary/DailyFlowOverview';
import { TAPE_DEFAULT_FILTER_ID } from '../../config/tape';
import { isMobileState } from '../../states';
import {
  tnsFlowSummaryExpandedState,
  tnsDailyOverviewExpandedState,
  tnsActiveCustomFilterState,
  tnsNewFilterState,
  tnsFlowLiveState,
  tnsFilterPanelContainerActiveTab,
  tnsSavedFiltersState,
  tnsColumnsSortModelState,
  tnsContractsColumnsSortModelState,
  tnsContractsColumnOrderState,
  tnsContractsColumnSizesState,
  tnsFilterPanelContainerOpenState,
  tnsColumnsVisibilityState,
  tnsContractsColumnsVisibilityState,
  tnsColumnOrderState,
  tnsColumnSizesState,
  tnsActiveWatchlistsIdsState,
} from '../../states/tape';
import {
  TopNameItem,
  Filter,
  FilterOperator,
  OptionsFeedColumnKey,
  FilterItem,
} from '../../types/tape';
import { addOrUpdateFilters } from '../../util/tape';

const TapeContainer = () => {
  const isMobile = useRecoilValue(isMobileState);
  const tnsFlowSummaryExpanded = useRecoilValue(tnsFlowSummaryExpandedState);
  const tnsDailyOverviewExpanded = useRecoilValue(
    tnsDailyOverviewExpandedState,
  );

  const setActiveCustomFilter = useSetRecoilState(tnsActiveCustomFilterState);
  const setCurrentFilters = useSetRecoilState(tnsNewFilterState);

  let minHeight = 1150;
  if (tnsFlowSummaryExpanded) {
    minHeight += 200;
  }
  if (tnsDailyOverviewExpanded) {
    minHeight += 200;
  }

  const onOverviewItemClick = (item: TopNameItem) => {
    const newFilters: Filter[] = [
      {
        id: TAPE_DEFAULT_FILTER_ID.Symbols,
        value: [item.underlying],
        operator: FilterOperator.IsAnyOf,
        field: OptionsFeedColumnKey.Underlying,
      },
      ...(item.values
        .flatMap((iVal) => iVal.filterItems)
        .filter(Boolean) as FilterItem[]),
    ];
    //  override filters instead of "merging" in with current filters
    setActiveCustomFilter(undefined);
    setCurrentFilters(addOrUpdateFilters([], newFilters));
  };

  return (
    <Stack
      sx={{
        height: isMobile ? '85vh' : '100%',
        minHeight: isMobile ? minHeight - 200 : minHeight,
        paddingBottom: '60px',
        width: '100%',
        gap: 3,
      }}
    >
      <DailyFlowOverview onItemClick={onOverviewItemClick} />
      <OptionsFeed
        tnsFlowLiveState={tnsFlowLiveState}
        filterActiveTabState={tnsFilterPanelContainerActiveTab}
        newFilterItemsState={tnsNewFilterState}
        savedFiltersState={tnsSavedFiltersState}
        activeCustomFilterState={tnsActiveCustomFilterState}
        columnSortModel={tnsColumnsSortModelState}
        contractsSortModelState={tnsContractsColumnsSortModelState}
        contractsColumnOrderState={tnsContractsColumnOrderState}
        contractsColumnSizingState={tnsContractsColumnSizesState}
        filterPanelProps={{
          openState: tnsFilterPanelContainerOpenState,
        }}
        columnVisibilityState={tnsColumnsVisibilityState}
        contractsColumnVisibilityState={tnsContractsColumnsVisibilityState}
        columnOrderState={tnsColumnOrderState}
        columnSizingState={tnsColumnSizesState}
        activeWatchlistIdsState={tnsActiveWatchlistsIdsState}
      />
    </Stack>
  );
};

export default TapeContainer;
