import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDateAndTime, mean } from '../../util';
import styled from 'styled-components';
import useReviewFoundersNotes from '../../hooks/foundersNotes/useReviewFoundersNotes';
import { FoundersNoteReviewData } from '../../types';

interface ViewFoundersNoteReviewProps {
  review: FoundersNoteReviewData;
}

export const ViewFoundersNoteReview = ({
  review,
}: ViewFoundersNoteReviewProps) => {
  const theme = useTheme();

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconEmpty': {
      color: theme.palette.info.main,
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
        gap: '20px',
        p: 4,
      }}
    >
      <Typography variant="body2" color="white">
        Review submitted {getDateAndTime(review?.ts)}
      </Typography>
      <StyledRating
        name="founders-note-review-rating"
        size="large"
        value={review.rating}
        readOnly={true}
      />
      <TextField
        type="text"
        id="founders-note-review-comment-view"
        name="founders-note-review-comment-view"
        label="Comments"
        multiline
        value={review.comments}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </Box>
  );
};

interface NoteReviewsAccordionProps {
  note: string;
  reviews: FoundersNoteReviewData[];
}

const NoteReviewsAccordion = ({ note, reviews }: NoteReviewsAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const averageRating = mean(reviews.map((review) => review.rating));
  const theme = useTheme();

  const AverageRatingStyle = styled(Rating)({
    marginLeft: 10,
    '& .MuiRating-iconFilled': {
      color: theme.palette.primary.main,
    },
    '& .MuiRating-iconEmpty': {
      color: theme.palette.primary.dark,
      opacity: 0.4,
    },
  });

  return (
    <Accordion
      key={`view-reviews-${note}`}
      sx={{ margin: '0px !important', p: 2 }}
      onChange={(_evt, expanded: boolean) => setExpanded(expanded)}
    >
      <AccordionSummary
        sx={{
          height: '40px',
          minHeight: '40px',
        }}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Typography variant="h2" sx={{ fontWeight: 600 }}>
          {note}
        </Typography>
        <AverageRatingStyle
          size="small"
          value={averageRating}
          readOnly={true}
        />
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            {reviews.map((review) => (
              <ViewFoundersNoteReview review={review} />
            ))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

interface FoundersNoteReviewsProps {
  onClose: () => void;
}

export const FoundersNoteReviews = ({ onClose }: FoundersNoteReviewsProps) => {
  const [data, setData] = useState<Map<string, FoundersNoteReviewData[]>>(
    new Map(),
  );
  const { getAllReviews } = useReviewFoundersNotes();

  useEffect(() => {
    async function getReviews() {
      const reviewData = await getAllReviews();
      setData(reviewData);
    }
    getReviews();
  }, [setData, getAllReviews]);

  return (
    <Box
      sx={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Button
        onClick={() => {
          onClose();
        }}
        sx={{ width: '100px' }}
      >
        {'<- Back'}
      </Button>
      <Box>
        {Array.from(data.entries()).map(([note, reviews]) => (
          <NoteReviewsAccordion note={note} reviews={reviews} />
        ))}
      </Box>
    </Box>
  );
};
