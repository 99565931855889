import { Box, GlobalStyles, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import parse from 'html-react-parser';

interface SGHtmlGlobalStylesProps {
  theme?: Theme;
  id?: string;
}

interface SGHtmlContentProps {
  html?: string | null;
  theme?: Theme;
  sx?: SxProps;
}

export const SGHtmlGlobalStyles = ({ theme, id }: SGHtmlGlobalStylesProps) => {
  const siteTheme = useTheme();
  const finalTheme = theme ?? siteTheme;

  const styles = {
    '.ql-editor img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
      objectFit: 'contain',
    },
    '.ql-editor p:has(> img)': {
      textAlign: 'center',
    },
    '.ql-editor a': {
      color: `${finalTheme.palette.primary.main} !important`,
    },
    '.sg-html-editor .ql-editor img': {
      maxWidth: '50%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: 0,
    },
    [id ? `#${id}` : '*']: {
      '.founders-note-disclaimer': {
        fontSize: 11,
        marginTop: '20px',
        padding: '10px',
        color: finalTheme.palette.text.secondary,
        '& a': {
          color: finalTheme.palette.text.secondary,
        },
      },
    },
  };

  return <GlobalStyles styles={styles} />;
};

export const SGHtmlContent = ({ html, theme, sx = {} }: SGHtmlContentProps) => {
  const siteTheme = useTheme();
  const finalTheme = theme ?? siteTheme;

  if (html == null) {
    return null;
  }

  return (
    <Box
      className="ql-editor"
      width={1}
      height={1}
      sx={{
        color: finalTheme.palette.text.primary,
        background: finalTheme.palette.background.default,
        ...sx,
      }}
    >
      <SGHtmlGlobalStyles theme={finalTheme} />
      {parse(html)}
    </Box>
  );
};
