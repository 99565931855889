import { atom, selector } from 'recoil';
import { userSettingsState } from '../auth';
import { DefaultChartSettings } from '../../config';
import { hiroUpdatingTypeState } from '../hiro';
import { Manifest, BottomCandlesType, HiroUpdatingType } from '../../types';

export const hiroManifestState = atom<Manifest | null>({
  key: 'hiro-manifestState',
  default: null,
});

export const bottomCandlesTypeState = selector<BottomCandlesType>({
  key: 'hiro-bottomCandlesTypeState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.bottomCandlesType ??
      DefaultChartSettings.bottomCandlesType
    );
  },
});

export const candleDurationState = selector<number>({
  key: 'hiro-candleDuration',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.candleDurationSecs ??
      DefaultChartSettings.candleDurationSecs
    );
  },
});

export const showChartTooltipState = selector<boolean>({
  key: 'hiro-showChartTooltip',
  get: ({ get }) => {
    const hiroUpdatingType = get(hiroUpdatingTypeState);
    if (hiroUpdatingType !== HiroUpdatingType.STREAMING) {
      return false;
    }

    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.showChartTooltip ??
      DefaultChartSettings.showChartTooltip
    );
  },
});
