import {
  CompassSavedView,
  CompassZoomDomain,
  StrategyCompassXYZAxis,
} from 'types/compass';

export const COMPASS_DEFAULT_ZOOM_DOMAIN: CompassZoomDomain = {
  x: [0, 1],
  y: [0, 1],
};

export const COMPASS_DEFAULT_ZOOM_DOMAIN_WITH_BUFFER: CompassZoomDomain = {
  x: [
    0 - COMPASS_DEFAULT_ZOOM_DOMAIN.x[1] * 0.035,
    1 + COMPASS_DEFAULT_ZOOM_DOMAIN.x[1] * 0.035,
  ],
  y: [
    0 - COMPASS_DEFAULT_ZOOM_DOMAIN.y[1] * 0.035,
    1 + COMPASS_DEFAULT_ZOOM_DOMAIN.y[1] * 0.035,
  ],
};

export const SG_DEFAULT_COMPASS_VIEWS: CompassSavedView[] = [
  {
    id: 'sg_view_iv_rv',
    name: 'SG 30day IV vs. 30day RV',
    xAxis: StrategyCompassXYZAxis.OneMonthIv,
    yAxis: StrategyCompassXYZAxis.OneMonthRv,
  },
];
