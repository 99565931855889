import {
  ALPHA_PRODUCTS,
  BETA_ACCESS_TO_PRODUCTS,
  DEV_ACCESS_TO_PRODUCTS,
  BETA_MODE,
  DEV_MODE,
  NON_PROD,
  PROD_ACCESS_TO_PRODUCTS,
  PRO_PRODUCTS,
  RELEASED_PRODUCTS,
  STANDARD_PRODUCTS,
} from '../../config';
import { DataExchangeAgreementMap, SubLevel, UserDetails } from '../../types';
import { isBloomberg } from '../bloomberg';
import { isValidTokenOverrideTimeframe } from '../user';
import { getEnv } from './common';

export const getAvailableProducts = (userDetails?: UserDetails) => {
  const modernAccess =
    userDetails?.wordpress?.profile.mepr_modern_dashboard_access;
  if (DEV_MODE) {
    return DEV_ACCESS_TO_PRODUCTS[modernAccess ?? ''] ?? [];
  } else if (BETA_MODE) {
    return BETA_ACCESS_TO_PRODUCTS[modernAccess ?? ''] ?? [];
  }
  return PROD_ACCESS_TO_PRODUCTS[modernAccess ?? ''] ?? RELEASED_PRODUCTS;
};

export function getSubLevelFromMembership(membership: any) {
  if (isBloomberg()) {
    return SubLevel.ALPHA;
  }

  const title = membership?.title;
  if (title.includes('Alpha')) {
    return SubLevel.ALPHA;
  } else if (/Pro|Equity Hub/.test(title)) {
    return SubLevel.PRO;
  }
  return SubLevel.STANDARD;
}

export const getSubscriptionLevel = (userDetails?: UserDetails) => {
  if (isBloomberg() && userDetails != null) {
    return SubLevel.ALPHA;
  }

  if ((userDetails?.wordpress?.active_memberships?.length ?? 0) === 0) {
    return SubLevel.NONE;
  }
  const memberships = userDetails!.wordpress!.active_memberships;
  return Math.max(...memberships.map(getSubLevelFromMembership));
};

export const getProductAccess = (userDetails?: UserDetails) => {
  const products = getAvailableProducts(userDetails) || [];
  if (isBloomberg()) {
    return ALPHA_PRODUCTS;
  } else if ((userDetails?.wordpress?.active_memberships?.length ?? 0) === 0) {
    return [];
  }

  const membership = getSubscriptionLevel(userDetails);

  switch (membership) {
    case SubLevel.ALPHA:
      return products;
    case SubLevel.PRO:
      return products.filter((p) => PRO_PRODUCTS.includes(p));
    default:
      return products.filter((p) => STANDARD_PRODUCTS.includes(p));
  }
};

export const SubLevelTitles: Record<SubLevel, string> = {
  [SubLevel.NONE]: 'NONE',
  [SubLevel.STANDARD]: 'STANDARD',
  [SubLevel.PRO]: 'PRO',
  [SubLevel.ALPHA]: 'ALPHA',
};

export const checkPermissions = (userDetails: UserDetails) => {
  if (userDetails == null && !isValidTokenOverrideTimeframe()) {
    return {
      hasAccess: false,
      errorMessage:
        'Failed to get MemberPress Details. Please try logging in again.',
      productAccess: [],
    };
  }
  const productAccess = getProductAccess(userDetails);
  const hasAccess = productAccess.length > 0;
  const mode = BETA_MODE ? 'beta' : 'dev';
  return {
    hasAccess: hasAccess,
    errorMessage: !hasAccess
      ? NON_PROD
        ? `You don't have ${mode} access to this dashboard.`
        : 'Previous subscriber? Restart your subscription by signing up below.'
      : null,
    productAccess,
  };
};

export const getCachedToken = () => {
  const tokenCookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('sgToken='))
    ?.split('=')[1];

  if (tokenCookieValue) {
    localStorage.setItem('sgToken', tokenCookieValue);
    // deletes the sgToken cookie before returning its value
    document.cookie =
      'sgToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.spotgamma.com; secure; SameSite=None;';
    return tokenCookieValue;
  }

  return localStorage.getItem('sgToken');
};

export const isAdmin = (userDetails?: UserDetails) => {
  if (userDetails?.wordpress == null) {
    return false;
  }
  const roles = userDetails.wordpress.wp_user?.roles ?? [];
  return (
    getEnv() === 'development' ||
    roles.includes('administrator') ||
    roles.includes('editor')
  );
};

export const getHasLoggedIn = () =>
  localStorage.getItem('hasLoggedIn') === 'true';

export const setHasLoggedIn = () => {
  if (localStorage.getItem('hasLoggedIn') == null) {
    localStorage.setItem('hasLoggedIn', 'true');
  }
};

export const showNoLoginUI = () => {
  return /zerohedge|earnings|foundersNotes\/preview/.test(
    window.location.pathname,
  );
};

export const showSubscribeButton = () => {
  return /foundersNotes\/preview/.test(window.location.pathname);
};

export const getIsInstitutional = (form: DataExchangeAgreementMap) => {
  const {
    institutional_trader,
    data_usage_non_business,
    is_registered_body,
    is_asset_manager,
    external_capital_usage,
    trading_for_corp,
    is_profit_sharing,
    benefit_exchange_for_trading,
  } = form;

  return (
    institutional_trader ||
    !data_usage_non_business ||
    is_registered_body ||
    is_asset_manager ||
    external_capital_usage ||
    trading_for_corp ||
    is_profit_sharing ||
    benefit_exchange_for_trading
  );
};
