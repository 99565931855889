import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDateAndTime } from '../../util';
import useReviewFoundersNotes from '../../hooks/foundersNotes/useReviewFoundersNotes';
import useToast from '../../hooks/useToast';
import { isMobileState } from '../../states';
import {
  isReviewingFoundersNoteState,
  foundersNotesSelectedNoteReviewState,
} from '../../states/foundersNotes';
import { FoundersNoteData } from '../../types';

interface FoundersNoteReviewProps {
  selectedNote: FoundersNoteData | null;
}

export const FoundersNoteReview = ({
  selectedNote,
}: FoundersNoteReviewProps) => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(isReviewingFoundersNoteState);
  const [existingReview, setExistingReview] = useRecoilState(
    foundersNotesSelectedNoteReviewState,
  );
  const isMobile = useRecoilValue(isMobileState);
  const [comments, setComments] = useState<string>('');
  const [rating, setRating] = useState<number | null>(null);
  const [error, setError] = useState<string | undefined>();
  const { submitReview } = useReviewFoundersNotes();
  const { openToast } = useToast();

  const onSubmit = useCallback(async () => {
    const data = await submitReview(rating || 0, comments);
    if (!data.error) {
      setOpen(false);
      setRating(null);
      setComments('');
      setError(undefined);
      openToast({ message: 'Successfully left review.', type: 'success' });
    } else {
      setError(data.error);
      openToast({ message: 'Error leaving a review', type: 'error' });
    }
  }, [
    submitReview,
    setOpen,
    setRating,
    setComments,
    rating,
    comments,
    setError,
    openToast,
  ]);

  const onClose = useCallback(() => {
    setRating(null);
    setOpen(false);
    setComments('');
    setError(undefined);
    setExistingReview(undefined);
  }, [setOpen, setRating, setComments, setError, setExistingReview]);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconEmpty': {
      color: theme.palette.info.main,
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Leave a Review</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          minWidth: isMobile ? '100%' : '500px',
        }}
      >
        <DialogContentText>{selectedNote?.title}</DialogContentText>
        {error && existingReview != null && (
          <Typography variant="body2" color="error">
            Error submitting review
          </Typography>
        )}
        {existingReview && (
          <Typography variant="body2" color="info">
            Review submitted {getDateAndTime(existingReview?.ts)}
          </Typography>
        )}

        {/*
          MUI rating seems to have some javascript that depends on the width of the element.
          when displayed in a column flex parent, it automatically gets stretched (same as width=100%).
          this causes that javascript to mess up how it measures the right star to select on hover,
          so you'd have to go more to the right to select a star vs what it should be.
          <Box width=1> as a parent causes the box to get stretched but not the child rating
        */}
        <Box width={1}>
          <StyledRating
            name="founders-note-review-rating"
            size="large"
            value={existingReview?.rating ?? rating}
            onChange={(_event, newValue) => {
              setRating(newValue);
            }}
            readOnly={existingReview != null}
          />
        </Box>

        <TextField
          type="text"
          id="field-comment"
          name="founders-note-review-comment"
          label="Comments"
          placeholder="Comments"
          onChange={(event) => setComments(event.target.value)}
          inputRef={(input) => input && input.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length,
            )
          }
          multiline
          rows={4}
          value={existingReview != null ? existingReview.comments : comments}
          InputProps={{
            readOnly: existingReview != null,
            disableUnderline: existingReview != null,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={existingReview != null ? 'primary' : 'info'}
          onClick={onClose}
        >
          {existingReview != null ? 'Close' : 'Cancel'}
        </Button>
        {existingReview == null && (
          <Button
            onClick={onSubmit}
            disabled={(rating || 0) < 1}
            sx={{
              '&.Mui-disabled': {
                color: `${theme.palette.primary.dark} !important`,
              },
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
