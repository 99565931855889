import React, { useEffect, useState } from 'react';

import { Box, Button, Input, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import { Loader, ErrorContent } from 'components';
import { GREEK_IDX, RawGreeksDataMap, RawStatsDataMap, STAT_IDX } from 'types';
import { useSearchParams } from 'react-router-dom';
import D3Plot from './D3Plot';
import { extractTermStructureData, getFilteredGreeksData } from 'util/iVol';
import { OptionLegType, ModelParameters, ProfitLossWithDomain, TableData, OptionLeg, TimeframeBoundsPoint } from 'types/riskEngine';
import { extractTableData, getStrikePricesForFirstTimestamp, calculateProfitLoss, interpolateDataTable, isCall } from 'util/riskEngine';
import { DEFAULT_OPTION_LEG, DEFAULT_MODEL_PARAMETERS } from 'config/riskEngine';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { getCurrentDate } from 'util/shared';

// Is data still loading
const optionsStratLoadingState = atom<boolean>({
  key: 'optionsStrat-loadingState',
  default: true,
});

// Were there errors fetching data?
const optionsStratErrorState = atom<any>({
  key: 'optionsStrat-errorState',
  default: null,
});

interface OptionEditorProps {
  optionLegs: OptionLeg[];
  setOptionLegs: (newOptionLegs: OptionLeg[]) => void;
  selectedSym: string;
  modelParameters: ModelParameters;
}

const OptionEditor: React.FC<OptionEditorProps> = ({
  optionLegs,
  setOptionLegs,
  selectedSym,
  modelParameters
}) => {
  const optionTypeFriendlyNames: { [key in OptionLegType]: string } = {
    [OptionLegType.SHORT_PUT]: 'Short Put',
    [OptionLegType.SHORT_CALL]: 'Short Call',
    [OptionLegType.LONG_PUT]: 'Long Put',
    [OptionLegType.LONG_CALL]: 'Long Call',
  };

  const handleAddOption = () => {
    setOptionLegs([...optionLegs, DEFAULT_OPTION_LEG]);
  };

  const handleDeleteOption = (index: number) => {
    const newOptionLegs = [...optionLegs];
    newOptionLegs.splice(index, 1);
    setOptionLegs(newOptionLegs);
  };

  const handleUpdateOption = (index: number, updatedOption: OptionLeg) => {
    const newOptionLegs = [...optionLegs];
    newOptionLegs[index] = updatedOption;
    setOptionLegs(newOptionLegs);
  };

  const handleDateChange = async (index: number, date: Dayjs | null) => {
    const newOptionLegs = [...optionLegs];
    newOptionLegs[index] = {
      ...newOptionLegs[index],
      premiumSelectedDate: date,
      premium: date ? (await loadOptionPriceFromApi(optionLegs[index].strikePrice, isCall(optionLegs[index].legType), date)) : optionLegs[index].premium,
    };
    setOptionLegs(newOptionLegs);
  };

  const { getDailyGreeksData, getCurrentGreeksData } = useImpliedVolatility();
  const loadOptionPriceFromApi = async (strikePrice: number, isCall: boolean, date: Dayjs) => {
    const targetDate = date.tz('UTC');
    const formattedDate: string = targetDate.format('YYYY-MM-DD');
    const rawData: RawGreeksDataMap | null = targetDate.isSame(getCurrentDate(), 'day') || targetDate.isAfter(getCurrentDate())
      ? await getCurrentGreeksData(selectedSym)
      : await getDailyGreeksData(formattedDate, selectedSym);

    const filteredGreeks = getFilteredGreeksData(rawData as RawGreeksDataMap);
    const priceTable = extractTableData(filteredGreeks, isCall, GREEK_IDX.PRICE);
    const optionPrice = interpolateDataTable(priceTable, [strikePrice], modelParameters.daysToExpiration)[0];

    return Math.round(optionPrice * 100.0) / 100.0;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box width="100%">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Option Type</TableCell>
              <TableCell>Strike Price</TableCell>
              <TableCell>Premium</TableCell>
              <TableCell>Premium from date</TableCell>
              <TableCell>Shares</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {optionLegs.map((option, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Select
                    value={option.legType}
                    onChange={(e) => {
                      handleUpdateOption(index, {
                        ...option,
                        legType: e.target.value as OptionLegType,
                      });
                    }}
                  >
                    {Object.values(OptionLegType).map((optionType) => (
                      <MenuItem key={optionType} value={optionType}>
                        {optionTypeFriendlyNames[optionType]}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={option.strikePrice}
                    onChange={(e) => {
                      handleUpdateOption(index, {
                        ...option,
                        strikePrice: Number(e.target.value),
                      });
                    }}
                    sx={{
                      width: '100px',
                      background: '#fff',
                      margin: 'auto',
                      color: '#000',
                      height: '40px',
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    key={(option.premiumSelectedDate as unknown as string) || ''}
                    defaultValue={option.premium}
                    onChange={(e) => {
                      handleUpdateOption(index, {
                        ...option,
                        premium: Number(e.target.value),
                      });
                    }}
                    sx={{
                      width: '100px',
                      background: '#fff',
                      margin: 'auto',
                      color: '#000',
                      height: '40px',
                    }}
                  />
                </TableCell>
                <TableCell>
                  <DatePicker
                    sx={{
                      background: '#fff',
                      margin: 'auto',
                      color: '#000',
                    }}
                    value={option.premiumSelectedDate ? dayjs(option.premiumSelectedDate) : null}
                    onChange={(date) => handleDateChange(index, date)} />
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={option.shares}
                    onChange={(e) => {
                      handleUpdateOption(index, {
                        ...option,
                        shares: Number(e.target.value),
                      });
                    }}
                    sx={{
                      width: '100px',
                      background: '#fff',
                      margin: 'auto',
                      color: '#000',
                      height: '40px',
                    }}
                  />
                </TableCell>
                <TableCell>
                  {optionLegs.length > 1 && (
                    <Button onClick={() => handleDeleteOption(index)}>Delete</Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={handleAddOption}>Add New Option</Button>
      </Box>
    </LocalizationProvider>
  );
};

interface ModelParametersEditorProps {
  modelParameters: ModelParameters;
  setModelParameters: (modelParameters: ModelParameters) => void;
  termStructureDates: number[]
}

const ModelParametersEditor: React.FC<ModelParametersEditorProps> = ({
  modelParameters,
  setModelParameters,
  termStructureDates
}) => {
  enum VolatilityDataSource {
    Fixed = 'Fixed',
    Statistical = 'Statistical',
  }

  const [volatilityDataSource, setVolatilityDataSource] = React.useState(
    modelParameters.volatility !== undefined ? VolatilityDataSource.Fixed : VolatilityDataSource.Statistical
  );

  const handleUpdateModelParameter = (param: keyof ModelParameters, value: number | undefined) => {
    setModelParameters({ ...modelParameters, [param]: value });
  };

  const handleVolatilityDataSourceChange = (dataSource: VolatilityDataSource) => {
    setVolatilityDataSource(dataSource);
    if (dataSource === VolatilityDataSource.Statistical) {
      setModelParameters({ ...modelParameters, volatility: undefined });
    } else {
      setModelParameters({ ...modelParameters, volatility: 0 });
    }
  };

  const handleVolatilityChange = (volatility: number) => {
    setModelParameters({ ...modelParameters, volatility });
  };

  return (
    <Box width="100%">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell width="200px">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            { param: 'riskFreeRate', label: 'Risk Free Rate' },
            { param: 'daysToExpiration', label: 'Days to Expiration' },
          ].map(({ param, label }) => (
            <TableRow key={param}>
              <TableCell>{label}</TableCell>
              <TableCell>
                <Input sx={{
                  width: '100px',
                  background: '#fff',
                  margin: 'auto',
                  color: '#000',
                  height: '40px',
                }}
                  defaultValue={modelParameters[param as keyof ModelParameters]}
                  onChange={(e) => {
                    handleUpdateModelParameter(param as keyof ModelParameters, Number(e.target.value));
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow key="volatility">
            <TableCell>Volatility</TableCell>
            <TableCell>
              <Box display="flex" gap={1}>
                {volatilityDataSource === VolatilityDataSource.Fixed && (
                  <Input sx={{
                    width: '100px',
                    background: '#fff',
                    margin: 'auto',
                    color: '#000',
                    height: '40px',
                  }}
                    defaultValue={modelParameters.volatility}
                    onChange={(e) => {
                      handleVolatilityChange(Number(e.target.value));
                    }}
                  />
                )}
                &nbsp;
                <Select
                  value={volatilityDataSource}
                  onChange={(e) => {
                    handleVolatilityDataSourceChange(e.target.value as VolatilityDataSource);
                  }}
                >
                  <MenuItem value={VolatilityDataSource.Fixed}>{VolatilityDataSource.Fixed}</MenuItem>
                  <MenuItem value={VolatilityDataSource.Statistical}>{VolatilityDataSource.Statistical}</MenuItem>
                </Select>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow key="lookback">
            <TableCell>Lookback</TableCell>
            <TableCell>
              <Select
                value={modelParameters.lookback}
                onChange={(e) => {
                  handleUpdateModelParameter('lookback', Number(e.target.value));
                }}>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={90}>90</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
          <TableRow key="exitTime">
            <TableCell>Exit Time</TableCell>
            <TableCell>
              <Select
                value={modelParameters.exitTime || 'now'}
                onChange={(e) => {
                  handleUpdateModelParameter('exitTime', e.target.value && e.target.value !== 'now' ? Number(e.target.value) : undefined);
                }}>
                <MenuItem value={'now'}>Now</MenuItem>
                {termStructureDates.map((o) => <MenuItem value={o}>{dayjs(o).format('MM/DD/YYYY')}</MenuItem>)}
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

const Chart = (props: { data: ProfitLossWithDomain }) => {
  const lines = [
    {
      data: props.data.profitLossData.map((d) => [d.price, d.profit, d.volatility]),
      stroke: '#8888FF',
      fill: 'none',
    },
  ];

  const areas = [
    {
      data: props.data.profitLossData.map((d) => [
        d.price,
        0, Math.max(d.profit, 0),
      ]),
      fill: '#33FF33',
      opacity: 0.15,
    },
    {
      data: props.data.profitLossData.map((d) => [
        d.price,
        0, Math.min(d.profit, 0),
      ]),
      fill: '#FF3333',
      opacity: 0.15,
    },
    {
      data: props.data.profitLossData.map((d) => [
        d.price,
        d.profitBounds[0], d.profitBounds[1]
      ]),
      fill: '#3333FF',
      opacity: 0.25,
    }
  ];

  const yValues = props.data.profitLossData.map((d) => d.profit);

  return (
    <D3Plot
      canvasWidth={800}
      canvasHeight={500}
      xDomain={props.data.domain}
      yDomain={[Math.min(...yValues), Math.max(...yValues)]}
      lines={lines}
      areas={areas}
      zoomExtent={[1, 5]}
      tooltipFormat={(data: number[]) =>
        `<strong>Stock Price:</strong> $${data[0].toFixed(2)}<br /><strong>PnL:</strong> $${data[1].toFixed(2)}<br /><strong>Volatility:</strong> ${data[2].toFixed(3)}`
      }
    />
  );
};

export const RiskEngineContainer = () => {
  const ref = null;
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);

  const [loading, setLoading] = useRecoilState(optionsStratLoadingState);
  const [error, setError] = useRecoilState(optionsStratErrorState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [strikePriceOptions, setStrikePriceOptions] = useState<{ value: number; label: string }[]>([]);
  const [volDataCall, setVolDataCall] = useState<TableData[]>([]);
  const [volDataPut, setVolDataPut] = useState<TableData[]>([]);
  const [rawCurrentGreeks, setRawCurrentGreeks] = useState<RawGreeksDataMap>([]);
  const [rawStats, setRawStats] = useState<RawStatsDataMap>([]);

  const [optionLegs, setOptionLegs] = useState<OptionLeg[]>([DEFAULT_OPTION_LEG]);
  const [modelParameters, setModelParameters] = useState<ModelParameters>(DEFAULT_MODEL_PARAMETERS);
  const selectedSym = searchParams.get('sym') ?? 'SPX';

  useEffect(() => {
    const riskFreeRate = searchParams.get('riskFreeRate');
    const expiration = searchParams.get('daysToExpiration');
    const volatility = searchParams.get('volatility');
    const lookback = searchParams.get('lookback');
    const exitTime = searchParams.get('exitTime');
    if (riskFreeRate && expiration && lookback) {
      const newModelParameters: ModelParameters = {
        riskFreeRate: parseFloat(riskFreeRate),
        volatility: volatility !== null ? parseFloat(volatility as string) : undefined,
        daysToExpiration: parseFloat(expiration),
        lookback: parseFloat(lookback),
        exitTime: exitTime ? parseFloat(exitTime) : undefined
      }

      if (modelParameters != newModelParameters) {
        setModelParameters(newModelParameters);
      }
    }

    const options: OptionLeg[] = [];
    for (let i = 0; ; i++) {
      const legType = searchParams.get(`o${i}Type`);
      const strikePrice = searchParams.get(`o${i}Strike`);
      const premium = searchParams.get(`o${i}Premium`);
      const shares = searchParams.get(`o${i}Shares`);
      if (!legType || !strikePrice || !premium || !shares) {
        if (i > 0) {
          // Only set option legs if we loaded any
          setOptionLegs(options);
        }
        break;
      }
      options.push({
        legType: legType as OptionLegType,
        strikePrice: parseFloat(strikePrice),
        premium: parseFloat(premium),
        shares: parseFloat(shares),
        premiumSelectedDate: null
      });
    }
  }, []);

  useEffect(() => {
    const params: { [key: string]: string } = {};
    optionLegs.forEach((option, index) => {
      params[`o${index}Type`] = option.legType;
      params[`o${index}Strike`] = option.strikePrice.toString();
      params[`o${index}Premium`] = option.premium.toString();
      params[`o${index}Shares`] = option.shares.toString();
    });

    params['riskFreeRate'] = modelParameters.riskFreeRate.toString();
    params['daysToExpiration'] = modelParameters.daysToExpiration.toString();
    params['lookback'] = modelParameters.lookback.toString();

    if (modelParameters.volatility !== undefined) {
      params['volatility'] = modelParameters.volatility.toString();
    }

    if (modelParameters.exitTime !== undefined) {
      params['exitTime'] = modelParameters.exitTime.toString();
    }

    params['sym'] = selectedSym;
    setSearchParams(params);
  }, [optionLegs, modelParameters]);

  const { getCurrentGreeksData, getDailyGreeksData, getStatisticsData } =
    useImpliedVolatility();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const rawCurrentGreeksData: RawGreeksDataMap | null = await getCurrentGreeksData(selectedSym);
      const rawStatsData = await getStatisticsData(selectedSym);

      const filteredGreeks = getFilteredGreeksData(rawCurrentGreeksData as RawGreeksDataMap);
      setRawStats(rawStatsData as RawStatsDataMap);
      setRawCurrentGreeks(rawCurrentGreeksData as RawGreeksDataMap);
      setVolDataCall(extractTableData(filteredGreeks, true, GREEK_IDX.VOLATILITY));
      setVolDataPut(extractTableData(filteredGreeks, false, GREEK_IDX.VOLATILITY));

      const strikePrices =
        getStrikePricesForFirstTimestamp(rawCurrentGreeksData);

      setStrikePriceOptions(
        strikePrices?.map((strikePrice) => ({
          value: strikePrice,
          label: '$' + strikePrice.toString(),
        })) ?? [],
      );

      setLoading(false);
    };
    fetchData().catch(console.error);
  }, [selectedSym]);

  const termStructure = rawStats && rawStats[modelParameters.lookback] ?
    extractTermStructureData(rawCurrentGreeks, dayjs(modelParameters.exitTime ? modelParameters.exitTime : new Date()), "timeframe", (rawStats as RawStatsDataMap)[modelParameters.lookback], STAT_IDX.P25, STAT_IDX.P75) : [];

  if (!selectedSym || loading) {
    return <Loader isLoading={loading} />;
  }

  if (!loading && error != null) {
    return (
      <ErrorContent content="Failed to retrieve the data needed for Options Strat. Please either refresh the page or contact us!" />
    );
  }

  // Calculate profit loss over min to max
  const allProfitLoss = calculateProfitLoss(optionLegs, volDataCall, volDataPut, modelParameters, null, null);
  // Now calculate profit loss over the smaller region of interest for better chart resolution
  const chartData = calculateProfitLoss(optionLegs, volDataCall, volDataPut, modelParameters, allProfitLoss.domain, termStructure as unknown as TimeframeBoundsPoint[]);

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <Stack direction="row" sx={{ height: '100%' }}>
        <div style={{ flex: 0.1 }}>
          <OptionEditor optionLegs={optionLegs} setOptionLegs={setOptionLegs} selectedSym={selectedSym} modelParameters={modelParameters} />
          <ModelParametersEditor modelParameters={modelParameters} setModelParameters={setModelParameters} termStructureDates={termStructure.map((o) => o.expirationDate)} />
        </div>

        <ChartWatermarkContainer
          ref={ref ?? null}
          style={{ flex: 1, position: 'relative' }}
          size={20}
          offsetX={55}
          offsetY={50}
          sym={selectedSym}
          symStyles={{ right: '35px' }}
        >
          <Chart data={chartData} />
        </ChartWatermarkContainer>
      </Stack>
    </Stack>
  );
};
