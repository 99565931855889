import { ProductType, Scanner } from '../../types';
import { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import useAuth from '../auth/useAuth';

const useActiveScanner = (
  activeScannerState: RecoilState<Scanner | undefined>,
) => {
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const [currentScanner, setCurrentScanner] =
    useRecoilState(activeScannerState);

  const deleteActiveScanner = useCallback(() => {
    setCurrentScanner(undefined);
  }, [setCurrentScanner]);

  const setActiveScanner = useCallback(
    (scanner: Scanner | undefined) => {
      if (!hasScannersAccess || scanner == null) {
        deleteActiveScanner();
        return;
      }

      setCurrentScanner(scanner);
    },
    [hasScannersAccess, deleteActiveScanner],
  );

  return {
    activeScanner: currentScanner,
    setActiveScanner,
    deleteActiveScanner,
  };
};

export default useActiveScanner;
