import { Dayjs } from 'dayjs';

export type GreeksData = number[][];

// keys in this map are strikes, value is 2d array of put/call data
export type RawGreeksObject = {
  [strike: number]: GreeksData;
};

// keys are expiration dates
export type RawGreeksDataMap = {
  [exp: number]: RawGreeksObject;
};

export type RawStatsObject = {
  [delta: number]: number[];
};

export type RawStatsData = {
  [tte: number]: RawStatsObject;
};

export type RawStatsDataMap = {
  [lookback: number]: RawStatsData;
};

export enum StrikeDisplayType {
  FixedStrike = 'Strike',
  Delta = 'Delta',
  Moneyness = '% Moneyness',
}

export enum ExpirationsDisplayType {
  ExpirationDate = 'Expiration Date',
  DaysToExpiration = 'Days to Expiration',
}

export interface IVolSettingsProps {
  selectedSym: string;
}
export type VolSkewKey = 'delta' | 'delta-x' | 'strike' | 'moneyness';

export const GREEK_IDX = {
  PRICE: 0,
  DELTA: 1,
  GAMMA: 2,
  THETA: 3,
  VEGA: 4,
  VOLATILITY: 5,
  TIMESTAMP: 6,
};

export const OPTION_IDX = {
  CALL: 0,
  PUT: 1,
};

export const STAT_IDX = {
  MIN: 0,
  MAX: 1,
  P10: 2,
  P25: 3,
  P75: 4,
  P90: 5,
  MEAN: 6,
  MEDIAN: 7,
  STDEV: 8,
};

export const CANDLE_IDX = {
  TIMESTAMP_OPEN: 0,
  OPEN: 1,
  HIGH: 2,
  LOW: 3,
  CLOSE: 4,
};

export type Point = {
  x: number;
  y: number;
};

export enum GreeksStatsLookback {
  THIRTY = '30',
  SIXTY = '60',
  NINETY = '90',
}

export enum IVolTab {
  FixedStrikeMatrix = 'fixed_strike_matrix',
  TermStructure = 'term_structure',
  VolSkew = 'vol_skew',
  Vix = "vix",
}

export const IVOL_LABEL_MAPPING = new Map([
  [IVolTab.FixedStrikeMatrix, 'Fixed Strike Matrix'],
  [IVolTab.TermStructure, 'Term Structure'],
  [IVolTab.VolSkew, 'Volatility Skew'],
  [IVolTab.Vix, 'VIX'],
]);

export const IVOL_TABS: Set<string> = new Set(
  Object.values(IVolTab).map((s) => s as string),
);

export enum MatrixDataMode {
  SKEW_PREMIUM_MODE = 'Skew Premium',
  STATS_MODE = 'Statistical Mode',
  COMPARE_MODE = 'Compare Mode',
  DEFAULT = 'Implied Vol Mode',
}

export interface TermStructure {
  // days to expiry, on x-axis
  daysToExpiry: number;
  // expiration date, on x-axis
  expirationDate: number;
  // dynamic number of key/value pairs ranging from timePeriods to statistical numbers
  [key: string]: any;
}

// representation of raw daily greeks data
export type DailyGreeks = {
  key: string;
  tradeDate: Dayjs;
  data: RawGreeksDataMap;
  color: string;
};

export interface VolSkew {
  // strike price, on x-axis
  strike: number;
  // moneyness, on x-axis
  moneyness: number;

  'delta-x': number;
  // dynamic number of key/value pairs ranging from timePeriods to statistical numbers
  [key: string]: any;
}

// Represents the raw greeks strike data associated with a specific trade and expiry date pair
export type DatedGreeks = {
  key: string;
  tradeDate: Dayjs;
  expiryDate: string;
  greeksMap: RawGreeksDataMap;
  statsMap: RawStatsObject;
  color: string;
};

export enum FixedStrikeZoomLevel {
  Normal = 'Normal',
  ZoomedOut = 'Zoomed Out',
}

export interface FixedStrikeCellZoomProps {
  expColumnWidth: number;
  cellColumnWidth: number;
  height: number;
  fontSize: string;
  transform: string;
}

export enum MatrixExpirationsRange {
  '3months' = 3,
  '6months' = 6,
  '9months' = 9,
  '12months' = 12,
  all = 'All Expirations',
}

export const MATRIX_EXPIRATIONS_RANGE_MAPPING = new Map([
  [MatrixExpirationsRange['3months'], '3 Months'],
  [MatrixExpirationsRange['6months'], '6 Months'],
  [MatrixExpirationsRange['9months'], '9 Months'],
  [MatrixExpirationsRange['12months'], '12 Months'],
  [MatrixExpirationsRange.all, 'All Expirations'],
]);

export enum MatrixPercentOTMRange {
  '5percent' = 0.05,
  '10percent' = 0.1,
  '15percent' = 0.15,
  '20percent' = 0.2,
  all = 'All Strikes',
}

export const MATRIX_PERCENT_OTM_RANGE_MAPPING = new Map([
  [MatrixPercentOTMRange['5percent'], '5%'],
  [MatrixPercentOTMRange['10percent'], '10%'],
  [MatrixPercentOTMRange['15percent'], '15%'],
  [MatrixPercentOTMRange['20percent'], '20%'],
  [MatrixPercentOTMRange.all, 'All Strikes'],
]);

export enum PrunePercentRange {
  none = 'None',
  'low' = 0.25,
  'medium' = 0.5,
  'high' = 0.75,
}

export const PRUNE_PERCENT_MAPPING = new Map([
  [PrunePercentRange.none, 'None'],
  [PrunePercentRange['low'], 'Low'],
  [PrunePercentRange['medium'], 'Medium'],
  [PrunePercentRange['high'], 'High'],
]);

export interface MatrixData {
  id: number | string;
  expiryDate: number;
  [key: string]: any;
}

export interface MatrixColumnData {
  dataKey: keyof MatrixData;
  label: string;
  numeric?: boolean;
  styling?: any;
}

export interface MatrixTooltipInfo {
  label: string;
  value: string;
  style?: any;
}

export interface SkewPrem {
  strike: number | null;
  value: number | null;
}

export interface MatrixSkewPrem {
  putMax: SkewPrem;
  callMax: SkewPrem;
  totalMax: SkewPrem;
}

export type CellValueFormat = 'absolute' | 'currency' | 'percentage';

export const MATRIX_MODE_LABEL_MAPPING = new Map([
  [MatrixDataMode.COMPARE_MODE, 'Implied Vol Time-Scaled Difference'],
  [MatrixDataMode.STATS_MODE, 'Implied Vol Z-Score'],
  [MatrixDataMode.SKEW_PREMIUM_MODE, 'Vol Skew Premium'],
]);

export const MATRIX_MODE_VALUE_LABEL_MAPPING = new Map([
  [MatrixDataMode.COMPARE_MODE, 'Implied Vol Difference'],
  [MatrixDataMode.SKEW_PREMIUM_MODE, 'Implied Vol'],
  [MatrixDataMode.STATS_MODE, 'Implied Vol'],
  [MatrixDataMode.DEFAULT, 'Implied Vol'],
]);


export interface SelectedDateLine {
  key: string;
  tradeDate: dayJs;
  color: string;
  notDisplayed?: boolean

}