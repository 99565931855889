import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPinnedColumnFields,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { defaultColumnScannerVisibility, scannerFields } from '../config';
import { Scanner, EquityFieldKey, LegacyEquity, SynthOIEquity } from '../types';

const { persistAtom } = recoilPersist();

// Currently active equity scanner in screener
export const activeScannerState = atom<Scanner | undefined>({
  key: 'scanners-activeScannerState',
  default: Scanner.VOL_RISK_PREMIUM,
  effects_UNSTABLE: [persistAtom],
});

export const useDefaultColumnsState = atom<boolean>({
  key: 'scanners-useDefaultColumnsState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const scannerColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'scanners-scannerColumnsVisibilityState',
  default: defaultColumnScannerVisibility,
  effects_UNSTABLE: [persistAtom],
});

export const scannerPinnedColumnsState = atom<GridPinnedColumnFields>({
  key: 'scanners-scannerPinnedColumnsState',
  default: {
    left: ['isWatchlisted', 'sym'],
    right: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const scannerSortModelState = atom<GridSortModel>({
  key: 'scanners-scannerSortModelState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const scannerFilterModelState = atom<GridFilterModel>({
  key: 'scanners-scannerFilterModelState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const scannerActiveWatchlistsIdsState = atom<number[]>({
  key: 'scanners-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const scannerColumnOrderState = atom<EquityFieldKey[]>({
  key: 'scanners-scannerColumnOrderState',
  default: scannerFields,
  effects_UNSTABLE: [persistAtom],
});

export const scannerDataState = atom<(LegacyEquity | SynthOIEquity)[]>({
  key: 'scanners-dataState',
  default: [],
});
