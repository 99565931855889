import { Box, Stack, Typography } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  GridColDef,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from 'states';
import {
  dayjs,
  formatAsCompactNumber,
  formatAsCurrency,
  formatAsPercentage,
  getDateFormatted,
  isOutsideTradingHours,
  nullsToEndComparator,
  valOrNa,
} from 'util/shared';
import {
  Aggressor,
  AggressorLabels,
  OptionFlag,
  OptionSaleType,
  OptionSaleTypeLabels,
  OptionsFeedColumnKey,
  OptionsFeedContractColumnKey,
  OptionTradeSide,
  OptionTradeSideLabels,
} from 'types/tape';
import { getTransactionSentiment } from 'util/tape';
import { SGTooltip } from 'components/core';
import { Earnings } from 'types';
import { blue, lightBlue, red } from '@mui/material/colors';
import { ColorMode } from 'theme';
import StockIcon from 'components/StockIcon';
import { TAPE_COLUMN_TOOLTIPS_MAP } from 'config/tape';

interface UseOptionsFeedColumnsProps {
  blurredRowIds?: string[];
  earningsList?: Earnings[];
}

export const useTapeColumns = ({
  blurredRowIds,
  earningsList,
}: UseOptionsFeedColumnsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 12 : 14,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (_params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: theme.palette.primary.main,
    }),
    [defaultHeaderTitleStyles, theme.palette.primary.main],
  );

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  // Helper function to create base column configuration
  const createBaseColumn = (
    field: string,
    headerName: string,
    minWidth: number,
    additionalConfig = {},
    cellSx = {},
    tooltip?: string,
  ): GridColDef => ({
    field,
    headerName,
    headerClassName: 'grid-header-cell',
    minWidth,
    description: tooltip,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Box>
        <SGTooltip title={tooltip}>
          <Typography
            sx={{
              ...getColHeaderStyles(params),
              ...cellSx,
            }}
          >
            {headerName}
          </Typography>
        </SGTooltip>
      </Box>
    ),
    ...additionalConfig,
  });

  // Helper function for number columns
  const createNumberColumn = (
    field: string,
    headerName: string,
    minWidth: number,
    formatFn?: (value: any) => string,
    additionalConfig = {},
    cellSx: SxProps<Theme> = {},
    tooltip?: string,
  ) => {
    return createBaseColumn(field, headerName, minWidth, {
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{
            ...getBlurredStyles(params),
            ...cellSx,
            whiteSpace: 'nowrap',
          }}
        >
          {formatFn
            ? formatFn(params.value)
            : valOrNa(params.value?.toLocaleString())}
        </Typography>
      ),
      ...additionalConfig,
      cellSx,
      tooltip,
    });
  };

  // Helper function for Greek columns (Delta, Gamma, Vega)
  const createGreekColumn = (
    field: string,
    headerName: string,
    cellSx = {},
    tooltip?: string,
  ) => {
    return createNumberColumn(
      field,
      headerName,
      70,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
      cellSx,
      tooltip,
    );
  };

  // Helper function for Notional columns
  const createNotionalColumn = (
    field: string,
    headerName: string,
    cellSx: SxProps<Theme> = {},
    tooltip?: string,
  ) => {
    return createNumberColumn(
      field,
      headerName,
      80,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
      cellSx,
      tooltip,
    );
  };

  const columns: GridColDef[] = [
    // Time Column
    createBaseColumn(
      OptionsFeedColumnKey.Time,
      'Time',
      90,
      {
        type: 'dateTime',
        getSortComparator: nullsToEndComparator(gridDateComparator),
        valueGetter: (value: bigint) =>
          value ? dayjs.utc(parseInt(value.toString())).toDate() : null,
        renderCell: (params: GridRenderCellParams) => {
          const date = dayjs(params?.value).utc();
          const tsForUser = date.tz(currentTimezone);
          const now = dayjs().tz(currentTimezone);

          // Format time based on whether it's today or not
          let formattedTime = tsForUser.isSame(now, 'day')
            ? tsForUser.format('HH:mm:ss')
            : tsForUser.isSame(now, 'year')
            ? tsForUser.format('HH:mm:ss, MM-DD') // Same year
            : tsForUser.format('HH:mm:ss, YYYY-MM-DD'); // Different year

          return (
            <Typography
              sx={{ whiteSpace: 'nowrap', ...getBlurredStyles(params) }}
            >
              {valOrNa(formattedTime)}
              {isOutsideTradingHours(date) && (
                <SGTooltip title="Extended Trading Hours">
                  <sub style={{ fontSize: 8 }}>ETH</sub>
                </SGTooltip>
              )}
            </Typography>
          );
        },
      },
      undefined,
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Time),
    ),

    // Underlying Column
    createBaseColumn(
      OptionsFeedColumnKey.Underlying,
      'Symbol',
      90,
      {
        type: 'string',
        getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
        renderCell: (params: GridRenderCellParams) => {
          const sym = params.value?.replace(/^.*\|/, '');
          return (
            <Stack
              sx={{
                gap: 3,
                flexDirection: 'row',
                alignItems: 'center',
                ...getBlurredStyles(params),
              }}
            >
              <StockIcon symbol={sym} />
              <Typography>
                {valOrNa(sym)}
                {earningsList?.find((e) => e.sym === sym) && (
                  <SGTooltip title="Earnings within 3 weeks from now">
                    <sup style={{ fontSize: 8, color: red[600] }}>E</sup>
                  </SGTooltip>
                )}
              </Typography>
            </Stack>
          );
        },
      },
      undefined,
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Underlying),
    ),

    // Trade Side Column
    createBaseColumn(
      OptionsFeedColumnKey.TradeSide,
      'Side',
      70,
      {
        type: 'singleSelect',
        valueOptions: Object.values(OptionTradeSide),
        getOptionLabel: (value: OptionTradeSide) =>
          OptionTradeSideLabels[value],
        getSortComparator: nullsToEndComparator(gridNumberComparator),
        renderCell: (params: GridRenderCellParams) => (
          <Typography sx={getBlurredStyles(params)}>
            {valOrNa(
              OptionTradeSideLabels[
                params.value as OptionTradeSide
              ]?.toUpperCase(),
            )}
          </Typography>
        ),
      },
      undefined,
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.TradeSide),
    ),

    // Aggressor Column
    createBaseColumn(
      OptionsFeedColumnKey.Aggressor,
      'Buy/Sell',
      70,
      {
        type: 'singleSelect',
        valueOptions: [Aggressor.BUY, Aggressor.SELL],
        getOptionLabel: (value: Aggressor) => AggressorLabels[value],
        renderCell: (params: GridRenderCellParams) => {
          const sentiment = getTransactionSentiment(params.row);
          return (
            <Typography
              sx={{
                ...getBlurredStyles(params),
                color:
                  sentiment === 'bullish'
                    ? serverPositiveTrendColor
                    : sentiment === 'bearish'
                    ? serverNegativeTrendColor
                    : 'inherit',
                fontWeight: 600,
              }}
            >
              {valOrNa(params.value.toString().toUpperCase())}
            </Typography>
          );
        },
      },
      undefined,
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Aggressor),
    ),

    // C/P Column
    createBaseColumn(
      OptionsFeedColumnKey.IsPut,
      'C/P',
      70,
      {
        type: 'string',
        getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
        valueFormatter: (value: boolean) =>
          valOrNa(value === true ? 'PUT' : 'CALL'),
        renderCell: (params: GridRenderCellParams) => {
          const sentiment = getTransactionSentiment(params.row);
          return (
            <Typography
              sx={{
                ...getBlurredStyles(params),
                color:
                  sentiment === 'bullish'
                    ? serverPositiveTrendColor
                    : sentiment === 'bearish'
                    ? serverNegativeTrendColor
                    : 'inherit',
                fontWeight: 600,
              }}
            >
              {valOrNa(params.value === true ? 'PUT' : 'CALL')}
            </Typography>
          );
        },
      },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.IsPut),
    ),

    // Strike Column
    createNumberColumn(
      OptionsFeedColumnKey.Strike,
      'Strike',
      80,
      undefined,
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Strike),
    ),

    // Expiration Column
    createBaseColumn(
      OptionsFeedColumnKey.Expiry,
      'Expiration',
      95,
      {
        type: 'date',
        getSortComparator: nullsToEndComparator(gridDateComparator),
        valueGetter: (value: bigint) =>
          value ? dayjs.utc(parseInt(value.toString())).toDate() : null,
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            sx={{ whiteSpace: 'normal', ...getBlurredStyles(params) }}
          >
            {valOrNa(
              params.value && getDateFormatted(dayjs(params.value).utc()),
            )}
          </Typography>
        ),
      },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Expiry),
    ),

    // Volume Column
    createNumberColumn(
      OptionsFeedColumnKey.DailyVolCumsum,
      'Volume',
      60,
      (value) => valOrNa(formatAsCompactNumber(value)),
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.DailyVolCumsum),
    ),

    // OI Column
    createNumberColumn(
      OptionsFeedColumnKey.PrevOi,
      'OI',
      60,
      (value) => value && formatAsCompactNumber(value),
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.PrevOi),
    ),

    // Premium Column
    createNumberColumn(
      OptionsFeedColumnKey.Premium,
      'Premium',
      70,
      (value) => value && `$${formatAsCompactNumber(value)}`,
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Premium),
    ),

    // Size Column
    createNumberColumn(
      OptionsFeedColumnKey.Size,
      'Size',
      60,
      undefined,
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Size),
    ),

    // Spot Column
    createNumberColumn(
      OptionsFeedColumnKey.StockPrice,
      'Spot',
      70,
      (value) => value && formatAsCurrency(value),
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.StockPrice),
    ),

    // Bid Column
    createNumberColumn(
      OptionsFeedColumnKey.Bid,
      'Bid',
      70,
      undefined,
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Bid),
    ),

    // Ask Column
    createNumberColumn(
      OptionsFeedColumnKey.Ask,
      'Ask',
      70,
      undefined,
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Ask),
    ),

    // Option Price Column
    createNumberColumn(
      OptionsFeedColumnKey.Price,
      'Option Price',
      80,
      (value) => value && formatAsCurrency(value),
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Price),
    ),

    // Flags Column
    createBaseColumn(
      OptionsFeedColumnKey.Flags,
      'Flags',
      90,
      {
        sortable: false,
        valueGetter: ({ isBlock, isSpread, isSweep, isCross }: OptionFlag) => {
          const flags: OptionSaleType[] = [];
          if (isBlock) {
            flags.push(OptionSaleType.BLOCK);
          }
          if (isSweep) {
            flags.push(OptionSaleType.SWEEP);
          }
          if (isSpread) {
            flags.push(OptionSaleType.SPREAD);
          }
          if (isCross) {
            flags.push(OptionSaleType.CROSS);
          }
          return flags;
        },
        renderCell: (params: GridRenderCellParams) =>
          params.value?.length !== 0 ? (
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              {params.value?.map((v: OptionSaleType) => (
                <Box
                  sx={{
                    paddingX: '8px',
                    backgroundColor:
                      theme.colorMode === ColorMode.LIGHT
                        ? lightBlue[300]
                        : blue[500],
                    borderRadius: 12,
                    height: 19,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{ ...getBlurredStyles(params), fontSize: 10 }}
                  >
                    {valOrNa(OptionSaleTypeLabels[v])}
                  </Typography>
                </Box>
              ))}
            </Stack>
          ) : null,
      },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Flags),
    ),

    // IV Column
    createNumberColumn(
      OptionsFeedColumnKey.IVol,
      'IV',
      80,
      (value) => value && formatAsPercentage(value),
      {},
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.IVol),
    ),

    // Greeks Columns
    createGreekColumn(
      OptionsFeedColumnKey.Delta,
      'Delta',
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Delta),
    ),
    createGreekColumn(
      OptionsFeedColumnKey.Gamma,
      'Gamma',
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Gamma),
    ),
    createGreekColumn(
      OptionsFeedColumnKey.Vega,
      'Vega',
      {
        paddingRight: '4px',
      },
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Vega),
    ),
  ];

  const contractColumns: GridColDef[] = [
    // Reuse base columns
    columns.find((col) => col.field === OptionsFeedColumnKey.Underlying)!,
    columns.find((col) => col.field === OptionsFeedColumnKey.IsPut)!,
    columns.find((col) => col.field === OptionsFeedColumnKey.Strike)!,
    createBaseColumn(OptionsFeedColumnKey.Expiry, 'Expiration', 90, {
      type: 'date',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => (value ? dayjs(value).toDate() : null),
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ whiteSpace: 'normal', ...getBlurredStyles(params) }}>
          {valOrNa(params.value && getDateFormatted(dayjs(params.value)))}
        </Typography>
      ),
    }),

    // Contract specific columns
    createNumberColumn(
      OptionsFeedContractColumnKey.TotalPremium,
      'Total Premium',
      80,
      (value) => value && `$${formatAsCompactNumber(value)}`,
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.TotalPremium),
    ),
    // OI Column
    createNumberColumn(
      OptionsFeedContractColumnKey.PrevOi,
      'OI',
      60,
      (value) => value && formatAsCompactNumber(value),
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.PrevOi),
    ),
    createNumberColumn(
      OptionsFeedContractColumnKey.TotalVolume,
      'Total Volume',
      80,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.TotalVolume),
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.DeltaNotional,
      'Delta Notional',
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.DeltaNotional),
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.GammaNotional,
      'Gamma Notional',
      {},
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.GammaNotional),
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.VegaNotional,
      'Vega Notional',
      { paddingRight: '4px' },
      TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedContractColumnKey.DeltaNotional),
    ),
  ];

  return {
    columns,
    contractColumns,
  };
};
