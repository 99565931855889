import { Box } from '@mui/material';
import { StrategyCompassEditSymbols } from './StrategyCompassEditSymbols';
import { CompassData } from 'hooks/scanners/useCompassData';
import { CompassParams } from 'hooks/scanners/useCompassParams';

interface StrategyCompassSidePanelProps {
  compassParams: CompassParams;
  compassData: CompassData;
}

const StrategyCompassSidePanel = ({
  compassParams,
  compassData,
}: StrategyCompassSidePanelProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: { xs: '400px', md: '100%' },
      }}
    >
      <StrategyCompassEditSymbols
        compassData={compassData}
        compassParams={compassParams}
      />
    </Box>
  );
};

export default StrategyCompassSidePanel;
