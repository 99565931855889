import bbgAuthUntyped, {
  EnvType,
  IGlobalAuthentication,
  TokenType,
} from '../../../lib/bloomberg/bb-apps-authentication';
import { useCallback } from 'react';
import { isBBEnvAvailable, isBbgDemo } from '../../../util';
import useAuth from '../../auth/useAuth';
import useLog from '../../useLog';
import { OH_TOKEN } from 'config/user';

type OAuthLoginParams = {
  demoPassword?: string;
};

type OAuthLoginStatus = {
  success: boolean;
  error?: string;
};

const useOauth = () => {
  const bbgAuth = bbgAuthUntyped as unknown as IGlobalAuthentication;
  const { getBbgSGToken, setToken } = useAuth();
  const { logError } = useLog('useOauth');
  const { setTokensAndUserDetails } = useAuth();

  const handleAuthPayload = useCallback((payload: any) => {
    if (payload?.data?.message != null || payload?.data?.error != null) {
      setToken(null);
      const error = payload.data.message ?? payload.data.error.message;
      logError(error, 'handleAuthPayload');
      return error;
    }
    const sgToken = payload.sgToken;
    if (!sgToken) {
      return 'There was an error logging in. Please try reopening the app. Code 185';
    }
    const bgDetails = payload.data;
    if (!bgDetails) {
      return 'There was an error logging in. Please try reopening the app. Code 147';
    }
    if (sgToken === OH_TOKEN) {
      throw new Error('Setting token to Open House!');
    }
    setTokensAndUserDetails(sgToken, bgDetails);
    return null;
  }, []);

  const getSignOnParams = () => {
    return {
      clientId: import.meta.env.VITE_BBG_CLIENT_ID ?? '',
      redirectURI: import.meta.env.VITE_BBG_REDIRECT_URI ?? '',
      scopes: ['profile', 'openid'],
      clientSecret: import.meta.env.VITE_BBG_CLIENT_SECRET ?? '',
      noCache: true,
      envType: EnvType.Prod,
      nonce: '',
      tokenType: TokenType.All,
      state: '',
    };
  };

  const oauthLogin = async (
    setErrorCallback: (error: string | null) => void = () => {},
    opts: OAuthLoginParams,
  ): Promise<any> => {
    let retries = 0;
    const maxRetries = 10;
    let useHfn = false;

    const callErrorCallback = (err: string) => {
      logError(err, 'oauthLogin');
      setErrorCallback(err);
    };

    while (retries < maxRetries) {
      const status = await attemptOauthLogin(opts, useHfn);
      if (status.success) {
        return;
      } else if (status.error != null) {
        return callErrorCallback(status.error);
      } else {
        useHfn = !useHfn;
        retries += 1;
      }
    }

    callErrorCallback(
      'There was an error logging you in. Please re-open the app and try again. Code 604',
    );
  };

  const attemptOauthLogin = async (
    opts: OAuthLoginParams,
    useHfn = false,
  ): Promise<OAuthLoginStatus> => {
    let tokenResponse;

    try {
      if (isBBEnvAvailable()) {
        const response = await bbgAuth.OAuth2.getOauthPKCEParams({
          ...getSignOnParams(),
          useHfn,
        });
        tokenResponse = await getBbgSGToken(response);
        if (tokenResponse.status === 500) {
          // sometimes bbg server will return a 500. it's unclear why but let's retry instead of cancelling login
          return { success: false, error: undefined };
        }
      } else if (isBbgDemo()) {
        if (!opts.demoPassword?.length) {
          return { success: false, error: 'Invalid demo password' };
        }
        tokenResponse = await getBbgSGToken({
          demoPassword: opts.demoPassword,
        });
      } else {
        return { success: false, error: 'Unauthorized.' };
      }

      const error = handleAuthPayload(tokenResponse);
      if (error) {
        return { success: false, error };
      }

      return { success: true, error: undefined };
    } catch (err) {
      logError(err, 'attemptOauthLogin');
      return { success: false, error: undefined };
    }
  };

  return { oauthLogin };
};

export default useOauth;
