import { Paper, Typography, Button, useTheme } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userDetailsState, isInstitutionalLocalState } from '../../states';
import IsInstitutionalModal from '../shared/IsInstitutionalModal';

const DataAgreementContainer = () => {
  const [instModalOpen, setInstModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const userDetails = useRecoilValue(userDetailsState);
  const isInstitutionalLocal = useRecoilValue(isInstitutionalLocalState);

  const isInstitutionalResponded = useMemo(
    () => isInstitutionalLocal != null || userDetails?.isInstitutional != null,
    [isInstitutionalLocal, userDetails?.isInstitutional],
  );

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        display: 'flex',
        maxWidth: '650px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        padding: '24px',
        borderRadius: '8px',
      }}
    >
      <QuestionAnswerIcon
        sx={{
          color: theme.palette.text.secondary,
          fontSize: {
            xs: 24,
            md: 36,
          },
        }}
      />
      <Typography
        sx={{
          fontSize: {
            xs: 16,
            md: 18,
          },
        }}
      >
        {isInstitutionalResponded
          ? 'Your Market Data Exchange Agreement response indicates that you may qualify as an institutional trader which prevents us from giving you access to the live Tape data.'
          : 'Market Data Exchange Agreement is required before you can view this data.'}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          textTransform: 'none',
        }}
        onClick={() => setInstModalOpen(true)}
      >
        {isInstitutionalResponded ? 'Edit Response' : 'Open Agreement'}
      </Button>
      <IsInstitutionalModal open={instModalOpen} setOpen={setInstModalOpen} />
    </Paper>
  );
};

export default DataAgreementContainer;
