import { useCallback } from 'react';
import { businessDaysAdd, getQueryDate } from '../../util';
import { AdminContentCategory, Earnings } from '../../types';
import useLog from '../useLog';
import { getEarningsObj } from 'util/shared/earnings';

const useHomeContent = () => {
  const { fetchAPIWithLog } = useLog('useHomeContent');

  const getEarningsForSyms = useCallback(
    async (
      syms: string[],
      startDate = getQueryDate(true),
      endDate = businessDaysAdd(getQueryDate(true), 365),
    ) => {
      if (syms.length === 0) {
        return [];
      }

      const start = startDate.format('YYYY-MM-DD');
      const end = endDate.format('YYYY-MM-DD');
      const encodedSyms = encodeURIComponent(syms.join(','));
      const url = `v1/earnings?syms=${encodedSyms}&start=${start}&end=${end}`;

      const result: any[] = await fetchAPIWithLog(url);
      return result?.map(getEarningsObj) as Earnings[];
    },
    [],
  );

  const getContentForSpecialCategory = useCallback(async (category: string) => {
    const url = `home/contentForCategory?category=${category}`;
    return await fetchAPIWithLog(url);
  }, []);

  const getTooltips = useCallback(async () => {
    return await getContentForSpecialCategory(AdminContentCategory.Tooltips);
  }, []);

  const getKeyLevelsData = useCallback(async (includeGammaCurve?: boolean) => {
    const url = `home/keyLevels?includeGammaCurve=${includeGammaCurve ? 1 : 0}`;
    return await fetchAPIWithLog(url);
  }, []);

  const getAllData = useCallback(async () => {
    return await fetchAPIWithLog(`home/allData`);
  }, []);

  return {
    getEarningsForSyms,
    getContentForSpecialCategory,
    getKeyLevelsData,
    getAllData,
    getTooltips,
  };
};

export default useHomeContent;
