import { useEffect, useMemo, useState } from 'react';
import {
  ProcessingState,
  TraceContourData,
  TraceGreek,
  TraceParams,
} from '../../types';
import {
  fetchRawAPI,
  getContourColorOptions,
  getContourData,
  getTableParquetUrl,
  getTzOffsetMs,
  responseToTable,
} from '../../util';
import { useRecoilValue } from 'recoil';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiScaleRangeState,
  screenWidthWithoutSidebarState,
  timezoneState,
} from '../../states';
import { dayjs } from '../../util/shared/date';
import useWasmParquet from '../../hooks/useWasmParquet';
import useToast from '../../hooks/useToast';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow';
import useLog from '../../hooks/useLog';

type useContourDataProps = {
  timestamp: dayjs.Dayjs | null;
  timestamps: dayjs.Dayjs[];
  getTable: () => any;
  candlesDeps: any[];
  getLastCandle: () => any;
  setTimestamp: (val: dayjs.Dayjs | null) => void;
  setTable: (cb: (oldTable: any) => any, greek: TraceGreek) => void;
  width: number;
  boundsStr: string | null;
  traceParams: TraceParams;
};

export const useContourData = ({
  timestamp,
  timestamps,
  getTable,
  candlesDeps,
  getLastCandle,
  setTimestamp,
  setTable,
  width,
  boundsStr,
  traceParams,
}: useContourDataProps) => {
  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const tz = useRecoilValue(timezoneState);
  const screenWidth = useRecoilValue(screenWidthWithoutSidebarState);
  const selectedScaleRange = useRecoilValue(oiScaleRangeState);

  const [processingState, setProcessingState] = useState<ProcessingState>(
    ProcessingState.LOADING,
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const { getWasmPromise } = useWasmParquet();
  const { openToast } = useToast();
  const { logError, fetchAPIWithLog, nonProdDebugLog } =
    useLog('useContourData');

  const {
    intradaySym,
    intradayDate,
    heatmapColorSettings,
    selectedLense,
    selectedGreek,
  } = traceParams;

  const handleUpdate = (data: any, greek: TraceGreek) => {
    try {
      const arrowTable = readParquet(new Uint8Array(data));
      console.log(arrowTable);
      const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
      console.log(tableAppend);
      const array = tableAppend.toArray();
      if (array.length === 0) {
        nonProdDebugLog(`no items in array returned from polling oi ${greek}`);
        return;
      }
      const lastTS = array[array.length - 1].timestamp;
      const polledLastTs = dayjs.utc(lastTS).tz(tz);
      nonProdDebugLog(
        `last timestamp received from polling oi ${greek}: `,
        polledLastTs,
      );
      // if the last timestamp we've polled and received is later than the current timestamp
      // and the current timestamp is the latest timestamp in the timestamps array
      // set the current timestamp to the last polled timestamp
      if (
        greek === selectedGreek &&
        polledLastTs?.isAfter(timestamp) &&
        timestamp?.isSameOrAfter(timestamps[timestamps.length - 1])
      ) {
        nonProdDebugLog(
          `${greek}: current timestamp below. setting timestamp to polledLastTs`,
          timestamp,
          polledLastTs,
        );
        setTimestamp(polledLastTs);
      }

      setTable((oldTable) => oldTable?.concat(tableAppend), greek);

      nonProdDebugLog(`received polling data for OI ${greek}`);
    } catch (err) {
      logError(err, 'handleLatestResponse', { greek, selectedGreek });
      if (greek === selectedGreek) {
        openToast({
          message: 'There was an error updating the heatmap data.',
          type: 'error',
        });
      }
    }
  };

  const triggerUpdate = async () => {
    if (isUpdating) {
      return;
    }

    setIsUpdating(true);

    try {
      const opts = { buffer: true };
      const [deltaData, gammaData] = await Promise.all([
        fetchAPIWithLog(getPollUrl(TraceGreek.Delta), opts),
        fetchAPIWithLog(getPollUrl(TraceGreek.Gamma), opts),
      ]);

      handleUpdate(deltaData, TraceGreek.Delta);
      handleUpdate(gammaData, TraceGreek.Gamma);
    } catch (err) {
      logError(err, 'triggerUpdate');
    } finally {
      setIsUpdating(false);
    }
  };

  const getPollUrl = (greek: TraceGreek) => {
    const parquetUrl = getTableParquetUrl(greek, intradayDate, intradaySym);
    return `${parquetUrl}&last=1&cb=${dayjs().valueOf()}`;
  };

  const setProcessingStateForSelectedGreek = (
    newState: ProcessingState,
    greek: TraceGreek,
  ) => {
    if (greek !== selectedGreek) {
      return;
    }

    setProcessingState(newState);
  };

  const contourData = useMemo<TraceContourData | null>(() => {
    const table = getTable();
    if (table == null) {
      return null;
    }
    const bounds = boundsStr?.split(',').map((v) => parseFloat(v)) ?? null;
    nonProdDebugLog('using bounds', bounds);
    const data = getContourData(
      table,
      timestamp?.valueOf(),
      parquetKeys,
      invert,
      bounds,
      getTzOffsetMs(tz),
      selectedLense,
      getLastCandle(),
      width,
      nonProdDebugLog,
    );

    if (data == null) {
      return data;
    }

    const colorContourOptions = getContourColorOptions(
      data.chartData?.z,
      heatmapColorSettings,
      selectedLense,
      selectedScaleRange,
    );
    data.chartData = { ...data.chartData, ...colorContourOptions };

    nonProdDebugLog('rendering contour data', data);
    return data;
  }, [
    parquetKeys,
    invert,
    selectedGreek,
    timestamp,
    boundsStr,
    selectedLense,
    selectedScaleRange,
    screenWidth,
    getTable,
    ...candlesDeps,
    heatmapColorSettings,
  ]);

  for (const greek of [TraceGreek.Gamma, TraceGreek.Delta]) {
    const {
      data: table,
      loading,
      error,
    } = useFetchParquetCountourData({
      greek,
      intradayDate,
      intradaySym,
      setProcessingStateForSelectedGreek,
    });
    useEffect(() => {
      setProcessingStateForSelectedGreek(
        error
          ? ProcessingState.FAILED_FETCH
          : loading
            ? ProcessingState.LOADING
            : ProcessingState.DONE,
        greek,
      );
    }, [loading, error]);

    useEffect(() => {
      if (!table) {
        return;
      }
      setTable((_oldTable) => table, greek);
      if (greek === selectedGreek) {
        const array = table.toArray();

        const tsMappedToDate = timestamp
          ?.dayOfYear(intradayDate.dayOfYear())
          ?.month(intradayDate.month())
          ?.year(intradayDate.year());

        if (
          tsMappedToDate == null ||
          !new Set(array.map((e) => e.timestamp.valueOf())).has(
            tsMappedToDate.valueOf(),
          )
        ) {
          const lastTS = array[array.length - 1].timestamp;
          const newTs = dayjs.utc(lastTS).tz(tz);
          setTimestamp(newTs);
        } else if (!tsMappedToDate.isSame(timestamp)) {
          setTimestamp(tsMappedToDate);
        }
      }
    }, [table, selectedGreek, intradayDate]);
  }

  const [minY, maxY] = useMemo(() => {
    const contourStrikes = contourData?.chartData?.y ?? [];
    const min = contourStrikes[0];
    const max = contourStrikes[contourStrikes.length - 1];
    return [min, max];
  }, [contourData]);

  return {
    contourData,
    processingState,
    minY,
    maxY,
    minX: contourData?.firstChartTimestamp,
    maxX: contourData?.lastChartTimestamp,
    triggerUpdate,
  };
};

export const useFetchParquetCountourData = <
  TData = any,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    greek,
    intradayDate,
    intradaySym,
    candleDuration,
    setProcessingStateForSelectedGreek,
  }: {
    greek: TraceGreek;
    intradayDate: dayjs;
    intradaySym: string;
    setProcessingStateForSelectedGreek: any;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) => {
  const { getWasmPromise } = useWasmParquet();
  return useQuery<TData, TError>({
    queryKey: [
      'useFetchParquetContourData',
      { greek, intradayDate, intradaySym },
    ],
    queryFn: async () => {
      const [resp, _wasm] = await Promise.all([
        fetchRawAPI(getTableParquetUrl(greek, intradayDate, intradaySym)),
        getWasmPromise(),
      ]);

      if (resp.status !== 200) {
        console.error('Received status: ' + resp.status);
        throw await resp.json();
      }
      return await responseToTable(resp);
    },
    ...options,
  });
};
