import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Typography,
  Link as MaterialLink,
  Stack,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import { alpha, styled, Theme, useTheme } from '@mui/material/styles';
import { AutoComplete } from './Autocomplete';
import { AlertsDropdown } from './alerts';
import { useLocation } from 'react-router-dom';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useEffect, useMemo, useState } from 'react';
import {
  isBloomberg,
  readableProductType,
  NON_PRODUCT_PAGES,
  isZerohedge,
  showSubscribeButton,
  updateSearch,
  getDefaultSlotProps,
  dayjs,
} from '../../util';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentProductTypeState,
  ehModelTypeState,
  isMobileState,
  isSideDrawerExpandedState,
  synthOISelectedDateState,
  userDashAccessState,
  userDetailsState,
  userIsLoggedInState,
} from '../../states';
import {
  DashAccess,
  EhModelType,
  hasDashAccess,
  ProductType,
} from '../../types';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SGTooltip } from './SGTooltip';
import PageTitle from './PageTitle';
import RefreshIcon from '@mui/icons-material/Refresh';
import useRefresh from '../../hooks/useRefresh';
import { TickersSnapshot } from '../home/TickersSnapshot';
import { UsingProductButton } from './UsingProductButton';
import { Center } from '../shared/Center';
import useAuth from '../../hooks/auth/useAuth';
import UserNotificationsDropdown from '../../pages/components/Notifications/UserNotificationsDropdown';
import { useSearchParams } from 'react-router-dom';
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import useSynthOi from 'hooks/equityhub/useSynthOi';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: '#1E1F23',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
  borderColor: `rgba(255, 255, 255, 0.05)`,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  alignItems: 'center',
}));

export const Header = () => {
  const theme = useTheme();
  const isLoggedIn = useRecoilValue(userIsLoggedInState);
  const isMobile = useRecoilValue(isMobileState);
  const isLgDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const [isMobileDrawerExpanded, setIsMobileDrawerExpanded] = useRecoilState(
    isSideDrawerExpandedState,
  );
  const userDashAccess = useRecoilValue(userDashAccessState);
  const currentProductType = useRecoilValue(currentProductTypeState);
  const userDetails = useRecoilValue(userDetailsState);
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(currentProductType);
  const setEhModelType = useSetRecoilState(ehModelTypeState);
  const [synthOiDate, setSynthOiDate] = useRecoilState(
    synthOISelectedDateState,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const { isSynthOI } = useSynthOi();

  const showSearch = useMemo(() => {
    return new Set<ProductType | null>([
      ProductType.IMPLIED_VOL,
      ProductType.HOME,
      ProductType.HIRO,
      ProductType.EQUITYHUB,
      ProductType.INTERNAL_OPEN_INTEREST,
      ProductType.INTERNAL_RISK_ENGINE,
    ]).has(currentProductType);
  }, [currentProductType]);

  const showSynthOIComponents = useMemo(
    () => currentProductType === ProductType.EQUITYHUB,
    [currentProductType],
  );

  const { triggerRefresh } = useRefresh();

  const location = useLocation();

  const pageHeadertitle: string | null = useMemo(() => {
    return (
      readableProductType(currentProductType) ??
      NON_PRODUCT_PAGES[location.pathname]
    );
  }, [currentProductType, location]);

  if (userDetails == null && showSubscribeButton()) {
    return (
      <Box sx={{ textAlign: 'center', margin: '10px' }}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            textTransform: 'none',
            color: 'black',
            fontSize: isMobile ? '18px' : '24px',
            py: '6px',
            px: '24px',
            margin: 'auto',
          }}
          onClick={() =>
            window.open(
              'https://spotgamma.com/subscribe-to-spotgamma/',
              '_blank',
            )
          }
        >
          Want full access? Subscribe Now
        </Button>
      </Box>
    );
  }

  const search = showSearch && (
    <Search
      sx={{
        marginX: isMobile ? 0 : '10px',
        width: isMobile ? '100%' : '125px',
      }}
    >
      <AutoComplete
        inputStyle={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          width: '100%',
          borderRadius: '10px',
        }}
        optionsStyle={{
          color: theme.palette.text.primary,
          boxShadow: theme.palette.shadows.default,
          backgroundColor: theme.palette.background.paper,
        }}
      />
    </Search>
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelChange = (value: EhModelType) => {
    setSearchParams(updateSearch({ 'eh-model': value }));
    setEhModelType(value);
    handleClose();
  };

  useEffect(() => {
    const date = searchParams.get('date');
    if (date) {
      setSynthOiDate(dayjs(date));
    } else {
      setSearchParams(updateSearch({ date: dayjs().format('YYYY-MM-DD') }));
    }
  }, [searchParams]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setSynthOiDate(date);
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setSearchParams(updateSearch({ date: formattedDate }));
    } else {
      setSearchParams(updateSearch({ date: dayjs().format('YYYY-MM-DD') }));
    }
  };

  const synthOIToggle = showSynthOIComponents && (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        sx={{
          borderRadius: '4px',
          minWidth: '100px',
          fontSize: isMobile ? 11 : 13,
          fontWeight: 500,
          textTransform: 'none',
          px: 1.5,
          py: 0.5,
        }}
      >
        {isSynthOI ? 'Synthetic OI Model' : 'Total OI Model'}
      </Button>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              mt: 1,
              p: 4,
              boxShadow: theme.palette.shadows.default,
              borderRadius: '4px',
              width: { xs: 260, md: 350 },
            }}
          >
            <RadioGroup
              value={isSynthOI ? EhModelType.SynthOI : EhModelType.Legacy}
              defaultValue={EhModelType.SynthOI}
              onChange={(_e, value) => handleModelChange(value as EhModelType)}
              sx={{ gap: '12px' }}
            >
              <FormControlLabel
                value={EhModelType.SynthOI}
                control={<Radio />}
                label={
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, md: 14 },
                        color: isSynthOI ? 'primary.main' : 'text.secondary',
                      }}
                    >
                      Synthetic OI Model
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      This model delivers more precise tracking of short-term
                      options hedging flows by eliminating assumptions and
                      categorizing transactions based on multiple new data
                      feeds.
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                value={EhModelType.Legacy}
                control={<Radio />}
                label={
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, md: 14 },
                        color: isSynthOI ? 'text.secondary' : 'primary.main',
                      }}
                    >
                      Total OI Model
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      SpotGamma's legacy Equity Hub model with years of validity
                      and data supporting its impact. This model assumes that
                      all options are sold by market makers.
                    </Typography>
                  </Stack>
                }
              />
            </RadioGroup>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );

  const defaultSlotProps = getDefaultSlotProps(theme);

  const synthOIDatePicker = isSynthOI && showSynthOIComponents && (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={synthOiDate}
        onChange={handleDateChange}
        minDate={dayjs('2024-09-03')}
        maxDate={dayjs()}
        disableFuture
        shouldDisableDate={(date) => {
          return date.day() === 0 || date.day() === 6; // 0 is Sunday, 6 is Saturday
        }}
        slotProps={{
          textField: {
            size: 'small',
            ...defaultSlotProps.textField,
            style: {
              fontSize: '14px',
            },
          },
          ...defaultSlotProps,
        }}
        format="YYYY-MM-DD"
      />
    </LocalizationProvider>
  );

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        pt: '25px',
        pb: '15px',
        pr: '7px',
        pl: '7px',
        width: '100%',
      }}
    >
      <Stack
        sx={{
          gap: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            onClick={() => setIsMobileDrawerExpanded(!isMobileDrawerExpanded)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        )}

        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {pageHeadertitle && !isMobile && (
            <PageTitle title={pageHeadertitle} />
          )}
          {!isLgDown && synthOIToggle}
          {search}
          {!isLgDown && synthOIDatePicker}
        </Stack>

        <Stack
          sx={{
            gap: 3,
            flexDirection: 'row',
            marginRight: '10px',
            alignItems: 'center',
            flexGrow: 1,
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <Box flexGrow={1} />
          {/* there is also another render of TickersSnapshot below this one, for mobile users */}
          {!isMobile && <TickersSnapshot />}
          {!isMobile && <UsingProductButton />}

          {isBloomberg() && (
            <IconButton
              onClick={triggerRefresh}
              color="primary"
              aria-label="refresh page"
              sx={{
                textTransform: 'none',
                height: '30px',
                width: '30px',
                marginTop: '-4px',
              }}
            >
              <SGTooltip title={'Refresh page'}>
                <RefreshIcon />
              </SGTooltip>
            </IconButton>
          )}
          <SGTooltip title="Chart functionality courtesy of TradingView Lightweight Charts Copyright (с) 2022 TradingView, Inc">
            <ReceiptIcon
              style={{
                fontSize: isZerohedge() ? '14px' : '20px',
                color: theme.palette.gray,
              }}
            />
          </SGTooltip>
          <MaterialLink
            href="https://support.spotgamma.com/hc/en-us"
            target="_blank"
          >
            <SGTooltip title="FAQ">
              <HelpIcon
                style={{ fontSize: '20px', color: theme.palette.gray }}
              />
            </SGTooltip>
          </MaterialLink>
          <MaterialLink href="/admin">
            <SGTooltip title="Admin">
              <AdminPanelSettingsIcon
                style={{
                  fontSize: '20px',
                  color: theme.palette.gray,
                }}
              />
            </SGTooltip>
          </MaterialLink>

          {hasDashAccess(userDashAccess, DashAccess.BETA) && isLoggedIn && (
            <UserNotificationsDropdown>
              <SGTooltip title="Notifications">
                <IconButton>
                  <CampaignIcon
                    style={{
                      fontSize: '20px',
                      color: theme.palette.gray,
                    }}
                  />
                </IconButton>
              </SGTooltip>
            </UserNotificationsDropdown>
          )}

          <AlertsDropdown />
        </Stack>
      </Stack>

      {isLgDown && (
        <Stack
          sx={{
            marginTop: '5px',
            gap: '4px',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {synthOIToggle}
          {synthOIDatePicker}
        </Stack>
      )}

      {isMobile && (
        <Stack sx={{ marginTop: '5px', gap: '4px', alignItems: 'center' }}>
          <TickersSnapshot />
        </Stack>
      )}

      {isMobile && (
        <Center vertical={false}>
          <Stack direction="row" gap="15px" alignItems="center">
            {hasAccess && pageHeadertitle && (
              <PageTitle title={pageHeadertitle} />
            )}
            <UsingProductButton />
          </Stack>
        </Center>
      )}
    </Stack>
  );
};
