import { isAdmin } from '../util';
import { useRecoilValue } from 'recoil';
import { userDetailsState } from '../states';
import { Page } from './shared/Page';
import { AdminContentList } from '../components/admin/AdminContentList';

import 'react-quill/dist/quill.snow.css';
import { HideSupportStyle } from '../components/shared/HideSupportStyle';

export const AdminPage = () => {
  const userDetails = useRecoilValue(userDetailsState);

  if (!isAdmin(userDetails!)) {
    return <></>;
  }

  return (
    <Page>
      {/* <HideSupportStyle /> */}
      <AdminContentList />
    </Page>
  );
};
