import {
  HomeEventsCalendarTab,
  Scanner,
  IndexSymbols,
  QuadrantId,
  Quadrant,
  HomeQuadrantTabId,
  QuadrantTabCategory,
  IndicesContentType,
  QuadrantTab,
} from '../types';
import { getDefaultSymForContent } from '../util/home';
import { getDefaultSymOptions } from '../util/indices';
import { ComboSymbol } from './hiro';
import { CONTENT_TYPE_LABEL_MAP } from './shared';

export const EVENTS_CALENDAR_LABEL_MAPPING = new Map([
  [HomeEventsCalendarTab.MACRO_ECONOMIC_CALENDAR, 'Macro Economic'],
  [HomeEventsCalendarTab.EARNINGS, 'Earnings'],
  [HomeEventsCalendarTab.EARNINGS_CHART, 'Earnings Chart'],
]);

export const TOP_STOCK_SCANNERS = [Scanner.VOL_RISK_PREMIUM, Scanner.SQUEEZE];

export const FUTURES_DEFAULT_SYM_OPTIONS = [
  ComboSymbol.ES_F,
  ...Array.from(IndexSymbols),
];

export const HOME_DEFAULT_QUADRANTS_MAP: Map<QuadrantId, Quadrant> = new Map<
  QuadrantId,
  Quadrant
>([
  [
    QuadrantId.TOP_LEFT,
    {
      tabs: [
        {
          id: HomeQuadrantTabId.LIVE_PRICES_CHART,
          contentId: HomeQuadrantTabId.LIVE_PRICES_CHART,
          label: CONTENT_TYPE_LABEL_MAP.get(
            HomeQuadrantTabId.LIVE_PRICES_CHART,
          )!,
          category: QuadrantTabCategory.CHART,
          sym: getDefaultSymForContent(HomeQuadrantTabId.LIVE_PRICES_CHART),
          symOptions: FUTURES_DEFAULT_SYM_OPTIONS,
        },
        {
          id: IndicesContentType.GAMMA_MODEL,
          contentId: IndicesContentType.GAMMA_MODEL,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_MODEL)!,
          category: QuadrantTabCategory.CHART,
          sym: getDefaultSymForContent(IndicesContentType.GAMMA_MODEL),
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
        },
        {
          id: IndicesContentType.COMBO_STRIKES,
          contentId: IndicesContentType.COMBO_STRIKES,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.COMBO_STRIKES)!,
          category: QuadrantTabCategory.CHART,
          sym: getDefaultSymForContent(IndicesContentType.COMBO_STRIKES),
          symOptions: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
        },
        {
          id: IndicesContentType.GAMMA_LEVELS,
          contentId: IndicesContentType.GAMMA_LEVELS,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_LEVELS)!,
          category: QuadrantTabCategory.CHART,
          sym: getDefaultSymForContent(IndicesContentType.GAMMA_LEVELS),
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
        },
      ],
    },
  ],
  [
    QuadrantId.TOP_RIGHT,
    {
      tabs: [
        {
          id: HomeQuadrantTabId.INDEX_LEVELS,
          contentId: HomeQuadrantTabId.INDEX_LEVELS,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.INDEX_LEVELS)!,
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: HomeQuadrantTabId.SG_LEVELS,
          contentId: HomeQuadrantTabId.SG_LEVELS,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.SG_LEVELS)!,
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: HomeQuadrantTabId.INDEX_METRICS,
          contentId: HomeQuadrantTabId.INDEX_METRICS,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.INDEX_METRICS)!,
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS,
          contentId: HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS,
          label: CONTENT_TYPE_LABEL_MAP.get(
            HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS,
          )!,
          category: QuadrantTabCategory.TABLE,
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_LEFT,
    {
      tabs: [
        {
          id: HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
          contentId: HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
          label: CONTENT_TYPE_LABEL_MAP.get(
            HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
          )!,
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
          contentId: HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
          label: CONTENT_TYPE_LABEL_MAP.get(
            HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
          )!,
          category: QuadrantTabCategory.TABLE,
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_RIGHT,
    {
      tabs: [
        {
          id: HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR,
          contentId: HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR,
          label: CONTENT_TYPE_LABEL_MAP.get(
            HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR,
          )!,
          category: QuadrantTabCategory.LIST,
        },
        {
          id: HomeQuadrantTabId.EARNINGS,
          contentId: HomeQuadrantTabId.EARNINGS,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.EARNINGS)!,
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: HomeQuadrantTabId.EARNINGS_CHART,
          contentId: HomeQuadrantTabId.EARNINGS_CHART,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.EARNINGS_CHART)!,
          category: QuadrantTabCategory.CHART,
        },
        {
          id: HomeQuadrantTabId.AT_SPOTGAMMA,
          contentId: HomeQuadrantTabId.AT_SPOTGAMMA,
          label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.AT_SPOTGAMMA)!,
          category: QuadrantTabCategory.TEXT_CONTENT,
        },
      ],
    },
  ],
]);

export const ALL_HOME_QUADRANT_TABS: QuadrantTab[] = [
  ...[...HOME_DEFAULT_QUADRANTS_MAP.values()].flatMap((q: Quadrant) => q.tabs),
  {
    id: HomeQuadrantTabId.OCC_CHART,
    contentId: HomeQuadrantTabId.OCC_CHART,
    label: CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.OCC_CHART)!,
    category: QuadrantTabCategory.CHART,
  },
  {
    id: HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
    contentId: HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
    label: CONTENT_TYPE_LABEL_MAP.get(
      HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
    )!,
    category: QuadrantTabCategory.CHART,
  },
  {
    id: HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
    contentId: HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
    label: CONTENT_TYPE_LABEL_MAP.get(
      HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
    )!,
    category: QuadrantTabCategory.CHART,
  },
  {
    id: HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
    contentId: HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
    label: CONTENT_TYPE_LABEL_MAP.get(
      HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
    )!,
    category: QuadrantTabCategory.CHART,
  },
  {
    id: HomeQuadrantTabId.VIX_CHART,
    contentId: HomeQuadrantTabId.VIX_CHART,
    label: CONTENT_TYPE_LABEL_MAP.get(
      HomeQuadrantTabId.VIX_CHART,
    )!,
    category: QuadrantTabCategory.CHART,
  },
];

export const EARNINGS_PERIOD_LABEL = new Map([
  ['BMO', 'Pre-market'],
  ['AMC', 'After-hours'],
  ['DMH', 'During market hours'],
]);
