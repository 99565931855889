import * as d3 from 'd3';
import { OIEntity } from '../../types';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Stack, Typography } from '@mui/material';
import { SGTooltip } from '../../components/core';
import { useMemo } from 'react';
import { predicateSearch } from '../../util';
import useLog from '../../hooks/useLog';

type TraceVolSliderProps = {
  statsData: any;
  gammaAtLastPriceUninverted: number | undefined;
  parquetKey: OIEntity;
  width: number;
  height: number;
  fontSize?: number;
  suppress?: boolean;
};

const MEAN_KEY = 'vol_10m_mean';
const BIN_MEAN_KEY = 'mean';
const BIN_MAX_KEY = 'max';
const BIN_MIN_KEY = 'min';

interface IVStats { 
  [MEAN_KEY]: number,
  [BIN_MEAN_KEY]: number,
  [BIN_MAX_KEY]: number,
  [BIN_MIN_KEY]: number,
}

const interpolateBucket = (
  arr: any[],
  idxA: number,
  idxB: number,
  targetGammaMean: number,
) => {
  const fraction =
    (targetGammaMean - arr[idxA][BIN_MEAN_KEY]) /
    (arr[idxB][BIN_MEAN_KEY] - arr[idxA][BIN_MEAN_KEY]);
  return d3.interpolate(arr[idxA][MEAN_KEY], arr[idxB][MEAN_KEY])(fraction);
};

// Ensure our buckets have monotonically increasing 10-minute volatility values
// such that the inverse correlation between gamma and volatility remains consistent 
function normalizeBuckets(arr: IVStats[]) {
  while (!arr.every((e, i) => (i >= arr.length - 1) || e[MEAN_KEY] < arr[i + 1][MEAN_KEY])) {
    for (let i = arr.length - 1; i > 0; --i) {
      if (arr[i][MEAN_KEY] > arr[i - 1][MEAN_KEY]) {
        continue;
      }
      let j = i - 1;
      while (j > 0 && arr[i][MEAN_KEY] <= arr[j - 1][MEAN_KEY]) {
        j = j - 1;
      }
      const cells = arr.slice(j, i + 1);
      arr[i][BIN_MEAN_KEY] = d3.mean(cells.map((c) => c[BIN_MEAN_KEY]))!;
      arr[i][MEAN_KEY] = d3.mean(cells.map((c) => c[MEAN_KEY]))!;
      arr[i][BIN_MAX_KEY] = Math.max(...cells.map((c) => c[BIN_MAX_KEY]))!;
      arr[i][BIN_MIN_KEY] = Math.min(...cells.map((c) => c[BIN_MIN_KEY]))!;
      for (let k = j; k < i; ++k) {
        // @ts-ignore: Allow nulls in our array which we filter out below
        arr[k] = null; // Mark for removal
      }
      i = j; // skip our nulled-out entries
    }
    arr = arr.filter((e) => e != null);
  }
  return arr;
}

export const TraceVolGauge = ({
  statsData,
  gammaAtLastPriceUninverted,
  parquetKey,
  width,
  height,
  fontSize = 12,
  suppress = false,
}: TraceVolSliderProps) => {
  const { nonProdDebugLog } = useLog('TraceVolGauge');

  const meanArr: { mean: number; vol_10m_mean: number }[] | null =
    useMemo(() => {
      const gammaVol = statsData?.['gamma_vol'];
      const volData =
        gammaVol?.['90']?.[`${parquetKey}_gamma`] ??
        gammaVol?.['60']?.[`${parquetKey}_gamma`];
      if (volData == null || volData.length < 1) {
        return null;
      }

      let arr = Object.keys(volData)
        .sort((a, b) => (parseFloat(a) > parseFloat(b) ? 1 : -1))
        .map((key) => ({ ...volData[key] }));

      const maxMean = Math.max(...arr.map((c) => c[MEAN_KEY]));
      const maxBinMean = Math.max(...arr.map((c) => c[BIN_MEAN_KEY]));

      // First find any buckets that don't have strictly increasing vol_10m_mean values and combine the buckets
      arr = normalizeBuckets(arr);

      // Extend our percentiles with one "phantom" linearly interpolated bucket on
      // the low end with the minimum gamma mapped to the linearly interpolated
      // 10m volatility value for that gamma
      const firstBinMin = arr[0][BIN_MIN_KEY];
      const firstVolMean = interpolateBucket(arr, 0, 1, firstBinMin);
      arr.unshift({ mean: firstBinMin, vol_10m_mean: firstVolMean });

      // If we have consolidated the last bucket, add back a "pseudo-maximum" 
      if (arr[length - 1][BIN_MEAN_KEY] != maxBinMean) {
        arr.push({mean: maxBinMean, vol_10m_mean: maxMean});
      }

      nonProdDebugLog('normalized stats array: ', arr);
      return arr;
    }, [statsData]);

  const stabilityPercentile = useMemo(() => {
    if (gammaAtLastPriceUninverted == null || meanArr == null) {
      return null;
    }

    const binMeans = meanArr.map((o) => o[BIN_MEAN_KEY]);
    let idxA = predicateSearch(
      binMeans,
      (s) => s <= gammaAtLastPriceUninverted,
    );
    const idxB = Math.min(idxA + 1, binMeans.length - 1);
    idxA = Math.max(0, idxA);
    const interpolate = d3.interpolate(
      meanArr[idxA][MEAN_KEY],
      meanArr[idxB][MEAN_KEY],
    );

    // If idxA == idxB, the fraction doesn't matter, just make sure we don't divide by zero.
    const fraction = idxA === idxB ? 1 :
      (gammaAtLastPriceUninverted - binMeans[idxA]) / (binMeans[idxB] - binMeans[idxA]);
    const volMean = interpolate(fraction);

    const volMax = meanArr[meanArr.length - 1][MEAN_KEY];
    const volMin = meanArr[0][MEAN_KEY];
    const volPercentile = (volMean - volMin) / (volMax - volMin);
    nonProdDebugLog(
      'stats: using last directional gamma uninverted',
      gammaAtLastPriceUninverted,
    );
    nonProdDebugLog(
      'stats: interpolated vol mean, min, max, stability percentile',
      volMean,
      volMin,
      volMax,
      1 - volPercentile,
    );
    // stability is inverted vol
    return 1 - volPercentile;
  }, [meanArr, gammaAtLastPriceUninverted, parquetKey]);

  if (stabilityPercentile == null) {
    return null;
  }

  const opts = suppress ? { opacity: 0.3 } : {};
  return (
    <SGTooltip title="This is a proprietary, forward-looking metric measuring the likelihood of large movement over the next 60 minutes, with higher values corresponding to a lower likelihood of significant price movement. This is applicable between 9:30am and 3:30pm ET.">
      <Stack
        direction="column"
        alignItems="center"
        height={height + 3}
        sx={{ ...opts, marginY: '-5px' }}
      >
        <Gauge
          width={width}
          height={height}
          value={stabilityPercentile * 100}
          startAngle={-90}
          endAngle={90}
          valueMin={0}
          valueMax={100}
          text={({ value }) => (value != null ? `${Math.round(value)}%` : '')}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: fontSize + 2,
              transform: 'translate(0px, -6px)',
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
            marginTop: '-5px',
            marginBottom: '-2px',
          })}
        />
        <Typography sx={{ fontSize, marginTop: '-3px' }}>Stability</Typography>
      </Stack>
    </SGTooltip>
  );
};
