import dayjs from 'dayjs';
import { useCallback } from 'react';
import useLog from '../useLog';
import { CandlesData } from 'types/shared';

const useRealizedVol = () => {
  const { fetchAPIWithLog } = useLog('useRealizedVol');

  const getDailyCandles = useCallback(async (rawSym: string) => {
    const start = dayjs().subtract(7, 'year').format('YYYY-MM-DD');
    const sym = encodeURIComponent(rawSym);
    return (await fetchAPIWithLog(
      `v1/twelve_series?symbol=${sym}&interval=1day&start_date=${start}&order=ASC`,
    )) as CandlesData;
  }, []);

  return { getDailyCandles };
};

export default useRealizedVol;
