import { Box } from '@mui/material';
import VixChart from 'components/implied_vol/Vix/VixChart';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../states';

export const Vix = () => {
  const isMobile = useRecoilValue(isMobileState);
  return (
    <Box
      sx={{
        width: isMobile ? '100vw' : '800px',
        height: isMobile ? '65vh' : '650px',
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <VixChart includeControlsWidget />
    </Box>
  );
};
