import { IconButton, SxProps, Theme } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { useFullscreen } from 'layouts/ResizableDashboardLayout';
import { SGTooltip } from 'components/core';

interface PanelFoldButtonProps {
  panelType: 'bottom' | 'top';
  sx?: SxProps<Theme>;
  showTooltip?: boolean;
}

const PanelFoldButton = ({
  panelType,
  sx,
  showTooltip = true,
}: PanelFoldButtonProps) => {
  const {
    toggleExpandBottom,
    isBottomCollapsed,
    toggleExpandTop,
    isTopCollapsed,
  } = useFullscreen();

  const isCollapsed =
    panelType === 'bottom' ? isBottomCollapsed : isTopCollapsed;

  const toggleExpand = () => {
    if (panelType === 'bottom') {
      toggleExpandBottom();
    } else {
      toggleExpandTop();
    }
  };

  const button = (
    <IconButton color="primary" onClick={toggleExpand} sx={sx}>
      {isCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
    </IconButton>
  );

  if (!showTooltip) {
    return button;
  }

  return (
    <SGTooltip title={isCollapsed ? 'Expand' : 'Collapse'}>{button}</SGTooltip>
  );
};

export default PanelFoldButton;
