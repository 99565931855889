import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { fetchAPI } from '../util';
import useToast from './useToast';
import { openZendeskModalState, zendeskDataState } from '../states/resources';

const useZendesk = () => {
  const setOpen = useSetRecoilState(openZendeskModalState);
  const setData = useSetRecoilState(zendeskDataState);
  const [isLoading, setIsLoading] = useState(false);

  const { openToast } = useToast();

  const openZendesk = useCallback(async (articleId: string) => {
    const success = await getZendeskArticle(articleId);
    if (!success) {
      return openToast({
        message: 'Error loading support article. Please try again later.',
        type: 'error',
      });
    }

    setOpen(true);
  }, []);

  const getZendeskArticle = useCallback(async (articleId: string) => {
    setIsLoading(true);
    const url = `v1/zendesk_article?id=${articleId}`;
    const data = await fetchAPI(url);

    if (data?.article?.body != null) {
      const { body, title, url } = data?.article;
      setData({ body, title, url });
    }
    setIsLoading(false);

    return data?.article?.body != null;
  }, []);

  return { openZendesk, getZendeskArticle, isLoading };
};

export default useZendesk;
