import useUserDetails from 'hooks/user/useUserDetails';
import { useState, useMemo } from 'react';
import {
  Button,
  IconButton,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CompassSavedView,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisReadableMap,
  StrategyCompassMode,
  StrategyCompassModeXMap,
  StrategyCompassModeYMap,
  StrategyCompassModeZMap,
} from 'types/compass';
import { CompassParams } from 'hooks/scanners/useCompassParams';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { SGSelect } from 'components/shared';
import { isMobileState } from 'states/shared';
import { compassSavedViewsState } from 'states/compass';
import useToast from 'hooks/useToast';
import { SG_DEFAULT_COMPASS_VIEWS } from 'config/compass';

interface StrategyCompassSavedViewsProps {
  compassParams: CompassParams;
}

interface StrategyViewProps {
  view: CompassSavedView;
  compassParams: CompassParams;
  loading?: boolean;
  handleEditClick?: (view: CompassSavedView) => void;
  handleDeleteClick?: (view: CompassSavedView) => void;
  isEditable?: boolean;
  getAxisLabel: (axis?: StrategyCompassXYZAxis) => string;
}

const StrategyView = ({
  view,
  compassParams,
  loading = false,
  handleEditClick,
  handleDeleteClick,
  isEditable = true,
  getAxisLabel,
}: StrategyViewProps) => {
  const theme = useTheme();

  // Check if the current view matches the compass parameters
  const isSelected = useMemo(() => {
    return (
      (!view.xAxis || view.xAxis === compassParams.xAxis) &&
      (!view.yAxis || view.yAxis === compassParams.yAxis) &&
      (!view.zAxis || view.zAxis === compassParams.zAxis)
    );
  }, [view, compassParams.xAxis, compassParams.yAxis, compassParams.zAxis]);

  const handleViewClick = () => {
    if (view.xAxis) {
      compassParams.setXAxis(view.xAxis);
    }
    if (view.yAxis) {
      compassParams.setYAxis(view.yAxis);
    }
    // Always set Z axis, even if it's None
    compassParams.setZAxis(view.zAxis || StrategyCompassXYZAxis.None);
  };

  return (
    <Paper
      elevation={2}
      onClick={handleViewClick}
      sx={{
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        paddingY: '4px',
        paddingX: '12px',
        backgroundColor: isSelected
          ? alpha(theme.palette.primary.main, 0.3)
          : alpha(theme.palette.primary.main, 0.1),
        border: isSelected ? `1px solid ${theme.palette.primary.main}` : 'none',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          backgroundColor: isSelected
            ? alpha(theme.palette.primary.main, 0.4)
            : alpha(theme.palette.primary.main, 0.2),
        },
        '&:active': {
          transform: 'translateY(0)',
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: 2,
        }}
      >
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">{view.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            X: {getAxisLabel(view.xAxis)} | Y: {getAxisLabel(view.yAxis)}{' '}
            {`| Z: ${getAxisLabel(view.zAxis)}`}
          </Typography>
        </Stack>
        {isEditable && handleEditClick && handleDeleteClick && (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(view);
              }}
              disabled={loading}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(view);
              }}
              disabled={loading}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

const StrategyCompassSavedViews = ({
  compassParams,
}: StrategyCompassSavedViewsProps) => {
  const theme = useTheme();
  const { saveSgSettings } = useUserDetails();
  const isMobile = useRecoilValue(isMobileState);

  const { openToast } = useToast();

  const compassSavedViews = useRecoilValue(compassSavedViewsState);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentView, setCurrentView] = useState<CompassSavedView | null>(null);
  const [editName, setEditName] = useState('');
  const [editXAxis, setEditXAxis] = useState<StrategyCompassXYZAxis>(
    StrategyCompassXYZAxis.None,
  );
  const [editYAxis, setEditYAxis] = useState<StrategyCompassXYZAxis>(
    StrategyCompassXYZAxis.None,
  );
  const [editZAxis, setEditZAxis] = useState<StrategyCompassXYZAxis>(
    StrategyCompassXYZAxis.None,
  );

  const [loading, setLoading] = useState(false);

  // Determine which views are currently selected based on matching axes
  // Multiple views can be selected if they have the same axes configuration
  const selectedViewIds = useMemo(() => {
    return compassSavedViews
      .filter(
        (view) =>
          (!view.xAxis || view.xAxis === compassParams.xAxis) &&
          (!view.yAxis || view.yAxis === compassParams.yAxis) &&
          (!view.zAxis || view.zAxis === compassParams.zAxis),
      )
      .map((view) => view.id);
  }, [
    compassSavedViews,
    compassParams.xAxis,
    compassParams.yAxis,
    compassParams.zAxis,
  ]);

  const handleEditClick = (view: CompassSavedView) => {
    setCurrentView(view);
    setEditName(view.name);
    setEditXAxis(view.xAxis || StrategyCompassXYZAxis.None);
    setEditYAxis(view.yAxis || StrategyCompassXYZAxis.None);
    setEditZAxis(view.zAxis || StrategyCompassXYZAxis.None);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (view: CompassSavedView) => {
    setCurrentView(view);
    setDeleteDialogOpen(true);
  };

  const handleEditSave = async () => {
    if (!currentView || !editName.trim()) return;

    const isCurrentlySelected = selectedViewIds.includes(currentView.id);

    const updatedView = {
      ...currentView,
      name: editName.trim(),
      xAxis: editXAxis,
      yAxis: editYAxis,
      zAxis: editZAxis,
    };

    const updatedViews = compassSavedViews.map((view) =>
      view.id === currentView.id ? updatedView : view,
    );

    try {
      setLoading(true);
      await saveSgSettings({ compass: { savedViews: updatedViews } });
      openToast({
        message: `"${updatedView.name}" view updated successfully`,
        type: 'success',
      });

      // If the edited view was selected, update the compass params to match the new axes
      if (isCurrentlySelected) {
        if (updatedView.xAxis) {
          compassParams.setXAxis(updatedView.xAxis);
        }
        if (updatedView.yAxis) {
          compassParams.setYAxis(updatedView.yAxis);
        }
        if (updatedView.zAxis) {
          compassParams.setZAxis(updatedView.zAxis);
        }
      }

      setEditDialogOpen(false);
    } catch (error) {
      console.error('Failed to update view:', error);
      openToast({
        message: `Failed to update "${updatedView.name}" view. Please try again.`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!currentView) return;

    const viewName = currentView.name;
    const updatedViews = compassSavedViews.filter(
      (view) => view.id !== currentView.id,
    );

    try {
      setLoading(true);
      await saveSgSettings({ compass: { savedViews: updatedViews } });
      openToast({
        message: `"${viewName}" view deleted successfully`,
        type: 'success',
      });
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Failed to delete view:', error);
      openToast({
        message: `Failed to delete "${viewName}" view. Please try again.`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getAxisLabel = (axis?: StrategyCompassXYZAxis): string => {
    // Convert enum to readable format
    return axis
      ? StrategyCompassXYZAxisReadableMap.get(axis) ?? axis
      : StrategyCompassXYZAxis.None;
  };

  const selectSx = isMobile
    ? {
        '.MuiSelect-icon': {
          display: 'none !important',
        },
        '.MuiSelect-select': {
          paddingX: '10px !important',
        },
      }
    : undefined;

  return (
    <Stack sx={{ width: '100%', height: '100%', overflowY: 'auto', pt: 2 }}>
      {compassSavedViews.length === 0 &&
      SG_DEFAULT_COMPASS_VIEWS.length === 0 ? (
        <Stack
          sx={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Typography color="text.secondary">No saved views yet</Typography>
        </Stack>
      ) : (
        <Stack spacing={4}>
          {compassSavedViews.length > 0 && (
            <Stack spacing={2}>
              <Typography
                variant="h6"
                sx={{ color: 'text.secondary', fontSize: 14 }}
              >
                My Views
              </Typography>
              {compassSavedViews.map((view) => (
                <StrategyView
                  key={view.id}
                  view={view}
                  compassParams={compassParams}
                  loading={loading}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  isEditable={true}
                  getAxisLabel={getAxisLabel}
                />
              ))}
            </Stack>
          )}

          {SG_DEFAULT_COMPASS_VIEWS.length > 0 && (
            <Stack spacing={2}>
              <Typography
                variant="h6"
                sx={{ color: 'text.secondary', fontSize: 14 }}
              >
                SpotGamma Preset Views
              </Typography>
              {SG_DEFAULT_COMPASS_VIEWS.map((view) => (
                <StrategyView
                  key={view.id}
                  view={view}
                  compassParams={compassParams}
                  isEditable={false}
                  getAxisLabel={getAxisLabel}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit View</DialogTitle>
        <DialogContent>
          <Stack spacing={8} sx={{ mt: 4 }}>
            <TextField
              autoFocus
              label="View Name"
              fullWidth
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              variant="outlined"
              sx={{
                '& fieldset': {
                  borderColor: alpha(theme.palette.primary.main, 0.35),
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              }}
            />

            <Stack sx={{ gap: 1 }}>
              <Typography>View Axes</Typography>
              <Stack gap="8px">
                <SGSelect
                  key="edit-compass-x"
                  label="X Axis"
                  multiple={false}
                  value={editXAxis}
                  setter={setEditXAxis}
                  options={
                    StrategyCompassModeXMap.get(StrategyCompassMode.Freeform)!
                  }
                  optionsTextTransform={(m) =>
                    `${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  valueTextTransform={(m) =>
                    `X: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  sx={selectSx}
                />
                <SGSelect
                  key="edit-compass-y"
                  label="Y Axis"
                  multiple={false}
                  value={editYAxis}
                  setter={setEditYAxis}
                  options={
                    StrategyCompassModeYMap.get(StrategyCompassMode.Freeform)!
                  }
                  optionsTextTransform={(m) =>
                    `${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  valueTextTransform={(m) =>
                    `Y: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  sx={selectSx}
                />
                <SGSelect
                  key="edit-compass-z"
                  label="Z Axis"
                  multiple={false}
                  value={editZAxis}
                  setter={setEditZAxis}
                  options={
                    StrategyCompassModeZMap.get(StrategyCompassMode.Freeform)!
                  }
                  optionsTextTransform={(m) =>
                    `${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  valueTextTransform={(m) =>
                    `Z: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  selectTooltipProps={{
                    title:
                      'The Z axis is displayed as the size of the dot. The larger the dot, the higher the Z, and vice versa.',
                  }}
                  sx={selectSx}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleEditSave}
            disabled={!editName.trim() || loading}
            startIcon={loading ? <CircularProgress size={16} /> : null}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete View</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "{currentView?.name}"? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDelete}
            color="error"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={16} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default StrategyCompassSavedViews;
