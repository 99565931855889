import { Box, useTheme } from '@mui/material';
import { EquityStockScanner } from 'components/stock_scanner/EquityStockScanner';

export const StockScanner = () => {
  const theme = useTheme();

  return (
    <Box
      gap="16px"
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        paddingBottom: '35px',
        padding: 4,
      }}
    >
      <EquityStockScanner />
    </Box>
  );
};
