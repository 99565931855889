import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { timezoneState } from 'states';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import ClearIcon from '@mui/icons-material/Clear';
import { dayjs, getDefaultSlotProps } from 'util/shared';

interface DateTimeSelectorProps {
  value: number | null; // Single timestamp in milliseconds (number)
  onChange: (newValue: number | null) => void;
  label: string;
  min?: number; // Minimum allowed timestamp
  max?: number; // Maximum allowed timestamp
  disabled?: boolean;
}

const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  value,
  onChange,
  label,
  min,
  max,
  disabled,
}) => {
  const theme = useTheme();
  const userTimezone = useRecoilValue(timezoneState);

  // Convert UTC timestamp to local timezone for display
  const toLocalDate = (timestamp: number | null) => {
    if (!timestamp) {
      return null;
    }
    return dayjs(timestamp).tz(userTimezone);
  };

  // Convert local date back to UTC timestamp
  const toUTCTimestamp = (date: dayjs.Dayjs | null) => {
    if (!date || !date.isValid()) {
      return null;
    }
    // First convert to UTC, then get timestamp
    return date.tz('UTC').valueOf();
  };

  const handleDateChange = (newDate: dayjs.Dayjs | null) => {
    onChange(toUTCTimestamp(newDate));
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the picker from opening
    onChange(null);
  };

  const defaultSlotProps = getDefaultSlotProps(theme);

  return (
    <Box sx={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          timezone={userTimezone}
          label={label}
          value={toLocalDate(value)}
          onChange={handleDateChange}
          minDateTime={toLocalDate(min ?? null) ?? undefined}
          maxDateTime={toLocalDate(max ?? null) ?? undefined}
          disabled={disabled}
          slotProps={{
            ...defaultSlotProps,
            textField: {
              ...defaultSlotProps.textField,
              style: {
                width: '100%',
                fontSize: '14px',
              },
              InputProps: {
                ...defaultSlotProps.textField?.InputProps,
                startAdornment:
                  value && !disabled ? (
                    <IconButton
                      onClick={handleClear}
                      size="small"
                      sx={{ mr: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ) : undefined,
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimeSelector;
