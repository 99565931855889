import {
  alpha,
  InputProps,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { FilterValueFormat } from 'types/tape';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { SGTooltip } from 'components/core/SGTooltip';

interface MinMaxFilterProps {
  title: string;
  description?: string;
  minValue: string;
  maxValue: string;
  minPlaceholder: string;
  maxPlaceholder: string;
  onChangeMin: InputProps['onChange'];
  onChangeMax: InputProps['onChange'];
  format?: FilterValueFormat;
  viewOnly?: boolean;
}

const MinMaxFilter = ({
  title,
  description,
  minValue,
  maxValue,
  minPlaceholder,
  maxPlaceholder,
  onChangeMin,
  onChangeMax,
  format,
  viewOnly = false,
}: MinMaxFilterProps) => {
  const theme = useTheme();
  const [localMinValue, setLocalMinValue] = useState(minValue);
  const [localMaxValue, setLocalMaxValue] = useState(maxValue);

  // Track if we're currently editing to prevent prop updates from interrupting user input
  const isEditing = useRef(false);

  const debouncedMinChange = useCallback(
    debounce((value: string) => {
      onChangeMin?.({
        target: { value },
      } as React.ChangeEvent<HTMLInputElement>);
      isEditing.current = false;
    }, 500),
    [onChangeMin],
  );

  const debouncedMaxChange = useCallback(
    debounce((value: string) => {
      onChangeMax?.({
        target: { value },
      } as React.ChangeEvent<HTMLInputElement>);
      isEditing.current = false;
    }, 500),
    [onChangeMax],
  );

  const handleMinChange: InputProps['onChange'] = (event) => {
    const newValue = event.target.value;
    isEditing.current = true;
    setLocalMinValue(newValue);
    debouncedMinChange(newValue);
  };

  const handleMaxChange: InputProps['onChange'] = (event) => {
    const newValue = event.target.value;
    isEditing.current = true;
    setLocalMaxValue(newValue);
    debouncedMaxChange(newValue);
  };

  // Update local state when props change, but only if we're not editing
  useEffect(() => {
    if (!isEditing.current) {
      setLocalMinValue(minValue);
    }
  }, [minValue]);

  useEffect(() => {
    if (!isEditing.current) {
      setLocalMaxValue(maxValue);
    }
  }, [maxValue]);

  // Cleanup debounced handlers on unmount
  useEffect(
    () => () => {
      debouncedMinChange.cancel();
      debouncedMaxChange.cancel();
    },
    [debouncedMinChange, debouncedMaxChange],
  );

  return (
    <Stack
      sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', width: '100%' }}
    >
      <SGTooltip title={description}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: theme.palette.text.secondary,
            fontSize: 11,
            flex: '0 0 60px',
          }}
        >
          {title}
        </Typography>
      </SGTooltip>

      <Stack
        sx={{
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TextFieldComponent
          value={localMinValue}
          placeholder={minPlaceholder}
          onChange={handleMinChange}
          format={format}
          viewOnly={viewOnly}
        />
        <HorizontalRuleIcon sx={{ fontSize: 10 }} />
        <TextFieldComponent
          value={localMaxValue}
          placeholder={maxPlaceholder}
          onChange={handleMaxChange}
          format={format}
          viewOnly={viewOnly}
        />
      </Stack>
    </Stack>
  );
};

interface TextFieldComponentProps {
  value: string;
  placeholder: string;
  format?: FilterValueFormat;
  onChange: InputProps['onChange'];
  viewOnly?: boolean;
}

const TextFieldComponent = ({
  onChange,
  value,
  placeholder,
  format,
  viewOnly = false,
}: TextFieldComponentProps) => {
  const theme = useTheme();

  const getPrefix = () => {
    switch (format) {
      case 'currency':
        return '$';
      default:
        return undefined;
    }
  };

  const getSuffix = () => {
    switch (format) {
      case 'percentage':
        return '%';
      default:
        return undefined;
    }
  };

  if (viewOnly) {
    // Render as Typography if in view-only mode
    return (
      <Typography
        sx={{
          width: '100%',
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
          border: `1px solid ${alpha(theme.palette.sgGreen, 0.85)}`,
          borderRadius: 1,
          padding: '6px 12px',
          fontSize: 12,
          color: theme.palette.text.secondary,
        }}
      >
        {value || placeholder}
      </Typography>
    );
  }

  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      variant="outlined"
      autoComplete="off"
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          prefix: getPrefix(),
          suffix: getSuffix(),
          value,
          format,
        },
        sx: {
          height: 28,
          fontSize: 12,
          // Autofill styles
          '&:-webkit-autofill': {
            backgroundColor: 'transparent !important',
            boxShadow: `0 0 0 1000px ${alpha(
              theme.palette.background.default,
              1,
            )} inset !important`,
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
          },
        },
      }}
      sx={{
        width: '100%',
        '& fieldset': {
          borderColor: alpha(theme.palette.sgGreen, 0.85),
        },
        '&:hover fieldset': {
          borderColor: theme.palette.sgGreen,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.sgGreen,
        },
      }}
    />
  );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string | undefined;
  suffix?: string | undefined;
  format?: FilterValueFormat;
  value: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, prefix, suffix, value, format, ...other } = props;

    // Determine what to display based on the format and value
    const displayValue =
      format === 'percentage' && value
        ? (parseFloat(value) * 100).toString() // Convert raw percentage (0.2) to display as (20)
        : value; // For other formats, use the value as-is

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        value={displayValue} // Display the formatted value
        onValueChange={({ floatValue }) => {
          // Adjust value back to raw percentage if it's a percentage format
          const adjustedValue =
            format === 'percentage' && floatValue != null
              ? (floatValue / 100).toString() // Convert 20 back to 0.2
              : floatValue?.toString(); // Keep other formats as they are

          // Ensure the value does not exceed JavaScript's maximum safe integer
          if (floatValue != null && floatValue > Number.MAX_SAFE_INTEGER) {
            console.warn("Value exceeds JavaScript's maximum safe integer.");
            return; // Avoid triggering onChange if value exceeds the limit
          }

          // Trigger onChange with the adjusted value
          onChange({
            target: {
              name: props.name,
              value: adjustedValue ?? '',
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
      />
    );
  },
);

export default MinMaxFilter;
