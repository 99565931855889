import ReactQuill from 'react-quill';
import { SGHtmlGlobalStyles } from '../shared/SGHtmlContent';
import { Box, GlobalStyles } from '@mui/material';

type SGHtmlEditorProps = {
  html: string;
  onHtmlChange: (html: string) => void;
  includeStyles?: boolean;
};
export const SGHtmlEditor = ({
  html,
  onHtmlChange,
  includeStyles = true,
}: SGHtmlEditorProps) => {
  const modules = {
    toolbar: {
      handlers: {},
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
    },
  };

  const quillStyles = {
    '.ql-toolbar, .quill': {
      backgroundColor: '#fff',
      color: '#000',
    },
    '.ql-editor, .quill': {
      height: '100%',
    },
    '.ql-container': {
      height: 'calc(100% - 47px)',
    },
  };

  return (
    <Box width={1} height={1} className="sg-html-editor">
      {includeStyles && [
        <SGHtmlGlobalStyles />,
        <GlobalStyles styles={quillStyles} />,
      ]}
      <ReactQuill
        theme="snow"
        value={html}
        modules={modules}
        onChange={(newHtml) => onHtmlChange(newHtml)}
      />
    </Box>
  );
};
